import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoggedInGuard} from './guards/logged-in.guard';
import {LoginComponent} from '../newboa/login/views/login/login.component';
import {HomeComponent} from '../newboa/home/views/home/home.component';
import {AppContentComponent} from '../newboa/content/views/app-content/app-content.component';
import {LastConnectionResolver} from '../newboa/users/resolvers/last-connection.resolver';

const routes: Routes = [
    {path: 'login', component: LoginComponent},
    {path: '', pathMatch: 'full', redirectTo: '/boa/home'},
    {
        path: 'boa', canActivate: [LoggedInGuard], component: AppContentComponent, children: [
            {path: 'home', component: HomeComponent, resolve: {
                users: LastConnectionResolver
            }},
            {
                path: 'accounting',
                loadChildren: () => import('../newboa/accounting/accounting-routing.module')
                    .then(m => m.AccountingRoutingModule)
            },
            {
                path: 'consumptions',
                loadChildren: () => import('../newboa/consumptions/consumptions-routing.module')
                    .then(m => m.ConsumptionsRoutingModule)
            },
            {
                path: 'operations',
                loadChildren: () => import('../newboa/operations/operations-routing.module')
                    .then(m => m.OperationsRoutingModule)
            },
            {
                path: 'customers',
                loadChildren: () => import('../newboa/customers/customers-routing.module')
                    .then(m => m.CustomersRoutingModule)
            },
            {
                path: 'users',
                loadChildren: () => import('../newboa/users/users-routing.module')
                    .then(m => m.UsersRoutingModule)
            },

        ]
    },
    {path: '**', redirectTo: '/boa/home'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
