import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../models/user';

@Component({
  selector: 'm-user-last-connection-entry',
  templateUrl: './m-user-last-connection-entry.component.html',
  styleUrls: ['./m-user-last-connection-entry.component.scss']
})
export class MUserLastConnectionEntryComponent implements OnInit {

    @Input()
    public user: User;

    constructor() {
    }

    ngOnInit(): void {
    }
}
