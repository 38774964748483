<label [class._required]="required">
    {{label}}
    <div class="input-group _discount">
        <input class="input-group-field"
               #input [class._delete]="delete"
               [required]="required"
               [attr._aria-describedby]="helpTextID"
               [name]="name"
               [class._disabled]="disabled"
               [disabled]="disabled"
               [placeholder]="placeholder"
               [type]="inputType"
               (blur)="onBlur()"
               [ngModel]="data"
               (ngModelChange)="onChange($event)"
               [min]="min"
               [max]="max">
        <div class="input-group-button" [class.disabled]="disabled">
            <div (click)="onResetClick()" class="discountButton">
                <i class="fas fa-redo-alt"></i>
            </div>

            <span class="reloadMessage">Revenir au taux calculé</span>
        </div>
    </div>
</label>


