import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'a-field',
    templateUrl: './a-field.component.html',
    styleUrls: ['./a-field.component.scss']
})
export class AFieldComponent implements OnInit {

    @Input()
    public label: string;
    @Input()
    public value: string;

    @Input()
    public showLabel = true;

    constructor() {
    }

    ngOnInit(): void {
    }
}
