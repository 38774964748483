<o-editable-block [headerLabel]="customerAccount?.name | boaNotNull :'common.not.provided'|translate"
                  [display]="'display'"
                  (editModeLaunched)="onEditModeLaunched()"
                  [updateLoading]="updateLoading"
                  [creationLoading]="creationLoading"
                  *ngIf="!edit && !creationMode"
                  [styleType]="customerAccount.status"
                  (cancel)="onCancel()">
    <div display>
        <spacer size="16"></spacer>
        <div class="grid-x grid-margin-x">
            <div class="cell phone-12">
                <div class="grid-x grid-margin-x">
                    <a-account-type class="cell shrink" [type]="customerAccount?.type"
                                    [class._empty]="!customerAccount?.type"
                                    [ngClass]="'_' + customerAccount.status.toLowerCase()">
                    </a-account-type>

                    <ng-container *ngIf="customerAccount?.chorusProActivated">
                        <a-chorus-pro-badge></a-chorus-pro-badge>
                    </ng-container>
                </div>
                <spacer size="8"></spacer>
                <a-field [label]="'customers.detail.information.code'|translate">
                    <ng-container value>
                        {{customerAccount.accountingReference | boaNotNull : 'common.not.provided'|translate}}
                    </ng-container>
                </a-field>
                <a-field [label]="'Autre nom'" *ngIf="customerAccount.subtitle">
                    <ng-container value>
                        {{customerAccount.subtitle}}
                    </ng-container>
                </a-field>
                <a-field [label]="'Abréviation'" *ngIf="customerAccount.abbreviation">
                    <ng-container value>
                        {{customerAccount.abbreviation}}
                    </ng-container>
                </a-field>
                <a-field [label]="'customers.detail.information.siret'|translate">
                    <ng-container value>
                        {{customerAccount?.siretNumber | boaNotNull :'common.not.provided'|translate}}
                    </ng-container>
                </a-field>

                <spacer size="40"></spacer>

                <div class="grid-x align-center">
                    <m-user-info-token-balance class="cell shrink"
                                               [ngClass]="'_' + customerAccount.status.toLowerCase()"
                                               [tokenBalance]="customerAccount?.globalTokenBalance"
                                               [label]="('customers.detail.information.token.balance' | translate) + ' ' + ('CUSTOMER' | translate | lowercase)"></m-user-info-token-balance>
                </div>
            </div>
            <div class="cell phone-12 grid-y">
                <m-customer-addresses class="cell shrink"
                                      [contacts]="customerAccount.contacts">
                </m-customer-addresses>
                <spacer size="16"></spacer>
                <m-customer-billing-contact class="cell shrink"
                                            [customerAccount]="customerAccount"
                                            [ngClass]="'_' + customerAccount.status.toLowerCase()">
                </m-customer-billing-contact>
            </div>
        </div>
    </div>
</o-editable-block>

<o-customer-account-creation *ngIf="creationMode"
                             (create)="onCreate()"
                             [customerAccount]="customerAccountBuffer">
</o-customer-account-creation>

<o-customer-account-information-edit *ngIf="edit"
                                     (save)="onSave()"
                                     (cancel)="onCancel()"
                                     [customerAccount]="customerAccountBuffer">
</o-customer-account-information-edit>
