import {Component, OnInit} from '@angular/core';
import {HomeStats} from '../../../../statistics/models/home-stats';
import {StatisticsService} from '../../../../statistics/services/statistics.service';

@Component({
    selector: 'o-home-stats',
    templateUrl: './o-home-stats.component.html',
    styleUrls: ['./o-home-stats.component.scss']
})
export class OHomeStatsComponent implements OnInit {

    generalStatistics: HomeStats;

    constructor(private statisticsService: StatisticsService) {}

    ngOnInit(): void {
        this.statisticsService.computeGeneralStatistics()
            .subscribe(data => this.generalStatistics = data);
    }

}
