import {Component, Input, OnInit} from '@angular/core';
import {MenuItem} from '../../../models/menu-item';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {LogoutService} from '../../../services/logout.service';

@Component({
    selector: 'o-menu',
    templateUrl: './o-menu.component.html',
    styleUrls: ['./o-menu.component.scss'],
    host: {class: 'cell auto grid-y align-justify align-middle'}
})
export class OMenuComponent implements OnInit {

    @Input()
    public menus: Array<MenuItem>;

    constructor(private router: Router, private logoutService: LogoutService) {
        router.events.pipe(
            filter(event => event instanceof NavigationStart))
            .subscribe((event: NavigationStart) => {
                this.updateActiveMenu(event.url);
            });
    }

    private updateActiveMenu(url: string): void {
        if (this.menus) {
            this.menus.forEach(menu => menu.active = url.indexOf(menu.target) > -1);
        }
    }

    ngOnInit(): void {
        this.updateActiveMenu(this.router.url);
    }

    disconnect(): void {
        this.logoutService.logout();
    }
}
