import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
    selector: 'o-empty-list',
    templateUrl: './o-empty-list.component.html',
    styleUrls: ['./o-empty-list.component.scss']
})
export class OEmptyListComponent implements OnInit {

    @HostBinding('attr.class') class = 'grid-y align-middle align-center';

    @Input()
    noResultTitle: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
