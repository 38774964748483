import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Credit} from '../models/invoices/credit';
import {Observable} from 'rxjs/internal/Observable';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';

@Injectable()
export class CreditService {

    private baseUrl = 'api/invoice/credit';

    constructor(private http: HttpClient) { }

    public getCreditById(creditId: string): Observable<Credit> {
        return this.http.get(`${this.baseUrl}/${creditId}`)
            .pipe(map(el => plainToClass(Credit, el as object)));
    }

    public createCredit(credit: Credit): Observable<Credit> {
        return this.http.post(`${this.baseUrl}`, credit)
            .pipe(map(el => plainToClass(Credit, el as object)));
    }

    public getLinkedCredits(invoiceId: string): Observable<Credit[]> {
        return this.http.get(`${this.baseUrl}/${invoiceId}/credits`)
            .pipe(
                map(el => plainToClass(Credit, el as object[]))
            );
    }

    public getNextCreditNoteReference(): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/billingNum`);
    }
}
