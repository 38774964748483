<a-select
    [itemList]="monthsList"
    [(ngModel)]="month"
    (ngModelChange)="onChange($event)"
    (blur)="onNgTouched()"
    [bindLabel]="'label'"
    [bindValue]="'month'"
    [label]="label"
    [placeholder]="'month.select.placeholder'|translate"
    class="cell phone-12 desktop-13 medium-desktop-10"
    [name]="name"
></a-select>
