import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BoaPositiveValidatorDirective} from './boa-positive-validator.directive';
import { BoaMaxDirective } from './boa-max.directive';
import { BoaNotZeroValidatorDirective } from './boa-not-zero-validator.directive';
import { BoaMinDirective } from './boa-min.directive';
import { ModalDirective } from '../components/atoms/a-popup-component/modal.directive';

@NgModule({
    declarations: [
        BoaPositiveValidatorDirective,
        BoaMaxDirective,
        BoaNotZeroValidatorDirective,
        BoaMinDirective
    ],
    exports: [
        BoaPositiveValidatorDirective,
        BoaMaxDirective,
        BoaNotZeroValidatorDirective,
        BoaMinDirective
    ],
    imports: [
        CommonModule
    ]
})
export class DirectivesModule { }
