import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'm-customers-type-select',
  templateUrl: './m-customers-type-select.component.html',
  styleUrls: ['./m-customers-type-select.component.scss']
})
export class MCustomersTypeSelectComponent implements OnInit {
    public prospectQuery: boolean;
    public activeQuery: boolean;

    public openClass = false;

    @Input()
    public set status(value: Array<string>) {
        this.prospectQuery = value.includes('PROSPECT');
        this.activeQuery = value.includes('ACTIVE');
    }

    @Output()
    public statusChange: EventEmitter<Array<string>> = new EventEmitter();

    constructor() { }

    ngOnInit(): void {
    }

    onModelChange(): void {
        const roles = [
            this.takeIf('PROSPECT', this.prospectQuery),
            this.takeIf('ACTIVE', this.activeQuery),
        ].filter(el => el != null);
        this.statusChange.emit(roles);
    }

    private takeIf(value: any, cond: boolean): any | null {
        return cond ? value : null;
    }

    toggleOpen(): void {
        this.openClass = !this.openClass;
    }
}
