import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {areLinesReadonly, BillingDocument} from '../../../models/invoices/billing-document';
import {ArticleType} from '../../../models/article';
import {InvoiceLine} from '../../../models/invoices/invoice-line';
import {LineEdit} from '../../../models/invoices/line-edit';

@Component({
    selector: 'o-invoice-token-articles',
    templateUrl: './o-invoice-token-articles.component.html',
    styleUrls: ['./o-invoice-token-articles.component.scss']
})
export class OInvoiceTokenArticlesComponent implements OnInit {

    @Input()
    billingDocument: BillingDocument;

    @Input()
    vatRate: number;

    @Input()
    tokenGrossTotal: number;

    @Input()
    discountTotal: number;

    @Output()
    lineEdited = new EventEmitter<LineEdit>();

    @Output()
    lineDeleted = new EventEmitter<number>();

    @Output()
    lineAdded = new EventEmitter<InvoiceLine>();

    @Output()
    discountPercentageChange = new EventEmitter<number>();

    @Output()
    private resetClick = new EventEmitter<void>();

    @Input()
    discountPercentage: number;

    @Input()
    trustValues = false;

    @Output()
    dirty = new EventEmitter<void>();

    articleTypes = ArticleType;
    tokenLines: InvoiceLine[] = [];

    @HostBinding('attr.class') class = 'grid-x grid-margin-x align-stretch';

    constructor() {
    }

    ngOnInit(): void {
    }

    onLineEdited(invoiceLine: LineEdit): void {
        this.lineEdited.emit(invoiceLine);
    }

    onLineAdded(invoiceLine: InvoiceLine): void {
        this.lineAdded.emit(invoiceLine);
    }

    onLineDeleted(invoiceLineId: number): void {
        this.lineDeleted.emit(invoiceLineId);
    }

    onDiscountPercentageChange(discountPercentage: number): void {
        this.discountPercentage = discountPercentage;
        this.discountPercentageChange.emit(discountPercentage);
    }

    onResetClick(): void {
        this.resetClick.emit();
    }

    areLinesReadonly(): boolean {
        return areLinesReadonly(this.billingDocument);
    }
}
