import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import {CustomerAccount} from '../models/customer-account';
import {CustomerAccountService} from '../services/customer-account.service';
import {Result} from '../../common/models/shared/result';

@Injectable()
export class CustomerAccountsResolver implements Resolve<Result<CustomerAccount>> {

    private limit = 50;
    private page = 0;

    constructor(private customerAccountService: CustomerAccountService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Result<CustomerAccount>> {
        return this.customerAccountService.getPaginatedCustomerAccounts(
            this.page,
            this.limit,
            this.blankOrValue(route.queryParamMap.get('search')),
            route.queryParamMap.getAll('status'),
            route.queryParamMap.getAll('type'),
            route.queryParamMap.get('sort'));
    }

    blankOrValue(value: string | null): string {
        return value == null ? '' : value;
    }
}
