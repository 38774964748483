import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
    name: 'boaDate'
})
export class BoaDatePipe extends DatePipe implements PipeTransform {

    public transform(value: any, ...args: any[]): any {
        return super.transform(value, 'dd/MM/yyyy');
    }
}

@Pipe({
    name: 'boaDateTime'
})
export class BoaDateTimePipe extends DatePipe implements PipeTransform {

    public transform(value: any, ...args: any[]): any {
        return super.transform(value, `dd/MM/yyyy ${(args[0] ? args[0] : '')} HH\'h\'mm`);
    }
}

@Pipe({
    name: 'boaTime'
})
export class BoaTimePipe extends DatePipe implements PipeTransform {

    public transform(value: any, ...args: any[]): any {
        return super.transform(value, 'HH\'h\'mm');
    }
}
