import {AnimatorType, User} from './user';
import {CustomerAccount} from '../../customers/models/customer-account';
import {Team} from './team';

export class Player extends User {

    teamRef: string;
    team: Team;
    teamNumber: number;
    leader: boolean;


    constructor(typeId: string, teamRef: string, team: Team, teamNumber: number, leader: boolean) {
        super(typeId);
        this.teamRef = teamRef;
        this.team = team;
        this.teamNumber = teamNumber;
        this.leader = leader;
    }

    public getTokens(): number {
        return null;
    }

    public getCustomerAccount(): CustomerAccount {
        return null;
    }

    public getAnimatorType(): AnimatorType {
        return null;
    }
}
