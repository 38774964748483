<o-editable-block (cancel)="onCancel()" (editModeLaunched)="onEditModeLaunched()" (save)="onSaveClick()"
                  [updateLoading]="updateLoading"
                  [headerLabel]="(user.role + (user.animatorType === 'DEMONSTRATION' ? '.DEMONSTRATION' : '')) | translate"
                  [styleType]="user.role === 'ANIMATOR' ? user.animatorType : user.role">

    <ng-container button *ngIf="showTransferTokens">
        <a-dashboard-button-icon class="cell shrink show-for-small hide-for-big-desktop"
                                 [visibility]="'LOW'" [title]="'user.manager.information.action' | translate"
                                 (click)="transferTokens()">
            <i class="fas fa-exchange-alt"></i>
        </a-dashboard-button-icon>

        <a-button-default class="cell shrink hide-for-small show-for-big-desktop"
                          (click)="transferTokens()">
            <i class="fas fa-exchange-alt"></i>{{'user.manager.information.action' | translate}}
        </a-button-default>
    </ng-container>
    <div class="personalInformation" display>
        <span class="personalInformation-name">
                    {{(user.person?.firstName + ' ' + user.person?.lastName) | translate}}
        </span>

        <a-field [label]="'user.detail.username' | translate">
            <i class="fas fa-user"></i>
            <ng-container value>
                {{user?.login}}
            </ng-container>
        </a-field>

        <a-field [label]="'user.detail.mail' | translate">
            <i class="fas fa-envelope"></i>
            <ng-container value>
                {{user.person?.contact?.mail | boaNotNull : 'common.not.provided'|translate}}
            </ng-container>
        </a-field>
        <a-field [label]="'user.detail.mail.optional' | translate">
            <i class="fas fa-envelope"></i>
            <ng-container value>
                {{user.person?.contact?.mailOptional | boaNotNull : 'common.not.provided'|translate}}
            </ng-container>
        </a-field>
        <spacer size="24"></spacer>
        <a-field [label]="'user.detail.phone.fix' | translate">
            <i class="fas fa-phone-alt"></i>
            <ng-container value>
                {{user.person?.contact.fixPhone | boaNotNull : 'common.not.provided'|translate}}
            </ng-container>
        </a-field>

        <a-field [label]="'user.detail.phone.mobile' | translate">
            <i class="fas fa-mobile"></i>
            <ng-container value>
                {{user.person?.contact.mobilePhone | boaNotNull : 'common.not.provided'|translate}}
            </ng-container>
        </a-field>

        <spacer size="24"></spacer>

        <div class="grid-y align-center align-middle">
            <m-user-info-token-balance *ngIf="(user.role == 'ANIMATOR' && user.animatorType !== 'DEMONSTRATION') || user.role == 'ACCOUNT_MANAGER'"
                                       class="cell shrink"
                                       [ngClass]="'_' + (user.role === 'ANIMATOR' ? user.animatorType?.toLowerCase() : user.role?.toLowerCase())"
                                       [tokenBalance]="user.tokenBalance"
                                       [label]="('user.detail.token.amount' | translate) + ' ' + (user.role | translate)">
            </m-user-info-token-balance>
        </div>
    </div>
    <div class="grid-x grid-margin-x grid-margin-y" edit>
        <a-input [name]="'username'" [inputType]="'text'" [(ngModel)]="userBuffer.login"
                 [label]="'user.detail.username'|translate"
                 [disabled]="true" class="cell phone-24"></a-input>
        <a-input [name]="'firstname'" [inputType]="'text'" [(ngModel)]="userBuffer.person.firstName"
                 [label]="'user.detail.firstname'|translate"
                 [disabled]="false" class="cell phone-12"></a-input>
        <a-input [name]="'lastname'" [inputType]="'text'" [(ngModel)]="userBuffer.person.lastName"
                 [label]="'user.detail.lastname' | translate"
                 [disabled]="false" class="cell phone-12"></a-input>

        <a-input [name]="'mail'" [inputType]="'text'" [(ngModel)]="userBuffer.person.contact.mail"
                 [label]="'user.detail.mail'|translate"
                 [disabled]="false" class="cell phone-12"></a-input>

        <a-input [name]="'secondmail'" [inputType]="'text'" [(ngModel)]="userBuffer.person.contact.mailOptional"
                 [label]="'user.detail.mail.optional'|translate"
                 [disabled]="false" class="cell phone-12"></a-input>

        <a-input [name]="'fix'" [inputType]="'text'" [(ngModel)]="userBuffer.person.contact.fixPhone"
                 [label]="'user.detail.phone.fix'|translate"
                 [disabled]="false" class="cell phone-12"></a-input>
        <a-input [name]="'mobile'" [inputType]="'text'" [(ngModel)]="userBuffer.person.contact.mobilePhone"
                 [label]="'user.detail.phone.mobile'|translate"
                 [disabled]="false" class="cell phone-12"></a-input>
        <a-select *ngIf="user.role === role.ANIMATOR && $any(user).animatorType === animatorType.DEMONSTRATION"
                  [ngModel]="userBuffer.customerAccount.id"
                  [bindLabel]="'label'"
                  [bindValue]="'id'"
                  (ngModelChange)="onCustomerAccountChange($event)"
                  [itemList]="customerAccounts"
                  [name]="'demo-customer'"
                  [label]="'user.detail.demo.customer.select' | translate"
                  class="cell phone-12"></a-select>
    </div>
</o-editable-block>
