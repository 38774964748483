import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'a-indicator',
    templateUrl: './a-indicator.component.html',
    styleUrls: ['./a-indicator.component.scss']
})
export class AIndicatorComponent implements OnInit {

    @Input()
    value: string;

    @Input()
    title: string;

    @Input()
    imgSrc: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
