import {AfterContentInit, Component, HostBinding, OnInit} from '@angular/core';
import {TypeList} from '../../../../shared/models/type-list';
import {TypeEntry} from '../../../../shared/models/type-entry';
import {TableHeader} from '../../../../shared/models/table-header';
import {Consumption} from '../../models/consumption';
import {ActivatedRoute, Router} from '@angular/router';
import {Result} from '../../../common/models/shared/result';
import {ConsumptionsService} from '../../service/consumptions.service';
import {skip, tap} from 'rxjs/operators';
import {Scenarii} from '../../../common/models/scenario/scenarii';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {DownloadService} from '../../../common/services/download.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'boa-consumption-list',
    templateUrl: './consumption-list.component.html',
    styleUrls: ['./consumption-list.component.scss']
})
export class ConsumptionListComponent implements OnInit, AfterContentInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    private readonly TOTAL_KEY = 'common.lists.results.number';

    countsMap = new Map([[this.TOTAL_KEY, 0]]);
    public aggregations: Map<string, number> = new Map();
    page = 0;
    limit = 50;
    searchQuery = '';
    scenarioNames: TypeList;
    tableHeaders = [];
    consumptions: Array<Consumption>;
    fromDate: Date;
    toDate: Date;
    customerAccountId: string;
    animatorId: string;
    private sort: string;
    wholePageLoader: boolean;
    private searchSubscription: Subscription | null = null;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private datePipe: DatePipe,
                private consumptionsService: ConsumptionsService,
                private translateService: TranslateService,
                private readonly downloadService: DownloadService) { }

    ngOnInit(): void {
        this.route.data.subscribe((data: {consumptions: Result<Consumption>, scenarioNames: Array<string>}) => {
            this.consumptions = data.consumptions.data;
            this.aggregations = data.consumptions.aggregations;
            this.countsMap = this.countsMap.set(this.TOTAL_KEY, data.consumptions.totalCount);
            this.scenarioNames = new TypeList(
                Object.keys(Scenarii).map(scenarioName => new TypeEntry(scenarioName, Scenarii[scenarioName], false))
            );
        });

        this.translateService.get([
            'consumption.list.date',
            'consumption.list.operator',
            'consumption.list.source.account',
            'consumption.list.amount',
            'consumption.list.session',
            'consumption.list.scenario',
            'consumption.list.period',
            'consumption.list.team.count',
            'consumption.list.customer.name',
            'consumption.list.aggregation.amount'
        ]).subscribe(translations =>
            this.tableHeaders = [
                new TableHeader(translations['consumption.list.date'], true, 'CONSUMPTION_DATE', null),
                new TableHeader(translations['consumption.list.operator'], true, 'SOURCE_ANIMATOR_LOGIN', null),
                new TableHeader(translations['consumption.list.source.account'], true, 'ANIMATOR_LOGIN', null),
                new TableHeader(translations['consumption.list.amount'], true, 'AMOUNT', 'tokenColumn', false, null,
                    translations['consumption.list.aggregation.amount'] + ' : '),
                new TableHeader(translations['consumption.list.session'], true, 'SESSION_NAME', null),
                new TableHeader(translations['consumption.list.scenario'], false, 'SCENARIO_NAME', null),
                new TableHeader(translations['consumption.list.period'], false, 'SIMULATION_PERIOD', null),
                new TableHeader('',     false, 'SIMULATION_PERIOD', null),
                new TableHeader(translations['consumption.list.team.count'], true, 'TEAM_COUNT', null),
                new TableHeader(translations['consumption.list.customer.name'], false, 'CUSTOMER_ACCOUNT_REF', null)]);
        this.tableHeaders[3].tooltipData = this.aggregations.get('amount');
    }

    ngAfterContentInit(): void {
        this.route.queryParamMap.pipe(
            tap(queryParam => this.searchQuery = queryParam.has('search') ? queryParam.get('search') : ''),
            tap(queryParam => {
                this.sort = queryParam.get('sort');
                const value = this.sort?.split(':')[0];
                const direction = this.sort?.split(':')[1];
                this.tableHeaders = this.tableHeaders.map(el => {
                    if (el.value === value) {
                        el.sortActive = true;
                        el.sortDirection = direction as 'ASC' | 'DESC' | null;
                    }
                    return el;
                });
            }),
            tap(queryParam => this.scenarioNames.setSelected(queryParam.getAll('scenarioNames'))),
            tap(queryParam => this.customerAccountId = queryParam.get('customerAccountId')),
            tap(queryParam => this.animatorId = queryParam.get('animatorId')),
            tap(queryParam => {
                const fromDateQuery = queryParam.get('fromDate');
                fromDateQuery ? this.fromDate = new Date(fromDateQuery) : this.fromDate = null;
            }),
            tap(queryParam => {
                const toDateQuery = queryParam.get('toDate');
                toDateQuery ? this.toDate = new Date(toDateQuery) : this.toDate = null;
            }),
        ).subscribe(_ => this.search());
    }

    loadNext(): void {
        this.page++;
        this.consumptionsService.find(this.page,
            this.limit,
            this.searchQuery,
            this.sort,
            this.scenarioNames.getSelectedValues(),
            this.customerAccountId,
            this.animatorId,
            this.fromDate ? this.fromDate.toISOString() : null,
            this.toDate ? this.toDate.toISOString() : null)
            .subscribe(data => {
                this.consumptions = this.consumptions.concat(data.data);
            });
    }


    search(): void {
        this.page = 0;
        this.wholePageLoader = true;

        if (this.searchSubscription != null) {
            this.searchSubscription.unsubscribe();
        }

        this.searchSubscription = this.consumptionsService.find(
            this.page,
            this.limit,
            this.searchQuery,
            this.sort,
            this.scenarioNames.getSelectedValues(),
            this.customerAccountId,
            this.animatorId,
            this.fromDate ? this.fromDate.toISOString() : null,
            this.toDate ? this.toDate.toISOString() : null)
            .subscribe(data => {
                this.wholePageLoader = false;
                this.consumptions = data.data;
                this.countsMap = this.countsMap.set(this.TOTAL_KEY, data.totalCount);
                this.aggregations = data.aggregations;
                this.tableHeaders[3].tooltipData = this.aggregations.get('amount');
            });
    }

    downloadCsv(): void {
        this.downloadService.downloadFileWithParams(
            this.consumptionsService.getCsvLink(),
            `consommations-${this.datePipe.transform(Date.now(), 'dd/MM/yy')}`,
            'csv',
            {
                page: null,
                limit: null,
                sort: this.sort,
                search: this.searchQuery,
                scenarioNames: this.scenarioNames.getSelectedValues(),
                customerAccountId: this.customerAccountId,
                animatorId: this.animatorId,
                fromDate: this.fromDate ? this.fromDate.toISOString() : null,
                toDate: this.toDate ? this.toDate.toISOString() : null
            }
        );
    }}
