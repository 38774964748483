<m-card-header [label]="'consumption.stats.title'| translate"></m-card-header>

<spacer size="16"></spacer>

<a-info-message>
    {{'consumption.stats.message'| translate}}
</a-info-message>

<spacer size="16"></spacer>

<div class="grid-x grid-margin-x grid-margin-y phone-up-1 medium-desktop-up-2">
    <a-big-indicator class="cell">
        <i icon class="fas fa-coins"></i>
        <span label>{{'consumption.stats.delivered.tokens.year'|translate}}</span>
        <span value>{{generalStats?.operationStats?.deliveredTokensThisYear | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-coins"></i>
        <span label>{{'consumption.stats.delivered.tokens.month'|translate}}</span>
        <span value>{{generalStats?.operationStats?.deliveredTokensThisMonth | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="consumption.stats.scenario.most.played.tooltip">
        <i icon class="fas fa-gamepad"></i>
        <span label>{{'consumption.stats.scenario.most.played' | translate}}</span>
        <span value>{{
            generalStats?.consumptionStats?.mostPlayedScenarioLastYear?.name ?
                ('scenario.' + generalStats?.consumptionStats?.mostPlayedScenarioLastYear?.name | translate )
                : 'common.not.applicable' | translate
            }}</span>
        <span tokenInformation #content>{{generalStats?.consumptionStats?.mostPlayedScenarioLastYear?.count | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="consumption.stats.scenario.most.profitable.tooltip">
        <i icon class="fas fa-gamepad"></i>
        <span label>{{'consumption.stats.scenario.most.profitable' | translate}}</span>
        <span tokenInformation #content>{{generalStats?.consumptionStats?.mostProfitableScenarioLastYear?.count | number}}</span>
        <span value>{{
            generalStats?.consumptionStats.mostProfitableScenarioLastYear?.name ?
                ('scenario.' + generalStats?.consumptionStats.mostProfitableScenarioLastYear?.name | translate)
                : 'common.not.applicable' | translate
            }}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-user"></i>
        <span label>{{'consumption.stats.sessions.creation.count'|translate}}</span>
        <span value>{{generalStats?.consumptionStats?.sessionCreationCount | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-user"></i>
        <span label>{{'consumption.stats.sessions.simulation.count'|translate}}</span>
        <span value>{{generalStats?.consumptionStats?.sessionSimulationCount | number}}</span>
    </a-big-indicator>
</div>
