import {ToastrModule} from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgModule} from '@angular/core';
import {CapitalizeFirstPipe} from './pipes/capitalize.pipe';
import {OMenuComponent} from './components/organisms/o-menu/o-menu.component';
import {OContentBlockComponent} from './components/organisms/o-content-block/o-content-block.component';
import {SpacerComponent} from './components/spacer/spacer.component';
import {ButtonsModule} from './components/atoms/buttons/buttons.module';
import {RouterModule} from '@angular/router';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {OFiltersComponent} from './components/organisms/o-filters/o-filters.component';
import {AFormsModule} from './components/atoms/forms/a-forms.module';
import {MTotalResultsComponent} from './components/molecules/m-total-results/m-total-results.component';
import {MIconComponent} from './components/molecules/m-icon/m-icon.component';
import {MTypeSelectComponent} from './components/molecules/m-type-select/m-type-select.component';
import {MPreviewButtonComponent} from './components/molecules/m-download-button/m-preview-button.component';
import {MSearchQueryComponent} from './components/molecules/m-search-query/m-search-query.component';
import {MTypeSelectQueryComponent} from './components/molecules/m-type-select-query/m-type-select-query.component';
import {OTableComponent} from './components/organisms/o-table/o-table.component';
import {MEntryQueryComponent} from './components/molecules/m-sortable/m-entry-query.component';
import {OEmptyTableComponent} from './components/organisms/o-empty-table/o-empty-table.component';
import {TranslateModule} from '@ngx-translate/core';
import {OEditableBlockComponent} from './components/organisms/o-editable-block/o-editable-block.component';
import {MCardHeaderComponent} from './components/molecules/m-card-header/m-card-header.component';
import {OBlockComponent} from './components/organisms/o-block/o-block.component';
import {AFieldComponent} from './components/atoms/a-field/a-field.component';
import {BoaDatePipe, BoaDateTimePipe, BoaTimePipe} from './pipes/boa-date.pipe';
import {MChoiceButtonsComponent} from './components/molecules/m-choice-buttons/m-choice-buttons.component';
import {MDatePickerQueryComponent} from './components/molecules/m-date-picker-query/m-date-picker-query.component';
import {OEmptyListComponent} from './components/organisms/o-empty-list/o-empty-list.component';
import {BoaNotNullPipe} from './pipes/not-null.pipe';
import {ODoubleDatepickerComponent} from './components/organisms/o-double-datepicker/o-double-datepicker.component';
import {BoaPeriodPipe} from './pipes/boa-period.pipe';
import {MInputTooltipComponent} from './components/molecules/m-input-tooltip/m-input-tooltip.component';
import {AIndicatorComponent} from './components/atoms/a-indicator/a-indicator.component';
import {MInvoicesListComponent} from './components/molecules/m-invoices-list/m-invoices-list.component';
import {AInvoiceComponent} from './components/atoms/a-invoice/a-invoice.component';
import {AInfoMessageComponent} from './components/atoms/a-info-message/a-info-message.component';
import {ABigIndicatorComponent} from './components/atoms/a-big-indicator/a-big-indicator.component';
import {ABadgeComponent} from './components/atoms/a-badge/a-badge.component';
import {NotificationService} from './services/notification.service';
import {AInputUploadComponent} from './components/atoms/a-input-upload/a-input-upload.component';
import {BoaBytesPipe} from './pipes/boa-bytes.pipe';
import {ABoaBigLoaderComponent} from './components/atoms/a-boa-big-loader/a-boa-big-loader.component';
import {AAccountTypeComponent} from './components/atoms/a-account-type/a-account-type.component';
import {LogoutService} from './services/logout.service';
import {ACustomerLinkComponent} from './components/atoms/a-customer-link/a-customer-link.component';
import { APopupComponent } from './components/atoms/a-popup-component/a-popup.component';
import {ModalDirective} from './components/atoms/a-popup-component/modal.directive';

const components = [
  ABadgeComponent,
  AInputUploadComponent,
  AFieldComponent,
  AInvoiceComponent,
  AAccountTypeComponent,
  MCardHeaderComponent,
    MSearchQueryComponent,
    MChoiceButtonsComponent,
    MDatePickerQueryComponent,
    MPreviewButtonComponent,
    MEntryQueryComponent,
    MIconComponent,
    MSearchQueryComponent,
    MTotalResultsComponent,
    MTypeSelectComponent,
    MTypeSelectQueryComponent,
    MInvoicesListComponent,
    OBlockComponent,
    OContentBlockComponent,
    ODoubleDatepickerComponent,
    OEditableBlockComponent,
    OEmptyListComponent,
    OEmptyTableComponent,
    OFiltersComponent,
    OMenuComponent,
    OTableComponent,
    SpacerComponent,
    ACustomerLinkComponent,
    APopupComponent
];

const pipes = [BoaDatePipe, BoaDateTimePipe, BoaTimePipe, CapitalizeFirstPipe, BoaNotNullPipe, BoaBytesPipe];

@NgModule({
    declarations: [
        components,
        pipes,
        BoaPeriodPipe,
        AInvoiceComponent,
        MInputTooltipComponent,
        AIndicatorComponent,
        MInvoicesListComponent,
        AInfoMessageComponent,
        ABigIndicatorComponent,
        ABoaBigLoaderComponent,
        AAccountTypeComponent,
        ModalDirective
    ],
    exports: [
        components,
        pipes,
        AFormsModule,
        ButtonsModule,
        InfiniteScrollModule,
        BoaPeriodPipe,
        MInputTooltipComponent,
        AIndicatorComponent,
        ABigIndicatorComponent,
        AInvoiceComponent,
        MInvoicesListComponent,
        AInfoMessageComponent,
        AInputUploadComponent,
        ABoaBigLoaderComponent,
    ],
    imports: [
        AFormsModule,
        ButtonsModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        NgSelectModule,
        RouterModule,
        ToastrModule.forRoot({timeOut: 5000, positionClass: 'toast-bottom-center'}),
        TranslateModule
    ],
     providers: [
         NotificationService,
         LogoutService
     ]
})
export class SharedModule {
}
