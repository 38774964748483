<div class="cell shrink grid-x align-middle align-justify">
    <h1 class="cell auto">{{'accounting.dashboard.title' | translate}}</h1>
    <div class="cell shrink grid-x grid-margin-x">
        <a-split-button class="cell shrink" [loading]="creationButtonLoading" [visibility]="'HIGH'" (listButtonClicked)="onListButtonClicked($event)" (clicked)="goToQuoteCreationPage()" [buttonsList]="[
            {
                label: 'Nouvelle facture',
                icon: 'fa-plus'
            }
        ]"><i class="fas fa-plus"></i> Nouveau devis</a-split-button>
    </div>
</div>

<spacer size="24"></spacer>

<div class="cell shrink grid-x">
    <div class="cell phone-24 desktop-8 grid-x align-stretch grid-margin-y accountingDashboard-left">
        <o-accounting-stats class="cell phone-24"></o-accounting-stats>
        <m-accounting-export class="cell phone-16 desktop-24"></m-accounting-export>
        <a class="cell phone-8 desktop-24" routerLink="articles" [queryParams]="defaultTypes">
            <m-articles-link></m-articles-link>
        </a>
    </div>
    <div class="cell phone-24 desktop-auto grid-y">
        <o-accounting-lists class="cell shrink"
                            [listTitle]="'accounting.dashboard.quotes.in.progress.sent.title' | translate"
                            [type]="billingType.QUOTE"
                            [status]="[quoteStatus.IN_PROGRESS]"
                            [illustrationId]="1"
                            [emptyLabel]="'accounting.dashboard.quotes.in.progress.empty.message'| translate">
        </o-accounting-lists>
        <spacer size="16"></spacer>
        <o-accounting-lists class="cell shrink"
                            [listTitle]="'accounting.dashboard.invoice.sent.title' | translate"
                            [type]="billingType.INVOICE"
                            [status]="[invoiceStatus.CUSTOMER_SENT, invoiceStatus.PARTLY_PAID, invoiceStatus.UNPAID]"
                            [illustrationId]="2"
                            [emptyLabel]="'accounting.dashboard.invoice.sent.empty.message' | translate">
        </o-accounting-lists>
        <spacer size="16"></spacer>
        <o-accounting-lists class="cell shrink"
                            [listTitle]="'accounting.dashboard.invoice.to.deliver.title' | translate"
                            [type]="billingType.INVOICE"
                            [status]="[invoiceStatus.PAID]"
                            [isDelivered]="false"
                            [illustrationId]="4"
                            [emptyLabel]="'accounting.dashboard.invoice.to.deliver.empty.message' | translate">
        </o-accounting-lists>
        <spacer size="16"></spacer>
        <o-accounting-lists class="cell shrink"
                            [listTitle]="'accounting.dashboard.invoice.late.title' | translate"
                            [type]="billingType.INVOICE"
                            [status]="[invoiceStatus.OVERDUE]"
                            [illustrationId]="3"
                            [emptyLabel]="'accounting.dashboard.invoice.late.empty.message' | translate">
        </o-accounting-lists>
    </div>
</div>

<spacer size="24"></spacer>

