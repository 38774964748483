import {Injectable} from '@angular/core';
import {BillingDocument, BillingDocumentType, ExtendedBillingDocument} from '../models/invoices/billing-document';
import {Invoice, InvoiceStatus} from '../models/invoices/invoice';
import {Observable} from 'rxjs/internal/Observable';
import {Quote, QuoteStatus} from '../models/invoices/quote';
import {QuotesService} from './quotes.service';
import {HttpClient} from '@angular/common/http';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';
import {Result} from '../../common/models/shared/result';
import {SelectEntry} from '../../common/models/select/select-entry';
import {Attachment} from '../../attachments/models/attachment';
import {InvoiceHttpService} from './invoice-http.service';

@Injectable()
export class BillingDocumentService {

    private baseUrl = '/api/invoice/billingDocs';

    constructor(private readonly invoiceService: InvoiceHttpService,
                private quoteService: QuotesService,
                private http: HttpClient) {
    }

    getById(id: string): Observable<BillingDocument> {
        return this.http.get(`${this.baseUrl}/${id}`).pipe(
            map((el: BillingDocument) => plainToClass(this.getBillingDocType(el), el))
        );
    }

    create(billingDocument: BillingDocument): Observable<BillingDocument> {
        if (billingDocument instanceof Invoice) {
            return this.invoiceService.create(billingDocument);
        }
        if (billingDocument instanceof Quote) {
            return this.quoteService.create(billingDocument);
        }
    }

    findExtended(
        search: string,
        lastModificationDate: Date,
        billingTypes: Array<BillingDocumentType | 'ALL'>,
        billingStatus: Array<InvoiceStatus | QuoteStatus | 'ALL'>,
        limit: number,
        offset: number,
        sort: string = '',
        customerAccountId: string,
        isDelivered: boolean
    ): Observable<Result<ExtendedBillingDocument>> {
        if (billingTypes && billingTypes.includes('ALL')) {
            billingTypes = Object.keys(BillingDocumentType).map(el => BillingDocumentType[el]);
            billingStatus = null;
        } else if (billingTypes && billingTypes.includes(BillingDocumentType.INVOICE) && billingStatus && billingStatus.includes('ALL')) {
            billingStatus = Object.keys(InvoiceStatus).map(el => InvoiceStatus[el]);
        } else if (billingTypes && billingTypes.includes(BillingDocumentType.QUOTE) && billingStatus && billingStatus.includes('ALL')) {
            billingStatus = Object.keys(QuoteStatus).map(el => InvoiceStatus[el]);
        }

        return this.http.get(`${this.baseUrl}/extended`, {
            params: {
                search,
                billingTypes,
                lastModificationDate: lastModificationDate?.toISOString(),
                billingStatus: billingStatus?.join(','),
                limit,
                offset,
                sort,
                customerAccountId,
                isDelivered
            },
            observe: 'response'
        })
            .pipe(
                map(billings => {
                        return new Result(
                            (billings.body as Array<ExtendedBillingDocument>).map(el => plainToClass(this.getBillingDocType(el), el)),
                            parseInt(billings.headers.get('X_TOTAL_COUNT'), 10),
                            new Map()
                        );
                    }
                ));
    }

    findMap(
        search: string,
        lastModificationDate: Date,
        billingTypes: Array<BillingDocumentType | 'ALL'>,
        billingStatus: Array<InvoiceStatus | QuoteStatus | 'ALL'>,
        limit: number,
        offset: number,
        sort: string = '',
        customerAccountId: string,
        responseType: string = 'code_map'
    ): Observable<SelectEntry[]> {

        if (billingTypes && billingTypes.includes('ALL')) {
            billingTypes = Object.keys(BillingDocumentType).map(el => BillingDocumentType[el]);
            billingStatus = null;
        } else if (billingTypes && billingTypes.includes(BillingDocumentType.INVOICE) && billingStatus && billingStatus.includes('ALL')) {
            billingStatus = Object.keys(InvoiceStatus).map(el => InvoiceStatus[el]);
        } else if (billingTypes && billingTypes.includes(BillingDocumentType.QUOTE) && billingStatus && billingStatus.includes('ALL')) {
            billingStatus = Object.keys(QuoteStatus).map(el => InvoiceStatus[el]);
        }

        return this.http.get(`${this.baseUrl}`, {
            params: {
                search,
                billingTypes,
                lastModificationDate: lastModificationDate?.toISOString(),
                billingStatus: billingStatus?.join(','),
                limit,
                offset,
                sort,
                customerAccountId,
                responseType
            }
        })
            .pipe(
                map(billings => plainToClass(SelectEntry, billings as object[]))
            );
    }

    find(search: string,
         fromDate: Date,
         toDate: Date,
         billingTypes: Array<BillingDocumentType | 'ALL'>,
         billingStatus: Array<InvoiceStatus | QuoteStatus | 'ALL'>,
         limit: number,
         offset: number,
         sort: string = '',
         customerAccountId: string,
         isDelivered: boolean = null): Observable<Result<BillingDocument>> {
        if (billingTypes && billingTypes.includes('ALL')) {
            billingTypes = Object.keys(BillingDocumentType).map(el => BillingDocumentType[el]);
            billingStatus = null;
        } else if (billingTypes && billingTypes.includes(BillingDocumentType.INVOICE) && billingStatus && billingStatus.includes('ALL')) {
            billingStatus = Object.keys(InvoiceStatus).map(el => InvoiceStatus[el]);
        } else if (billingTypes && billingTypes.includes(BillingDocumentType.QUOTE) && billingStatus && billingStatus.includes('ALL')) {
            billingStatus = Object.keys(QuoteStatus).map(el => InvoiceStatus[el]);
        }

        return this.http.get(`${this.baseUrl}`, {
            params: {
                search,
                billingTypes,
                fromDate: fromDate?.toISOString(),
                toDate: toDate?.toISOString(),
                billingStatus: billingStatus?.join(','),
                limit,
                offset,
                sort,
                customerAccountId,
                isDelivered
            },
            observe: 'response'
        })
            .pipe(
                map(billings => {
                        return new Result(
                            (billings.body as Array<BillingDocument>).map(el => plainToClass(this.getBillingDocType(el), el)),
                            parseInt(billings.headers.get('X_TOTAL_COUNT'), 10),
                            new Map()
                        );
                    }
                ));
    }

    private getBillingDocType(billingDoc: BillingDocument): any {
        if (billingDoc.billingDocumentType === BillingDocumentType.INVOICE) {
            return Invoice;
        }
        if (billingDoc.billingDocumentType === BillingDocumentType.QUOTE) {
            return Quote;
        }
    }

    getCurrentBillingNo(billingDocument: BillingDocument): Observable<string> {
        if (billingDocument instanceof Quote) {
            return this.quoteService.getCurrentBillingNo();
        }
        if (billingDocument instanceof Invoice) {
            return this.invoiceService.getCurrentBillingNo();
        }
    }

    getCsvLink(): string {
        return `${this.baseUrl}/csv`;
    }

    uploadLinkedDocument(file: File, invoiceId: string): Observable<void> {
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<void>(`${this.baseUrl}/${invoiceId}/linkedDocuments`, formData);
    }

    getLinkedDocuments(invoiceId: string): Observable<Array<Attachment>> {
        return this.http.get<Array<Attachment>>(`${this.baseUrl}/${invoiceId}/linkedDocuments`)
            .pipe(map(attachments => plainToClass(Attachment, attachments as object[])));
    }
}
