<h2 class="headerTitle">{{'invoice.token.customers.title' | translate}}</h2>

<spacer size="16"></spacer>

<div *ngFor="let customerAccountForTokenDelivery of tokenDeliveryCustomersTarget; let i = index">
    <a-invoice-token-customer-line [customerAccountForTokenDelivery]="customerAccountForTokenDelivery"
                                   [availableCustomersByName]="availableCustomersByName"
                                   [availableCustomersByAccountingReference]="availableCustomersByAccountingReference"
                                   (deleted)="onDelete(i)"
                                   (updated)="onUpdate(i, $event)"
                                   [disabled]="disabled">
    </a-invoice-token-customer-line>
</div>

<div class="grid-x align-middle align-justify newTokenCustomer" *ngIf="!disabled">
    <div class="cell auto grid-x grid-margin-x">
        <a-select class="cell phone-12 big-desktop-24"
                  [label]="'invoice.customer.name' | translate"
                  [itemList]="availableCustomersByName"
                  [bindValue]="'value'"
                  [name]="'customer-name'"
                  [(ngModel)]="customerIdBuffer"></a-select>

        <a-select class="cell phone-12 big-desktop-24"
                  [label]="'invoice.customer.accounting.reference' | translate"
                  [itemList]="availableCustomersByAccountingReference"
                  [bindValue]="'value'"
                  [name]="'customer-code'"
                  [(ngModel)]="customerIdBuffer"></a-select>
    </div>

    <a-button-icon class="cell shrink newTokenCustomer-add" (click)="addCustomer()" [disabled]="!customerIdBuffer">
        <i class="fas fa-plus"></i>
    </a-button-icon>
</div>
