import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Player} from '../../../models/player';
import {TeamService} from '../../../services/team.service';
import {GenericSession} from '../../../../genericSessions/models/generic-session';
import {switchMap, tap} from 'rxjs/operators';
import {Scenarii} from '../../../../common/models/scenario/scenarii';
import {Navigation} from '@angular/router';
import {NavigationService} from '../../../../common/services/navigation.service';

@Component({
    selector: 'o-player-session',
    templateUrl: './o-player-session.component.html',
    styleUrls: ['./o-player-session.component.scss']
})
export class OPlayerSessionComponent implements OnInit {

    @Input()
    playerId: string;

    player: Player;
    session: GenericSession;
    scenario: Scenarii;

    constructor(private userService: UserService,
                private teamService: TeamService,
                public navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.userService.getPlayer(this.playerId)
            .pipe(
                tap(player => this.player = player),
                switchMap(_ => this.teamService.getSessionFromTeam(this.player.teamRef))
            )
            .subscribe(session => {
                this.session = session;
            });
    }
}
