export enum Scenarii {
    KREAFIRM = 'kreafirm',
    KALYPSO = 'kalypso_quarterly,kalypso_monthly,kalypso',
    KEYFLASH = 'keyflash',
    KIWI = 'kiwi',
    KEESHIRT = 'keeshirt',
    SUBAKOUA = 'subakoua',
    BILBOKET = 'bilboket,bilboket_lamp',
    KOLUMPI = 'kolumpi'
}

export const ScenariiValues: Array<Scenarii> = [
    Scenarii.KREAFIRM,
    Scenarii.KALYPSO,
    Scenarii.KEYFLASH,
    Scenarii.KIWI,
    Scenarii.KEESHIRT,
    Scenarii.BILBOKET,
    Scenarii.KOLUMPI,
    Scenarii.SUBAKOUA
];
