<div m-icon [type]="user.role" class="connectionIcon"></div>

<div class="connectionInfos">
    <h4 class="connectionInfos-name" [ngClass]="'_' + user.role.toLowerCase()">{{user.role | translate}}</h4>
    <span class="connectionInfos-item">
        <span class="connectionInfos-item-label">{{'user.last.connection.username' | translate}}</span>
        {{user.login}}
    </span>
    <span class="connectionInfos-item">
        <span class="connectionInfos-item-label">{{'user.last.connection.date' | translate}}</span>
            {{user.lastLoginDate | boaDateTime : ('common.at' | translate)}}
    </span>
</div>
