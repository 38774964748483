<div class="article" *ngIf="!isEdit">
    <div class="grid-x align-middle align-justify articleHeader">
        <div class="cell auto grid-x align-middle">
            <div class="cell shrink">
                    <span class="articleHeader-icon"
                          [ngClass]="'_' + article?.type.toLowerCase()">
                    </span>
            </div>
            <div class="cell auto">
                <h2 class="articleHeader-title">{{this.article?.title}}</h2>

                <div class="grid-x grid-margin-x tokensDetails" *ngIf="isSession">
                    <div class="cell shrink tokensDetails-item">
                        <span class="tokensDetails-item-value">{{ creationTokenCost }}</span>
                        <span
                            class="tokensDetails-item-label">{{ 'accounting.article.session.creation.tokens' | translate }}</span>
                    </div>
                    <div class="cell shrink tokensDetails-item">
                        <span class="tokensDetails-item-value">{{ periodTokenCost }}</span>
                        <span
                            class="tokensDetails-item-label">{{ 'accounting.article.session.simulation.tokens' | translate }}</span>
                    </div>
                </div>
            </div>

        </div>

        <div class="cell shrink grid-x grid-margin-x align-center">
            <a-button-icon class="cell shrink" (click)="articleDeleted()" [visibility]="'LOW'">
                <i class="fas fa-trash-alt"></i>
            </a-button-icon>
            <a-button-icon class="cell shrink" (click)="editMode()">
                <i class="fas fa-pencil-alt"></i>
            </a-button-icon>
        </div>
    </div>

    <p *ngIf="article.description && article.description != ''" class="articleDescription">
        {{article?.description}}
    </p>

    <div class="grid-x align-justify">
        <span class="articlePrice cell auto">{{article?.unitaryCost | currency : 'EUR': 'symbol': '1.0-2':'fr-FR' }}</span>
        <span class="operationType cell auto">
            {{'article.operation.type.' + article?.operationType | translate}}
        </span>
    </div>
</div>

<m-article-edit *ngIf="isEdit"
                [article]="article"
                [loading]="loading"
                (articleSaved)="articleSaved($event)"
                (canceled)="onCancel()"></m-article-edit>
