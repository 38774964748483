<div class="cell shrink grid-x align-middle align-justify">
    <h1 class="cell auto">{{'user.list.title' | translate}}</h1>
    <a-button-default (click)="downloadCsv()">
        <i class="fas fa-download"></i>
        {{'common.action.export' | translate}}
    </a-button-default>
</div>

<spacer size="16"></spacer>

<o-filters class="cell shrink" [countsMap]="countsMap">
    <m-search-query
        [disabled]="usersLoading"
        class="cell phone-12"
        [(ngModel)]="searchQuery"
        [placeholder]="'user.list.search.placeholder' | translate"></m-search-query>

    <m-type-select-query [(types)]="roles" queryName="roles" [disabled]="usersLoading"></m-type-select-query>
</o-filters>

<spacer size="24"></spacer>

<div class="cell auto grid-y" *ngIf="!wholePageLoader">
    <o-table class="_fixedColumns" [headers]="headers" (scroll)="onScroll()" [loading]="usersLoading" [isEmpty]="users.length == 0">
        <tr *ngFor="let user of users">
            <td m-icon [type]="user.role === 'ANIMATOR' ? user.animatorType : user.role"></td>
            <td><a [ngClass]="'_' + (user.role === 'ANIMATOR' ? user.animatorType?.toLowerCase() : user.role?.toLowerCase())"
                   [routerLink]="user.id">{{user.login ? user.login : "Aucun login"}}</a></td>
            <td>{{user.person.contact.mail}}</td>
            <td>{{user.person.firstName | titlecase}}</td>
            <td>{{user.person.lastName | titlecase}}</td>
            <td>
                <a-customer-link [customerAccount]="user.customerAccount" *ngIf="user.customerAccount !== null else noCustomer">
                </a-customer-link>
                <ng-template #noCustomer>
                    {{'common.not.provided' | translate}}
                </ng-template>
            </td>
            <td class="text-right">
                <span class="_tableNumber" *ngIf="user.role !== roleEnum.PLAYER else noToken">
                    {{user.tokenBalance | number}}
                </span>
                <ng-template #noToken>
                    {{'common.not.applicable' | translate}}
                </ng-template>
            </td>
            <td>{{user.lastLoginDate | boaDateTime | boaNotNull : 'common.never-connected'}}</td>
        </tr>
        <tr *ngIf="users.length === 0"
            [columnNumber]="headers.length"
            o-empty-table [emptyLabel]="'user.list.table.empty.title' | translate">
            {{'user.list.table.empty.message' | translate}}
        </tr>
    </o-table>
</div>

<a-boa-big-loader *ngIf="wholePageLoader"></a-boa-big-loader>
