import {AccountManager} from '../../users/models/account-manager';
import {CustomerAccountContact} from '../../users/models/contact';
import {CustomerStatus} from './customer-status';
import {classToPlain, plainToClass, Type} from 'class-transformer';
import {CustomerAccountTypes} from './customer-account-types';
import {AddressType} from '../../common/models/address/addressType';
import {Periodicity} from '../../common/models/scenario/periodicity';
import {DEFAULT_VAT_RATE_VALUE, VatRate} from '../../accounting/models/invoices/vat-rate';

class RemoteScenario {
     name: string;
     targetUrl: string;
     targetUrlApi: string;
     type: string;
     creationTokenCost: number;
     unitDecisionTokenCost: number;
     metaScenarioRef: string;
     complexityLevel: number;
     startingMonth: number;
     periodicity: Periodicity;
     defaultScenario: boolean;


    constructor(name: string,
                targetUrl: string,
                targetUrlApi: string,
                type: string,
                creationTokenCost: number,
                unitDecisionTokenCost: number,
                metaScenarioRef: string,
                complexityLevel: number,
                startingMonth: number,
                periodicity: Periodicity,
                defaultScenario: boolean) {
        this.name = name;
        this.targetUrl = targetUrl;
        this.targetUrlApi = targetUrlApi;
        this.type = type;
        this.creationTokenCost = creationTokenCost;
        this.unitDecisionTokenCost = unitDecisionTokenCost;
        this.metaScenarioRef = metaScenarioRef;
        this.complexityLevel = complexityLevel;
        this.startingMonth = startingMonth;
        this.periodicity = periodicity;
        this.defaultScenario = defaultScenario;
    }
}

export class CustomerAccount {
    id = undefined;
    status: CustomerStatus = CustomerStatus.PROSPECT;
    locked = false;
    name = '';
    accountingReference = '';
    siretNumber = '';
    type: CustomerAccountTypes = undefined;
    sirenNumber = '';
    vatNumber = '';
    tokenBalance = '';
    globalTokenBalance = 0;
    @Type(() => CustomerAccountContact)
    contacts = [CustomerAccountContact.empty(AddressType.BILLING), CustomerAccountContact.empty(AddressType.DELIVERY)];
    accountManager: AccountManager = undefined;
    subtitle = '';
    abbreviation = '';
    vatRatio = DEFAULT_VAT_RATE_VALUE;
    accessibleScenarii = [];
    chorusProActivated = false;

    constructor(init?: Partial<CustomerAccount>) {
        Object.assign(this, init);
    }

    static from(customerAccount: CustomerAccount): CustomerAccount {
        return plainToClass(CustomerAccount, classToPlain(customerAccount));
    }

    static empty(): CustomerAccount {
        return new CustomerAccount();
    }

    getContact(addressType: AddressType): CustomerAccountContact {
        return this.contacts.find(contact => contact.address.type === addressType);
    }
}
