import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'o-double-datepicker',
  templateUrl: './o-double-datepicker.component.html',
  styleUrls: ['./o-double-datepicker.component.scss']
})
export class ODoubleDatepickerComponent implements OnInit {

    @Input()
    private queryName: string;

    @Input()
    from: Date;

    @Input()
    to: Date;

    @Output()
    fromChange = new EventEmitter<Date>();

    @Output()
    toChange = new EventEmitter<Date>();

    constructor() {
    }

    ngOnInit(): void {
    }
}
