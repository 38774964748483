import {AfterContentInit, Component, HostBinding, OnInit} from '@angular/core';
import {LinkedDocumentInformation, Operation} from '../../models/operation';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {OperationsService} from '../../services/operations.service';
import {Result} from '../../../common/models/shared/result';
import {TableHeader} from '../../../../shared/models/table-header';
import {tap} from 'rxjs/operators';
import {TypeList} from '../../../../shared/models/type-list';
import {TypeEntry} from '../../../../shared/models/type-entry';
import {OperationQueryFilter} from '../../models/operation-query-filter';
import {BillingDocumentType} from '../../../accounting/models/invoices/billing-document';
import {DownloadService} from '../../../common/services/download.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'boa-operation-list',
    templateUrl: './operation-list.component.html',
    styleUrls: ['./operation-list.component.scss']
})
export class OperationListComponent implements OnInit, AfterContentInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    private readonly TOTAL_KEY = 'common.lists.results.number';

    countsMap = new Map([[this.TOTAL_KEY, 0]]);
    aggregations: Map<string, number> = new Map();

    page = 0;
    limit = 50;
    searchQuery = '';
    tableHeaders = [];
    operations: Array<Operation>;
    gift: boolean;
    fromDate: Date;
    toDate: Date;

    private sort: string;
    csvSrcLink: string;
    csvFilename: string;
    operationQueryFilter: TypeList;
    private accountId: string;
    private searchSubscription: Subscription | null = null;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private datePipe: DatePipe,
                private downloadService: DownloadService,
                private operationsService: OperationsService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: { operations: Result<Operation>, operationQueryFilter: Array<OperationQueryFilter>}) => {
            this.operations = data.operations.data;
            this.aggregations = data.operations.aggregations;
            this.countsMap = this.countsMap.set(this.TOTAL_KEY, data.operations.totalCount);
            this.operationQueryFilter = new TypeList([
                new TypeEntry('operation.destination.' + OperationQueryFilter.ARKHE_TO_ACCOUNT_MANAGER,
                    OperationQueryFilter.ARKHE_TO_ACCOUNT_MANAGER, false),
                new TypeEntry('operation.destination.' + OperationQueryFilter.ACCOUNT_MANAGER_TO_ARKHE,
                    OperationQueryFilter.ACCOUNT_MANAGER_TO_ARKHE, false),
                new TypeEntry('operation.destination.' + OperationQueryFilter.ACCOUNT_MANAGER_TO_ANIMATOR,
                    OperationQueryFilter.ACCOUNT_MANAGER_TO_ANIMATOR, false),
                new TypeEntry('operation.destination.' + OperationQueryFilter.ANIMATOR_TO_ACCOUNT_MANAGER,
                    OperationQueryFilter.ANIMATOR_TO_ACCOUNT_MANAGER, false),
            ]);
        });

        this.csvFilename = `listeOperations--${this.datePipe.transform(Date.now(), 'dd/MM/yy')}`;
        this.csvSrcLink = this.operationsService.getCsvLink();

        this.translateService.get([
            'operation.list.date',
            'operation.list.label',
            'operation.list.source',
            'operation.list.target',
            'operation.list.amount',
            'operation.list.customer.name',
            'operation.list.token.aggregation'
        ]).subscribe(translations =>
            this.tableHeaders = [
                new TableHeader(translations['operation.list.date'], true, 'OPERATION_DATE', 'operationDateColumn'),
                new TableHeader(translations['operation.list.label'], true, 'LABEL', 'operationColumn'),
                new TableHeader(translations['operation.list.source'], false, 'SOURCE', 'operationSourceColumn'),
                new TableHeader(translations['operation.list.target'], false, 'TARGET', 'operationTargetColumn'),
                new TableHeader(translations['operation.list.amount'], true, 'AMOUNT', 'tokenColumn', false, null,
                    translations['operation.list.token.aggregation'] + ' : '),
                new TableHeader(translations['operation.list.customer.name'], false, 'CUSTOMER_ACCOUNT_REF', 'customerColumn'),
                new TableHeader('', false, '', ''),
                new TableHeader('', false, '', '')]);
        this.tableHeaders[4].tooltipData = this.aggregations.get('amount');
    }

    ngAfterContentInit(): void {
        this.route.queryParamMap.pipe(
            tap(queryParam => this.searchQuery = queryParam.has('search') ? queryParam.get('search') : ''),
            tap(queryParam => {
                this.sort = queryParam.get('sort');
                const value = this.sort?.split(':')[0];
                const direction = this.sort?.split(':')[1];
                this.tableHeaders = this.tableHeaders.map(el => {
                    if (el.value === value) {
                        el.sortActive = true;
                        el.sortDirection = direction as 'ASC' | 'DESC' | null;
                    }
                    return el;
                });
            }),
            tap(queryParam => this.operationQueryFilter.setSelected(queryParam.getAll('operationQueryFilter'))),
            tap(queryParam => {
                const giftQuery = queryParam.get('gift');
                giftQuery ? this.gift = giftQuery === 'true' : this.gift = null;
            }), tap(queryParam => {
                const fromDateQuery = queryParam.get('fromDate');
                fromDateQuery ? this.fromDate = new Date(fromDateQuery) : this.fromDate = null;
            }),
            tap(queryParam => {
                const toDateQuery = queryParam.get('toDate');
                toDateQuery ? this.toDate = new Date(toDateQuery) : this.toDate = null;
            }),
            tap(queryParam => {
                this.accountId = queryParam.get('accountId') ? queryParam.get('accountId') : null;
            }),
        ).subscribe(_ => this.search());
    }

    loadNext(): void {
        this.page++;
        this.operationsService.find(this.page,
            this.limit,
            this.gift,
            this.searchQuery,
            this.operationQueryFilter.getSelectedValues(),
            this.sort,
            this.fromDate ? this.fromDate.toISOString() : null,
            this.toDate ? this.toDate.toISOString() : null,
            this.accountId)
            .subscribe(data => {
                this.operations = this.operations.concat(data.data);
            });
    }

    search(): void {
        this.page = 0;

        if (this.searchSubscription != null) {
            this.searchSubscription.unsubscribe();
        }

        this.searchSubscription = this.operationsService.find(
            this.page,
            this.limit,
            this.gift,
            this.searchQuery,
            this.operationQueryFilter.getSelectedValues(),
            this.sort,
            this.fromDate ? this.fromDate.toISOString() : null,
            this.toDate ? this.toDate.toISOString() : null,
            this.accountId)
            .subscribe(data => {
                this.operations = data.data;
                this.countsMap = this.countsMap.set(this.TOTAL_KEY, data.totalCount);
                this.tableHeaders[4].tooltipData = this.aggregations.get('amount');
            });
    }

    giftChanged($event: boolean): void{
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                gift: this.gift
            },
            queryParamsHandling: 'merge'
        });
    }

    buildDocumentLink(linkedDocumentInformation: LinkedDocumentInformation): string {
        let link = '/boa/accounting/';

        switch (linkedDocumentInformation.type) {
            case BillingDocumentType.CREDIT:
                link += 'credit';
                break;
            case BillingDocumentType.INVOICE:
                link += 'invoices';
                break;
            case BillingDocumentType.QUOTE:
                link += 'quotes';
                break;
        }

        link += '/';

        if (linkedDocumentInformation.draft) {
            link += 'drafts/';
        }

        link += linkedDocumentInformation.id;

        return link;
    }

    downloadCsv(): void {
        this.downloadService.downloadFileWithParams(
            this.csvSrcLink,
            `operations-${this.datePipe.transform(Date.now(), 'dd/MM/yy')}`,
            'csv',
            this.operationsService.buildHttpParams(
                null,
                null,
                this.gift,
                this.searchQuery,
                null,
                this.operationQueryFilter.getSelectedValues(),
                this.fromDate ? this.fromDate.toISOString() : null,
                this.toDate ? this.toDate.toISOString() : null,
                this.accountId,
                null)
        );
    }
}
