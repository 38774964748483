import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FullUser} from '../../../models/full-user';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {Role} from '../../../models/role';
import {AnimatorType} from '../../../models/user';
import {CustomerAccount} from '../../../../customers/models/customer-account';
import {CustomerAccountService} from '../../../../customers/services/customer-account.service';

@Component({
  selector: 'o-user-personal-information',
  templateUrl: './o-user-personal-information.component.html',
  styleUrls: ['./o-user-personal-information.component.scss']
})
export class OUserPersonalInformationComponent implements OnInit, OnChanges {

    @Input()
    user: FullUser;

    @Input()
    customerAccounts: CustomerAccountCompleteMap[];

    @Input()
    showTransferTokens = false;

    @Output()
    editModeLaunched = new EventEmitter<void>();

    @Output()
    editModeQuit = new EventEmitter<void>();

    @Output()
    transferTokensCliked = new EventEmitter<void>();

    updateLoading = false;
    userBuffer: FullUser;

    role = Role;
    animatorType = AnimatorType;
    customerDemoBuffer: CustomerAccount;

    constructor(private userService: UserService,
                private toastService: ToastrService,
                private customerAccountService: CustomerAccountService,
                private translateService: TranslateService,
                private router: Router,
                private route: ActivatedRoute) { }

    ngOnInit(): void {
       this.userBuffer = JSON.parse(JSON.stringify(this.user));
       this.customerAccountService.getAllCustomerAccountsMap('complete_name_map').subscribe(custAccounts => {
           this.customerAccounts = custAccounts;
       });
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes.user.previousValue && changes.user.previousValue !== changes.user.currentValue) {
            this.user = changes.user.currentValue;
            this.userBuffer = JSON.parse(JSON.stringify(this.user));
        }
    }

    onSaveClick(): void {
        this.editModeQuit.emit();
        this.updateLoading = true;
        this.userService.updateUser(this.userBuffer).subscribe(_ => {
            this.updateLoading = false;
            this.toastService.success(this.translateService.instant('common.success'));
            this.refresh();
        }, err => {
            this.updateLoading = false;
            this.toastService.success(this.translateService.instant('common.error'));
        });
    }

    refresh(): void {
        this.router.navigate([], {
            relativeTo: this.route
        });
    }

    onEditModeLaunched(): void {
        this.editModeLaunched.emit();
    }

    onCancel(): void {
        this.editModeQuit.emit();
        this.userBuffer = JSON.parse(JSON.stringify(this.user));
    }

    onCustomerAccountChange(newId: string): void {
        this.userBuffer.customerAccount = this.customerAccounts.find(el => el.id === newId).value;
    }

    transferTokens(): void {
        this.transferTokensCliked.emit();
    }
}

class CustomerAccountCompleteMap {
    constructor(label: string, value: CustomerAccount, id: string) {
        this.label = label;
        this.value = value;
        this.id = id;
    }
    label: string;
    value: CustomerAccount;
    id: string;
}
