import {Directive, Input} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {of} from 'rxjs';
import {UserService} from '../services/user.service';
import {switchMap} from 'rxjs/operators';

@Directive({
    selector: '[boaUniqueLogin]',
    providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueLoginDirective, multi: true}]
})
export class UniqueLoginDirective implements AsyncValidator {

    @Input()
    boaUniqueLogin = false;

    constructor(private userService: UserService) { }

    validate(control: AbstractControl): Observable<ValidationErrors | null> {
        if (this.boaUniqueLogin) {
            return this.userService.doesAnUserExistWith(control.value)
                .pipe(
                    switchMap(exist => {
                        if (exist) {
                            return of({duplicate: true});
                        } else {
                            return of(null);
                        }
                    })
                );
        } else {
            return of(null);
        }
    }
}
