import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {InvoiceAddressWithName} from '../../../models/invoices/address-invoice';

@Component({
    selector: 'o-invoice-header-addresses',
    templateUrl: './o-invoice-header-addresses.component.html',
    styleUrls: ['./o-invoice-header-addresses.component.scss'],
    host: {class: 'grid-y'}
})
export class OInvoiceHeaderAddressesComponent implements OnInit, AfterViewInit {

    @Input()
    customerName: string;

    @Input()
    disabled: boolean;

    @Input()
    billingAddress: InvoiceAddressWithName;

    @Output()
    billingAddressChange = new EventEmitter<InvoiceAddressWithName>();

    @Input()
    deliveryAddress: InvoiceAddressWithName;

    @Output()
    deliveryAddressChange = new EventEmitter<InvoiceAddressWithName>();

    @ViewChild('billingAddressForm')
    billingAddressForm: NgForm;

    @ViewChild('deliveryAddressForm')
    deliveryAddressForm: NgForm;

    @Input()
    invalid: boolean;

    @Output()
    invalidChange = new EventEmitter<boolean>();

    @Input()
    dirty: boolean;

    @Output()
    dirtyChange = new EventEmitter<boolean>();

    isInProgress = false;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.deliveryAddressForm?.form.markAsPristine();
        this.billingAddressForm?.form.markAsPristine();
    }

    ngAfterViewInit(): void {
        combineLatest([
            this.deliveryAddressForm.statusChanges,
            this.billingAddressForm.statusChanges
        ]).subscribe(([_, __]) => {
            const invalid = this.deliveryAddressForm?.invalid || this.billingAddressForm?.invalid;
            if (invalid !== this.invalid) {
                this.invalid = invalid;
                this.invalidChange.emit(this.invalid);
            }

            const dirty = this.deliveryAddressForm?.dirty || this.billingAddressForm?.dirty;
            if (this.dirty !== dirty) {
                this.dirty = dirty;
                this.dirtyChange.emit(this.dirty);
            }

            this.changeDetector.detectChanges();
        });
    }
}
