import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'boa-app-content',
    templateUrl: './app-content.component.html',
    styleUrls: ['./app-content.component.scss'],
    host: {class: 'grid-x'}
})
export class AppContentComponent implements OnInit {
    loading = false;

    constructor() {}

    ngOnInit(): void {
    }
}
