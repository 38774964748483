<o-block [headerLabel]="'user.demonstration.animator.header.title' | translate"
         [userType]="animatorType.DEMONSTRATION">
    <div class="cell shrink grid-x grid-margin-x" button>
        <a-dashboard-button-icon *ngIf="lastAnimator" [visibility]="'LOW'"
                                 class="cell shrink hide-for-phone show-for-medium-desktop"
                                 (click)="createNewAnimator()">
            <i class="fas fa-plus"></i>
        </a-dashboard-button-icon>
        <a-dashboard-button-icon [visibility]="'LOW'" class="cell shrink" (click)="seeAllAnimators()">
            <i class="fas fa-list"></i>
        </a-dashboard-button-icon>
    </div>

    <div *ngIf="lastAnimator && !creationMode">
        <div class="lastDemo">
            <span class="lastDemo-title">{{'user.demonstration.animator.body.title' | translate}}&nbsp;:</span>
            <a-field [showLabel]="false">
                <i class="fas fa-user"></i>
                <ng-container value>
                 <span class="lastDemo-item-label">{{'user.demonstration.animator.username' | translate}}
                     <a [routerLink]="['/boa/users/', lastAnimator?.id]">{{lastAnimator?.login}}</a>
                 </span>
                </ng-container>
            </a-field>
            <a-field [showLabel]="false">
                <i class="fas fa-calendar"></i>
                <ng-container value>
                 <span class="lastDemo-item-label">{{'user.demonstration.animator.date' | translate}}
                     {{(lastAnimator?.lastLoginDate | boaDateTime : ('common.at' | translate)) | boaNotNull : 'common.never-connected'}}
            </span>
                </ng-container>
            </a-field>
        </div>

        <spacer size="16"></spacer>

        <div class="grid-x align-center">
            <a-button-default class="cell shrink hide-for-medium-desktop show-for-phone" *ngIf="lastAnimator"
                              [visibility]="'LOW'"
                              (click)="createNewAnimator()">
                <i class="fas fa-plus"></i> {{'user.demonstration.animator.create' | translate}}
            </a-button-default>
        </div>
    </div>

    <ng-template #empty [ngIf]="!lastAnimator && !creationMode">
        <div class="grid-x align-center align-middle _emptyMode">
            <spacer size="32"></spacer>
            <a-button-default class="cell shrink" (click)="createNewAnimator()" visibility="LOW"
                              size="LARGE">
                <i class="fas fa-plus"></i> {{'user.demonstration.animator.create' | translate}}
            </a-button-default>
            <spacer size="16"></spacer>
        </div>
    </ng-template>

    <ng-template #creationBlock [ngIf]="creationMode">
        <form #form='ngForm'>
            <div class="grid-y grid-margin-y">
                <div class="cell shrink">
                    <div class="grid-x grid-margin-x">
                        <a-input #firstName="ngModel" [required]="true" [boaNotBlank]="true"
                                 [(ngModel)]="demonstrationAnimatorBuffer.person.firstName"
                                 [name]="'firstName'" [label]="'user.detail.manager.creation.firstName' | translate"
                                 class="cell phone-12"></a-input>
                        <a-input #lastName="ngModel" [required]="true" [boaNotBlank]="true"
                                 [(ngModel)]="demonstrationAnimatorBuffer.person.lastName"
                                 (blur)="nameChanged()"
                                 [name]="'lastName'" [label]="'user.detail.manager.creation.lastName' | translate"
                                 class="cell phone-12"></a-input>
                    </div>
                    <a-input #login="ngModel"
                             [boaUniqueLogin]="true"
                             [required]="true"
                             [boaNotBlank]="true"
                             class="cell shrink"
                             [(ngModel)]="demonstrationAnimatorBuffer.login" [name]="'login'"
                             [label]="'user.detail.manager.creation.login' | translate"></a-input>
                </div>
                <div class="cell shrink">
                    <div class="grid-x grid-margin-x">
                        <a-input [boaNotBlank]="true" [(ngModel)]="demonstrationAnimatorBuffer.person.contact.mail"
                                 [name]="'mail'"
                                 [required]="true"
                                 [label]="'user.detail.manager.creation.mail' | translate"
                                 class="cell phone-12"></a-input>
                        <a-input [boaNotBlank]="true"
                                 [(ngModel)]="demonstrationAnimatorBuffer.person.contact.mailOptional" [name]="'mailOpt'"
                                 [label]="'user.detail.manager.creation.mail-opt' | translate"
                                 class="cell phone-12"></a-input>
                    </div>
                </div>
                <div class="cell shrink">
                    <div class="grid-x grid-margin-x">
                        <a-input #mobilePhone="ngModel"
                                 [boaNotBlank]="true"
                                 class="cell phone-12"
                                 [(ngModel)]="demonstrationAnimatorBuffer.person.contact.mobilePhone"
                                 [name]="'mobilePhone'"
                                 [label]="'user.detail.manager.creation.mobile-phone' | translate"></a-input>
                        <a-input #fixPhone="ngModel"
                                 [boaNotBlank]="true"
                                 [(ngModel)]="demonstrationAnimatorBuffer.person.contact.fixPhone"
                                 [name]="'fixPhone'" [label]="'user.detail.manager.creation.fix-phone' | translate"
                                 class="cell phone-12"></a-input>
                    </div>
                </div>

                <spacer size="16"></spacer>

                <div class="cell shrink grid-x align-center grid-margin-x">
                    <a-button-cancel class="cell shrink"
                                     (click)="onCancelCreation()">{{'common.action.cancel' | translate}}</a-button-cancel>

                    <a-button-default (click)="onValidateCreation()"
                                     [visibility]="'HIGH'"
                                     [disabled]="!form.valid"
                                     [loading]="validateCreationLoading"
                                     class="cell shrink">{{'common.validate' | translate}}</a-button-default>
                </div>
            </div>
        </form>
    </ng-template>
</o-block>
