import {AfterContentInit, Component, EventEmitter, Input, Output} from '@angular/core';
import {Article, ArticleType} from '../../../models/article';
import {ArticleSession} from '../../../models/article-session';

@Component({
    selector: 'm-article',
    templateUrl: './m-article.component.html',
    styleUrls: ['./m-article.component.scss']
})
export class MArticleComponent implements AfterContentInit {

    @Input()
    public article: Article;

    @Input()
    public loading = false;

    @Output()
    public saved = new EventEmitter<Article>();

    @Output()
    public deleted = new EventEmitter<Article>();

    @Output()
    public canceled = new EventEmitter<void>();

    public isSession: boolean;
    public creationTokenCost: number;
    public periodTokenCost: number;
    public isEdit: boolean;

    constructor() {
    }

    ngAfterContentInit(): void {
        this.isSession = this.article?.type === ArticleType.SESSION;
        this.creationTokenCost = (this.article as ArticleSession)?.creationTokenCost;
        this.periodTokenCost = (this.article as ArticleSession)?.periodTokenCost;
    }

    editMode(): void {
        this.isEdit = true;
    }

    articleSaved(article: Article): void {
        this.saved.emit(article);
        this.isEdit = false;
    }

    articleDeleted(): void {
        this.deleted.emit(this.article);
    }

    onCancel(): void {
        this.isEdit = false;
        this.canceled.emit();
    }
}
