import {Component, Input, OnInit} from '@angular/core';
import {Operation} from '../../../models/operation';
import {
    OperationDestinationType
} from '../../../models/operation-destination';
import {Params} from '@angular/router';
import {UserService} from '../../../../users/services/user.service';

@Component({
  selector: 'o-last-operations',
  templateUrl: './o-last-operations.component.html',
  styleUrls: ['./o-last-operations.component.scss']
})
export class OLastOperationsComponent implements OnInit {

    @Input()
    operations: Array<Operation>;

    @Input()
    verticalLayout = false;

    @Input()
    actualUserId: string;

    @Input()
    operationType: string[];

    @Input()
    label: string;

    operationDestinationType = OperationDestinationType;
    queryParams: Params;

    constructor(public userService: UserService) { }

    ngOnInit(): void {
        this.queryParams = {sort: 'OPERATION_DATE:DESC', accountId: this.actualUserId, operationQueryFilter: this.operationType};
    }
}
