import {Component, forwardRef, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'm-choice-buttons',
    templateUrl: './m-choice-buttons.component.html',
    styleUrls: ['./m-choice-buttons.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MChoiceButtonsComponent),
            multi: true
        }
    ]
})
export class MChoiceButtonsComponent implements OnInit, ControlValueAccessor {

    value: 'manual' | 'automatic';
    onChange = (newValue: 'manual' | 'automatic') => {};
    onTouched = (newValue: 'manual' | 'automatic') => {};

    constructor() { }

    ngOnInit(): void {
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: 'manual' | 'automatic'): void {
        this.value = obj;
    }

    onManualClick(): void {
        this.value = 'manual';
        this.onChange(this.value);
    }

    onAutomaticClick(): void {
        this.value = 'automatic';
        this.onChange(this.value);
    }
}
