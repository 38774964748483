export class BaseTime {

  public creationDate: Date;
  public createdBy: string;

  public lastEditionDate: Date;
  public lastEditedBy: string;

  public deletionDate: Date;
  public deletedBy: string;
}
