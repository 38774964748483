import {Component, HostBinding, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FullUser} from '../../models/full-user';
import {ConnectedUserService} from '../../../../app-root/services/connected-user.service';
import {Animator} from '../../models/animator';
import {UserService} from '../../services/user.service';
import {Role} from '../../models/role';
import {AnimatorType} from '../../models/user';

@Component({
  selector: 'boa-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    user: FullUser;
    connectedUser: FullUser;
    userPersonalInfoEditMode = false;
    userConnexionDisplayMode: 'simple' | 'full' = 'full';

    showTransferWindow = false;
    customerAccountManager: FullUser;

    targetUserBuffer: FullUser;
    originUserBuffer: FullUser;

    role = Role;
    animatorType = AnimatorType;

    constructor(private route: ActivatedRoute,
                private userService: UserService,
                private router: Router,
                private connectedUserService: ConnectedUserService) {
        connectedUserService
            .getCurrentFullUser()
            .subscribe(fullUser => {
                this.connectedUser = fullUser;
            });
    }

    refresh(): void {
        this.router.navigate([], {relativeTo: this.route});
    }

    onEditModeLaunched(): void {
        this.userPersonalInfoEditMode = true;
        this.userConnexionDisplayMode = 'simple';
    }

    onEditModeQuit(): void {
        this.userPersonalInfoEditMode = false;
        this.userConnexionDisplayMode = 'full';
    }

    ngOnInit(): void {
        this.route.data.pipe(
        ).subscribe((data: {user: FullUser})  => {
            this.user = data.user;
            this.userService.getAccountUserByLogin(this.user.customerAccount.accountManager.login).subscribe(accountManager => {
                this.customerAccountManager = accountManager;
            });
        });
    }

    onAnimatorTransferClicked(animator: Animator): void {
        this.targetUserBuffer = animator;
        this.originUserBuffer = this.user;
        this.showTransferWindow = true;
    }

    closeTransferWindow(): void {
        this.showTransferWindow = false;
    }

    onOperationSaved(): void {
        this.refresh();
        this.closeTransferWindow();
    }

    onCustomerTransferClicked(): void {
        this.originUserBuffer = this.customerAccountManager;
        this.targetUserBuffer = this.user; // the user we're watching the detail of
        this.showTransferWindow = true;
    }
}
