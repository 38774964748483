import {Injectable} from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {Result} from '../../common/models/shared/result';
import {OperationsService} from '../services/operations.service';
import {Operation} from '../models/operation';

@Injectable()
export class AllOperationsResolver implements Resolve<Result<Operation>> {

    private page = 0;
    private limit = 50;

    constructor(private operationsService: OperationsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Result<Operation>> {
        const queryParams = route.queryParamMap;

        return this.operationsService.find(
            this.page,
            this.limit,
            queryParams.get('gift') ? queryParams.get('gift') === 'true' : false,
            queryParams.get('search') ? queryParams.get('search') : '',
            queryParams.get('operationQueryFilter') ? [queryParams.get('operationQueryFilter')] : null,
            queryParams.get('sort'),
            queryParams.get('fromDate'),
            queryParams.get('toDate'),
            queryParams.get('accountId')
        );
    }
}
