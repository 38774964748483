<div class="cell shrink">
    <div class="grid-x grid-margin-x align-center align-middle">
        <div class="cell phone-6 ">
            <img alt="Logo New-Boa" src="./assets/images/svg/logo/logo-color.svg"/>

            <spacer [size]="56"></spacer>

            <form #loginForm="ngForm" (submit)="authenticate(loginForm)">
                <div class="form-group">
                    <a-input-login [(ngModel)]="authentication.name"
                             [tabindex]="3"
                             [placeholder]="'login.username' | translate"
                             (ngModelChange)="userChange($event)"
                             name="login"
                             id="login"
                             required
                    ><i class="fas fa-user"></i></a-input-login>
                    <spacer [size]="56"></spacer>
                    <a-input-login [(ngModel)]="authentication.password"
                             [tabindex]="3"
                             [placeholder]="'login.password' | translate"
                             (ngModelChange)="onPasswordChange()"
                             inputType="password"
                             [name]="'password'"
                             [id]="'password'"
                             required
                    ><i class="fas fa-lock-open"></i></a-input-login>
                </div>
                <div *ngIf="authenticationError">
                    <span>{{'login.error.message' | translate}}</span>
                </div>
                <spacer [size]="56"></spacer>
                <a-button-submit class="loginButton" [disabled]="!loginForm.valid && !loginForm.pristine" [loading]="connectionLoading" [expanded]="true" [size]="'LARGE'">
                    {{'login.connect' | translate}}
                    <i class="loginButton-icon fas fa-arrow-right"></i>
                </a-button-submit>
            </form>
        </div>
        <div class="cell phone-1"></div>
        <div class="cell phone-10">
            <img [alt]="'login.connection.illustration.alt'|translate" src="./assets/images/svg/connexion-illu.svg"/>
        </div>
    </div>
</div>
