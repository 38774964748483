import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article, ArticleType} from '../../../models/article';
import {InvoiceLine} from '../../../models/invoices/invoice-line';

@Component({
    selector: 'm-invoice-line-creator',
    templateUrl: './m-invoice-line-creator.component.html',
    styleUrls: ['./m-invoice-line-creator.component.scss']
})
export class MInvoiceLineCreatorComponent implements OnInit {
    emptyLine: boolean;

    @Input()
    linesFilters: ArticleType[];

    @Input()
    articles: {label: string, data: Article, id: string }[];

    @Input()
    vatRate: number;

    @Input()
    showTokenSessionHeaders: boolean;

    @Output()
    lineAdded = new EventEmitter<InvoiceLine>();

    @Output()
    dirty = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    addInvoiceLine($event: InvoiceLine): void {
        this.lineAdded.emit($event);
        this.resetLineType();
    }

    resetLineType(): void {
        this.emptyLine = false;
    }
}
