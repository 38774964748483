export class Article{
    constructor(public id: string,
                public type: ArticleType,
                public description: string,
                public title: string,
                public accountantCode: string,
                public unitaryCost: number,
                public serviceKind: ServiceKind,
                public operationType: ArticleOperationType) {
    }

    // tslint:disable-next-line:no-unnecessary-initializer
    public static empty(type: ArticleType = undefined): Article {
        return new Article(
            null,
            type,
            '',
            '',
            '',
            0,
            ServiceKind.SERVICE,
            null);
    }

    public isSession(): boolean {
        return this.type === ArticleType.SESSION;
    }
}

export enum ArticleType {
    TOKEN = 'TOKEN',
    SESSION = 'SESSION',
    ANIMATION = 'ANIMATION',
    FEE = 'FEE',
    WORKS = 'WORKS'
}

export enum ArticleOperationType {
    GOODS = 'GOODS',
    SERVICES = 'SERVICES'
}

export enum AccountantCode {
    TOKEN = '0101',
    SESSION = '01231958904',
    ANIMATION = '129312041',
    WORKS = '01259823591031',
    FEE = '120495534329T1'
}

// Duplicated from Invoice, search UUID 2398f6e7-2892-4da9-ab52-adbff5e02808
export enum ServiceKind {
    SERVICE = 'SERVICE',
    FEE = 'FEE',
    ANIMATION_ENTERPRISE = 'ANIMATION_ENTERPRISE',
    ANIMATION_TOURNAMENT = 'ANIMATION_TOURNAMENT',
    ANIMATION = 'ANIMATION',
    TRAINING = 'TRAINING',
    ASSISTANCE = 'ASSISTANCE',
    WORKS = 'WORKS',
    SUBCONTRACTING = 'SUBCONTRACTING',
    BUY_SELL = 'BUY_SELL',
    HOSTING = 'HOSTING',
    LICENCE = 'LICENCE',
}
