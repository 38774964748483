import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'm-total-results',
    templateUrl: './m-total-results.component.html',
    styleUrls: ['./m-total-results.component.scss']
})
export class MTotalResultsComponent implements OnInit {

    @Input()
    public label: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
