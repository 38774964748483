<div class="invoice-icon" m-icon [type]="billingDocument?.billingDocumentType"></div>

<span class="invoice-number" [ngClass]="'_' + billingDocument?.billingDocumentType.toLowerCase()">N°{{reference}}</span>
<span class="invoice-customer">{{billingDocument.customer.name}}</span>
<span class="invoice-amount"><b>{{billingDocument.netAmount.value | currency : billingDocument.netAmount.currency}} {{'common.netAmount' | translate}}</b></span>

<div class="invoiceStatus-container">
    <span class="invoice-status" [ngClass]="'_' + billingDocument.getStatus().toLowerCase()">
        {{'billings.status.' + billingDocument.billingDocumentType + '.' + status | translate}}
    </span>
    <span class="billing-status" *ngIf="billingDocument?.isDelivered">
        {{'billings.delivery.status.' + billingDocument.getType() +  '.true' | translate}}
    </span>
    <span class="billing-status _undelivered" *ngIf="!billingDocument?.isDelivered">
        {{'billings.delivery.status.' + billingDocument.getType() +  '.false' | translate}}
    </span>
</div>
