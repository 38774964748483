import {Amount} from './invoices/amount';

export class Payment {
    id: string;
    amount: Amount;
    date: Date;
    mode: PaymentModeEnum;
    customerRef: string;
    status: PaymentStatus;


    constructor(id: string, amount: Amount, date: Date, mode: PaymentModeEnum, customerRef: string, status: PaymentStatus) {
        this.id = id;
        this.amount = amount;
        this.date = date;
        this.mode = mode;
        this.customerRef = customerRef;
        this.status = status;
    }
}

export enum PaymentModeEnum {
    CREDIT_CARD = 'CREDIT_CARD',
    CHECK = 'CHECK',
    WIRE_TRANSFER = 'WIRE_TRANSFER',
    OTHER = 'OTHER'
}

export class PaymentStatus {
    value: PaymentStatusEnum;
    label: string;

    constructor(value: PaymentStatusEnum, label: string) {
        this.value = value;
        this.label = label;
    }
}

export enum PaymentStatusEnum {
    PENDING = 'PENDING',
    DONE = 'DONE',
    CANCELED = 'CANCELED',
    ERROR = 'ERROR'
}
