import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../../shared/shared.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {LoginComponent} from './views/login/login.component';
import {FormsModule} from '@angular/forms';
import {AuthenticationService} from './services/authentication.service';
import {ButtonsModule} from '../../shared/components/atoms/buttons/buttons.module';
import {HomeModule} from '../home/home.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    LoginComponent
  ],
  exports: [
    LoginComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        NgSelectModule,
        SharedModule,
        FormsModule,
        ButtonsModule,
        HomeModule,
        RouterModule,
    ],
  providers: [AuthenticationService]
})
export class LoginModule {
}
