import {Animator} from '../../users/models/animator';
import {User} from '../../users/models/user';
import {Team} from '../../users/models/team';
import {SimulationData} from './simulation-data';
import {SessionEvent} from './session-event';
import {BaseMetaScenario} from './base-meta-scenario';
import {SessionStatus} from './session-status';
import {SessionConfiguration} from './session-configuration';
import {SessionType} from './session-type';
import {BaseEntity} from '../../../shared/models/base-entity';
import {Type} from 'class-transformer';

export class GenericSession extends BaseEntity {
    name: string;
    @Type(() => SimulationData)
    simulationData: SimulationData;
    teamCount: number;
    scheduledSimulations: number;
    @Type(() => SessionEvent)
    events: Array<SessionEvent>;
    @Type(() => BaseMetaScenario)
    baseMetaScenario: BaseMetaScenario;
    customerAccountRef: string;
    mainAnimatorRef: string;
    @Type(() => Animator)
    mainAnimator: Animator;
    coAnimatorRefs: string[];
    @Type(() => User)
    coAnimators: User[];
    status: SessionStatus;
    @Type(() => Team)
    teams: Team[];
    @Type(() => SessionConfiguration)
    configuration: SessionConfiguration;
    teamsModificationAllowed: boolean;
    /**
     * Whether this session has been created by demonstration animator. Some functionality will not be available.
     */
    demonstration: boolean;
    /**
     * Animator can decide to lock a session temporarily so that players cannot connect to it anymore.
     */
    animatorLocked: boolean;
    currentPeriod: string;
    progress: number;
    type: SessionType;
}
