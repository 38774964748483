import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectEntry} from '../../../../common/models/select/select-entry';
import {BillingDocumentService} from '../../../services/billing-document.service';
import {BillingDocumentType} from '../../../models/invoices/billing-document';

@Component({
    selector: 'a-select-billing',
    templateUrl: './a-select-billing.component.html',
    styleUrls: ['./a-select-billing.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ASelectBillingComponent),
            multi: true
        }
    ]
})
export class ASelectBillingComponent implements OnInit, OnChanges, ControlValueAccessor {

    @Input()
    required: boolean;

    @Input()
    disabled: boolean;

    @Input()
    label: string;

    @Input()
    customerAccountRefFilter: string;

    billingId: string;
    allBillingsCode: SelectEntry[];

    onChange = (userId: string) => {
    }
    onTouched = (userId: string) => {
    }

    constructor(private billingService: BillingDocumentService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerAccountRefFilter) {
            this.billingId = undefined;
            this.billingService
                .findMap(
                    null,
                    null,
                    [BillingDocumentType.QUOTE, BillingDocumentType.INVOICE],
                    null,
                    null,
                    null,
                    null,
                    this.customerAccountRefFilter,
                    'code_map'
                )
                .subscribe(res => {
                    this.allBillingsCode = res;
                });
        }
    }

    ngOnInit(): void {
        if (!this.customerAccountRefFilter) {
            console.log('[a-select-billings] - no customer ref filter given - no search was made');
            return;
        }
        this.billingService
            .findMap(
                null,
                null,
                [BillingDocumentType.QUOTE, BillingDocumentType.INVOICE],
                null,
                null,
                null,
                null,
                this.customerAccountRefFilter,
                'code_map'
            )
            .subscribe(res => {
                this.allBillingsCode = res;
            });
    }

    onUserIdChange(newUserId: string): void {
        this.billingId = newUserId;
        this.onChange(this.billingId);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: string): void {
        this.billingId = obj;
    }
}
