<label [class._required]="required">
    {{label}}
    <div class="input-group password">
        <input class="input-group-field passwordField"
               #input [class._delete]="delete"
               [required]="required"
               [name]="name"
               [class._disabled]="disabled"
               [disabled]="disabled"
               [placeholder]="placeholder"
               [type]="inputType"
               (blur)="onBlur()"
               [ngModel]="data"
               (ngModelChange)="onChange($event)"
               [min]="min"
               [max]="max">
        <span class="input-group-label passwordIcon">
            <i class="fas fa-lock"></i>
            <div class="passwordIcon-infos">
                <p>Le mot de passe doit contenir : </p>
                <ul>
                    <li>Au moins 6 caractères</li>
                </ul>
            </div>
        </span>
    </div>
</label>
