import {Address} from '../../common/models/address/address';
import {BaseObject} from '../../common/base-object';
import {Type} from 'class-transformer';
import {AddressType} from '../../common/models/address/addressType';

export class Contact extends BaseObject {

    constructor() {
        super();
        this.address = Address.empty(AddressType.BILLING);
    }

    head: string;
    service: string;
    fixPhone: string;
    mobilePhone: string;
    mail: string;
    mailOptional: string;
    @Type(() => Address)
    address: Address;
}

export class CustomerAccountContact extends Contact {
    head: string;
    service: string;
    fixPhone: string;
    mobilePhone: string;
    mail: string;
    mailOptional: string;
    @Type(() => Address)
    address: Address;
    firstName: string;
    lastName: string;

    constructor(head: string,
                service: string,
                fixPhone: string,
                mobilePhone: string,
                mail: string,
                mailOptional: string,
                address: Address,
                firstName: string,
                lastName: string) {
        super();
        this.head = head;
        this.service = service;
        this.fixPhone = fixPhone;
        this.mobilePhone = mobilePhone;
        this.mail = mail;
        this.mailOptional = mailOptional;
        this.address = address;
        this.firstName = firstName;
        this.lastName = lastName;
    }

    static empty(addressType: AddressType): CustomerAccountContact {
        return new CustomerAccountContact('',
            '',
            '',
            '',
            '',
            '',
            Address.empty(addressType),
            '',
            '');
    }
}
