<label [class._required]="required">
    <span class="formLabel" *ngIf="label">{{label}}</span>
    <i *ngIf="info" class="fa fa-info-circle infoIcon"></i>
    <div class="input">
        <input #input='ngModel'
               [class._delete]="delete"
               [required]="required"
               [boaNotBlank]="boaNotBlank"
               [minlength]="minlength"
               [maxlength]="maxlength"
               [attr._aria-describedby]="helpTextID"
               [boaUniqueLogin]="boaUniqueLogin"
               [name]="name"
               [boaMax]="boaMax"
               [ngClass]="alignment"
               [class._disabled]="disabled"
               [disabled]="disabled"
               [placeholder]="placeholder"
               [type]="inputType"
               (click)="onTouched()"
               (blur)="onBlur()"
               [ngModel]="data"
               (ngModelChange)="onChange($event)"
               [min]="min"
               [max]="max">
        <a *ngIf="delete" (click)="onDelete()" class="deleteIcon"><i class="fas fa-times-circle"></i></a>
    </div>
</label>

<ng-template [ngIf]="input.dirty">
    <span class="help-text _error" *ngIf="input.errors?.blank">{{'error.blank' | translate}}</span>
    <span class="help-text _error" *ngIf="input.errors?.duplicate">{{'error.manager.duplicate' | translate}} </span>
    <span class="help-text _error" *ngIf="input.errors?.required">{{'error.required' | translate}} </span>
    <span class="help-text _error" *ngIf="input.errors?.boaMax">{{'error.boa-max' | translate : {value: boaMax} }} </span>
    <span class="help-text _error" *ngIf="input.errors?.minlength">{{'error.minlength' | translate : {value: minlength} }} </span>
    <span class="help-text _error" *ngIf="input.errors?.maxlength">{{'error.maxlength' | translate : {value: maxlength} }} </span>
</ng-template>

<ng-container *ngIf="warningTemplate">
    <ng-container *ngTemplateOutlet="warningTemplate"></ng-container>
</ng-container>

