import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppContentComponent} from './views/app-content/app-content.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {MenuLeftComponent} from './views/menu-left/menu-left.component';
import { MenuRightComponent } from './views/menu-right/menu-right.component';
import {NewBoaCommonModule} from '../common/common.module';

@NgModule({
    declarations: [
        AppContentComponent,
        MenuLeftComponent,
        MenuRightComponent
    ],
    exports: [
        AppContentComponent,
        MenuLeftComponent
    ],

    imports: [
        CommonModule,
        RouterModule,
        SharedModule,
        NewBoaCommonModule
    ]
})
export class ContentModule {
}
