import {AfterContentInit, Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AnimatorType} from '../../models/user';
import {UserService} from '../../services/user.service';
import {Result} from '../../../common/models/shared/result';
import {ASearchComponent} from '../../../../shared/components/atoms/forms/a-search/a-search.component';
import {skip, tap} from 'rxjs/operators';
import {DatePipe} from '@angular/common';
import {Role} from '../../models/role';
import {TypeEntry} from '../../../../shared/models/type-entry';
import {TypeList} from '../../../../shared/models/type-list';
import {TableHeader} from '../../../../shared/models/table-header';
import {FullUser} from '../../models/full-user';
import {TranslateService} from '@ngx-translate/core';
import {DownloadService} from '../../../common/services/download.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'boa-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterContentInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    private readonly TOTAL_KEY = 'common.lists.results.number';
    private page = 0;
    private limit = 50;

    public roleEnum = Role;
    public searchQuery;
    public users: Array<FullUser>;
    public countsMap: Map<string, number> = new Map();
    public aggregations: Map<string, number> = new Map();
    public sort: string;

    @ViewChild('searchQueryComponent')
    public searchQueryComponent: ASearchComponent;

    public roles = new TypeList([
        new TypeEntry('Gestionnaire', Role.ACCOUNT_MANAGER, false),
        new TypeEntry('Animateur', Role.ANIMATOR, false),
        new TypeEntry('Joueur', Role.PLAYER, false),
        new TypeEntry('Démonstration', AnimatorType.DEMONSTRATION, false)
    ]);
    public headers: Array<TableHeader> = [];
    public wholePageLoader: boolean;

    private customerAccountRef: string;
    usersLoading: boolean;
    private searchSubscription: Subscription | null = null;

    constructor(private route: ActivatedRoute,
                private downloadService: DownloadService,
                private router: Router,
                private datePipe: DatePipe,
                private translateService: TranslateService,
                private userService: UserService) { }

    observeQueriesChange(): void {
        this.route.queryParamMap
            .pipe(
                tap(queryParams => this.searchQuery = queryParams.get('search') ? queryParams.get('search') : ''),
                tap(queryParams => this.roles.setSelected(queryParams.getAll('roles'))),
                tap(queryParams => {
                    this.sort = queryParams.get('sort');
                    const value = this.sort?.split(':')[0];
                    const direction = this.sort?.split(':')[1];
                    this.headers = this.headers.map(el => {
                        if (el.value === value) {
                            el.sortActive = true;
                            el.sortDirection = direction as 'ASC' | 'DESC' | null;
                        }
                        return el;
                    });
                }),
                tap(queryParams => this.sort = queryParams.get('sort')),
                tap(queryParams => this.customerAccountRef = queryParams.get('customerAccountRef')),
                skip(1) // needed because there is a resolver that already resolves the first set of data for us
            ).subscribe(_ => {
                this.search();
            });
    }

    ngAfterContentInit(): void {
        this.observeQueriesChange();
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {users: Result<FullUser>, sort: string}) => {
            this.users = data.users.data;
            this.aggregations = data.users.aggregations;
            this.countsMap = new Map([
                [this.TOTAL_KEY, data.users.totalCount],
            ]);
        });

        this.translateService.get([
            'user.list.username',
            'user.list.email',
            'user.list.firstname',
            'user.list.lastname',
            'user.list.customer',
            'user.list.token.amount',
            'user.list.customer.token.amount',
            'user.list.last.connection'
        ]).subscribe(translations =>
            this.headers = [
                new TableHeader('', false, '', 'tableRoleIcon'),
                new TableHeader(translations['user.list.username'], true, 'LOGIN', 'userIdColumn'),
                new TableHeader(translations['user.list.email'], true, 'PERSON.CONTACT.MAIL', 'emailColumn'),
                new TableHeader(translations['user.list.firstname'], true, 'PERSON.FIRST_NAME', 'firstNameColumn'),
                new TableHeader(translations['user.list.lastname'], true, 'PERSON.LAST_NAME', 'lastNameColumn'),
                new TableHeader(translations['user.list.customer'], false, 'CUSTOMER_ACCOUNT', 'accountColumn'),
                new TableHeader(translations['user.list.token.amount'], true, 'TOKEN_BALANCE', 'tokenColumn', false, null,
                    translations['user.list.customer.token.amount'] + ' : '),
                new TableHeader(translations['user.list.last.connection'], true, 'LAST_LOGIN_DATE', 'connectionColumn')
            ]);
        this.headers[6].tooltipData = this.aggregations.get('tokenBalance');
    }

    onScroll(): void {
        this.page++;
        this.usersLoading = true;
        this.userService.getUsers(
            this.page,
            this.limit,
            this.searchQuery,
            this.roles.getSelectedValues(),
            this.sort,
            this.customerAccountRef)
        .subscribe(users => {
            this.usersLoading = false;
            this.users = this.users.concat(users.data);
        });
    }

    private search(): void {
        this.page = 0;
        this.wholePageLoader = true;

        if (this.searchSubscription != null) {
            this.searchSubscription.unsubscribe();
        }

        this.searchSubscription = this.userService.getUsers(
            this.page,
            this.limit,
            this.searchQuery ? this.searchQuery : '',
            this.roles.getSelectedValues(),
            this.sort,
            this.customerAccountRef
        ).subscribe((result: Result<FullUser>) => {
            this.wholePageLoader = false;
            this.users = result.data;
            this.aggregations = result.aggregations;
            this.countsMap = new Map([
                [this.TOTAL_KEY, result.totalCount]
            ]);
            this.headers[6].tooltipData = this.aggregations.get('tokenBalance');
        });
    }

    downloadCsv(): void {
        this.downloadService.downloadFileWithParams(
            this.userService.getCSVLink(),
            `utilisateurs-${this.datePipe.transform(Date.now(), 'dd/MM/yy')}`,
            'csv',
            {
                page: this.page,
                limit: this.limit,
                search: this.searchQuery ? this.searchQuery : '',
                roles: this.roles.getSelectedValues().toString(),
                sort: this.sort,
                customerAccountRef: this.customerAccountRef
            }
        );
    }
}
