import {Component, ElementRef, EventEmitter, forwardRef, HostBinding, Input, Output, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'a-textarea',
  templateUrl: './a-textarea.component.html',
  styleUrls: [
    './../forms.scss',
    './a-textarea.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ATextareaComponent),
      multi: true
    }
  ]
})
export class ATextareaComponent implements ControlValueAccessor {

    @HostBinding('class') get class(): string {
        return this.disabled ? '_disabled' : '';
    }

  constructor() {}
  @Input()
  public label: string;

  @Input()
  public placeholder = '';

  @Input()
  public required: boolean;

  @Input()
  public disabled: boolean;

  @Input()
  public delete: boolean;

  @Input()
  public info: string;

  public data: string;

  private onNgChange: (value: string) => void;

  @Output()
  public deleted: EventEmitter<void> = new EventEmitter<void>();
  @Output()
  public blur: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('input')
  public inputField: ElementRef;
  private onNgTouched: () => void = () => {};

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(obj: string): void {
    this.data = obj;
  }

  public onChange(data: string): void {
    this.data = data;
    this.onNgChange(data);
  }

  public onBlur(): void {
    this.blur.next(this.data);
  }

  public onDelete(): void {
    this.deleted.emit();
  }
}
