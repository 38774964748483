import {Injectable} from '@angular/core';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable()
export class LogoutService {

    public logoutEvents: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);

    constructor() {
    }

    public logout(): void {
        this.logoutEvents.next('logout');
    }

    public subscribe(observer: (_) => void): Subscription {
        return this.logoutEvents.subscribe();
    }
}
