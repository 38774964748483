<o-block [headerLabel]="'consumption.last.consumptions.title' | translate">
    <a-dashboard-button-icon button [visibility]="'LOW'" class="cell shrink" [routerLink]="'/boa/consumptions/list'"
                             [queryParams]="queryParams">
        <i class="fas fa-list"></i>
    </a-dashboard-button-icon>
    <ng-template [ngIf]="consumptions?.length > 0" [ngIfElse]="empty">
        <div
            [ngClass]="verticalLayout ? 'grid-y grid-margin-y' : 'grid-x grid-margin-y grid-margin-x align-stretch phone-up-2 tablet-landscape-up-3 medium-desktop-up-4'">
            <div class="cell grid-x lastConsumptions-item" *ngFor="let consumption of consumptions">
                <div class="cell auto">
                    <span class="movement-type">{{'consumption.type.' + consumption.type | translate}}<span *ngIf="consumption.sessionName"> - {{consumption.sessionName}}</span></span>
                    <a-field [label]="'consumption.last.consumptions.date' | translate">
                        <i class="fas fa-calendar-alt"></i>
                        <ng-container value>
                            {{consumption.consumptionDate | boaDateTime : ('common.at' | translate)}}
                        </ng-container>
                    </a-field>
                    <a-field [label]="'consumption.last.consumptions.token.amount' | translate">
                        <i class="fas fa-coins"></i>
                        <ng-container value>
                            {{consumption.amount}} {{'common.token' | translate}}
                        </ng-container>
                    </a-field>
                    <a-field [label]="'consumption.last.consumptions.origin' | translate">
                        <i class="fas fa-user"></i>
                        <ng-container value>
                            {{consumption.sourceAnimator?.getFullName()}}<a *ngIf="consumption.sourceAnimator" [routerLink]="['/boa/users/', consumption.sourceAnimator?.id]"> - {{consumption.sourceAnimator?.login}}</a>
                        </ng-container>
                    </a-field>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #empty>
        <o-empty-list [noResultTitle]="'consumption.last.consumptions.empty.title' | translate"></o-empty-list>
    </ng-template>
</o-block>
