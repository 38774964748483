<o-content-block class="linkedDocuments">
    <div class="grid-x align-middle align-justify">
        <h2 class="cell auto linkedDocuments-title">Liste des documents liés</h2>
        <a-input-upload [label]="'Ajouter un document'"  [disabled]="disabled" (fileChosen)="onFileChosen($event)"></a-input-upload>
    </div>

    <spacer size="16"></spacer>

    <table class="linkedDocuments-table _tableColor">
        <thead>
        <tr>
            <th>Nom de la pièce jointe</th>
            <th>Taille</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let attachment of linkedDocuments">
            <td>{{attachment.fileName}}</td>
            <td>{{attachment.size | boaBytes}}</td>
            <td>
                <div class="grid-x grid-margin-x">
                    <a-button-icon [disabled]="disabled" class="cell shrink" (click)="downloadBlob(attachment)"><i class="fas fa-download"></i></a-button-icon>
                    <a-button-icon [disabled]="disabled" class="cell shrink" (click)="deleteFile(attachment)" [visibility]="'LOW'"><i class="fas fa-trash-alt"></i>
                    </a-button-icon>
                </div>
            </td>
        </tr>
        </tbody>
    </table>

</o-content-block>
