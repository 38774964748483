<div class="cell shrink grid-x align-middle align-justify">
    <h1 class="cell auto">{{'home.dashboard.title' | translate}}</h1>
    <div class="cell shrink grid-x grid-margin-x">
        <a-button-default class="cell shrink" (click)="navigationService.goToProspectCreationPage()">
            <i class="fas fa-plus"></i>{{'customer.action.prospect.create' | translate}}
        </a-button-default>
        <a-split-button (clicked)="navigationService.goToQuoteCreationPage()"
                        (listButtonClicked)="navigationService.goToInvoiceCreationPage()"
                        [buttonsList]="[
                            {
                                label: 'Nouvelle facture',
                                icon: 'fa-plus'
                            }
                        ]"
                        [visibility]="'HIGH'" class="cell shrink">
            <i class="fas fa-plus"></i> Nouveau devis
        </a-split-button>
    </div>
</div>

<spacer [size]="24"></spacer>

<div class="cell shrink">
    <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell phone-24 desktop-6 medium-desktop-8">
            <div class="grid-x grid-margin-x grid-margin-y">
                <o-home-stats class="cell phone-12 desktop-24"></o-home-stats>
                <o-scenario-information class="cell phone-12 desktop-24" [blocTitle]="'home.dashboard.scenarii.stats.title' | translate"></o-scenario-information>
            </div>
        </div>
        <div class="cell phone-24 desktop-18 medium-desktop-16">
            <div class="grid-y grid-margin-x grid-margin-y">
                <o-content-block class="cell shrink">
                    <o-arkhe-last-operations></o-arkhe-last-operations>
                </o-content-block>

                <div class="cell shrink">
                    <div class="grid-x grid-margin-x grid-margin-y">
                        <o-accounting-lists class="cell phone-8"
                                            [verticalLayout]="true"
                                            [listTitle]="'home.dashboard.quotes.to.manage.title'|translate"
                                            [type]="billingDocumentType.QUOTE"
                                            [status]="[quoteStatus.IN_PROGRESS]"
                                            [illustrationId]="1"
                                            [emptyLabel]="'home.dashboard.quotes.to.manage.empty.message'|translate">
                        </o-accounting-lists>
                        <o-accounting-lists class="cell phone-8"
                                            [verticalLayout]="true"
                                            [listTitle]="'home.dashboard.invoice.to.deliver.title'|translate"
                                            [type]="billingDocumentType.INVOICE"
                                            [status]="[invoiceStatus.PAID]"
                                            [isDelivered]="false"
                                            [illustrationId]="4"
                                            [emptyLabel]="'home.dashboard.invoice.to.deliver.empty.message'|translate">
                        </o-accounting-lists>
                        <o-accounting-lists class="cell phone-8"
                                            [verticalLayout]="true"
                                            [listTitle]="'home.dashboard.invoice.late.title'|translate"
                                            [type]="billingDocumentType.INVOICE"
                                            [status]="[invoiceStatus.OVERDUE]"
                                            [illustrationId]="3"
                                            [emptyLabel]="'home.dashboard.invoice.late.empty.message'|translate">
                        </o-accounting-lists>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<spacer [size]="24"></spacer>





