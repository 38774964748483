import {Amount} from './amount';
import {VatRate} from './vat-rate';
import {Type} from 'class-transformer';
import {Article, ArticleType, ServiceKind} from '../article';

export class InvoiceLine {

    constructor(quantity: number,
                unityCost: Amount,
                vatRate: VatRate,
                discount: number,
                grossAmount: Amount,
                netAmount: Amount,
                vatAmount: Amount,
                discountAmount: Amount,
                kind: ServiceKind,
                metadata: InvoiceLineMetadata,
                description: string) {
        this.quantity = quantity;
        this.kind = kind;
        this.unitCost = unityCost;
        this.vatRate = vatRate;
        this.discount = discount;
        this.grossAmount = grossAmount;
        this.netAmount = netAmount;
        this.vatAmount = vatAmount;
        this.discountAmount = discountAmount;
        this.metadata = metadata;
        this.description = description;
    }
    quantity: number;
    @Type(() => Amount)
    unitCost: Amount;
    @Type(() => VatRate)
    vatRate: VatRate;
    discount: number;
    @Type(() => Amount)
    grossAmount: Amount;
    @Type(() => Amount)
    netAmount: Amount;
    @Type(() => Amount)
    vatAmount: Amount;
    @Type(() => Amount)
    discountAmount: Amount;
    @Type(() => InvoiceLineMetadata)
    metadata: InvoiceLineMetadata;
    kind: ServiceKind;
    description: string;

    static empty(): InvoiceLine {
        return new InvoiceLine(0,
            Amount.empty(),
            VatRate.empty(),
            0,
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            undefined,
            InvoiceLineMetadata.empty(),
            '');
    }

    static copy(invoiceLine: InvoiceLine): InvoiceLine {
        return new InvoiceLine(invoiceLine.quantity,
            invoiceLine.unitCost,
            invoiceLine.vatRate,
            invoiceLine.discount,
            invoiceLine.grossAmount,
            invoiceLine.netAmount,
            invoiceLine.vatAmount,
            invoiceLine.discountAmount,
            invoiceLine.kind,
            invoiceLine.metadata,
            invoiceLine.description);
    }


    static articleToInvoiceKind(articleType: ArticleType): ServiceKind {
        switch (articleType) {
            case ArticleType.TOKEN:
                return ServiceKind.SERVICE;
            case ArticleType.ANIMATION:
                return ServiceKind.ANIMATION;
            case ArticleType.FEE:
                return ServiceKind.FEE;
            case ArticleType.SESSION:
                return ServiceKind.SERVICE;
            case ArticleType.WORKS:
                return ServiceKind.WORKS;
       }
    }

    getCorrespondantKind(): ServiceKind {
        if (!this.metadata.article) {
            return null;
        }
        return this.metadata.article.serviceKind;
    }
}

export class InvoiceLineMetadata {
    @Type(() => Article)
    article: Article;
    sessionArticleMetadata: {
        sessionNumber: number;
        teamNumber: number;
        simulationNumber: number;
    };
    emptyLine: boolean;
    isTokenArticleLine: boolean;

    constructor(article: Article,
                sessionArticleMetadata: { sessionNumber: number; teamNumber: number; simulationNumber: number },
                emptyLine: boolean) {
        this.article = article;
        this.sessionArticleMetadata = sessionArticleMetadata;
        this.emptyLine = emptyLine;
    }

    static empty(): InvoiceLineMetadata {
        return new InvoiceLineMetadata(
            undefined,
            {
                sessionNumber: undefined,
                teamNumber: undefined,
                simulationNumber: undefined,
            },
            undefined
        );
    }
}
