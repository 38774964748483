import {Phase1EvaluationType} from './phase-1-evaluation-type';

export class SessionConfiguration {
    phase1Enabled: boolean;
    phase1EvaluationType: Phase1EvaluationType;
    rankingMenuDisabled: boolean;
    initConstruction: number;
    initLand: number;
    initCapital: number;
    baseInvestorsCapital: number;
}
