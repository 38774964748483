<label [class._required]="required">
    {{label}}
    <i *ngIf="info" class="fa fa-info-circle infoIcon"></i>
    <div class="input input-group">
        <input [class._delete]="delete"
               #searchInput
               class="input-group-field"
               [required]="required"
               [class._disabled]="disabled"
               [placeholder]="placeholder"
               [type]="inputType"
               (blur)="onBlur()"
               [ngModel]="data"
               (ngModelChange)="onChange($event)"
               [min]="min"
               [max]="max">
        <span class="input-group-label">
            <i class="fas fa-search"></i>
        </span>
    </div>
</label>
