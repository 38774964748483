<a-select
    [itemList]="vatRates"
    [(ngModel)]="data"
    (ngModelChange)="onChange($event)"
    (blur)="onTouched()"
    [bindLabel]="'label'"
    [bindValue]="'value'"
    [disabled]="disabled"
    [label]="label"
    [placeholder]="'En pourcentage'"
    class="cell phone-12 desktop-13 medium-desktop-10"
    name="customerAccount-vatRatio"
></a-select>
