import {Component, Input, OnInit} from '@angular/core';
import {CustomerAccountContact} from '../../../../../users/models/contact';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
    selector: 'm-customer-billing-contact-edit',
    templateUrl: './m-customer-billing-contact-edit.component.html',
    styleUrls: ['./m-customer-billing-contact-edit.component.scss'],
})
export class MCustomerBillingContactEditComponent implements OnInit {

    @Input()
    billingContact: CustomerAccountContact;

    @Input()
    parentFormGroup: FormGroup;

    constructor(private fb: FormBuilder) { }

    billingContactForm = this.fb.group({
        service: [''],
        firstName: [''],
        lastName: [''],
        mail: [''],
        mobilePhone: ['']
    });

    ngOnInit(): void {
        this.billingContactForm.patchValue(this.billingContact);
        this.parentFormGroup.addControl('billingContact', this.billingContactForm);
    }
}
