import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
    selector: '[boaNotZeroValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: BoaNotZeroValidatorDirective, multi: true}]
})
export class BoaNotZeroValidatorDirective {

    @Input()
    boaNotZeroValidator = false;

    constructor() { }

    validate(control: AbstractControl): ValidationErrors | null {
        if (parseFloat(control.value) === 0 && this.boaNotZeroValidator) {
            return {zero: true};
        } else {
            return null;
        }
    }
}
