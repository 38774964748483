import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CustomerAccountContact} from '../../../../../users/models/contact';
import {AddressType} from '../../../../../common/models/address/addressType';

@Component({
    selector: 'm-customer-addresses-edit',
    templateUrl: './m-customer-addresses-edit.component.html',
    styleUrls: ['./m-customer-addresses-edit.component.scss']
})
export class MCustomerAddressesEditComponent implements OnChanges {
    @Input()
    parentFormGroup: FormGroup;

    @Input()
    contacts: Array<CustomerAccountContact> = [];

    billingContact: CustomerAccountContact;
    deliveryContact: CustomerAccountContact;
    contact: CustomerAccountContact = CustomerAccountContact.empty(null);

    addressType = AddressType;

    addressForm = this.fb.group({
        delivery: this.fb.group({
            name: [''],
            line1: [''],
            line2: [''],
            postalCode: [''],
            city: [''],
            country: ['']
        }),
        billing: this.fb.group({
            name: [''],
            line1: [''],
            line2: [''],
            postalCode: [''],
            city: [''],
            country: ['']
        })
    });

    constructor(private fb: FormBuilder) { }

    fill(contacts: Array<CustomerAccountContact>): void {
        if (contacts && contacts.length > 0) {
            this.uniformiseContacts(contacts);

            this.billingContact = contacts.find(el => el.address?.type === AddressType.BILLING);
            this.deliveryContact = contacts.find(el => el.address?.type === AddressType.DELIVERY);

            this.contact = this.billingContact;

            this.addressForm.get('delivery').patchValue(this.deliveryContact.address);
            this.addressForm.get('billing').patchValue(this.billingContact.address);
        } else { // if empty or inexistant
            this.uniformiseContacts(contacts);
        }
    }

    uniformiseContacts(contacts: CustomerAccountContact[]): void {
        if (!contacts.find(el => el.address?.type === AddressType.BILLING)) {
            contacts.push(CustomerAccountContact.empty(AddressType.BILLING));
        }
        if (!contacts.find(el => el.address?.type === AddressType.DELIVERY)) {
            contacts.push(CustomerAccountContact.empty(AddressType.DELIVERY));
        }
        if (!contacts.find(el => el.address?.type === AddressType.CONTACT)) {
            contacts.push(CustomerAccountContact.empty(AddressType.CONTACT));
        }
    }

    switchBilling(): void {
        this.contact = this.billingContact;
    }

    switchDelivery(): void {
        if (this.deliveryContact) {
            this.contact = this.deliveryContact;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contacts) {
            this.fill(this.contacts);
            this.parentFormGroup.addControl('address', this.addressForm);
        }
    }

}
