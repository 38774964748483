import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {VatRate} from '../../../models/invoices/vat-rate';
import {CompanyService} from '../../../services/company.service';

@Component({
    selector: 'a-select-vat-rate',
    templateUrl: './a-select-vat-rate.component.html',
    styleUrls: ['./a-select-vat-rate.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ASelectVatRateComponent),
            multi: true
        }
    ]
})
export class ASelectVatRateComponent implements OnInit, ControlValueAccessor {

    @Input()
    label: string;

    @Input()
    disabled = false;

    vatRates: {label: string, value: number}[] = [];
    data: number;

    onChange = (newData: number) => {};
    onTouched = () => {};

    constructor(private readonly companyService: CompanyService) {
    }

    ngOnInit(): void {
        this.companyService.getVatRates().subscribe(vatRates =>
            this.vatRates = vatRates.map(vatRate => {
                return {
                    'label': vatRate.label,
                    'value': vatRate.rate / 100
                }
            })
        );
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: number): void {
        this.data = obj
    }
}
