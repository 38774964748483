import {Periodicity} from '../../common/models/scenario/periodicity';

export class BaseMetaScenario {
    name: string;
    creationTokenCost: number;
    unitDecisionTokenCost: number;
    complexityLevel: number;
    periodicity: Periodicity;
    type: string;
    startingMonth: number;
    targetUrl: string;
    targetUrlApi: string;
    metaScenarioRef: string;
}
