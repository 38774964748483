import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[boaPositiveValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: BoaPositiveValidatorDirective, multi: true}]

})
export class BoaPositiveValidatorDirective implements Validator {

    @Input()
    boaPositiveValidator = false;

    constructor() { }

    validate(control: AbstractControl): ValidationErrors | null {
        if (parseInt(control.value, 10) < 0 && this.boaPositiveValidator) {
            return {negative: true};
        } else {
            return null;
        }
    }

}
