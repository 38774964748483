import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
    selector: '[boaMin]',
    providers: [{provide: NG_VALIDATORS, useExisting: BoaMinDirective, multi: true}]
})
export class BoaMinDirective {

    @Input()
    boaMin = null;

    constructor() { }

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.boaMin !== null && parseInt(control.value, 10) < this.boaMin) {
            return {boaMin: true};
        } else {
            return null;
        }
    }
}
