import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree} from '@angular/router';
import {Observable} from 'rxjs/internal/Observable';
import {filter, map} from 'rxjs/operators';
import {ConnectedUserService} from '../services/connected-user.service';

@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(private router: Router,
              private connectedUserService: ConnectedUserService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | true> {
    return this.connectedUserService.fetchCurrent().pipe(
      filter(user => user !== undefined),
      map(user => user !== null && user.role === 'ARKHE'),
      map(isConnectedUser => {
        if (!isConnectedUser) {
          return this.router.createUrlTree(['/login']);
        }
        return true;
      }));
  }
}
