import {Type} from 'class-transformer';
import {
    AnimatorOperationDestination,
    ArkheOperationDestination,
    CustomerOperationDestination,
    OperationDestination,
    OperationDestinationType
} from './operation-destination';
import {BillingDocumentType} from '../../accounting/models/invoices/billing-document';

export class Operation {
    operationDate: number = null;
    label = '';
    type: OperationType;
    title: string;
    amount = 1;
    @Type(() => OperationDestination, {
        discriminator: {
            property: '@type',
            subTypes: [
                { value: ArkheOperationDestination, name:  OperationDestinationType.ARKHE},
                { value: AnimatorOperationDestination, name:  OperationDestinationType.ANIMATOR},
                { value: CustomerOperationDestination, name:  OperationDestinationType.CUSTOMER}
            ]
        },
        keepDiscriminatorProperty: true
    })
    origin: OperationDestination;
    @Type(() => OperationDestination, {
        discriminator: {
            property: '@type',
            subTypes: [
                { value: ArkheOperationDestination, name: OperationDestinationType.ARKHE},
                { value: AnimatorOperationDestination, name: OperationDestinationType.ANIMATOR},
                { value: CustomerOperationDestination, name: OperationDestinationType.CUSTOMER}
            ]
        },
        keepDiscriminatorProperty: true
    })
    target: OperationDestination;
    gift = false;
    linkedDocumentInformation: LinkedDocumentInformation;
    customerAccountRef: string;
    companyName: string;

    constructor(operationDate: number,
                label: string,
                type: OperationType,
                title: string,
                amount: number,
                origin: OperationDestination,
                target: OperationDestination,
                gift: boolean,
                linkedDocumentInformation: LinkedDocumentInformation,
                companyName: string
    ) {
        this.operationDate = operationDate;
        this.label = label;
        this.type = type;
        this.title = title;
        this.amount = amount;
        this.origin = origin;
        this.target = target;
        this.gift = gift;
        this.linkedDocumentInformation = linkedDocumentInformation;
        this.companyName = companyName;
    }

    static getOperationType(operation: Operation): OperationType {
        if (operation.origin['@type'] === OperationDestinationType.ARKHE ||
            operation.target['@type'] === OperationDestinationType.ANIMATOR) {
            return OperationType.CREDIT;
        }
        if (operation.target['@type'] === OperationDestinationType.ARKHE ||
            operation.origin['@type'] === OperationDestinationType.ANIMATOR) {
            return OperationType.DEBIT;
        }
        return OperationType.CREDIT;
    }

    static getOperationTitle(operation: Operation): string {
        if (operation.origin['@type'] === OperationDestinationType.ARKHE ||
            operation.target['@type'] === OperationDestinationType.ARKHE) {
            return 'arkhe';
        } else {
            return 'account.manager';
        }
    }

    getOriginLink(): string {
        return TypeLink[this.origin['@type']];
    }

    getTargetLink(): string {
        return TypeLink[this.target['@type']];
    }
}

export enum OperationType {
    CREDIT = 'CREDIT',
    DEBIT = 'DEBIT'
}

enum TypeLink {
    animator = '/boa/users',
    arkhe = '/boa/users',
    customerAccount = '/boa/customers'
}

export class LinkedDocumentInformation {
    id: string;
    type: BillingDocumentType;
    draft: boolean;


    constructor(id: string, type: BillingDocumentType, draft: boolean) {
        this.id = id;
        this.type = type;
        this.draft = draft;
    }
}
