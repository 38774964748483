import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BillingDocument, BillingDocumentAdditionalInformation, CustomerAccountForTokenDelivery} from '../../../models/invoices/billing-document';
import {CustomerAccount} from '../../../../customers/models/customer-account';
import {ChorusProCustomerSettings} from '../../../../customers/models/chorus-pro-settings';

type CustomerType = 'CHARGED' | 'TOKEN';

@Component({
  selector: 'o-invoice-customers-info',
  templateUrl: './o-invoice-customers-info.component.html',
  styleUrls: ['./o-invoice-customers-info.component.scss']
})
export class OInvoiceCustomersInfoComponent {
  customerTypeDisplayed: CustomerType = 'CHARGED';

  // tslint:disable-next-line:variable-name
  private _billingDocument: BillingDocument;

  @Input()
  set billingDocument(billingDocument: BillingDocument) {
    this._billingDocument = billingDocument;
  }

  get billingDocument(): BillingDocument {
    return this._billingDocument;
  }

  @Input()
  customerAccount: CustomerAccount;

  @Output()
  customerAccountChange = new EventEmitter<CustomerAccount>();

  @Input()
  disabled: boolean;

  @Input()
  invalid: boolean;

  @Output()
  invalidChange = new EventEmitter<boolean>();

  @Input()
  dirty: boolean;

  @Output()
  dirtyChange = new EventEmitter<boolean>();

  @Output()
  customerAccountIdChange = new EventEmitter<string>();

  @Output()
  vatRateChange = new EventEmitter<number>();

  // select des customers

  @Input()
  allCustomersCode = [];

  @Input()
  allCustomersName = [];

  @Input()
  chorusProSettings: ChorusProCustomerSettings;

  displayCustomerType(customerType: CustomerType): void {
    this.customerTypeDisplayed = customerType;
  }

  isChargedCustomerDisplayed(): boolean {
    return this.customerTypeDisplayed === 'CHARGED';
  }

  areTokenCustomersDisplayed(): boolean {
    return this.customerTypeDisplayed === 'TOKEN';
  }

  onDeliverTokenToOtherCustomersToggle(newValue: any): void {
    if (newValue && typeof newValue === 'boolean') {
      this.customerTypeDisplayed = 'TOKEN';
    } else {
      this.customerTypeDisplayed = 'CHARGED';
    }
  }

  onTokenDeliveryCustomersTargetUpdate(tokenDeliveryCustomersTargetUpdate: CustomerAccountForTokenDelivery[]): void {
    if (!this.billingDocument.billingDocumentAdditionalInformation) {
      this.billingDocument.billingDocumentAdditionalInformation = BillingDocumentAdditionalInformation.empty();
    }
    this.billingDocument
      .billingDocumentAdditionalInformation
      .additionalCustomerAccountsForTokenDelivery = tokenDeliveryCustomersTargetUpdate;
    this.dirty = true;
    this.dirtyChange.emit(true);
  }
}
