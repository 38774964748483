import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {BillingDocument} from '../../../models/invoices/billing-document';
import {CustomerAccountService} from '../../../../customers/services/customer-account.service';
import {CustomerAccount} from '../../../../customers/models/customer-account';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {Credit} from '../../../models/invoices/credit';
import {ChorusProCustomerSettings} from '../../../../customers/models/chorus-pro-settings';

@Component({
    selector: 'o-invoice-charged-customer',
    templateUrl: './o-invoice-charged-customer.component.html',
    styleUrls: ['./o-invoice-charged-customer.component.scss']
})
export class OInvoiceChargedCustomerComponent implements OnChanges, AfterViewInit {
    private _billingDocument: BillingDocument;

    @Input()
    set billingDocument(billingDocument: BillingDocument) {
        if (this._chorusProSettings) {
            this.isChorusProServiceMandatory = billingDocument.isChorusProServiceMandatory(this._chorusProSettings);
        }
        this._billingDocument = billingDocument;
    }

    get billingDocument(): BillingDocument {
        return this._billingDocument;
    }

    @Input()
    customerAccount: CustomerAccount;

    @Input()
    customerMenu: boolean;

    @Output()
    customerAccountChange = new EventEmitter<CustomerAccount>();

    @Input()
    disabled: boolean;

    @Input()
    invalid: boolean;

    @Output()
    invalidChange = new EventEmitter<boolean>();

    @Input()
    dirty: boolean;

    get chorusProSettings(): ChorusProCustomerSettings {
        return this._chorusProSettings;
    }

    @Input()
    set chorusProSettings(chorusProCustomerSettings: ChorusProCustomerSettings) {
        if (this.billingDocument) {
            this.isChorusProServiceMandatory = this.billingDocument.isChorusProServiceMandatory(chorusProCustomerSettings);
        }
        this._chorusProSettings = chorusProCustomerSettings;
    }

    private _chorusProSettings: ChorusProCustomerSettings;

    @Output()
    dirtyChange = new EventEmitter<boolean>();

    @ViewChild('form1')
    form1: NgForm = new NgForm(null, null);

    @ViewChild('form2')
    form2: NgForm = new NgForm(null, null);

    @Output()
    customerAccountIdChange = new EventEmitter<string>();

    @Output()
    vatRateChange = new EventEmitter<number>();

    isInvoice = false;
    isInProgress = false;

    // select des customers

    @Input()
    allCustomersCode = [];

    @Input()
    allCustomersName = [];

    customerAccountId: string;
    isCredit: boolean;
    isChorusProServiceMandatory: boolean;

    constructor(private customerService: CustomerAccountService,
                private changeDetector: ChangeDetectorRef,
                private router: Router) { }

    // on changes needed because customerAccount is retrieved async (oninit would be too soon in the lifecycle)
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.billingDocument) {
            this.isInProgress = this.billingDocument.isInProgress();
            this.isCredit = this.billingDocument instanceof Credit;
        }

        if (changes.customerAccount) {
            this.customerAccountId = this.customerAccount?.id;
        }
    }

    onCustomerChange(customerAccountId: string): void {
        if (customerAccountId) {
            this.customerService.getCustomerAccountById(customerAccountId)
                .subscribe(customerAccount => {
                    this.customerAccount = customerAccount;
                    this.billingDocument.customer.chorusProServiceCode = null;

                    this.customerAccountChange.emit(customerAccount);
                    this.customerAccountIdChange.emit(customerAccountId);
                    this.vatRateChange.emit(customerAccount.vatRatio);
                    this.dirty = true;
                    this.dirtyChange.emit(this.dirty);
                });
        }
    }

    onVatRateChange(newVatRate: number): void {
        this.vatRateChange.emit(newVatRate.valueOf());
        this.dirty = true;
        this.dirtyChange.emit(this.dirty);
    }

    ngAfterViewInit(): void {
        if (this.disabled) {
            return;
        }

        combineLatest([
            this.form1.statusChanges,
            this.form2.statusChanges
        ]).subscribe(([_, __]) => {
            const invalid = this.form1?.invalid || this.form2?.invalid;
            if (invalid !== this.invalid) {
                this.invalid = invalid;
                this.invalidChange.emit(this.invalid);
            }

            this.changeDetector.detectChanges();
        });
    }

    goToCustomerAccountPage(): void {
        this.router.navigate(['boa', 'customers', this.customerAccountId]);
    }

    refreshCustomer(): void {
        this.onCustomerChange(this.customerAccountId);
    }
}
