<div class="quickAccess-menu" [ngClass]="{_open: isNotesOpened || isLastConnectionsOpened}">
    <!-- Dernières connexions -->
    <div class="quickItem"  [ngClass]="{_actif: isLastConnectionsOpened}" (click)="isLastConnectionsOpened = !isLastConnectionsOpened; isNotesOpened = false">
        <div class="quickItem-icon">
            <img src="../../../../../../../assets/images/svg/quick-access/quick-last-connexion.svg">
        </div>
        <span class="quickItem-name">{{'o-quick-access.last-connections' | translate}}</span>
    </div>
    <!-- Notes -->
    <div class="quickItem" [ngClass]="{_actif: isNotesOpened}" (click)="isNotesOpened = !isNotesOpened; isLastConnectionsOpened = false">
        <div class="quickItem-icon">
            <img src="../../../../../../../assets/images/svg/quick-access/quick-notes.svg">
        </div>
        <span class="quickItem-name">{{'o-quick-access.notes' | translate}}</span>
        <span *ngIf="count > 0" class="quickItem-alert">{{count}}</span>
    </div>
</div>

<div class="quickAccess-detail" [ngClass]="{_open: isLastConnectionsOpened}">
    <o-last-connections></o-last-connections>
</div>

<div class="quickAccess-detail" [ngClass]="{_open: isNotesOpened}">
    <o-notes></o-notes>
</div>
