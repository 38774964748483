<m-card-header [label]="'user.connection.information.title'|translate"></m-card-header>

<spacer size="16"></spacer>

<a-field [label]="'user.connection.information.username'|translate">
    <i class="fas fa-user"></i>
    <ng-container value>
        {{user?.login}}
    </ng-container>
</a-field>

<a-field [label]="'user.connection.information.last.connection'|translate">
    <i class="fas fa-hourglass-end"></i>
    <ng-container value>
        {{user?.lastLoginDate | boaDateTime : ('common.at'|translate)}}
    </ng-container>
</a-field>

<ng-template [ngIf]="mode !== 'simple'">
    <hr>

    <o-reset-password [user]="user"></o-reset-password>
</ng-template>
