import {Component, Input, OnInit} from '@angular/core';
import {Operation} from '../../../models/operation';
import {OperationsService} from '../../../services/operations.service';
import {NavigationEnd, Router} from '@angular/router';
import {OperationQueryFilter} from '../../../models/operation-query-filter';

@Component({
    selector: 'o-arkhe-last-operations',
    templateUrl: './o-arkhe-last-operations.component.html',
    styleUrls: ['./o-arkhe-last-operations.component.scss']
})
export class OArkheLastOperationsComponent implements OnInit {


    @Input()
    verticalLayout = false;

    operations: Array<Operation> = [];

    private limit = 4;

    constructor(private operationService: OperationsService, private router: Router) {
    }

    private load(): void {
        this.operationService.getArkheLasts(this.limit).subscribe(data => {
            this.operations = data.map(operation => {
                operation.type = Operation.getOperationType(operation);
                operation.title = Operation.getOperationTitle(operation);
                return operation;
            });
        });
    }

    ngOnInit(): void {
        this.load();
    }

    onViewOperationsClick(): void {
        this.router.navigate(['/boa/operations/list'], {
            queryParams: {
                operationQueryFilter: [
                    OperationQueryFilter.ARKHE_TO_ACCOUNT_MANAGER,
                    OperationQueryFilter.ACCOUNT_MANAGER_TO_ARKHE]
            }
        });
    }
}
