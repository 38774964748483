import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountManager} from '../../../models/account-manager';
import {CustomerAccount} from '../../../../customers/models/customer-account';
import {Role} from '../../../models/role';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AddressType} from '../../../../common/models/address/addressType';
import {StringUtils} from '../../../../../shared/string-utils';

@Component({
  selector: 'o-user-manager-information',
  templateUrl: './o-user-manager-information.component.html',
  styleUrls: ['./o-user-manager-information.component.scss']
})
export class OUserManagerInformationComponent implements OnInit {

    @Input()
    manager: AccountManager;

    @Input()
    customerAccount: CustomerAccount;

    @Input()
    showTransferTokens = true;

    @Output()
    transferClicked = new EventEmitter<void>();

    role = Role;
    creationMode = false;
    managerBuffer: AccountManager;
    creationLoading = false;

    constructor(private router: Router,
                private userService: UserService,
                private translateService: TranslateService,
                private notificationService: ToastrService,
                private activeRoute: ActivatedRoute) { }

    ngOnInit(): void {
        this.managerBuffer = new AccountManager();
    }

    transferTokens(): void {
        this.transferClicked.emit();
    }

    createAccountManagerForCustomer(): void {
        this.creationMode = true;
        if (!this.managerBuffer.login) {
            const accountName = StringUtils.normalize(this.customerAccount.abbreviation
                ? this.customerAccount.abbreviation : this.customerAccount.name);
            const city = StringUtils.normalize(this.customerAccount.getContact(AddressType.BILLING)?.address.city);
            this.managerBuffer.login = `${accountName}.${city}.gs`;
        }
    }

    refresh(): void {
        this.router.navigate([], {
            relativeTo: this.activeRoute
        });
    }

    submitCreation(): void {
        this.creationMode = false;
        this.creationLoading = true;
        this.managerBuffer.customerAccount = this.customerAccount;
        this.managerBuffer.customerAccountRef = this.customerAccount.id;
        this.userService.createUser(this.managerBuffer).subscribe(_ => {
            this.creationLoading = true;
            this.managerBuffer = new AccountManager();
            this.notificationService.success(this.translateService.instant('common.success'));
            this.refresh();
        }, err => {
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    cancelCreation(): void {
        this.creationMode = false;
        this.managerBuffer = new AccountManager();
    }
}
