import _ from 'lodash';

export function sirenFormatter(value: string | number): string {
    return _.toString(value).match(/.{1,3}/g).join(' ');
}

export function siretFormatter(value: string | number): string {
    let val = _.toString(value);
    val = sirenFormatter(val.substring(0, 9)) + val.substring(10, 13);
    return val;
}
