import {Component, Input, OnInit} from '@angular/core';
import {Operation} from '../../../models/operation';
import {OperationsService} from '../../../services/operations.service';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'o-customer-last-operations',
  templateUrl: './o-customer-last-operations.component.html',
  styleUrls: ['./o-customer-last-operations.component.scss']
})
export class OCustomerLastOperationsComponent {
    // tslint:disable-next-line:variable-name
    private _customerAccountId: string;

    @Input()
    set customerAccountId(customerAccountId: string) {
        this.load(customerAccountId);
        this._customerAccountId = customerAccountId;
    }

    get customerAccountId(): string {
        return this._customerAccountId;
    }

    @Input()
    verticalLayout = false;

    operations: Array<Operation> = [];

    private limit = 4;

    constructor(private operationService: OperationsService, private router: Router) { }

    private load(customerAccountId: string): void {
        this.operationService.getCustomerLasts(this.limit, customerAccountId).subscribe( data => {
            this.operations = data.map(operation => {
                operation.type = Operation.getOperationType(operation);
                operation.title = Operation.getOperationTitle(operation);
                return operation;
            });
        });
    }
}
