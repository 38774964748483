import {Component, Input, OnInit} from '@angular/core';
import {SortDirection} from '../../../../models/sort-direction';

@Component({
  selector: 'a-button-sort',
  templateUrl: './a-button-sort.component.html',
  styleUrls: ['./a-button-sort.component.scss']
})
export class AButtonSortComponent implements OnInit {

    @Input()
    public active: boolean;

    @Input()
    public direction: 'ASC' | 'DESC';

    public isAsc: boolean;

    constructor() { }

    ngOnInit(): void {
        this.isAsc = this.direction === SortDirection.ASC;
    }
}
