import {Component, Input, OnInit} from '@angular/core';
import {Role} from '../../../../newboa/users/models/role';
import {AnimatorType} from '../../../../newboa/users/models/user';

@Component({
  selector: 'o-block',
  templateUrl: './o-block.component.html',
  styleUrls: ['./o-block.component.scss']
})
export class OBlockComponent implements OnInit {

    @Input()
    headerLabel = '';

    @Input()
    userType: Role | AnimatorType = null;

    constructor() { }

    ngOnInit(): void {
    }

}
