import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {Note, NoteLinkedToBillingDocument, NoteLinkedToUser} from '../../../../notes/models/Note';
import {NoteService} from '../../../../notes/service/note.service';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {NoteDocumentReferenceType} from '../../../../notes/models/NoteDocumentReferenceType';
import {Role} from '../../../../users/models/role';
import {BillingDocument} from '../../../../accounting/models/invoices/billing-document';
import { FullUser } from 'src/app/modules/newboa/users/models/full-user';
import {InvoiceComputingUtils} from '../../../../accounting/invoice-computing-utils';

@Component({
    selector: 'm-note',
    templateUrl: './m-note.component.html',
    styleUrls: ['./m-note.component.scss']
})
export class MNoteComponent {
    linkedUser: FullUser | null = null;
    linkedBilling: BillingDocument | null = null;

    @HostBinding('class') get class(): string {
        let classBuffer = '';

        if (this.note) {
            if (this.note.author) {
               classBuffer = classBuffer + ' _manual';
            }
            if (this.note.priority) {
                classBuffer = classBuffer + ' _important';
            }
            if (this.note.treated) {
                classBuffer = classBuffer + ' _read';
            }
        }

        if (this.linkedUser && this.linkedUser.role === Role.ANIMATOR) {
            classBuffer = classBuffer + ' ' + this.linkedUser.animatorType;
        }
        if (this.linkedUser && this.linkedUser.role !== Role.ANIMATOR) {
            classBuffer = classBuffer + ' ' + this.linkedUser.role;
        }
        if (this.linkedBilling) {
            classBuffer = classBuffer + ' ' + this.linkedBilling.billingDocumentType;
        }
        return classBuffer;
    }

    // tslint:disable-next-line:variable-name
    private _note: Note | NoteLinkedToUser | NoteLinkedToBillingDocument;

    get note(): Note | NoteLinkedToUser | NoteLinkedToBillingDocument  {
        return this._note;
    }

    @Input()
    set note(note: Note) {
        if (note.documentReferenceType === NoteDocumentReferenceType.BILLING) {
            this.linkedBilling = InvoiceComputingUtils.instantiateBillingDocument((note as NoteLinkedToBillingDocument).linkedBilling);
        } else if (note.documentReferenceType === NoteDocumentReferenceType.USER) {
            this.linkedUser = (note as NoteLinkedToUser).linkedUser;
        }

        const author = note.author;

        if (author) {
            this.authorName = author.person.firstName + ' ' + author.person.lastName;
        }

        this._note = note;
    }

    @Output()
    noteMarkedAsRead = new EventEmitter<void>();

    authorName: string = null;
    role = Role;

    constructor(private readonly noteService: NoteService,
                private readonly translateService: TranslateService,
                private readonly notificationService: NotificationService) { }

    markAsRead(): void {
        this.noteService.updateStatus(this.note.id, true).subscribe(_ => {
            this.notificationService.success(this.translateService.instant('common.success'));
            this.noteMarkedAsRead.emit();
        });
    }
}
