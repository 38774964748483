import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BillingDocument} from '../../../models/invoices/billing-document';
import {Attachment} from '../../../../attachments/models/attachment';
import {DownloadService} from '../../../../common/services/download.service';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {AttachmentService} from '../../../../attachments/services/attachment.service';
import {BillingDocumentService} from '../../../services/billing-document.service';
import {HttpErrorResponse} from '@angular/common/http';
import {InvoiceHttpService} from '../../../services/invoice-http.service';

@Component({
    selector: 'o-linked-documents',
    templateUrl: './o-linked-documents.component.html',
    styleUrls: ['./o-linked-documents.component.scss']
})
export class OLinkedDocumentsComponent implements OnInit {

    @Input()
    disabled = false;

    @Input()
    billingDocument: BillingDocument;

    @Input()
    linkedDocuments: Attachment[] = [];

    @Output()
    fileEmitted = new EventEmitter<void>();

    @Output()
    fileDeleted = new EventEmitter<void>();

    constructor(private readonly invoiceService: InvoiceHttpService,
                private billingDocumentService: BillingDocumentService,
                private notificationService: ToastrService,
                private translateService: TranslateService,
                private attachmentService: AttachmentService,
                private downloadService: DownloadService) {
    }

    ngOnInit(): void {
    }

    onFileChosen(file: File): void {
        this.billingDocumentService.uploadLinkedDocument(file, this.billingDocument.id).subscribe(_ => {
            this.notificationService.success(this.translateService.instant('common.success'));
            this.fileEmitted.emit();
        }, (err: HttpErrorResponse) => {
            this.notificationService.error(this.translateService.instant(err.headers.get('x_exception_message')));
        });
    }

    downloadBlob(attachment: Attachment): void {
        this.downloadService.downloadFile(`api/attachments/${attachment.id}/blob`, attachment.fileName, null);
    }

    deleteFile(attachment: Attachment): void {
        this.attachmentService.delete(attachment.id).subscribe( _ => {
            this.notificationService.success(this.translateService.instant('common.success'));
            this.fileDeleted.emit();
        }, err => {
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }
}
