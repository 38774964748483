import {Component, Input, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
    selector: 'a-input-upload',
    templateUrl: './a-input-upload.component.html',
    styleUrls: ['./a-input-upload.component.scss']
})
export class AInputUploadComponent implements OnInit {

    @Input()
    disabled = false;

    @Input()
    label: string;

    @Output()
    fileChosen = new EventEmitter<File>();

    constructor() { }

    ngOnInit(): void {
    }

    onFileSelected(files: FileList): void{
        const file: File = files.item(0);
        if (file) {
            this.fileChosen.emit(file);
        }
    }
}
