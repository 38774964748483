import {
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostBinding,
    Input,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'a-input',
    templateUrl: './a-input.component.html',
    styleUrls: [
        './../forms.scss',
        './a-input.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AInputComponent),
            multi: true
        }
    ]
})
export class AInputComponent implements ControlValueAccessor {

    @HostBinding('class') get class(): string {
        return this.disabled ? '_disabled' : '';
    }

    constructor() {}

    @Input()
    public name: string;

    @Input()
    public inputType = 'text';

    @Input()
    public alignment = '';

    @Input()
    public label: string;

    @Input()
    public helpTextID: string;

    @Input()
    public placeholder = '';

    @Input()
    public required: boolean;

    @Input()
    public disabled: boolean;

    @Input()
    public delete: boolean;

    @Input()
    public info: string;

    @Input()
    public min: number;

    @Input()
    public max: number;

    @Input()
    public boaNotBlank = false;

    @Input()
    public boaMax: number = null;

    @Input()
    public boaUniqueLogin = false;

    @Input()
    public minlength: number = null;

    @Input()
    public maxlength: number = null;

    public data: string;

    @Input()
    warningTemplate: TemplateRef<any>;

    @Output()
    public deleted: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public touched: EventEmitter<string> = new EventEmitter<string>();

    @Output()
    public blur: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('input')
    public inputField: ElementRef;

    private onNgChange: (value: string) => void = () => {};
    private onNgTouched: () => void = () => {};

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(obj: string): void {
        this.data = obj;
    }

    public onChange(data: string): void {
        this.data = data;
        this.onNgChange(data);
    }

    public onTouched(): void {
        this.touched.next(this.data);
        this.onNgTouched();
    }

    public onBlur(): void {
        this.blur.emit();
    }

    public onDelete(): void {
        this.deleted.emit();
    }
}
