import {Component, OnInit} from '@angular/core';
import {StatisticsService} from '../../../../statistics/services/statistics.service';
import {AccountingDashboardStats} from '../../../../statistics/models/accounting-dashboard';

@Component({
    selector: 'o-accounting-stats',
    templateUrl: './o-accounting-stats.component.html',
    styleUrls: ['./o-accounting-stats.component.scss']
})
export class OAccountingStatsComponent implements OnInit {

    accountingStats: AccountingDashboardStats;

    constructor(private statisticsService: StatisticsService) {
    }

    ngOnInit(): void {
        this.statisticsService.computeAccountingDashboardStats()
            .subscribe(accountingStats => this.accountingStats = accountingStats);
    }

}
