import {Observable} from 'rxjs/internal/Observable';
import {AnimatorType, User} from '../models/user';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';
import {Result} from '../../common/models/shared/result';
import {Role} from '../models/role';
import {Animator} from '../models/animator';
import {AccountManager} from '../models/account-manager';
import {FullUser} from '../models/full-user';
import {CredentialGenerationRequest} from '../models/credential-generation-request';
import {PasswordChange} from '../models/password-change';
import {SelectEntry} from '../../common/models/select/select-entry';
import {Player} from '../models/player';
@Injectable({
    providedIn: 'root'
})
export class UserService {

    private baseUrl = '/api/users';

    constructor(private http: HttpClient) {
    }

    public getUsersMap(
        page: number,
        limit: number,
        search: string = '',
        roles: Array<string> = [],
        sort: string = '',
        customerAccountRef: string = null,
        responseType = 'name_map'
    ): Observable<Array<SelectEntry>> {

        let params = new HttpParams();

        if (roles.includes('ALL')) {
            roles = Object.keys(Role).concat(AnimatorType.DEMONSTRATION.toString());
        }

        params = params.appendAll({
            page,
            limit,
            search,
            sort,
            customerAccountRef,
            roles: roles.toString(),
            aggregate: 'tokenBalance',
            responseType
        });

        return this.http.get(`${this.baseUrl}`, {
            params
        }).pipe(
            map(el => plainToClass(SelectEntry, el as object[]))
        );
    }

    public getUsers(page: number,
                    limit: number,
                    search: string = '',
                    roles: Array<string> = [],
                    sort: string = '',
                    customerAccountRef: string = null): Observable<Result<FullUser>> {

        let params = new HttpParams();

        if (roles.includes('ALL')) {
            roles = Object.keys(Role).concat(AnimatorType.DEMONSTRATION.toString());
        }

        params = params.appendAll({
            page,
            limit,
            search,
            sort,
            customerAccountRef,
            roles: roles.toString(),
            aggregate: 'tokenBalance'
        });

        return this.http.get(`${this.baseUrl}`, {
            params,
            observe: 'response'
        }).pipe(
            map(users => new Result(
                    (users.body as [object]).map(user => plainToClass(FullUser, user as object)),
                    parseInt(users.headers.get('X_TOTAL_COUNT'), 10),
                    new Map([['tokenBalance', parseInt(users.headers.get('X_AGGREGATE_TOKENBALANCE'), 10)]])
                )
            )
        );
    }

    public getUserById(id: string): Observable<FullUser> {
        return this.http.get(`${this.baseUrl}/${id}`).pipe(
            map(user => plainToClass(FullUser, user as object))
        );
    }

    public getCSVLink(): string {
        return `${this.baseUrl}/csv`;
    }

    public getCustomerAccountsAnimatorLastConnections(customerAccountRef: string, limit: number = null): Observable<Result<User>> {
        const params = limit ? {
            customerAccountRef,
            limit
        } : {
            customerAccountRef
        };

        return this.http.get(`${this.baseUrl}/customerAccount/animators/lasts`, {
            params,
            observe: 'response'
        }).pipe(
            map(result => {
                return new Result(
                    (result.body as [User])?.map(user => plainToClass(getUserType(user.role), user as object)),
                    parseInt(result.headers.get('X_TOTAL_COUNT'), 10),
                    null
                );
            })
        );
    }

    public getLastConnections(roles: string = null,
                              limit: number = null,
                              includeDate: boolean = false,
                              customerAccountRef: string = null): Observable<Result<User>> {
        return this.http.get(`${this.baseUrl}/lastConnections`, {
            params: {
                roles,
                limit,
                includeDate,
                customerAccountRef
            },
            observe: 'response'
        }).pipe(
            map(result => {
                return new Result(
                    (result.body as [User])?.map(user => plainToClass(getUserType(user.role), user as object)),
                    parseInt(result.headers.get('X_TOTAL_COUNT'), 10),
                    null
                );
            })
        );
    }

    updateUser(user: FullUser): Observable<FullUser> {
        return this.http.put(`${this.baseUrl}/${user.id}`, user)
            .pipe(map(result => plainToClass(FullUser, result)));
    }

    createUser(user: User): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}`, user);
    }

    changePassword(userId: string, newPassword: string, sendMail: boolean): Observable<void> {
        const passwordChange = new PasswordChange(null, newPassword, sendMail);
        return this.http.put<void>(`${this.baseUrl}/${userId}/password`, passwordChange);
    }

    generateCredentials(credentialGenerationRequest: CredentialGenerationRequest): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/${credentialGenerationRequest.userId}/credentials`, credentialGenerationRequest);
    }

    getAccountUserByLogin(login: string): Observable<FullUser> {
        return this.http.get(`${this.baseUrl}/login/${login}`)
            .pipe(
                map(result => plainToClass(FullUser, result))
            );
    }

    doesAnUserExistWith(login: string): Observable<boolean> {
        return this.http.get<boolean>(`${this.baseUrl}/login/exists/${login}`);
    }

    findCustomerAccountManager(customerAccountId: string): Observable<FullUser> {
        return this.http.get(`${this.baseUrl}/customerAccount/manager/${customerAccountId}`)
            .pipe(
                map(result => plainToClass(FullUser, result))
            );
    }

    getPlayer(playerId: string): Observable<Player> {
        return this.http.get(`${this.baseUrl}/${playerId}`)
            .pipe(map(el => plainToClass(Player, el as object)));
    }
}

export function getUserType(role: Role): any {
    switch (role) {
        case Role.ANIMATOR:
            return Animator;
        case Role.ACCOUNT_MANAGER:
            return AccountManager;
        default:
            return User;
    }
}
