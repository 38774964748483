import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Animator} from '../../users/models/animator';
import {Observable} from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class AnimatorAccountService {

    private baseUrlCustomer = '/api/customerAccounts';
    private baseUrlAnimator = '/api/animators';

    constructor(private http: HttpClient) { }

    createDemonstrationAnimator(animator: Animator,  customerAccountId: string): Observable<void> {
        return this.http.post<void>(`${this.baseUrlCustomer}/${customerAccountId}/demonstrationAnimators`, animator);
    }
}
