import {
    BillingDocument, BillingDocumentAdditionalInformation,
    BillingDocumentMetadata,
    BillingDocumentType, buildFileNameSuffix,
} from './billing-document';
import {CurrencyEnum} from './currency-enum';
import {Customer} from './customer';
import {Amount} from './amount';
import {Vendor} from './vendor';
import {InvoiceLine} from './invoice-line';
import {QuoteStatus} from './quote';
import {InvoiceStatus} from './invoice';
import {ChorusProCustomerSettings} from '../../../customers/models/chorus-pro-settings';

export class Credit implements BillingDocument {
    billingDocumentType: BillingDocumentType;
    currency: CurrencyEnum;
    customer: Customer;
    date: Date;
    detail: string;
    discountAmount: Amount;
    grossAmount: Amount;
    id: string;
    legalNotice: string;
    tokensArticlesLines: Array<InvoiceLine>;
    articlesLines: Array<InvoiceLine>;
    metadata: BillingDocumentMetadata;
    netAmount: Amount;
    reference: string;
    showLegalNoticeForeignCustomer: boolean;
    subject: string;
    vatAmount: Amount;
    vatsAmount: Array<Amount>;
    vendor: Vendor;
    withVAT: boolean;
    grossAmountAfterDiscount: Amount;
    trustValues: boolean;
    billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation;
    chorusProSendFailError: string;

    invoiceRef: string;
    reason: string;

    constructor(billingDocumentType: BillingDocumentType,
                currency: CurrencyEnum,
                customer: Customer,
                date: Date,
                detail: string,
                discountAmount: Amount,
                grossAmount: Amount,
                id: string,
                legalNotice: string,
                tokensArticlesLines: Array<InvoiceLine>,
                articlesLines: Array<InvoiceLine>,
                metadata: BillingDocumentMetadata,
                netAmount: Amount,
                reference: string,
                showLegalNoticeForeignCustomer: boolean,
                subject: string,
                vatAmount: Amount,
                vatsAmount: Array<Amount>,
                vendor: Vendor,
                withVAT: boolean,
                invoiceRef: string,
                reason: string,
                grossAmountAfterDiscount: Amount,
                trustValues: boolean,
                billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation) {
        this.billingDocumentType = billingDocumentType;
        this.currency = currency;
        this.customer = customer;
        this.date = date;
        this.detail = detail;
        this.discountAmount = discountAmount;
        this.grossAmount = grossAmount;
        this.id = id;
        this.legalNotice = legalNotice;
        this.tokensArticlesLines = tokensArticlesLines;
        this.articlesLines = articlesLines;
        this.metadata = metadata;
        this.netAmount = netAmount;
        this.reference = reference;
        this.showLegalNoticeForeignCustomer = showLegalNoticeForeignCustomer;
        this.subject = subject;
        this.vatAmount = vatAmount;
        this.vatsAmount = vatsAmount;
        this.vendor = vendor;
        this.withVAT = withVAT;
        this.invoiceRef = invoiceRef;
        this.reason = reason;
        this.grossAmountAfterDiscount = grossAmountAfterDiscount;
        this.trustValues = trustValues;
        this.billingDocumentAdditionalInformation = billingDocumentAdditionalInformation;
        this.billingDocumentType = BillingDocumentType.CREDIT;
    }

    getDetailLink(): string {
        return `credit/${this.id}`;
    }

    getFileName(): string {
        return `Avoir ${buildFileNameSuffix(this)}`;
    }

    getPdfLink(): string {
        return `/api/invoice/credit/print/${this.id}`;
    }

    getReference(): string {
        return this.reference;
    }

    getStatus(): QuoteStatus | InvoiceStatus {
        return undefined;
    }

    getType(): BillingDocumentType {
        return BillingDocumentType.CREDIT;
    }

    isInProgress(): boolean {
        return this.id === null;
    }

    isStrictlyInProgress(): boolean {
        return false;
    }

    duplicate(): BillingDocument {
        throw Error('[Credit.Ts] - A credit can\'t be duplicated');
    }

    isChorusProServiceMandatory(chorusProSettings: ChorusProCustomerSettings): boolean {
        return false;
    }
}
