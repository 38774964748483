import { Component } from '@angular/core';
import {LocalStorageService} from './services/local-storage.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app-root.view.html',
  styleUrls: ['./app-root.view.scss']
})
export class AppRootComponent {
  constructor(private translate: TranslateService,
              private localStorageService: LocalStorageService) {
    translate.setDefaultLang('fr');
    translate.onLangChange.subscribe(({lang}) => {
      localStorageService.lang = lang;
    });
    const local = localStorageService.lang || translate.getDefaultLang();
    translate.use(local);

  }
}
