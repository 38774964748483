import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {MonthItem} from '../../../models/month-item';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ASelectMonthComponent),
    multi: true
};

@Component({
    selector: 'a-select-month',
    templateUrl: './a-select-month.component.html',
    styleUrls: ['./a-select-month.component.scss'],
    providers: [VALUE_ACCESSOR]
})

export class ASelectMonthComponent implements OnInit, ControlValueAccessor {

    @Input()
    label: string;
    @Input()
    name: string;

    public month: number;
    public monthsList: Array<MonthItem> = [];
    private onNgChange: (month: number) => void;
    onNgTouched: () => void;

    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
        for (let i = 1; i <= 12; i++) {
            this.monthsList.push(new MonthItem(i, this.translateService.instant(`common.monthly.${i}`)));
        }
        this.writeValue(new Date().getMonth() + 1);
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(month: number): void {
        this.month = month;
    }

    onChange(month: number): void {
        this.month = month;
        this.onNgChange(month);
    }

}

