import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'm-input-tooltip',
    templateUrl: './m-input-tooltip.component.html',
    styleUrls: ['./m-input-tooltip.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MInputTooltipComponent),
            multi: true
        }
    ]
})
export class MInputTooltipComponent implements OnInit, ControlValueAccessor {

    @Input()
    tooltipLabel;

    @Input()
    name: string;

    @Input()
    inputType = 'text';

    @Input()
    label: string;

    @Input()
    helpTextID: string;

    @Input()
    placeholder = '';

    @Input()
    required: boolean;

    @Input()
    disabled: boolean;

    @Input()
    delete: boolean;

    @Input()
    info: string;

    @Input()
    min: number;

    @Input()
    max: number;

    @Input()
    notBlank = false;

    data: string;

    private onNgChange: (value: any) => void = () => {};
    private onNgTouched: () => void = () => {};

    constructor() {
    }

    ngOnInit(): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(obj: string): void {
        this.data = obj;
    }

    public onChange(data: string): void {
        this.data = data;
        this.onNgChange(this.data);
    }

}
