export class AddressInvoice {
    constructor(public body: string,
                public zipCode: string,
                public city: string,
                public country: string,
                public additionalAddress: string) {
    }

    static empty(): AddressInvoice {
        return new AddressInvoice('', '', '', '', '');
    }

    isEmpty(): boolean {
        return this.body.length === 0 &&
            this.zipCode.length === 0 &&
            this.city.length === 0 &&
            this.country.length === 0 &&
            this.additionalAddress.length === 0;
    }
}


export class InvoiceAddressWithName {
    constructor(
        public name: string,
        public body: string,
        public zipCode: string,
        public city: string,
        public country: string,
        public additionalAddress: string) {
    }

    static empty(): InvoiceAddressWithName {
        return new InvoiceAddressWithName('', '', '', '', '', '');
    }

    isEmpty(): boolean {
        return this.name.length === 0 &&
            this.body.length === 0 &&
            this.zipCode.length === 0 &&
            this.city.length === 0 &&
            this.country.length === 0 &&
            this.additionalAddress.length === 0;
    }
}
