import { Injectable } from '@angular/core';
import {Note} from '../models/Note';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Result} from '../../common/models/shared/result';
import {FullUser} from '../../users/models/full-user';

@Injectable({
    providedIn: 'root'
})
export class NoteService {

    private baseUrl = 'api/note';

    constructor(private http: HttpClient) { }

    // notification is a note made by the system
    isNotification(note: Note): boolean {
        return note.author === null;
    }

    find(
        page: number,
        limit: number,
        customerAccountRef: string | null = null,
    ): Observable<Result<Note>> {
        return this.http.get(
            `${this.baseUrl}`,
            {
                params: {
                    page,
                    limit,
                    customerAccountRef
                },
                observe: 'response'
            }
        ).pipe(
            map(notes => new Result(
                (notes.body as [object]).map(note => plainToClass(Note, note as object)),
                parseInt(notes.headers.get('X_UNREAD_COUNT'), 10),
                null)
            ));
    }

    create(note: Note): Observable<Note> {
        return this.http.post(`${this.baseUrl}`, note).pipe(
            map(el => plainToClass(Note, el))
        );
    }

    updateStatus(noteId: string, treated: boolean): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${noteId}/status`, treated);
    }
}
