<label [class._required]="required">
    {{label}}
    <div class="input-group">
        <span class="input-group-label"><ng-content></ng-content></span>
        <input #input
               class="input-group-field"
               [required]="required"
               [name]="name"
               [class._disabled]="disabled"
               [disabled]="disabled"
               [placeholder]="placeholder"
               [type]="inputType"
               (blur)="onBlur()"
               [ngModel]="data"
               (ngModelChange)="onChange($event)"
               [min]="min"
               [max]="max">
    </div>
</label>
