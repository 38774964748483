import {AccountUser} from './account-user';
import {Role} from './role';
import {AddressType} from '../../common/models/address/addressType';
import {CustomerAccount} from '../../customers/models/customer-account';

export class AccountManager extends AccountUser {

  tokenBalance: number;

  constructor() {
    super('manager');
    this.role = Role.ACCOUNT_MANAGER;
    this.person.contact.address.type = AddressType.CONTACT;
  }

  override getTokens(): number {
      return this.tokenBalance;
  }

  override getCustomerAccount(): CustomerAccount {
      return this.customerAccount;
  }
}
