import {Component, Input} from '@angular/core';

@Component({
    selector: 'm-scenario-detail',
    templateUrl: './m-scenario-detail.component.html',
    styleUrls: ['./m-scenario-detail.component.scss'],
    host: {class: 'grid-x align-middle'}
})
export class MScenarioDetailComponent {

    @Input()
    scenario: string;

    @Input()
    count = 0;

    constructor() {
    }

}
