import {Component, EventEmitter, Input, Output} from '@angular/core';
import {fromVisibility, Visibility} from '../buttons-utils';

@Component({
  selector: 'a-dashboard-button-icon',
  templateUrl: './a-dashboard-button-icon.component.html',
  styleUrls: ['./a-dashboard-button-icon.component.scss']
})
export class ADashboardButtonIconComponent {
  @Input()
  public disabled = false;

  @Input()
  public tooltipLabelKey: string | null = null;

  @Input()
  public visibility: Visibility = 'MEDIUM';

  public fromVisibility = fromVisibility;

  @Output()
  public click: EventEmitter<MouseEvent> = new EventEmitter();

  constructor() {
  }

  public doAction(event: MouseEvent): void {
    event.stopPropagation();
    if (!this.disabled) {
        this.click.emit(event);
    }
  }
}
