import {
    AfterContentInit,
    AfterViewInit, ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import {BillingDocument} from '../../../models/invoices/billing-document';
import {Invoice} from '../../../models/invoices/invoice';
import {Quote} from '../../../models/invoices/quote';
import {QuotesService} from '../../../services/quotes.service';
import {BillingDocumentService} from '../../../services/billing-document.service';
import {NgForm} from '@angular/forms';
import {Credit} from '../../../models/invoices/credit';

@Component({
    selector: 'o-invoice-header-informations',
    templateUrl: './o-invoice-header-informations.component.html',
    styleUrls: ['./o-invoice-header-informations.component.scss']
})
export class OInvoiceHeaderInformationsComponent implements OnInit, AfterViewInit {
    private initialDate: Date;

    // tslint:disable-next-line:variable-name
    private _billingDocument: BillingDocument;

    @Input()
    set billingDocument(billingDocument: BillingDocument) {
        if (billingDocument) {
            this._billingDocument = billingDocument;
            this.initialDate = billingDocument.date;
        }
    }

    get billingDocument(): BillingDocument {
        return this._billingDocument;
    }

    @Input()
    invalid: boolean;

    @Output()
    invalidChange = new EventEmitter<boolean>();

    @Input()
    dirty: boolean;

    @Output()
    dirtyChange = new EventEmitter<boolean>();

    @ViewChild('form')
    form: NgForm;

    linkedDocuments: Array<Quote>;
    isInvoice = false;
    isCredit = false;
    isInProgress = false;
    reference: string;

    constructor(private quoteService: QuotesService,
                private changeDetector: ChangeDetectorRef,
                private billingDocumentService: BillingDocumentService) { }

    ngOnInit(): void {
        this.isInProgress = this.billingDocument?.isInProgress();
        this.isInvoice = this.billingDocument instanceof Invoice;
        this.isCredit = this.billingDocument instanceof Credit;

        if (!this.billingDocument.reference) {
            this.billingDocumentService.getCurrentBillingNo(this.billingDocument).subscribe(billingNo => {
                this.billingDocument.reference = billingNo;
                this.reference = this.billingDocument.reference;
                this.form?.form.markAsPristine();
            });
        } else {
            this.reference = this.billingDocument.getReference();
            this.form?.form.markAsPristine();
        }

        if ((this.billingDocument as Invoice).metadata?.linkedQuotesId) {
            this.quoteService.getQuotesById((this.billingDocument as Invoice).metadata?.linkedQuotesId)
                .subscribe(quotes => this.linkedDocuments = quotes);
        }
    }

    ngAfterViewInit(): void {
        this.form.statusChanges.subscribe(_ => {
            const invalid = this.form?.invalid;
            if (invalid !== this.invalid) {
                this.invalid = invalid;
                this.invalidChange.emit(this.invalid);
            }

            const dirty = this.form?.dirty && this.form?.touched;
            if (dirty !== this.dirty) {
                this.dirty = dirty;
                this.dirtyChange.emit(dirty);
            }

            this.changeDetector.detectChanges();
        });
    }

    refreshDueDate(newDate: Date): void {
        if (
            this.billingDocument instanceof Invoice &&
            newDate &&
            // tslint:disable-next-line:max-line-length
            (newDate.toString() !== this.initialDate.toString()) // Compare strings because for some reason, only !== did not work
        ) {
            const dateInAMonth = new Date(newDate);
            dateInAMonth.setMonth(newDate.getMonth() + 2);
            this.billingDocument.dueDate = dateInAMonth;
        }
    }
}
