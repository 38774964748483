<div class="articlesListTable">
    <div class="articlesListTable-header">
        <span class="articlesListTable-header-item _toggleColumn"></span>
        <span class="articlesListTable-header-item _articlesColumn" [class._articleLine]="!showTokenSessionHeaders">{{'accounting.article.list.table.label' | translate}}</span>
        <span class="articlesListTable-header-item _sessionsColumn text-right" *ngIf="showTokenSessionHeaders">{{'accounting.article.list.table.session' | translate}}</span>
        <span class="articlesListTable-header-item _teamsColumn text-right" *ngIf="showTokenSessionHeaders">{{'accounting.article.list.table.teams' | translate}}</span>
        <span class="articlesListTable-header-item _simulationsColumn text-right" *ngIf="showTokenSessionHeaders">{{'accounting.article.list.table.periods' | translate}}</span>
        <span class="articlesListTable-header-item _quantityColumn text-right" [class._articleLine]="!showTokenSessionHeaders">{{(showTokenSessionHeaders ? 'accounting.article.list.table.tokens':'accounting.article.list.table.quantity') | translate}}</span>
        <span class="articlesListTable-header-item _costColumn text-right" [class._articleLine]="!showTokenSessionHeaders">{{'accounting.article.list.table.price' | translate}}</span>
        <span class="articlesListTable-header-item _amountColumn text-right">{{'accounting.article.list.table.gross.amount' | translate}}</span>
        <span class="articlesListTable-header-item _actionsColumn" *ngIf="!areLinesReadonly"></span>
    </div>

    <div class="articlesListTable-content" dndDropzone dndEffectAllowed="copyMove" (dndDrop)="onDrop($event)">
        <div class="lineDragging" dndPlaceholderRef></div>
        <ng-container *ngFor="let invoiceLine of lines; let i = index; trackBy:trackByLineCode">
            <a-invoice-line
              [dndDraggable]="invoiceLine"
              [showTokenSessionFields]="showTokenSessionFields(invoiceLine)"
              [disabled]="areLinesReadonly"
              [invoiceLine]="invoiceLine"
              [articles]="articles"
              [vatRate]="vatRate"
              (lineEdited)="onLineEdited(i, $event)"
              [dndEffectAllowed]="'copyMove'"
              (deletion)="onInvoiceLineDelete(i)"
              [trustValues]="trustValues"
              *ngIf="!areLinesReadonly">
            </a-invoice-line>
          <a-invoice-line
            [showTokenSessionFields]="showTokenSessionFields(invoiceLine)"
            [disabled]="areLinesReadonly"
            [invoiceLine]="invoiceLine"
            [articles]="articles"
            [vatRate]="vatRate"
            (lineEdited)="onLineEdited(i, $event)"
            (deletion)="onInvoiceLineDelete(i)"
            [trustValues]="trustValues"
            *ngIf="areLinesReadonly">
          </a-invoice-line>
        </ng-container>

        <m-invoice-line-creator
            *ngIf="!areLinesReadonly"
            (dirty)="dirty.emit()"
            [articles]="articles"
            [linesFilters]="articlesTypes"
            [showTokenSessionHeaders]="showTokenSessionHeaders"
            [vatRate]="vatRate"
            (lineAdded)="addInvoiceLine($event)">
        </m-invoice-line-creator>

        <div *ngIf="lines.length == 0 && areLinesReadonly" class="emptyArticlesList">
            <img class="emptyArticlesList-img" src="assets/images/png/no-result.png"/>
            <h2>{{'accounting.invoice.detail.article.empty'|translate}}</h2>
            <span>{{'accounting.invoice.detail.article.empty.message'|translate}}</span>
        </div>
    </div>
</div>
