import {
    AfterContentInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {LinkedDocumentInformation, Operation} from '../../../models/operation';
import {FullUser} from '../../../../users/models/full-user';
import {ContextService} from '../../../../common/services/context.service';
import {BoaNavigationEvent, BoaNavigationEventType} from '../../../../common/models/shared/boa-navigation-event';
import {OperationsService} from '../../../services/operations.service';
import {ToastrService} from 'ngx-toastr';
import {OperationDestination} from '../../../models/operation-destination';
import {Role} from '../../../../users/models/role';
import {BillingDocument, ExtendedBillingDocument} from '../../../../accounting/models/invoices/billing-document';
import {BillingDocumentService} from '../../../../accounting/services/billing-document.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../../users/services/user.service';
import {forkJoin} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ExtendedInvoice, Invoice} from '../../../../accounting/models/invoices/invoice';
import {AnimatorType} from '../../../../users/models/user';
import {CustomerAccountService} from '../../../../customers/services/customer-account.service';
import {CustomerAccount} from '../../../../customers/models/customer-account';

type TargetDisplayState = 'DISPLAY_TOKEN_USER' | 'DISPLAY_NOTHING' | 'DISPLAY_NO_USERS_MESSAGE';

@Component({
  selector: 'o-token-transfer',
  templateUrl: './o-token-transfer.component.html',
  styleUrls: ['./o-token-transfer.component.scss']
})
export class OTokenTransferComponent implements OnInit, AfterContentInit, OnChanges {

    @Input()
    initialOrigin: FullUser;

    @Input()
    initialBillingDocument: ExtendedInvoice = null;

    @Input()
    initialTarget: FullUser;

    @Output()
    operationSent = new EventEmitter<Operation>();

    @Output()
    operationCanceled = new EventEmitter<void>();

    originTokens: number;

    switchClass = false;

    freeMode = false;
    transferLoading = false;
    billingDocuments: Array<{
        value: ExtendedBillingDocument,
        label: string,
        id: string
    }>;
    selectedBillingDocument: ExtendedBillingDocument;
    role = Role;

    allCustomerUsers: Array<FullUser>;
    customerUsersTarget: Array<FullUser>;
    customerUsersOrigin: Array<FullUser>;

    isArkheInvolved: boolean;

    operationForm: FormGroup;
    targetDisplayState: TargetDisplayState = 'DISPLAY_NOTHING';
    customerAccount: CustomerAccount;

    constructor(private contextService: ContextService,
                private notificationService: ToastrService,
                private userService: UserService,
                private fb: FormBuilder,
                private billingDocumentService: BillingDocumentService,
                private translateService: TranslateService,
                private operationService: OperationsService,
                private customerAccountService: CustomerAccountService
    ) {
    }

    // Initialization **/

    ngOnInit(): void {
        let companyName: string;

        if (this.initialOrigin.role === Role.ACCOUNT_MANAGER || this.initialOrigin.role === Role.ANIMATOR) {
            companyName = this.initialOrigin.customerAccount.name;
        } else {
            companyName = this.initialTarget.customerAccount.name;
        }

        this.operationForm = this.fb.group({
            operationLabel: ['', Validators.required],
            selectedBillingDocument: [null],
            originUser: [this.initialOrigin],
            targetUser: [this.initialTarget],
            amount: [0, [
                Validators.required,
                Validators.min(1)
            ]],
            sendMailNotification: [false],
            isGift: [false],
            companyName: companyName
        });
        this.operationForm.controls.targetUser.valueChanges.subscribe(newUser => this.onTargetChanged(newUser));
        this.operationForm.controls.originUser.valueChanges.subscribe(newUser => this.originChanged(newUser));
        this.operationForm.controls.selectedBillingDocument.valueChanges.subscribe(newBillingDoc => {
            if (newBillingDoc) {
                this.onBillingChange(newBillingDoc);
            }
        });
        this.isArkheInvolved = this.getIsArkheInvolved();

        if (this.initialBillingDocument) {
            this.inflateByCustomerId(this.initialBillingDocument.metadata.customerAccountId, this.initialBillingDocument);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.initialTarget || changes.initialOrigin) {
            this.operationForm?.controls.originUser.setValue(this.initialOrigin);
            this.operationForm?.controls.targetUser.setValue(this.initialTarget);
            this.isArkheInvolved = this.getIsArkheInvolved();
        }
    }

    ngAfterContentInit(): void {
        this.contextService.contextEvent.subscribe((event: BoaNavigationEvent) => {
            if (event.type === BoaNavigationEventType.CUSTOMER) {
               this.inflateByCustomerId(event.data);
            }
            if (event.type === BoaNavigationEventType.USER) {
                this.userService.getUserById(event.data).subscribe(foundUser => {
                    this.inflateByCustomerId(foundUser.customerAccount.id);
                });
            }
        });
    }

    inflateByCustomerId(customerId: string, initialBillingDocument?: Invoice): void {
        forkJoin({
            arkheUsers: this.userService
                .getUsers(null, null, null, ['ALL'], null, customerId),
            customerUsers: this.userService
                .getUsers(null, null, null, ['ARKHE'], null, null)}
        ).subscribe( ({customerUsers, arkheUsers}) => {
            this.allCustomerUsers = customerUsers.data.concat(arkheUsers.data);
            this.customerUsersTarget = this.filterCustomerUsersByRole(
                this.allCustomerUsers,
                this.operationForm.controls.originUser.value.role
            );
            this.targetDisplayState = OTokenTransferComponent.computeTargetDisplayState(this.customerUsersTarget);

            if (this.targetDisplayState === 'DISPLAY_NO_USERS_MESSAGE') {
                this.customerAccountService.getCustomerAccountById(initialBillingDocument?.metadata.customerAccountId).subscribe(customerAccount =>
                    this.customerAccount = customerAccount
                );
            }

            this.customerUsersOrigin = this.filterCustomerUsersByRole(
                this.allCustomerUsers,
                this.operationForm.controls.targetUser.value.role
            );
        });

        this.billingDocumentService
            .findExtended(null, null, ['ALL'], null, null, null, null, customerId, false)
            .subscribe(billingDocuments => {
                this.billingDocuments = billingDocuments.data.map(billingDocument => {
                    return {
                        value: billingDocument,
                        label: billingDocument.reference,
                        id: billingDocument.id
                    };
                });

                if (initialBillingDocument) {
                    this.operationForm.controls.selectedBillingDocument.patchValue(initialBillingDocument);
                }
            });
    }

    // Utilization **

    filterCustomerUsersByRole(users: Array<FullUser>, role: Role): Array<FullUser> {
        if (!users) {
            return [];
        }

        return users.filter(el => el.role !== role && el.animatorType !== AnimatorType.DEMONSTRATION);
    }

    updateOriginTokens(user: FullUser): void {
        this.originTokens = user.role !== Role.ARKHE
            ? user.tokenBalance
            : null;
    }

    switchFreeMode(): void {
        this.freeMode = !this.freeMode;
        this.setMandatoryBillingDoc(this.isArkheInvolved);
    }

    addBoaMaxIfNotArkhe(newOrigin: FullUser): void {
        if (newOrigin.role !== Role.ARKHE) {
            this.operationForm.controls.amount.addValidators(Validators.max(this.originTokens));
        }
    }

    switchOperationDirection(): void {
        this.switchClass = !this.switchClass;

        this.operationForm.controls.amount.clearValidators();
        // works because we're always beginning with false
        if (!this.switchClass) {
            this.updateOriginTokens(this.initialOrigin);
            this.addBoaMaxIfNotArkhe(this.initialOrigin);
        } else {
            this.updateOriginTokens(this.initialTarget);
            this.addBoaMaxIfNotArkhe(this.initialTarget);
        }

        this.operationForm.controls.amount.updateValueAndValidity({onlySelf: true});
        this.operationForm.controls.amount.setValue(this.recomputeTokenAmount(this.getCurrentOrigin()));
        console.log(this.operationForm.controls.amount.hasValidator(Validators.max(this.originTokens)));

        if (this.operationForm.controls.selectedBillingDocument.value) {
            this.generateOperationLabel(this.operationForm.controls.selectedBillingDocument.value);
        }
    }

    recomputeTokenAmount(origin: FullUser): number {
        const currentTokenAmountValue = this.operationForm.controls.amount.value;
        const currentBillingDocument = this.operationForm.controls.selectedBillingDocument.value;

        if (currentTokenAmountValue > origin.tokenBalance) {
            return origin.tokenBalance;
        }

        if (!currentBillingDocument) {
            return currentTokenAmountValue;
        }

        if (origin.role === Role.ARKHE) {
            // Arkhe has an infinite stock! Let's just deliver what should be delivered
            return currentBillingDocument.toDeliverTokens;
        }

        return currentTokenAmountValue;
    }

    setMandatoryBillingDoc(isArkheInvolved: boolean): void {
        this.operationForm.controls.selectedBillingDocument.clearValidators();
        if (isArkheInvolved && !this.freeMode) {
            this.operationForm.controls.selectedBillingDocument.addValidators(Validators.required);
        } else {
            this.operationForm.controls.selectedBillingDocument.removeValidators(Validators.required);
        }
        this.operationForm.controls.selectedBillingDocument.updateValueAndValidity({onlySelf: true});
    }

    clearBillingDocs(isArkheInvolved: boolean): void {
        if (!isArkheInvolved) {
            this.operationForm.controls.selectedBillingDocument.reset();
        }
    }

    originChanged(newOrigin: FullUser): void {
        if (!this.switchClass) {
            this.updateOriginTokens(newOrigin);
        }
        this.customerUsersTarget = this.filterCustomerUsersByRole(this.allCustomerUsers, newOrigin.role);
        this.isArkheInvolved = this.getIsArkheInvolved();

        this.setMandatoryBillingDoc(this.isArkheInvolved);
        this.clearBillingDocs(this.isArkheInvolved);
    }

    onTargetChanged(newTarget: FullUser): void {
        if (this.switchClass) {
            this.updateOriginTokens(newTarget);
        }
        this.customerUsersOrigin = this.filterCustomerUsersByRole(this.allCustomerUsers, newTarget.role);
        this.isArkheInvolved = this.getIsArkheInvolved();

        this.setMandatoryBillingDoc(this.isArkheInvolved);
        this.clearBillingDocs(this.isArkheInvolved);
    }

    getCurrentOrigin(): FullUser {
        if (this.switchClass) {
            return this.operationForm.controls.targetUser.value;
        } else {
            return this.operationForm.controls.originUser.value;
        }
    }

    getCurrentTarget(): FullUser {
        if (this.switchClass) {
            return this.operationForm.controls.originUser.value;
        } else {
            return this.operationForm.controls.targetUser.value;
        }
    }

    getIsArkheInvolved(): boolean {
        if (!this.operationForm) { return false; }
        return this.operationForm.controls.originUser.value.role === Role.ARKHE
            || this.operationForm.controls.targetUser.value.role === Role.ARKHE;
    }

    onBillingChange(newBilling: ExtendedBillingDocument): void {
        this.generateOperationLabel(newBilling);
        if (this.isArkheInvolved) {
            this.operationForm.controls.amount.setValue(newBilling.toDeliverTokens);
        }
    }

    private generateOperationLabel(newBilling: ExtendedBillingDocument): void {
        if (this.isArkheInvolved) {
            let operationLabel = newBilling.getReference();

            const purchaseOrderNumber = newBilling.metadata.purchaseOrderNumber;

            if (purchaseOrderNumber) {
                operationLabel += `/CDE ${purchaseOrderNumber}`;
            }

            this.operationForm.controls.operationLabel.setValue(operationLabel);
        }
    }

// Sending / Canceling (terminal workflow) ****

    inflateOperation(switchClass: boolean): Operation {
        const originUser = !switchClass
            ? this.operationForm.controls.originUser.value
            : this.operationForm.controls.targetUser.value;
        const targetUser = !switchClass
            ? this.operationForm.controls.targetUser.value
            : this.operationForm.controls.originUser.value;

        let linkedDocumentInformation = null;
        const selectedBillingDocument = this.operationForm.controls.selectedBillingDocument.value;

        if (selectedBillingDocument) {
            linkedDocumentInformation = new LinkedDocumentInformation(
                selectedBillingDocument.id,
                selectedBillingDocument.getType(),
                selectedBillingDocument.isInProgress()
            );
        }

        return new Operation(
            null,
            this.operationForm.controls.operationLabel.value,
            null,
            null,
            this.operationForm.controls.amount.value,
            OperationDestination.createOperationFromUser(originUser),
            OperationDestination.createOperationFromUser(targetUser),
            this.operationForm.controls.isGift.value,
            linkedDocumentInformation,
            this.operationForm.controls.companyName.value
        );
    }

    send(): void {
        this.transferLoading = true;

        const operation = this.inflateOperation(this.switchClass);

        this.operationService.create(operation).subscribe(
            _ => {
                this.transferLoading = false;
                this.operationSent.emit(operation);
                this.notificationService.success(this.translateService.instant('common.success'));
            },
            err => {
                this.transferLoading = false;
                this.notificationService.error(this.translateService.instant('common.error'));
            }
        );
    }

    cancel(): void {
        this.operationCanceled.emit();
    }

    billingDocumentComparisonFunction(billingDocument1: BillingDocument, billingDocument2: BillingDocument): boolean {
        return billingDocument1.id === billingDocument2.id;
    }

    private static computeTargetDisplayState(customerUsersTarget: Array<FullUser>): TargetDisplayState {
        if (customerUsersTarget.length > 0) {
            return 'DISPLAY_TOKEN_USER';
        }

        return 'DISPLAY_NO_USERS_MESSAGE';
    }
}
