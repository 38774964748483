import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'boaCapitalizeFirst'
})
export class CapitalizeFirstPipe implements PipeTransform {

  transform(value: string): string {
    const trimedValue = (value.trim());
    return trimedValue.charAt(0).toUpperCase()
      + trimedValue.slice(1).toLowerCase();
  }
}
