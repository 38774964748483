import {Injectable} from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import {Observable} from 'rxjs';
import {Result} from '../../common/models/shared/result';
import {Consumption} from '../models/consumption';
import {ConsumptionsService} from '../service/consumptions.service';

@Injectable()
export class AllConsumptionsResolver implements Resolve<Result<Consumption>> {

    private page = 0;
    private limit = 50;

    constructor(private consumptionsService: ConsumptionsService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Result<Consumption>> {
        const queryParams = route.queryParamMap;
        return this.consumptionsService.find(
            this.page,
            this.limit, queryParams.get('search') ? queryParams.get('search') : '',
            queryParams.get('sort'),
            queryParams.getAll('scenarioNames'),
            queryParams.get('customerAccountId'),
            queryParams.get('animatorId'),
            queryParams.get('fromDate'),
            queryParams.get('toDate')
        );
    }
}
