<form #form="ngForm" [formGroup]="customerForm" (ngSubmit)="onCreateClick()">
    <m-card-header [label]="'customers.detail.new'|translate" [userType]="customerAccount.status">
        <a-button-submit button [disabled]="form.invalid || form.pristine"
                         class="cell shrink">{{'common.action.create' | translate}}</a-button-submit>
    </m-card-header>

    <spacer size="16"></spacer>

    <div class="grid-x grid-margin-x grid-margin-y">
        <div class="cell phone-24 desktop-12 customerEditBlock grid-y grid-margin-y">
            <h2 class="customerEditBlock-title">{{'customers.detail.information.general.title' | translate}}</h2>
            <div class="cell shrink">
                <div class="grid-x grid-margin-x">
                    <a-input [inputType]="'text'"
                             [required]="true"
                             formControlName="accountingReference"
                             [label]="'customers.detail.information.code'|translate"
                             class="cell phone-8"
                             disabled></a-input>
                    <a-input [inputType]="'text'"
                             formControlName="abbreviation"
                             [label]="'customers.detail.information.abbreviation'|translate"
                             class="cell phone-8"></a-input>
                    <a-select formControlName="type"
                              [itemList]="typePointer"
                              [required]="true"
                              [bindValue]="'value'"
                              [bindLabel]="'label'"
                              [label]="'customer.detail.information.type' | translate"
                              class="cell phone-8"></a-select>
                </div>
            </div>
            <a-input
                [inputType]="'text'"
                [required]="true"
                formControlName="name"
                (blur)="onCustomerAccountNameChange()"
                [label]="'customers.detail.information.name'|translate"
                class="cell shrink"></a-input>
            <a-input
                [inputType]="'text'"
                formControlName="subtitle"
                [label]="'customers.detail.information.subtitle'|translate"
                class="cell shrink"></a-input>
            <div class="cell shrink">
                <div class="grid-x grid-margin-x">
                    <a-input
                        [inputType]="'number'"
                        [minlength]="14"
                        [maxlength]="14"
                        formControlName="siretNumber"
                        [label]="'customers.detail.information.siret' | translate"
                        (ngModelChange)="onSiretChange()"
                        (blur)="findCustomerAccountsWithSameSiret()"
                        [warningTemplate]="warningMessage"
                        class="cell phone-12">
                        <ng-template #warningMessage>
                            <ng-container *ngIf="customersAccountsWithSameSiretErrorState === 'ONE'">
                                {{'customers.detail.information.siret.one.customer.duplicated.siret.message' | translate}}
                                <a-customer-link [customerAccount]="customerAccountsWithSameSiret[0]" target="_blank">
                                </a-customer-link>
                            </ng-container>
                            <ng-container *ngIf="customersAccountsWithSameSiretErrorState === 'MULTIPLE'">
                                {{'customers.detail.information.siret.multiple.customer.duplicated.siret.message' | translate}}
                                <a [routerLink]="['/boa/customers']"
                                   [queryParams]="{search: customerForm.get('siretNumber').value, status: ['ACTIVE', 'PROSPECT']}"
                                   target="_blank">
                                    {{'customers.detail.information.siret.multiple.customer.duplicated.siret.link' | translate}}
                                </a>
                            </ng-container>
                        </ng-template>
                    </a-input>
                    <a-input
                        formControlName="sirenNumber"
                        [label]="'customers.detail.information.siren' | translate"
                        class="cell phone-12"></a-input>
                </div>
            </div>
            <div class="cell shrink">
                <div class="grid-x grid-margin-x">
                    <a-select-vat-rate
                        formControlName="vatRatio"
                        [label]="'customers.detail.information.vat' | translate"
                        class="cell phone-12">
                    </a-select-vat-rate>
                    <a-input
                        formControlName="vatNumber"
                        [label]="'customers.detail.information.vat-com' | translate"
                        class="cell phone-12"></a-input>
                </div>
            </div>
        </div>
        <div class="cell phone-24 desktop-12 grid-y">
            <m-customer-addresses-edit
                class="cell shrink grid-x"
                [contacts]="customerAccount.contacts"
                [parentFormGroup]="customerForm">
            </m-customer-addresses-edit>

            <spacer size="16"></spacer>

            <div class="cell phone-shrink desktop-auto customerEditBlock">
                <div class="grid-x align-justify align-middle">
                    <h2 class="cell shrink customerEditBlock-title">Chorus PRO</h2>
                    <a-checkbox class="cell shrink"
                                [label]="'Activation'"
                                formControlName="chorusProActivated"
                                size="small"></a-checkbox>
                </div>
            </div>
        </div>

        <m-customer-billing-contact-edit class="cell phone-24 customerEditBlock grid-y grid-margin-y"
                                         [billingContact]="billingContact"
                                         [parentFormGroup]="customerForm">
        </m-customer-billing-contact-edit>
    </div>
</form>
