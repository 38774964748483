import {Component, forwardRef, HostBinding, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FullUser} from '../../../../users/models/full-user';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {WARN} from '@angular/compiler-cli/src/ngtsc/logging/src/console_logger';
import {JsonPipe} from '@angular/common';

@Component({
    selector: 'm-token-user',
    templateUrl: './m-token-user.component.html',
    styleUrls: ['./m-token-user.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MTokenUserComponent),
        }
    ]
})
export class MTokenUserComponent implements ControlValueAccessor, OnChanges {

    @Input()
    type: 'emitter' | 'receiver';

    @Input()
    freeMode = false;

    @Input()
    operationDate: number;

    @Input()
    userSelect: Array<FullUser>;

    user = FullUser.empty();
    userSelectMapped: Array<{
        label: string;
        value: string;
    }>;

    selectedUserId: string;

    onNgChange = (newValue: FullUser) => {};
    onNgTouched = (newValue: FullUser) => {};

    constructor() { }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(user: FullUser): void {
        if (user) {
            this.user = user;
            this.selectedUserId = user.id;
        } else {
            console.warn('[m-token-user] - user is falsey : ' + JSON.stringify(user));
        }

    }

    onChange(newValue: string): void {
        if (newValue) {
            this.selectedUserId = newValue;
            this.user = this.userSelect.find(el => el.id === this.selectedUserId);
            this.onNgChange(this.user);
        } else {
            console.warn('[m-token-user] - new id is falsey : ' + JSON.stringify(newValue));
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.userSelect) {
            if (changes.userSelect.currentValue
                && changes.userSelect.currentValue.length > 0) {
                this.userSelectMapped = this.userSelect.map(user => {
                    return {
                        label: user.login,
                        value: user.id
                    };
                });
            } else {
                console.warn('[m-token-user] - changes user select is non standard : ' + JSON.stringify(changes.userSelect));
            }
        }
    }
}
