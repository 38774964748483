import {Invoice} from './models/invoices/invoice';
import {plainToClass} from 'class-transformer';
import {allLines, BillingDocument, BillingDocumentType} from './models/invoices/billing-document';
import {ArticleType} from './models/article';
import {toPrecision} from '../../shared/functions/to-precision';
import {Quote} from './models/invoices/quote';
import {Credit} from './models/invoices/credit';

export class InvoiceComputingUtils {

    private static readonly basePercentage = 0.40;
    private static readonly minimumAmount = 800;
    private static readonly lowerLimitForDiscountApplication = 1000;
    private static readonly medianAmount = 3000;
    private static readonly maximumAmount = 15000;

    constructor() {
        // tslint:disable-next-line:typedef
        Date.prototype.toJSON = function () {
            return new Date(this.getTime() - (this.getTimezoneOffset() * 60000)).toISOString();
        };
    }

    static calculateDiscountPercentage(totalTokens: number): number {
        if (totalTokens < this.lowerLimitForDiscountApplication) {
            return 0;
        } else if (totalTokens >= this.lowerLimitForDiscountApplication && totalTokens < this.medianAmount) {
            return toPrecision(
                (((this.basePercentage / 2) / (this.medianAmount - this.minimumAmount)) * (totalTokens - this.minimumAmount)),
                4
            );
        } else if (totalTokens >= this.medianAmount && totalTokens <= this.maximumAmount) {
            return toPrecision(
                // tslint:disable-next-line:max-line-length
                (((this.basePercentage / 2) / (this.maximumAmount - this.medianAmount)) * (totalTokens - this.maximumAmount)) + (this.basePercentage),
                4
            );
        } else if (totalTokens > this.maximumAmount) {
            return toPrecision(this.basePercentage, 4);
        }
    }

    static calculateTotalDiscount(billingDocument: BillingDocument): number {
        return billingDocument.tokensArticlesLines.reduce<number>((acc, currentValue) => {
            return acc + currentValue.discountAmount.value;
        }, 0);
    }

    static calculateTotalNet(billingDocument: BillingDocument): number {
        return billingDocument.grossAmountAfterDiscount.value + billingDocument.vatAmount.value;
    }

    static calculateTotalGross(billingDocument: BillingDocument): number {
        return this.reduceOnAllLines(billingDocument, line => line.grossAmount);
    }

    static calculateTotalVat(billingDocument: BillingDocument): number {
        return Math.round(billingDocument.grossAmountAfterDiscount.value * billingDocument.metadata.vatRate * 100) / 100;
    }

    private static reduceOnAllLines(billingDocument: BillingDocument, lineAmountGetter: Function): number {
        return allLines(billingDocument).reduce<number>((acc, currentValue) => {
            return acc + lineAmountGetter(currentValue).value;
        }, 0);
    }

    static calculateTokenGrossTotal(billingDocument: BillingDocument): number {
        return billingDocument.tokensArticlesLines.reduce<number>((acc, currentValue) => {
            return acc + (
                (currentValue.metadata.article?.type === ArticleType.TOKEN || currentValue.metadata.article?.type === ArticleType.SESSION)
                    ? currentValue.grossAmount.value
                    : 0
            );
        }, 0);
    }

    static instantiateBillingDocument(billingDoc: BillingDocument): BillingDocument {
        if (billingDoc.billingDocumentType === BillingDocumentType.INVOICE) {
            return plainToClass(Invoice, billingDoc);
        } else if (billingDoc.billingDocumentType === BillingDocumentType.QUOTE) {
            return plainToClass(Quote, billingDoc);
        } else if (billingDoc.billingDocumentType === BillingDocumentType.CREDIT) {
            return plainToClass(Credit, billingDoc);
        }
    }

    static getTotalTokens(billingDocument: BillingDocument): number {
        return billingDocument.tokensArticlesLines.reduce<number>((acc, currentValue) => {
            if ([ArticleType.TOKEN, ArticleType.SESSION].includes(currentValue.metadata.article?.type)) {
                return acc + currentValue.quantity;
            } else {
                return acc;
            }
        }, 0);
    }
}

