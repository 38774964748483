import {Component, Input, OnInit} from '@angular/core';
import {StatisticsService} from '../../../../statistics/services/statistics.service';
import {ScenarioUsageStats} from '../../../../statistics/models/scenario-usage-stats';

@Component({
    selector: 'o-scenario-information',
    templateUrl: './o-scenario-information.component.html',
    styleUrls: ['./o-scenario-information.component.scss']
})
export class OScenarioInformationComponent implements OnInit {

    @Input()
    blocTitle;

    scenariiUsages: Array<ScenarioUsageStats>;

    constructor(private statisticsService: StatisticsService) {
    }

    ngOnInit(): void {
        this.statisticsService.computeScenarioUsages()
            .subscribe(scenarioUsageStats => {
                scenarioUsageStats.filter(item => item.count > 0).sort(
                    (item1, item2) => item2.count - item1.count);
                this.scenariiUsages = scenarioUsageStats;
            });
    }

}
