import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppModule} from './app.module';
import {HttpClient, HttpClientModule, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {AppRootComponent} from './app-root.view';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

export function translateLoader(http: HttpClient): Observable<{ [p: string]: string }> | { getTranslation(lang: string): Observable<any> } {
    return {
        getTranslation(lang: string): Observable<any> {
            return http.get<{ [key: string]: string }>('/api/public/i18n/labels', {
                headers: new HttpHeaders({'Accept-Language': lang})
            });
        }
    };
}

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (translateLoader),
                deps: [HttpClient]
            }
        })
    ],
    bootstrap: [AppRootComponent],
    declarations: [],
    exports: []
})
export class AppRootModule {
}
