<div class="cell shrink grid-x align-middle align-justify">
    <h1 class="cell auto">{{'accounting.article.title' | translate}}</h1>
    <a-button-default [visibility]="'HIGH'" (click)="onCreateClick()"><i class="fas fa-plus"></i>{{'accounting.article.action.new'|translate}}</a-button-default>
</div>

<spacer size="16"></spacer>

<o-filters class="cell shrink">
    <m-search-query
        class="cell phone-12"
        [(ngModel)]="searchQuery"
        [placeholder]="'accounting.article.search.placeholder' | translate"></m-search-query>

    <m-type-select-query [(types)]="types"></m-type-select-query>
</o-filters>

<spacer size="24"></spacer>

<div class="cell shrink grid-x">
    <m-article-edit class="cell phone-24 tablet-landscape-12 desktop-8 big-desktop-6"
                    *ngIf="creationMode"
                    (canceled)="onCreationCancel()"
                    [loading]="creatingLoading"
                    (articleSaved)="createArticle($event)">
    </m-article-edit>
    <m-article class="cell phone-24 tablet-landscape-12 desktop-8 big-desktop-6"
               *ngFor="let article of articles"
               [article]="article"
               [loading]="updatingLoading"
               (saved)="updateArticle($event)"
               (deleted)="onArticleDelete($event)"
    ></m-article>

</div>
