import {AnimatorType} from './user';
import {AccountUser} from './account-user';
import {CustomerAccount} from '../../customers/models/customer-account';

export class Animator extends AccountUser {


  constructor(typeId: string) {
    super(typeId);
  }

  tokenBalance: number;
  animatorType: AnimatorType;

  override getTokens(): number {
      return this.tokenBalance;
  }

  override getCustomerAccount(): CustomerAccount {
      return this.customerAccount;
  }

  override getAnimatorType(): AnimatorType {
      return this.animatorType;
  }
}
