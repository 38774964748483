<ng-container *ngIf="note">
    <div class="note-header">
        <div [class._check]="note.treated" class="note-header-icon" [title]="'common.notes.action.check'|translate" (click)="markAsRead()"
             *ngIf="linkedUser" m-icon
             [type]="linkedUser.role === role.ANIMATOR? linkedUser.animatorType : linkedUser.role"></div>
        <div [class._check]="note.treated" class="note-header-icon" [title]="'common.notes.action.check'|translate" (click)="markAsRead()"
             *ngIf="linkedBilling" m-icon [type]="linkedBilling.billingDocumentType"></div>
        <div [class._check]="note.treated" class="note-header-icon" [title]="'common.notes.action.check'|translate" (click)="markAsRead()"
             *ngIf="!linkedBilling && !linkedUser" m-icon [type]="note.customerAccount.status"></div>

        <div>
            <a [routerLink]="['/boa', 'customers', note.customerAccount.id]"
               class="note-header-customer">{{note.customerAccount?.name}}</a>
            <span *ngIf="linkedBilling"
                  class="note-header-user"
                  [routerLink]="'/boa/accounting/'+linkedBilling.getDetailLink()"><i
                class="fas fa-link"></i><a>{{note.documentName | titlecase}}</a>
            </span>
            <span *ngIf="linkedUser"
                  class="note-header-user"
                  [routerLink]="['/boa', 'users', linkedUser.id]"><i
                class="fas fa-link"></i><a>{{note.documentName | titlecase}}</a>
            </span>
        </div>
    </div>
    <div class="note-content">
        <p>{{note.description}}</p>
    </div>

    <span class="note-author" *ngIf="authorName">{{'common.notes.from'| translate}} {{authorName | titlecase}} {{'common.notes.at'| translate }} {{note.creationDate | boaDateTime}}</span>
    <span class="note-author" *ngIf="!authorName">{{'common.notes.at'| translate | boaCapitalizeFirst }} {{note.creationDate | boaDateTime}}</span>
</ng-container>
