<a-select class="cell phone-12 big-desktop-24"
          [label]="label"
          [required]="required"
          [disabled]="disabled"
          [itemList]="allUsersName"
          [bindLabel]="'label'"
          [bindValue]="'value'"
          [name]="'customer-name'"
          (ngModelChange)="onUserIdChange($event)"
          [ngModel]="userId"></a-select>
