<m-card-header [label]="'player.session.title'|translate"></m-card-header>

<spacer size="16"></spacer>


<div class="game">
    <img class="game-icon" src="assets/images/logos/keyflash.jpg"/>
    <div class="gameInfos">
        <span class="gameInfos-name">{{'scenario.'+session?.baseMetaScenario.name| translate}}</span>
        <a-field [label]="'Session'">
            <i class="fas fa-puzzle-piece"></i>
            <ng-container value>
                {{session?.name}}
            </ng-container>
        </a-field>
        <a-field [label]="'Démarrage'">
            <i class="fas fa-calendar"></i>
            <ng-container value>
                {{session?.creationDate | boaDateTime}}
            </ng-container>
        </a-field>
    </div>
</div>

<spacer size="24"></spacer>

<a-field [label]="'Animateur principal'" (click)="navigationService.goToUserPage(session?.mainAnimator.id)">
    <i class="fas fa-user"></i>
    <ng-container value>
        <a>{{session?.mainAnimator.getFullName()}}</a>
    </ng-container>
</a-field>

<a-field [label]="'Équipe'">
    <i class="fas fa-users"></i>
    <ng-container value>
        {{player?.teamNumber}}
    </ng-container>
</a-field>

<a-field [label]="'Période en cours'">
    <i class="fas fa-calendar"></i>
    <ng-container value>
        {{session?.currentPeriod | boaPeriod : session?.baseMetaScenario.name | async}}
    </ng-container>
</a-field>


