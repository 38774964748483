<m-card-header [label]="'o-invoice-information.title'|translate">
    <div class="cell shrink grid-x grid-margin-x" button>
        <ng-container *ngIf="billingDocuments.length > 0">
            <a-split-button class="cell shrink"
                            [loading]="creationButtonLoading"
                            [visibility]="'HIGH'"
                            (listButtonClicked)="onListButtonClicked($event)"
                            (clicked)="goToQuoteCreationPage()" [buttonsList]="[
            {
                label: 'accounting.billings.create-invoice' | translate,
                icon: 'fa-plus'
            }
        ]"><i class="fas fa-plus"></i>{{'accounting.billings.create-quote' | translate}}</a-split-button>
        </ng-container>
        <a-dashboard-button-icon
            [queryParams]="filterParams"
            [routerLink]="['/boa', 'accounting', 'billings']"
            [visibility]="'LOW'"
            class="cell shrink"><i class="fas fa-list"></i>
        </a-dashboard-button-icon>
    </div>
</m-card-header>

<spacer size="16"></spacer>
<ng-container *ngIf="billingDocuments.length > 0">
    <m-invoices-list class="phone-up-6 desktop-up-3 big-desktop-up-4" [billingDocuments]="billingDocuments"></m-invoices-list>

    <spacer size="16"></spacer>

    <div class="grid-x grid-margin-x phone-up-2">
        <a-indicator class="cell _quotes"
                     [title]="'o-invoice-information.pending.quotes'|translate"
                     [imgSrc]="'assets/images/svg/indicator/quote-indicator.svg'"
                     [value]="customerAccountInvoiceInformation?.pendingQuotesAmount | currency : 'EUR'">
        </a-indicator>
        <a-indicator class="cell _invoices"
                     [title]="'o-invoice-information.invoices.turnover'|translate"
                     [imgSrc]="'assets/images/svg/indicator/invoice-indicator.svg'"
                     [value]="customerAccountInvoiceInformation?.invoicedTurnoverAmount| currency : 'EUR'">
        </a-indicator>
    </div>
</ng-container>


<ng-container *ngIf="billingDocuments.length == 0">
    <div class="grid-y align-center align-middle _emptyMode">
        <spacer size="32"></spacer>
        <a-button-default class="cell shrink text-center" visibility="LOW" size="LARGE" (click)="goToQuoteCreationPage()">
            <i class="fas fa-plus"></i> {{'accounting.billings.create-quote' | translate}}
        </a-button-default>
        <spacer size="16"></spacer>
    </div>
</ng-container>

