import {Component, HostBinding, OnInit} from '@angular/core';
import {NavigationService} from '../../../common/services/navigation.service';

@Component({
    selector: 'boa-menu-left',
    templateUrl: './menu-left.component.html',
    styleUrls: ['./menu-left.component.scss'],
    host: {class: 'grid-y align-justify'}
})
export class MenuLeftComponent implements OnInit {
    newBoaMenus: any;

    constructor(private navigationService: NavigationService) {
    }

    ngOnInit(): void {
        this.newBoaMenus = this.navigationService.APPLICATION_MENUS;
    }

}
