import { Pipe, PipeTransform } from '@angular/core';
import {siretFormatter} from '../../../shared/functions/formatter-utils';

@Pipe({
  name: 'boaSiretFormatter'
})
export class SiretFormatterPipe implements PipeTransform {

  transform(value: string | number, ...args: unknown[]): unknown {
    return siretFormatter(value);
  }

}
