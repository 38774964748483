export enum CustomerAccountTypes {
    HIGH_SCHOOL = 'HIGH_SCHOOL',
    IUT = 'IUT',
    IAE = 'IAE',
    UNIVERSITY = 'UNIVERSITY',
    FORMATION_SCHOOL = 'FORMATION_SCHOOL',
    EXPORT = 'EXPORT',
    COMPANY = 'COMPANY',
    ACCOUNTANTS = 'ACCOUNTANTS'
}
