<div class="cell shrink grid-x align-middle align-justify">
    <h1 class="cell auto">{{'operation.list.title'|translate}}</h1>
    <a-button-default (click)="downloadCsv()" class="cell shrink">
        <i class="fas fa-download"></i>
        {{'common.action.export' | translate}}
    </a-button-default>
</div>

<spacer size="16"></spacer>

<o-filters class="cell shrink" [countsMap]="countsMap">
    <m-search-query
        class="cell phone-12 desktop-10 big-desktop-12"
        [(ngModel)]="searchQuery"
        [placeholder]="'operation.list.search.placeholder' | translate"></m-search-query>

    <o-double-datepicker class="cell shrink" [(from)]="fromDate" [(to)]="toDate"></o-double-datepicker>

    <m-type-select-query class="cell shrink"  [(types)]="operationQueryFilter"
                         [selectTitle]="'operation.list.search.destination'|translate"
                         queryName="operationQueryFilter"></m-type-select-query>

    <a-checkbox class="cell shrink" [size]="'small'" [(ngModel)]="gift" (change)="giftChanged($event)" [label]="'operation.list.search.gift' | translate"></a-checkbox>
</o-filters>

<spacer size="24"></spacer>

<div class="cell auto grid-y">
    <o-table [headers]="tableHeaders" (scroll)="loadNext()" [class._empty]="operations.length === 0" [isEmpty]="operations.length === 0">
        <tr *ngFor="let operation of operations">
            <td>{{operation.operationDate | boaDateTime}}</td>
            <td>{{operation.label}}</td>
            <td>
                <a class="transferUser" *ngIf="operation.origin.getLinkRef()"
                    [routerLink]="[operation.getOriginLink() + '/' + operation.origin.getLinkRef()]">
                    <div class="transferUser-icon" m-icon [type]="operation.origin['@type']"></div>
                    {{operation.origin.getName()}}
                </a>
                <div class="transferUser" *ngIf="!operation.origin.getLinkRef()">
                    <div class="transferUser-icon" m-icon [type]="operation.origin['@type']"></div>
                    {{operation.origin.getName()}}
                </div>
            </td>
            <td>
                <a class="transferUser" *ngIf="operation.target.getLinkRef()"
                   [routerLink]="[operation.getTargetLink() + '/' + operation.target.getLinkRef()]">
                    <div class="transferUser-icon" m-icon [type]="operation.target['@type']"></div>
                    {{operation.target.getName()}}
                </a>
                <div class="transferUser" *ngIf="!operation.target.getLinkRef()">
                    <div class="transferUser-icon" m-icon [type]="operation.target['@type']"></div>
                    {{operation.target.getName()}}
                </div>

            </td>
            <td class="text-right"><span class="_tableNumber">{{operation.amount | number}}</span></td>
            <td><a [routerLink]="['/boa/customers', operation.customerAccountRef]">
                {{operation.companyName}}</a></td>
            <td>
                <a *ngIf="operation.linkedDocumentInformation"
                   class="linkedDocumentImg"
                   [ngClass]="'_' + operation.linkedDocumentInformation.type.toLocaleLowerCase()"
                   [routerLink]="buildDocumentLink(operation.linkedDocumentInformation)">
                </a>
            </td>
            <td>
                <img class="giftIcon" *ngIf="operation.gift" src="../../../../../../assets/images/svg/gift.svg">
            </td>
        </tr>
        <tr *ngIf="operations.length === 0"
            [columnNumber]="tableHeaders.length"
            [emptyLabel]="'operation.list.table.empty.title' | translate"
            o-empty-table>{{'operation.list.table.empty.message' | translate}}</tr>
    </o-table>
</div>
