import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {ButtonsModule} from '../../shared/components/atoms/buttons/buttons.module';
import {OTokenTransferComponent} from './components/organisms/o-token-transfer/o-token-transfer.component';
import {MTokenUserComponent} from './components/molecules/m-token-user-info/m-token-user.component';
import {OUserLastOperationsComponent} from './components/organisms/o-user-last-operations/o-user-last-operations.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {MCustomerUserSelectComponent} from '../customers/components/molecules/m-customer-user-select/m-customer-user-select.component';
import {UserComponentsModule} from '../users/components/user-components.module';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {OCustomerLastOperationsComponent} from './components/organisms/o-customer-last-operations/o-customer-last-operations.component';
import {OLastOperationsComponent} from './components/organisms/o-last-operations/o-last-operations.component';
import {OArkheLastOperationsComponent} from './components/organisms/o-arkhe-last-operations/o-arkhe-last-operations.component';
import {OperationListComponent} from './views/operation-list/operation-list.component';
import {AllOperationsResolver} from './resolvers/all-operations.resolver';
import {OperationsService} from './services/operations.service';
import {MOperationComponent } from './components/organisms/m-operation/m-operation/m-operation.component';
import {
    OAccountManagersLastOperationsComponent
} from './components/organisms/o-account-managers-last-operations/o-account-managers-last-operations.component';

const components = [
    MCustomerUserSelectComponent,
    MTokenUserComponent,
    OAccountManagersLastOperationsComponent,
    OArkheLastOperationsComponent,
    OCustomerLastOperationsComponent,
    OLastOperationsComponent,
    OperationListComponent,
    OTokenTransferComponent,
    OUserLastOperationsComponent,
];

@NgModule({
    declarations: [components, MOperationComponent],
    exports: [components, MOperationComponent],
    imports: [
        ButtonsModule,
        CommonModule,
        DirectivesModule,
        FormsModule,
        NgSelectModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        UserComponentsModule,
        ReactiveFormsModule
    ],
    providers: [
        AllOperationsResolver,
        OperationsService
    ]
})
export class OperationsModule {
}
