import {Person} from './person';
import {Role} from './role';
import {BaseObject} from '../../common/base-object';
import {ConnectedUser} from '../../../app-root/models/connected-user';
import {CustomerAccount} from '../../customers/models/customer-account';
import {AnimatorType} from './user';

export class FullUser extends BaseObject implements ConnectedUser {
    constructor(
        public login: string = '',
        public person: Person = null,
        public lastLoginDate: Date = null,
        public customerAccount: CustomerAccount = null,
        public tokenBalance: number,
        public animatorType: AnimatorType = null,
        public role: Role = null,
        typeId: string
    ) {
        super();
        this['@type'] = typeId;
    }

    static empty(): FullUser {
        return new FullUser('', Person.empty(), new Date(), CustomerAccount.empty(), 0, undefined, undefined, '');
    }

    getFullName(): string {
        return (this.person.firstName.trim()) + ' ' + (this.person.lastName.trim());
    }
}
