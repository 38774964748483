<a-select
    [itemList]="yearsList"
    [(ngModel)]="year"
    (ngModelChange)="onChange($event)"
    (blur)="onNgTouched()"
    [label]="label"
    [placeholder]="'year.select.placeholder'|translate"
    class="cell phone-12 desktop-13 medium-desktop-10"
    [name]="name"
></a-select>
