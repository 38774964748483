import {AddressInvoice} from './address-invoice';
import {Type} from 'class-transformer';

export class Vendor {
    id: string;
    name: string;
    fullName: string;
    @Type(() => AddressInvoice)
    address: AddressInvoice;
    siren: string;
    siret: string;

    constructor(id: string, name: string, fullName: string, address: AddressInvoice, siren: string, siret: string) {
        this.id = id;
        this.name = name;
        this.fullName = fullName;
        this.address = address;
        this.siren = siren;
        this.siret = siret;
    }

    static empty(): Vendor {
        return new Vendor('', '', '', AddressInvoice.empty(), '', '');
    }
}
