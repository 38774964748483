import {Component, Input, OnChanges} from '@angular/core';
import {BillingDocument, ExtendedBillingDocument} from '../../../../newboa/accounting/models/invoices/billing-document';

@Component({
    selector: 'a-invoice',
    templateUrl: './a-invoice.component.html',
    styleUrls: ['./a-invoice.component.scss']
})
export class AInvoiceComponent implements OnChanges {

    @Input()
    billingDocument: ExtendedBillingDocument;

    reference = '';
    status = '';

    constructor() { }

    ngOnChanges(): void {
        this.reference = this.billingDocument.getReference();
        this.status = this.billingDocument.getStatus();
    }


}
