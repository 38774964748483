<m-card-header [label]="'accounting.export.title'| translate">
</m-card-header>

<spacer size="16"></spacer>

<div class="grid-x grid-margin-x grid-margin-y">
    <a-select-month class="cell phone-8 desktop-12"
                    [(ngModel)]="month"
                    [label]="'common.month'|translate"
                    [name]="'accounting-export-month'">
    </a-select-month>
    <a-select-year class="cell phone-8 desktop-12"
                   [(ngModel)]="year"
                   [label]="'common.year'|translate"
                   [name]="'accounting-export-month'">
    </a-select-year>
    <a-button-default class="cell phone-8 desktop-24 accountingExportButton" (click)="exportSales()">
        <i class="fas fa-download"></i>{{'accounting.export.action'|translate}}
    </a-button-default>
</div>
