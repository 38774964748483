import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {fromRole, fromSize, Role, Size} from '../buttons-utils';
import {ControlContainer, NgForm} from '@angular/forms';

@Component({
    selector: 'a-button-submit',
    templateUrl: './a-button-submit.component.html',
    styleUrls: [
        './../buttons.scss',
        './a-button-submit.component.scss'
    ],
    viewProviders: [{provide: ControlContainer, useExisting: NgForm}]
})


export class AButtonSubmitComponent {

    @Input()
    public disabled = false;

    @Input()
    public validate: boolean;

    @Input()
    public loading = false;

    @Input()
    public size: Size = 'DEFAULT';

    @Input()
    public role: Role = 'DEFAULT';

    @HostBinding('class._expanded')
    @Input()
    public expanded = false;

    public fromSize = fromSize;
    public fromRole = fromRole;
}
