import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {User} from '../../../models/user';


const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MUserSelectComponent),
  multi: true
};

@Component({
  selector: 'boa-user-select',
  templateUrl: './m-user-select.component.html',
  styleUrls: ['./m-user-select.component.scss'],
  providers: [VALUE_ACCESSOR]
})
export class MUserSelectComponent implements OnInit, ControlValueAccessor {

  userList: Array<User>;
  user: User;

  @Input()
  selectOnTab: boolean;
  @Input()
  tabIndex: number;

  @Input()
  defaultUserLogin: string;
  @Input()
  public placeholder: string;

  private onNgChange: (user: User) => void;
  private onNgTouched: () => void;


  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this.onNgChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onNgTouched = fn;
  }

  writeValue(user: User): void {
    this.user = user;
  }

  onChange(user: User): void {
    this.user = user;
    this.onNgChange(user);
  }

}
