import {
    AfterContentInit,
    AfterViewInit,
    Component,
    EventEmitter, forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ASearchComponent} from '../../atoms/forms/a-search/a-search.component';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'm-search-query',
    templateUrl: './m-search-query.component.html',
    styleUrls: ['./m-search-query.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MSearchQueryComponent),
            multi: true
        }
    ]
})
export class MSearchQueryComponent implements OnInit, AfterViewInit, ControlValueAccessor {

    constructor(private router: Router, private route: ActivatedRoute) { }

    @ViewChild('searchQueryComponent')
    public searchQueryComponent: ASearchComponent;

    @Input()
    public placeholder = '';

    @Input()
    public queryName = 'search';

    searchQuery: string;
    disabled = false;

    private onChangeFn = (value) => {};
    private onTouchedFn = (value) => {};

    writeValue(data: string): void {
        this.searchQuery = data;
        this.onChangeFn(data);
    }
    registerOnChange(fn: (value: string) => void): void {
        this.onChangeFn = fn;
    }
    registerOnTouched(fn: (value) => void): void {
        this.onTouchedFn = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngAfterViewInit(): void {
        fromEvent(this.searchQueryComponent.inputField.nativeElement, 'keyup')
            .pipe(
                map((event: any) => event.target.value),
                filter(text => text != null),
                debounceTime(200),
                distinctUntilChanged()
            ).subscribe( () => {
            this.onKeyup();
        });
    }

    private onKeyup(): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                [this.queryName]: this.searchQuery
            },
            queryParamsHandling: 'merge'
        });
    }

    onChange(newValue: string): void {
        this.onChangeFn(this.takeOrBlank(newValue));
    }

    private takeOrBlank(value: string): string {
        return value ? value : '';
    }

    ngOnInit(): void {
    }
}
