<label>
    {{label}} <ng-template [ngIf]="required && label"><span class="requiredMark">*</span></ng-template>
    <ng-select #ngSelectComponent='ngModel'
               [items]="itemList"
               [required]="required"
               [ngClass]="dropdown"
               [bindLabel]="bindLabel"
               [bindValue]="bindValue"
               [notFoundText]="'common.select.empty-list' | translate"
               (blur)="onTouched()"
               [clearable]="clearable"
               [searchable]="searchable"
               [ngModel]="model"
               [selectOnTab]="selectOnTab"
               [addTag]="addTag"
               [placeholder]="placeholder"
               [multiple]="multiple"
               [isOpen]="isOpen"
               (ngModelChange)="onChange($event)"
               [disabled]="isDisabled"
               [compareWith]="compareWith"
               [tabIndex]="tabIndex">
    </ng-select>
</label>

<ng-template [ngIf]="!ngSelectComponent.pristine">
    <span class="help-text _error" *ngIf="ngSelectComponent.errors?.required">{{'error.required' | translate}} </span>
</ng-template>
