import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'o-content-block',
  templateUrl: './o-content-block.component.html',
  styleUrls: ['./o-content-block.component.scss']
})
export class OContentBlockComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }
}
