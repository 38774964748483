import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomerAccountContact} from '../../../../users/models/contact';
import {AddressType} from '../../../../common/models/address/addressType';

@Component({
    selector: 'm-customer-addresses',
    templateUrl: './m-customer-addresses.component.html',
    styleUrls: ['./m-customer-addresses.component.scss'],
    host: {class: 'grid-x'},
})
export class MCustomerAddressesComponent implements OnChanges {

    @Input()
    contacts: Array<CustomerAccountContact> = [];

    billingContact: CustomerAccountContact;
    deliveryContact: CustomerAccountContact;
    contactContact: CustomerAccountContact;
    contact: CustomerAccountContact = CustomerAccountContact.empty(null);

    constructor() {}

    fill(contacts: Array<CustomerAccountContact>): void {
        if (contacts && contacts.length > 0) {
            this.uniformiseContacts(contacts);
            this.billingContact = contacts.find(el => el.address?.type === AddressType.BILLING);
            this.deliveryContact = contacts.find(el => el.address?.type === AddressType.DELIVERY);
            this.contactContact = contacts.find(el => el.address?.type === AddressType.CONTACT);
            this.contact = this.billingContact;
        }
    }

    uniformiseContacts(contacts: CustomerAccountContact[]): void {
        if (!contacts.find(el => el.address?.type === AddressType.BILLING)) {
            contacts.push(CustomerAccountContact.empty(AddressType.BILLING));
        }
        if (!contacts.find(el => el.address?.type === AddressType.DELIVERY)) {
            contacts.push(CustomerAccountContact.empty(AddressType.DELIVERY));
        }
        if (!contacts.find(el => el.address?.type === AddressType.CONTACT)) {
            contacts.push(CustomerAccountContact.empty(AddressType.CONTACT));
        }
    }

    switchBilling(): void {
        this.contact = this.billingContact;
    }

    switchDelivery(): void {
        if (this.deliveryContact) {
            this.contact = this.deliveryContact;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.contacts) {
            this.fill(this.contacts);
        }
    }
}
