import {Component, OnInit} from '@angular/core';
import {ConsumptionDashboardStats} from '../../../../statistics/models/consumption-dahboard-stats';
import {StatisticsService} from '../../../../statistics/services/statistics.service';

@Component({
    selector: 'o-consumption-stats',
    templateUrl: './o-consumption-stats.component.html',
    styleUrls: ['./o-consumption-stats.component.scss']
})
export class OConsumptionStatsComponent implements OnInit {

    generalStats: ConsumptionDashboardStats;

    constructor(private statisticsService: StatisticsService) {
    }

    ngOnInit(): void {
        this.statisticsService.computeConsumptionDashboardStats().subscribe(stats => this.generalStats = stats);
    }

}
