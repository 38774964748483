import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Role, Size, fromRole, fromSize} from '../buttons-utils';
import {Location} from '@angular/common';

@Component({
  selector: 'a-button-back',
  templateUrl: './a-button-back.component.html',
  styleUrls: [
      './../buttons.scss',
      './a-button-back.component.scss'
  ]
})
export class AButtonBackComponent implements OnInit {
    @Input()
    public disabled = false;

    @Input()
    public size: Size = 'DEFAULT';

    @Input()
    public role: Role = 'DEFAULT';

    @Output()
    public click: EventEmitter<null> = new EventEmitter();

    public fromSize = fromSize;
    public fromRole = fromRole;

    constructor(private location: Location) {
    }

    public doAction(): void {
        this.location.back();
    }

    ngOnInit(): void {
    }
}
