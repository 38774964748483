import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {HomeStats} from '../models/home-stats';
import {HttpClient} from '@angular/common/http';
import {ScenarioUsageStats} from '../models/scenario-usage-stats';
import {ConsumptionDashboardStats} from '../models/consumption-dahboard-stats';
import {AccountingDashboardStats} from '../models/accounting-dashboard';

@Injectable()
export class StatisticsService {

    private baseUrl = '/api/statistics';

    constructor(private http: HttpClient) {
    }

    computeGeneralStatistics(): Observable<HomeStats> {
        return this.http.get<HomeStats>(`${this.baseUrl}/general`);
    }

    computeScenarioUsages(): Observable<Array<ScenarioUsageStats>> {
        return this.http.get<Array<ScenarioUsageStats>>(`${this.baseUrl}/scenarii-usage`);
    }

    computeConsumptionDashboardStats(): Observable<ConsumptionDashboardStats> {
        return this.http.get<ConsumptionDashboardStats>(`${this.baseUrl}/consumptions`, {});
    }

    computeAccountingDashboardStats(): Observable<AccountingDashboardStats> {
        return this.http.get<AccountingDashboardStats>(`${this.baseUrl}/accounting`, {});
    }
}
