import {Component, Input, OnInit} from '@angular/core';
import {Params} from '@angular/router';
import {BillingDocument, BillingDocumentType, ExtendedBillingDocument} from '../../../models/invoices/billing-document';
import {InvoiceStatus} from '../../../models/invoices/invoice';
import {QuoteStatus} from '../../../models/invoices/quote';
import {BillingDocumentService} from '../../../services/billing-document.service';

@Component({
    selector: 'o-accounting-lists',
    templateUrl: './o-accounting-lists.component.html',
    styleUrls: ['./o-accounting-lists.component.scss']
})
export class OAccountingListsComponent implements OnInit {

    @Input()
    isDelivered: boolean;

    @Input()
    listTitle;

    @Input()
    verticalLayout: boolean;

    @Input()
    emptyLabel: string;

    @Input()
    type: BillingDocumentType;

    @Input()
    status: Array<InvoiceStatus | QuoteStatus>;

    @Input()
    illustrationId: number;

    billingDocumentClass: string;
    billingDocuments: Array<ExtendedBillingDocument>;

    constructor(private billingDocumentService: BillingDocumentService) {
    }

    ngOnInit(): void {
        this.billingDocumentService.findExtended(
            null,
            null,
            [this.type],
            this.status,
            4,
            0,
            'date:DESC',
            null,
            this.isDelivered
        ).subscribe(result => this.billingDocuments = result.data);
        this.billingDocumentClass = (this.type === BillingDocumentType.INVOICE ? '_invoices' : '_quotes');
    }

}
