import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerAccountTokenDeliveryInformation} from '../customer-account-token-delivery-information';

@Component({
  selector: 'a-invoice-customer-account-for-token-delivery',
  templateUrl: './a-invoice-customer-account-for-token-delivery.component.html',
  styleUrls: ['./a-invoice-customer-account-for-token-delivery.component.scss']
})
export class AInvoiceCustomerAccountForTokenDeliveryComponent {
  @Input()
  isChargedCustomer = false;

  @Input()
  customerAccountTokenDeliveryInformation: CustomerAccountTokenDeliveryInformation;

  @Output()
  customerAccountSelectedForTokenDelivery = new EventEmitter<CustomerAccountTokenDeliveryInformation>();

  onSelect(): void {
    this.customerAccountSelectedForTokenDelivery.emit(this.customerAccountTokenDeliveryInformation);
  }
}
