import {CurrencyEnum} from './currency-enum';

export class Amount {
    value: number;
    currency: CurrencyEnum = CurrencyEnum.EUR;

    constructor(value: number, currency: CurrencyEnum) {
        this.value = value;
        this.currency = currency;
    }

    static empty(): Amount {
        return new Amount(0, CurrencyEnum.EUR);
    }
}
