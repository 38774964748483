<div class="cell grid-x lastConsumptions-item">
    <div class="cell auto">
        <div class="grid-x align-middle movementHeader">
            <img class="cell shrink giftIcon" *ngIf="operation.gift"
                 src="../../../../../../../../assets/images/svg/gift.svg">
            <a class="cell shrink movement-type">{{'operations.type.'+type | translate}}</a>
        </div>
        <a-field [label]="'operations.last.operations.label' | translate">
            <i class="fas fa-pencil-alt"></i>
            <ng-container value>
                {{operation.label}}
            </ng-container>
        </a-field>
        <a-field [label]="'operations.last.operations.date' | translate">
            <i class="fas fa-calendar-alt"></i>
            <ng-container value>
                {{operation.operationDate | boaDateTime}}
            </ng-container>
        </a-field>
        <a-field [label]="'operations.last.operations.token.amount' | translate">
            <i class="fas fa-coins"></i>
            <ng-container value>
                {{operation.amount}} {{'common.token' | translate}}
            </ng-container>
        </a-field>
        <a-field [label]="'operations.last.operations.source' | translate">
            <i class="fas fa-user"></i>
            <ng-container value>
                <a  *ngIf="(operation.origin?.getLinkRef()
                            && operation.target['&type'] !== operationDestinationType.ARKHE) else noLinkOrigin"
                    [routerLink]="[operation.getOriginLink(), operation.origin?.getLinkRef()]">{{operation.origin?.getName()}}</a>
                <ng-template #noLinkOrigin>{{operation.origin?.getName()}}</ng-template>
            </ng-container>
        </a-field>
        <a-field [label]="'operations.last.operations.target' | translate"
                 [value]="(operation.amount) + ' ' + ('common.token' | translate)">
            <i class="fas fa-user"></i>
            <ng-container value>
                <a *ngIf="(operation.target?.getLinkRef()
                            && operation.target['&type'] !== operationDestinationType.ARKHE) else noLinkTarget"
                   [routerLink]="[operation.getTargetLink(), operation.target?.getLinkRef()]">{{operation.target?.getName()}}</a>
                <ng-template #noLinkTarget>{{operation.target?.getName()}}</ng-template>
            </ng-container>
        </a-field>
    </div>
</div>
