<div class="grid-x align-justify align-middle">
    <span *ngIf="type === 'emitter'" class="cell shrink transferLabel">De :</span>
    <span *ngIf="type === 'receiver'" class="cell shrink transferLabel">À :</span>
    <div class="cell shrink grid-x align-middle">
        <div *ngIf="user.role === 'ARKHE'" class="cell shrink tokenUser-icon _arkhe">
            <img src="assets/images/svg/arkhe.svg"/>
        </div>
        <div *ngIf="user.role != 'ARKHE'" class="cell shrink tokenUser-icon" m-icon
             [type]="user?.role == 'ANIMATOR' ? user?.animatorType : user?.role"></div>
        <span class="cell shrink tokenUser-name"
              *ngIf="!freeMode"
              [ngClass]="'_' + user?.role?.toLowerCase()">
                {{user?.person?.firstName + ' ' + user?.person?.lastName | titlecase}}
            </span>
        <a-select (ngModelChange)="onChange($event)"
                  *ngIf="freeMode"
                  [itemList]="userSelectMapped"
                  [bindLabel]="'label'"
                  [bindValue]="'value'"
                  [name]="'user'"
                  [ngModel]="selectedUserId"
                  class="cell shrink tokenUser-name _edit">
        </a-select>
    </div>
</div>
