import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatisticsService} from './services/statistics.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule
    ],
    providers: [
        StatisticsService
    ]
})
export class StatisticsModule {
}
