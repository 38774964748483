import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Params} from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class DownloadService {

    constructor(private http: HttpClient) { }

    downloadFileWithParams(blobSrc: string, fileName: string, fileExtension: string, params: Params): void {
        this.http.get(blobSrc, {responseType: 'blob', params}).subscribe(blob => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: blob.type}));
            downloadLink.setAttribute('download', `${fileName}.${fileExtension}`);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });
    }

    downloadFile(blobSrc: string, fileName: string, fileExtension: string): void {
        this.http.get(blobSrc, {responseType: 'blob'}).subscribe(blob => {
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([blob], {type: blob.type}));
            fileExtension
                ? downloadLink.setAttribute('download', `${fileName}.${fileExtension}`)
                : downloadLink.setAttribute('download', `${fileName}`);
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });
    }
}
