import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Result} from '../../common/models/shared/result';
import {Consumption} from '../models/consumption';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Router} from '@angular/router';
import {Scenarii, ScenariiValues} from '../../common/models/scenario/scenarii';

@Injectable()
export class ConsumptionsService {
    private baseUrl = '/api/consumptions';
    private radix = 10;

    constructor(private http: HttpClient, private router: Router) { }

    getCsvLink(): string {
        return this.baseUrl + '/csv';
    }

    find(page: number = 0,
         limit: number = 50,
         searchQuery: string = '',
         sort: string = null,
         scenarioNames: Array<string> = [],
         customerAccountId: string = null,
         animatorId: string = null,
         fromDate: string = null,
         toDate: string = null): Observable<Result<Consumption>> {

        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('limit', limit);
        params = params.append('search', searchQuery);
        params = params.append('customerAccountId', customerAccountId);
        params = params.append('animatorId', animatorId);
        if (sort) { params = params.append('sort', sort); }
        if (fromDate) { params = params.append('fromDate', fromDate); }
        if (toDate) { params = params.append('toDate', toDate); }

        if (scenarioNames.indexOf('ALL') > -1) {
            scenarioNames = ScenariiValues
                .map(scenario => scenario)
                .map(scenario => scenario.split(','))
                .reduce((acc, value) => acc.concat(value), []);
        }
        params = params.appendAll({scenarioNames,  aggregate: 'amount'});

        return this.http.get<Array<Consumption>>(`${this.baseUrl}`, {
            observe: 'response',
            params
        }).pipe(
            map(consumptions => {
                return new Result<Consumption>(
                    plainToClass(Consumption, consumptions.body),
                    parseInt(consumptions.headers.get('TOTAL_COUNT'), this.radix),
                    new Map([['amount', parseInt(consumptions.headers.get('X_AGGREGATE_AMOUNT'), 10)]])
                );
            })
        );
    }

    findLasts(limit: number, customerAccountId: string, animatorIds: Array<string>): Observable<Result<Consumption>> {
        return this.http.get<Array<Consumption>>(`${this.baseUrl}/lasts`, {
            observe: 'response',
            params: {
                limit,
                customerAccountId,
                animatorIds
            }
        }).pipe(
            map(response => {
                return new Result<Consumption>(
                    plainToClass(Consumption, response.body),
                    null,
                    null
                );
            })
        );
    }
}
