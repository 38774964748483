<o-block [headerLabel]="'user.animator.list.title' | translate" [userType]="animatorType.REGULAR">
    <ng-container button>
        <a-dashboard-button-icon *ngIf="verticalLayout" [visibility]="'LOW'" (click)="onViewAnimatorsClick()">
            <i class="fas fa-list"></i>
        </a-dashboard-button-icon>
        <a-button-default *ngIf="!verticalLayout" (click)="onViewAnimatorsClick()">
            <i class="fas fa-list"></i> {{'user.animator.list.action' | translate}}
        </a-button-default>
    </ng-container>
    <div *ngIf="animators.length > 0"
        [ngClass]="verticalLayout ? 'grid-y grid-margin-y' : 'grid-x grid-margin-y grid-margin-x align-stretch phone-up-2 tablet-landscape-up-3 medium-desktop-up-4'">
        <div class="cell grid-x align-middle lastAnimators-item" *ngFor="let animator of animators">
            <div class="cell auto lastAnimators-item-infos">
                <a class="animator-id" [routerLink]="['/boa/users', animator.id]">{{animator.login}}</a>
                <span class="animator-secondaryInfo">
                    <span class="animator-secondaryInfo-value">{{animator.person.firstName}} {{animator.person.lastName}}</span>
                </span>
                <span class="animator-secondaryInfo">
                    {{'user.animator.list.last.token.amount' | translate}}
                    <span class="animator-secondaryInfo-value">{{animator.tokenBalance | number}} {{'common.token' | translate}}</span>
                </span>
                <span class="animator-secondaryInfo">
                    {{'user.animator.list.last.connection.date' | translate}}
                    <span class="animator-secondaryInfo-value">{{(animator.lastLoginDate | boaDateTime : ('common.at' | translate)) | boaNotNull : 'common.never-connected'}}</span>
                </span>
            </div>
            <a-dashboard-button-icon class="lastAnimators-action" *ngIf="animator.animatorType != animatorType.DEMONSTRATION" [visibility]="'LOW'" (click)="onTransferClick(animator)">
                <i class="fas fa-exchange-alt"></i>
            </a-dashboard-button-icon>
        </div>
    </div>

    <o-empty-list *ngIf="animators.length === 0" [noResultTitle]="'user.animator.list.table.empty.title' | translate">
    </o-empty-list>
</o-block>
