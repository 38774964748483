import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccountingDashboardComponent} from './views/accounting-dashboard/accounting-dashboard.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../shared/shared.module';
import {ArticleListComponent} from './views/article-list/article-list.component';
import {ArticleService} from './services/article.service';
import {AllArticlesResolver} from './resolvers/all-articles.resolver';
import {BillingsListComponent} from './views/invoice-list/billings-list.component';
import {QuoteListComponent} from './views/quote-list/quote-list.component';
import {InvoiceDetailComponent} from './views/invoice-detail/invoice-detail.component';
import {MArticleComponent} from './components/molecules/m-article/m-article.component';
import {MArticleEditComponent} from './components/molecules/m-article-edit/m-article-edit.component';
import {
    OInvoiceTokenArticlesComponent
} from './components/organisms/o-invoice-token-articles/o-invoice-token-articles.component';
import {
    MInvoiceArticlesListComponent
} from './components/molecules/m-invoice-articles-list/m-invoice-articles-list.component';
import {
    MInvoiceArticlesTokensTotalComponent
} from './components/molecules/m-invoice-articles-tokens-total/m-invoice-articles-tokens-total.component';
import {OLinkedDocumentsComponent} from './components/organisms/o-linked-documents/o-linked-documents.component';
import {OLinkedTransfersComponent} from './components/organisms/o-linked-transfers/o-linked-transfers.component';
import {QuotesService} from './services/quotes.service';
import {OAccountingListsComponent} from './components/organisms/o-accounting-lists/o-accounting-lists.component';
import {OAccountingStatsComponent} from './components/organisms/o-accounting-stats/o-accounting-stats.component';
import {MArticlesLinkComponent} from './components/molecules/m-articles-link/m-articles-link.component';
import {
    OInvoiceHeaderInformationsComponent
} from './components/organisms/o-invoice-header-informations/o-invoice-header-informations.component';
import {
    OInvoiceChargedCustomerComponent
} from './views/invoice-detail/o-invoice-charged-customer/o-invoice-charged-customer.component';
import {
    OInvoiceHeaderAddressesComponent
} from './components/organisms/o-invoice-header-addresses/o-invoice-header-addresses.component';
import {InvoiceByIdResolver} from './resolvers/invoice-by-id.resolver';
import {
    OInvoiceInfoPaymentComponent
} from './components/organisms/o-invoice-info-payment/o-invoice-info-payment.component';
import {OInvoiceArticlesComponent} from './components/organisms/o-invoice-articles/o-invoice-articles.component';
import {AInvoiceLineComponent} from './components/atoms/a-invoice-line/a-invoice-line.component';
import {
    MInvoiceArticlesTotalComponent
} from './components/molecules/m-invoice-articles-total/m-invoice-articles-total.component';
import {ASelectVatRateComponent} from './components/atoms/a-select-vat-rate/a-select-vat-rate.component';
import {BillingDocumentService} from './services/billing-document.service';
import {BillingDocumentsResolver} from './resolvers/billing-documents.resolver';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {OperationsModule} from '../operations/operations.module';
import {ASelectBillingComponent} from './components/atoms/a-select-billing/a-select-billing.component';
import {BoaNotNullPipe} from '../../shared/pipes/not-null.pipe';
import {CreditService} from './services/credit.service';
import {CreditResolver} from './resolvers/credit.resolver';
import {InvoiceLineService} from './services/invoice-line.service';
import {
    AInvoiceLineFormCreationComponent
} from './components/atoms/a-invoice-line-form/a-invoice-line-form-creation.component';
import {
    MInvoiceLineCreatorComponent
} from './components/molecules/m-invoice-line-creator/m-invoice-line-creator.component';
import {
    AInvoiceEmptyLineFormComponent
} from './components/atoms/a-invoice-empty-line-form/a-invoice-empty-line-form.component';
import {AInvoiceLineToggleComponent} from './components/atoms/a-invoice-line-toggle/a-invoice-line-toggle.component';
import {DndDraggableDirective, DndModule} from 'ngx-drag-drop';
import {MAccountingExportComponent} from './components/molecules/m-accounting-export/m-accounting-export.component';
import {ASelectMonthComponent} from './components/atoms/a-select-month/a-select-month.component';
import {ASelectYearComponent} from './components/atoms/a-select-year/a-select-year.component';
import {
    OInvoiceCustomersInfoComponent
} from './views/invoice-detail/o-invoice-customers-info/o-invoice-customers-info.component';
import {
    OInvoiceTokenCustomersComponent
} from './views/invoice-detail/o-invoice-token-customers/o-invoice-token-customers.component';
import {
    AInvoiceTokenCustomerLineComponent
} from './views/invoice-detail/o-invoice-token-customers/a-invoice-token-customer-line/a-invoice-token-customer-line.component';
import {
    MInvoiceTokenTransferComponent
} from './views/invoice-detail/m-invoice-token-transfer/m-invoice-token-transfer.component';
import {
    OInvoiceSelectCustomerAccountForTokenDeliveryComponent
} from './views/invoice-detail/m-invoice-token-transfer/o-invoice-select-customer-account-for-token-delivery/o-invoice-select-customer-account-for-token-delivery.component';
import {
    AInvoiceCustomerAccountForTokenDeliveryComponent
} from './views/invoice-detail/m-invoice-token-transfer/o-invoice-select-customer-account-for-token-delivery/a-invoice-customer-account-for-token-delivery/a-invoice-customer-account-for-token-delivery.component';
import {InvoiceHttpService} from './services/invoice-http.service';
import { AChorusProBadgeComponent } from './components/atoms/a-chorus-pro-badge/a-chorus-pro-badge.component';

const components = [
    ASelectMonthComponent,
    ASelectYearComponent,
    MInvoiceLineCreatorComponent,
    AInvoiceEmptyLineFormComponent,
    AccountingDashboardComponent,
    ArticleListComponent,
    ASelectVatRateComponent,
    BillingsListComponent,
    InvoiceDetailComponent,
    MArticleComponent,
    MArticleEditComponent,
    MArticlesLinkComponent,
    MInvoiceArticlesListComponent,
    MInvoiceArticlesTokensTotalComponent,
    MInvoiceArticlesTotalComponent,
    QuoteListComponent,
    OAccountingListsComponent,
    OAccountingStatsComponent,
    OInvoiceTokenArticlesComponent,
    OLinkedDocumentsComponent,
    OLinkedTransfersComponent,
    OInvoiceHeaderAddressesComponent,
    OInvoiceArticlesComponent,
    OInvoiceChargedCustomerComponent,
    OInvoiceHeaderInformationsComponent,
    OInvoiceInfoPaymentComponent,
    AInvoiceLineComponent,
    OInvoiceTokenArticlesComponent,
    ASelectBillingComponent,
    AInvoiceLineFormCreationComponent,
];

@NgModule({
  declarations: [
    components,
    AInvoiceLineToggleComponent,
    MAccountingExportComponent,
    OInvoiceCustomersInfoComponent,
    OInvoiceTokenCustomersComponent,
    AInvoiceTokenCustomerLineComponent,
    MInvoiceTokenTransferComponent,
    OInvoiceSelectCustomerAccountForTokenDeliveryComponent,
    AInvoiceCustomerAccountForTokenDeliveryComponent,
    AChorusProBadgeComponent,
  ],
    exports: [components, AChorusProBadgeComponent],
    imports: [
        CommonModule,
        DirectivesModule,
        DndModule,
        FormsModule,
        OperationsModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule,
    ],
    providers: [
        AllArticlesResolver,
        ArticleService,
        BillingDocumentsResolver,
        BillingDocumentService,
        BoaNotNullPipe,
        CreditResolver,
        CreditService,
        DndDraggableDirective,
        InvoiceHttpService,
        InvoiceByIdResolver,
        InvoiceLineService,
        QuotesService
    ]
})
export class AccountingModule {
}
