import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'tr[o-empty-table]',
    templateUrl: './o-empty-table.component.html',
    styleUrls: ['./o-empty-table.component.scss']
})
export class OEmptyTableComponent implements OnInit {

    @Input()
    columnNumber: number;

    @Input()
    emptyLabel: string;

    constructor() {
    }

    ngOnInit(): void {
    }

}
