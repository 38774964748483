import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Reveal} from 'foundation-sites';
import {ModalDirective} from './modal.directive';

@Component({
  selector: 'a-popup-component',
  templateUrl: './a-popup.component.html',
  styleUrls: ['./a-popup.component.scss']
})
export class APopupComponent {
    @ViewChild(ModalDirective)
    modal: ModalDirective;

    @Output()
    confirmed = new EventEmitter<void>();

    constructor() {}

    public open(): void {
        this.modal.open();
    }

    public close(): void {
        if (this.modal) {
            this.modal.close();
        }
    }

    public onConfirm(): void {
        this.confirmed.emit();
        this.close();
    }

    public onCancel(): void {
        this.close();
    }
}
