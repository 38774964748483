import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {ConnectedUserService} from '../../../app-root/services/connected-user.service';
import {User} from '../../users/models/user';
import {Authentication} from '../models/authentication';
import { Md5 } from 'ts-md5';

@Injectable()
export class AuthenticationService {

  private publicUrl = '/api/public/sessions';

  constructor(private router: Router,
              private http: HttpClient,
              private  connectedUserService: ConnectedUserService ) {}


  public authenticate(authentication: Authentication): Observable<User> {

    return this.http.post(`${this.publicUrl}`,
      {
        principal: {
          name: authentication.name,
          passwordHash: Md5.hashStr(authentication.password)
        }
      }, {
        observe: 'response'
      })
      .pipe(
        map((response: any) => response.body),
        tap((user: User) => this.connectedUserService.updateCurrentUser(user)));

  }

}
