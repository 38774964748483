import {Person} from './person';
import {Role} from './role';
import {BaseObject} from '../../common/base-object';
import {ConnectedUser} from '../../../app-root/models/connected-user';
import {CustomerAccount} from '../../customers/models/customer-account';

export class User extends BaseObject implements ConnectedUser {

  constructor(typeId: string) {
    super();
    this['@type'] = typeId;
    this.person = Person.empty();
  }

  login: string;
  person: Person;
  lastLoginDate: Date;
  role: Role;

  public getTokens(): number {
      return null;
  }

  public getCustomerAccount(): CustomerAccount {
      return null;
  }

  public getAnimatorType(): AnimatorType {
      return null;
  }

  public getFullName(): string {
      return `${this.person.firstName} ${this.person.lastName}`;
  }
}

export enum AnimatorType {
  REGULAR = 'REGULAR',
  DEMONSTRATION = 'DEMONSTRATION'
}
