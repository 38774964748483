<div class="cell phone-24 customerEditBlock grid-y grid-margin-y" [formGroup]="billingContactForm">
    <h2 class="customerEditBlock-title">Contact facturation</h2>
    <div class="cell shrink">
        <div class="grid-x grid-margin-x">
            <a-input [inputType]="'text'"
                     formControlName="firstName"
                     name="firstName"
                     [label]="'Prénom'"
                     class="cell phone-8"></a-input>
            <a-input [inputType]="'text'"
                     formControlName="lastName"
                     name="lastName"
                     [label]="'Nom'"
                     class="cell phone-8"></a-input>
            <a-input [inputType]="'text'"
                     formControlName="service"
                     name="service"
                     [label]="'Service'"
                     class="cell phone-8"></a-input>
        </div>
    </div>
    <div class="cell shrink">
        <div class="grid-x grid-margin-x">
            <a-input [inputType]="'text'"
                     formControlName="mail"
                     name="mail"
                     [label]="'Mail'"
                     class="cell phone-8"></a-input>
            <a-input [inputType]="'text'"
                     formControlName="mobilePhone"
                     name="mobilePhone"
                     [label]="'Téléphone'"
                     class="cell phone-8"></a-input>
        </div>
    </div>
</div>
