import {Role} from '../../users/models/role';
import {FullUser} from '../../users/models/full-user';

export abstract class OperationDestination {
    ['@type']: string;

    constructor(type: string) {
        this['@type'] = type;
    }

    static createOperationFromUser(user: FullUser): OperationDestination {
        switch (user.role) {
            case Role.ACCOUNT_MANAGER: return new CustomerOperationDestination(
                user.customerAccount.name,
                user.customerAccount.id,
                user.login
            );
            case Role.ANIMATOR: return new AnimatorOperationDestination(
                user.person?.firstName,
                user.person?.lastName,
                user.customerAccount.id,
                user.id,
                user.login);
            case Role.ARKHE: return new ArkheOperationDestination(user.person?.firstName, user.person?.lastName, user.id);
            default: throw new DOMException('Could not create operation from user');
        }
    }

    abstract getLinkRef(): string | null;
    abstract getName(): string | null;
}

export class ArkheOperationDestination extends OperationDestination {
    firstName: string;
    lastName: string;
    userId: string;

    constructor(firstName: string, lastName: string, userId: string) {
        super(OperationDestinationType.ARKHE);
        this.firstName = firstName;
        this.lastName = lastName;
        this.userId = userId;
    }

    getLinkRef(): string | null {
        return null;
    }

    getName(): string | null {
        return this.firstName + ' ' + this.lastName;
    }
}

export class AnimatorOperationDestination extends OperationDestination {
    login: string;
    firstName: string;
    lastName: string;
    customerAccountRef: string;
    animatorRef: string;

    constructor(firstName: string, lastName: string, customerAccountRef: string, animatorRef: string, login: string) {
        super(OperationDestinationType.ANIMATOR);
        this.login = login;
        this.firstName = firstName;
        this.lastName = lastName;
        this.customerAccountRef = customerAccountRef;
        this.animatorRef = animatorRef;
    }

    getLinkRef(): string | null {
        return this.animatorRef;
    }

    getName(): string | null {
        return this.login;
    }
}

export class CustomerOperationDestination extends OperationDestination {
    companyName: string;
    customerAccountRef: string;
    accountManagerLogin: string;

    constructor(companyName: string, customerAccountRef: string, accountManagerLogin: string) {
        super(OperationDestinationType.CUSTOMER);
        this.companyName = companyName;
        this.customerAccountRef = customerAccountRef;
        this.accountManagerLogin = accountManagerLogin;
    }

    getLinkRef(): string | null {
        return this.customerAccountRef;
    }

    getName(): string | null {
        return this.accountManagerLogin;
    }
}

export enum OperationDestinationType {
    ANIMATOR = 'animator',
    ARKHE = 'arkhe',
    CUSTOMER = 'customerAccount'
}
