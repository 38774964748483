import {Component, HostBinding, Input, Output} from '@angular/core';
import {areLinesReadonly, BillingDocument} from '../../../models/invoices/billing-document';
import {ArticleType} from '../../../models/article';
import { EventEmitter } from '@angular/core';
import {InvoiceLine} from '../../../models/invoices/invoice-line';
import {LineEdit} from '../../../models/invoices/line-edit';

@Component({
  selector: 'o-invoice-articles',
  templateUrl: './o-invoice-articles.component.html',
  styleUrls: ['./o-invoice-articles.component.scss']
})
export class OInvoiceArticlesComponent {

    @Input()
    billingDocument: BillingDocument;

    @Input()
    vatRate: number;

    @Input()
    grossTotal: number;

    @Input()
    netTotal: number;

    @Input()
    vatTotal: number;

    @Input()
    discountTotal: number;

    @Output()
    lineEdited = new EventEmitter<LineEdit>();

    @Output()
    lineDeleted = new EventEmitter<number>();

    @Output()
    lineAdded = new EventEmitter<InvoiceLine>();

    @Output()
    dirty = new EventEmitter<void>();

    articleTypes = ArticleType;

    @HostBinding('attr.class') class = 'grid-x grid-margin-x align-stretch';

    constructor() {
    }

    onLineEdited(invoiceLine: LineEdit): void {
        this.lineEdited.emit(invoiceLine);
    }

    onLineAdded(invoiceLine: InvoiceLine): void {
        this.lineAdded.emit(invoiceLine);
    }

    onLineDeleted(invoiceLineId: number): void {
        this.lineDeleted.emit(invoiceLineId);
    }

    areLinesReadonly(): boolean {
        return areLinesReadonly(this.billingDocument);
    }
}
