<div [class._empty]="isEmpty" class="tableScroll" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2.5"
     (scrolled)="onScroll()">
    <table>
        <thead>
        <tr>
            <th m-entry-query *ngFor="let tableHead of headers" [tableHeader]="tableHead"
                (sortClicked)="onSortClicked($event)"></th>
        </tr>
        </thead>
        <tbody #body>
        <ng-content></ng-content>
        <tr *ngIf="loading" class="loadingList">
            <td colspan="100"><i class="fas fa-spinner fa-spin"></i></td>
        </tr>
        </tbody>
    </table>
</div>

