<div class="cell shrink grid-x align-middle align-justify">
    <h1 class="cell auto">{{'consumption.list.title'|translate}}</h1>
    <a-button-default (click)="downloadCsv()">
        <i class="fas fa-download"></i>
        {{'common.action.export' | translate}}
    </a-button-default>
</div>

<spacer size="16"></spacer>

<o-filters class="cell shrink" [countsMap]="countsMap">
    <m-search-query
        class="cell phone-12"
        [(ngModel)]="searchQuery"
        [placeholder]="'consumption.list.search.placeholder' | translate"></m-search-query>

    <m-type-select-query class="cell shrink" [selectTitle]="'consumption.list.search.simulators'|translate"
                         [(types)]="scenarioNames" queryName="scenarioNames"></m-type-select-query>

    <o-double-datepicker class="cell shrink" [(from)]="fromDate" [(to)]="toDate"></o-double-datepicker>
</o-filters>

<spacer size="24"></spacer>

<div class="cell auto grid-y" *ngIf="!wholePageLoader">
    <o-table [headers]="tableHeaders" (scroll)="loadNext()" [class._empty]="consumptions.length === 0" [isEmpty]="consumptions.length === 0">
        <tr *ngFor="let consumption of consumptions">
            <td>{{consumption.consumptionDate | boaDateTime}}</td>
            <td>
                <a class="transferUser" [routerLink]="['/boa/users', consumption.sourceAnimator?.id]">
                    <div class="transferUser-icon" m-icon [type]="consumption.sourceAnimator?.animatorType"></div>
                    {{(consumption.sourceAnimator?.person.firstName | boaNotNull) + ' ' + (consumption.sourceAnimator?.person.lastName | boaNotNull) | titlecase}}
                </a>
            </td>
            <td>
                <a class="transferUser" [routerLink]="['/boa/users', consumption.animator?.id]">
                    <div class="transferUser-icon" m-icon [type]="consumption.animator?.animatorType"></div>
                    {{(consumption.animator?.person.firstName | boaNotNull) + ' ' + (consumption.animator?.person.lastName | boaNotNull) | titlecase}}
                </a>
            </td>
            <td class="text-right"><span class="_tableNumber">{{consumption.amount | number}}</span></td>
            <td>{{consumption.sessionName | boaNotNull : 'consumption.list.session.empty'}}</td>
            <td>{{('scenario.' + consumption.scenarioName) | translate}}</td>
            <td>
                {{consumption.simulationPeriod | boaPeriod : consumption.scenarioName | async}}
            </td>
            <td>
                <img class="cell shrink backInTimeIcon" *ngIf="consumption.type === 'REVERT_PERIOD'"
                     src="../../../../../../../../assets/images/svg/backInTime.svg">
            </td>
            <td class="text-right">{{consumption.teamCount}}</td>
            <td><a-customer-link [customerAccount]="consumption.customerAccount"></a-customer-link></td>
        </tr>
        <tr *ngIf="consumptions.length === 0"
            [columnNumber]="tableHeaders.length"
            [emptyLabel]="'consumption.list.table.empty.title' | translate"
            o-empty-table>{{'consumption.list.table.empty.message' | translate}}</tr>
    </o-table>
</div>

<a-boa-big-loader *ngIf="wholePageLoader"></a-boa-big-loader>
