import {Component, Input, OnInit} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {FullUser} from '../../../models/full-user';
import {CredentialGenerationRequest} from '../../../models/credential-generation-request';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {Md5} from 'ts-md5';

@Component({
  selector: 'o-reset-password',
  templateUrl: './o-reset-password.component.html',
  styleUrls: ['./o-reset-password.component.scss']
})
export class OResetPasswordComponent implements OnInit {

    @Input()
    user: FullUser;

    newPassword: string;
    sendMail: boolean;
    mode: 'manual' | 'automatic' = 'manual';

    constructor(private userService: UserService,
                private notificationService: NotificationService) { }

    ngOnInit(): void {
    }

    generatePassword(): string {
        return '';
    }

    saveNewPassword(): void {
        if (this.mode === 'automatic') {
            this.newPassword = this.generatePassword();
            const credentialGenerationRequest = new CredentialGenerationRequest(
                this.user.login,
                this.user.id,
                true,
                !this.sendMail
            );
            this.userService.generateCredentials(credentialGenerationRequest).subscribe(_ => {
                this.notificationService.success('user.reset.password.success');
            });
        } else {
            const newPassword = Md5.hashStr(this.newPassword).toString();
            this.userService.changePassword(this.user.id, newPassword, false).subscribe(_ => {
                this.notificationService.success('user.reset.password.success');
            });
        }
    }

    onModeChange(newMode: 'automatic' | 'manual'): void {
        this.mode = newMode;
        this.sendMail = this.mode === 'automatic';
    }
}
