import {Component, Input} from '@angular/core';

@Component({
    selector: 'm-invoice-articles-total',
    templateUrl: './m-invoice-articles-total.component.html',
    styleUrls: ['./m-invoice-articles-total.component.scss'],
    host: {class: 'grid-y'}
})
export class MInvoiceArticlesTotalComponent {

    @Input()
    set grossTotal(newGross: number) {
        this._grossTotal = newGross ? newGross : 0;
    }

    // tslint:disable-next-line:variable-name
    _grossTotal: number;

    @Input()
    set vatTotal(newVat: number) {
        this._vatTotal = newVat ? newVat : 0;
    }

    // tslint:disable-next-line:variable-name
    _vatTotal: number;

    @Input()
    set netTotal(newNet: number) {
        this._netTotal = newNet ? newNet : 0;
    }

    // tslint:disable-next-line:variable-name
    _netTotal: number;

    @Input()
    set discountTotal(newDiscount: number) {
        this._discountTotal = newDiscount ? newDiscount : 0;
    }

    // tslint:disable-next-line:variable-name
    _discountTotal: number;

    constructor() { }
}
