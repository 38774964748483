<o-block [headerLabel]=" label ? (label | translate) : ('operations.last.operations.title' | translate)">
    <a-dashboard-button-icon button
                             [visibility]="'LOW'"
                             class="cell shrink"
                             [routerLink]="'/boa/operations/list'"
                             [queryParams]="queryParams">
        <i class="fas fa-list"></i>
    </a-dashboard-button-icon>
    <ng-template [ngIf]="operations?.length > 0" [ngIfElse]="empty">
        <div
            [ngClass]="verticalLayout ? 'grid-y grid-margin-y' : 'grid-x grid-margin-y grid-margin-x align-stretch phone-up-2 tablet-landscape-up-3 medium-desktop-up-4'">
            <div class="cell grid-x lastConsumptions-item" *ngFor="let operation of operations">
                <div class="cell auto">
                    <div class="grid-x align-middle movementHeader">
                        <img class="cell shrink giftIcon" *ngIf="operation.gift"
                             src="../../../../../../../assets/images/svg/gift.svg">
                        <span class="cell shrink movement-type">
                            {{'operations.type.' + operation.type | translate}} {{'common.' + operation.title | translate}}
                        </span>
                    </div>
                    <a-field [label]="'operations.last.operations.label' | translate">
                        <i class="fas fa-pencil-alt"></i>
                        <ng-container value>
                            {{operation.label}}
                        </ng-container>
                    </a-field>
                    <a-field [label]="'operations.last.operations.date' | translate">
                        <i class="fas fa-calendar-alt"></i>
                        <ng-container value>
                            {{operation.operationDate | boaDateTime}}
                        </ng-container>
                    </a-field>
                    <a-field [label]="'operations.last.operations.token.amount' | translate">
                        <i class="fas fa-coins"></i>
                        <ng-container value>
                            {{operation.amount}} {{'common.token' | translate}}
                        </ng-container>
                    </a-field>
                    <a-field [label]="'operations.last.operations.source' | translate">
                        <img *ngIf="operation.origin['@type'] === operationDestinationType.ANIMATOR" class="operationIcon"
                             src="assets/images/svg/roles/animators.svg"/>
                        <img *ngIf="operation.origin['@type'] === operationDestinationType.ARKHE" class="operationIcon"
                             src="assets/images/svg/arkhe.svg">
                        <img *ngIf="operation.origin['@type'] === operationDestinationType.CUSTOMER" class="operationIcon"
                             src="assets/images/svg/roles/customers.svg">
                        <ng-container value>
                            <a *ngIf="operation.origin['@type'] === operationDestinationType.ANIMATOR" class="_animator"
                               [routerLink]="[operation.getOriginLink(), operation.origin?.getLinkRef()]">
                                {{operation.origin?.getName()}}
                            </a>
                            <span *ngIf="operation.origin['@type'] === operationDestinationType.ARKHE">
                                {{operation.origin?.getName() | titlecase}}
                            </span>
                            <a *ngIf="operation.origin['@type'] === operationDestinationType.CUSTOMER" class="_customer"
                                [routerLink]="[operation.getOriginLink(), operation.origin?.getLinkRef()]">
                                {{operation.origin?.getName() | titlecase}}
                            </a>
                        </ng-container>
                    </a-field>
                    <a-field [label]="'operations.last.operations.target' | translate"
                             [value]="(operation.amount) + ' ' + ('common.token' | translate)">
                        <img *ngIf="operation.target['@type'] === operationDestinationType.ANIMATOR" class="operationIcon"
                             src="assets/images/svg/roles/animators.svg"/>
                        <img *ngIf="operation.target['@type'] === operationDestinationType.ARKHE" class="operationIcon"
                             src="assets/images/svg/arkhe.svg">
                        <img *ngIf="operation.target['@type'] === operationDestinationType.CUSTOMER" class="operationIcon"
                             src="assets/images/svg/roles/customers.svg">
                        <ng-container value>
                            <a *ngIf="operation.target['@type'] === operationDestinationType.ANIMATOR" class="_animator"
                                [routerLink]="[operation.getTargetLink(), operation.target?.getLinkRef()]">
                                {{operation.target?.getName()}}
                            </a>
                            <span *ngIf="operation.target['@type'] === operationDestinationType.ARKHE">
                                {{operation.target?.getName() | titlecase}}
                            </span>
                            <a *ngIf="operation.target['@type'] === operationDestinationType.CUSTOMER" class="_customer"
                               [routerLink]="[operation.getTargetLink(), operation.target?.getLinkRef()]">
                                {{operation.target?.getName() | titlecase}}
                            </a>
                        </ng-container>
                    </a-field>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #empty>
        <o-empty-list [noResultTitle]="'operations.last.operations.empty' | translate">
            {{'operations.last.operations.empty.message' | translate}}
        </o-empty-list>
    </ng-template>
</o-block>
