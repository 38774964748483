<div class="cell shrink">
    <div class="grid-x grid-margin-x align-middle align-justify">
        <h1 class="cell auto">{{'customers.list.title' | translate}}</h1>
        <a-button-default (click)="downloadCsv()">
            <i class="fas fa-download"></i>
            {{'common.action.export' | translate}}
        </a-button-default>
        <a-button-default class="cell shrink"
                          (click)="navigationService.goToProspectCreationPage()"
                          [visibility]="'HIGH'"
        >
            <i class="fas fa-plus"></i>{{'customer.action.prospect.create' | translate}}
        </a-button-default>
    </div>
</div>

<spacer size="16"></spacer>

<o-filters class="cell shrink" [countsMap]="countsMap">
    <m-search-query
        class="cell phone-12"
        [(ngModel)]="searchQuery"
        [placeholder]="'customers.list.search.placeholder'|translate"></m-search-query>

    <m-type-select-query class="cell shrink" [selectTitle]="'customers.list.customers.type' | translate"
                         [(types)]="status" queryName="status">
    </m-type-select-query>

    <m-type-select-query class="cell shrink" [selectTitle]="'customers.list.type' | translate"
                         [(types)]="customerAccountsTypes" queryName="type">
    </m-type-select-query>
</o-filters>

<spacer size="24"></spacer>

<div class="cell auto grid-y" *ngIf="!wholePageSpinner">
    <o-table [headers]="tableHeaders" [loading]="bottomPageSpinner" (scroll)="loadNext()" [isEmpty]="customerAccounts.length == 0">
        <tr *ngFor="let customerAccount of customerAccounts">
            <td class="tableRoleIcon" m-icon [type]="customerAccount.status"></td>
            <td><a-customer-link [customerAccount]="customerAccount"></a-customer-link></td>
            <td>{{customerAccount.accountingReference}}</td>
            <td>
                <a-account-type [ngClass]="'_' + customerAccount?.status.toLowerCase()" [class._empty]="!customerAccount?.type"
                                [type]="customerAccount?.type"></a-account-type>
            </td>
            <td class="text-right"><span class="_tableNumber">{{customerAccount.globalTokenBalance | number}}</span></td>
            <td><a *ngIf="customerAccount.accountManager" [routerLink]="['/boa','users', customerAccount.accountManager.id]">{{(customerAccount.accountManager?.person?.firstName | boaNotNull) + " " + (customerAccount.accountManager?.person?.lastName | boaNotNull) | titlecase}}</a></td>
        </tr>
        <tr *ngIf="customerAccounts.length === 0"
            [columnNumber]="tableHeaders.length"
            o-empty-table
            [emptyLabel]="'customers.list.table.empty.title' | translate">
            {{'customers.list.table.empty.message' | translate}}
        </tr>
    </o-table>
</div>

<a-boa-big-loader *ngIf="wholePageSpinner"></a-boa-big-loader>
