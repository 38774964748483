import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TableHeader} from '../../../models/table-header';

@Component({
    selector: 'o-table',
    templateUrl: './o-table.component.html',
    styleUrls: ['./o-table.component.scss'],
    host: {class : 'cell auto tableScroll'}
})
export class OTableComponent implements OnInit {
    @Input()
    public headers: Array<TableHeader>;

    @Output()
    private scroll = new EventEmitter<void>();

    @ViewChild('body')
    private tbody: HTMLElement;

    @Input()
    public loading: boolean;

    @Input()
    public isEmpty: boolean;

    constructor() { }

    ngOnInit(): void {
    }

    onScroll(): void {
        this.scroll.emit();
    }

    onSortClicked(tableHeader: TableHeader): void {
        this.headers = this.headers.map(el => {
            el.sortActive = el.value === tableHeader.value;
            return el;
        });
    }
}
