<label [class._required]="required">
    <span class="formLabel" *ngIf="label">{{label}}</span>
    <i *ngIf="info" class="fa fa-info-circle infoIcon"></i>
    <div class="input">
        <textarea #input [class._delete]="delete"
                  [required]="required"
                  [class._disabled]="disabled"
                  [placeholder]="placeholder"
                  (blur)="onBlur()"
                  [ngModel]="data"
                  (ngModelChange)="onChange($event)">
        </textarea>
        <a *ngIf="delete" (click)="onDelete()" class="deleteIcon"><i class="fas fa-times-circle"></i></a>
    </div>
</label>
