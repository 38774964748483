import {Component, Input} from '@angular/core';
import { CustomerAccount } from 'src/app/modules/newboa/customers/models/customer-account';

@Component({
    selector: 'a-customer-link',
    templateUrl: './a-customer-link.component.html',
    styleUrls: ['./a-customer-link.component.scss']
})
export class ACustomerLinkComponent {
    @Input()
    customerAccount: CustomerAccount;

    @Input()
    target: '_blank' | '_self' = '_self';

    @Input()
    class: string;
}
