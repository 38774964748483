import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {CustomerAccount} from '../../models/customer-account';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {CustomerAccountService} from '../../services/customer-account.service';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../users/services/user.service';
import {FullUser} from '../../../users/models/full-user';
import {AnimatorType} from '../../../users/models/user';
import {Animator} from '../../../users/models/animator';
import {ConnectedUserService} from '../../../../app-root/services/connected-user.service';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
    selector: 'boa-customers-detail',
    templateUrl: './customers-detail.component.html',
    styleUrls: ['./customers-detail.component.scss']
})
export class CustomersDetailComponent implements OnInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    customerAccount: CustomerAccount;
    oldCustomerAccountName: string;
    lastAnimatorDemo: FullUser;
    customerInfoEditMode = false;
    showTransferWindow = false;
    connectedUser: FullUser;
    customerAccountManager: FullUser;

    targetUserBuffer: FullUser;
    originUserBuffer: FullUser;
    creationMode = false;

    constructor(private route: ActivatedRoute,
                private toastService: ToastrService,
                private userService: UserService,
                private notificationService: ToastrService,
                private translateService: TranslateService,
                private router: Router,
                private connectedUserService: ConnectedUserService,
                private customerAccountService: CustomerAccountService) {
    }

    loadLastDemoUser(): void {
        this.userService.getUsers(null, null, '', [AnimatorType.DEMONSTRATION], '', this.customerAccount.id)
            .subscribe(
                result => {
                    const animators = result.data
                        .filter(el => el.animatorType === AnimatorType.DEMONSTRATION);
                    animators.sort((a, b) => {
                        if (a.lastLoginDate === null) {
                            return 1;
                        }
                        if (a.lastLoginDate === b.lastLoginDate) {
                            return 0;
                        }
                        if (moment(a.lastLoginDate).isBefore(b.lastLoginDate)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    });
                    this.lastAnimatorDemo = animators[0];
                }
            );
    }

    onEditModeLaunched(): void {
        this.customerInfoEditMode = true;
    }

    onEditModeQuit(): void {
        this.customerInfoEditMode = false;
    }

    ngOnInit(): void {
        this.connectedUserService.getCurrentFullUser().subscribe(fullUser => {
            this.connectedUser = fullUser;
        });
        this.route.data.subscribe( (data: {customerAccount: CustomerAccount}) => {
            if (!data.customerAccount) {
                this.creationMode = true;
                return;
            }
            this.customerAccount = data.customerAccount;
            this.loadLastDemoUser();
            this.oldCustomerAccountName = data.customerAccount.name;
            if (this.customerAccount.accountManager) {
                this.userService.getAccountUserByLogin(this.customerAccount.accountManager.login).subscribe(accountManager => {
                    this.customerAccountManager = accountManager;
                });
            }
        });
    }

    onAnimatorTransferClicked(animator: Animator): void {
        this.targetUserBuffer = animator;
        this.originUserBuffer = this.customerAccountManager;
        this.showTransferWindow = true;
    }

    onTransferCanceled(): void {
        this.showTransferWindow = false;
    }

    onTransferSent(): void {
        this.showTransferWindow = false;
        this.refresh();
    }

    private refresh(): void {
        this.router.navigate([], {
            relativeTo: this.route
        });
    }

    onManagerTransferClicked(): void {
        this.originUserBuffer = this.connectedUser;
        this.targetUserBuffer = this.customerAccountManager;
        this.showTransferWindow = true;
    }

    lockCustomer(locked: boolean): void {
        this.customerAccountService.updateCustomerAccountLock(this.customerAccount.id, locked).subscribe(custAccount => {
                this.notificationService.success(this.translateService.instant('common.success'));
                this.refresh();
            },
            err => {
                this.notificationService.error(this.translateService.instant('common.error'));
            });
    }
}
