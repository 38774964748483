import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {ExtendedQuote, Quote} from '../models/invoices/quote';
import {map, switchMap, tap} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Invoice} from '../models/invoices/invoice';

@Injectable()
export class QuotesService {
    private baseUrl = '/api/invoice/quotes';

    constructor(private http: HttpClient) { }

    getAllQuotes(): Observable<Array<Quote>> {
        return this.http.get(`${this.baseUrl}`).pipe(
            map(el => plainToClass(Quote, el as [object]))
        );
    }

    findQuotes(startDate: Date, endDate: Date): Observable<Array<Quote>> {
        return this.http.get(`${this.baseUrl}`, {
            params: {
                startDate: startDate?.toISOString(),
                endDate: endDate?.toISOString()
            }
        }).pipe(
            map(el => plainToClass(Quote, el as [object]))
        );
    }

    getQuotesById(ids: Array<string>): Observable<Array<Quote>> {
        return this.http.get(`${this.baseUrl}/ids`, {
            params: {
                quoteId: ids
            }
        }).pipe(
            map(quotes => plainToClass(Quote, quotes as [object]))
        );
    }

    createQuoteDraft(quote: Quote): Observable<Quote> {
        return this.http.post(`${this.baseUrl}/drafts`, quote)
            .pipe(map(el => plainToClass(Quote, el)));
    }

    getQuoteDraftById(id: string): Observable<ExtendedQuote> {
        return this.http.get(`${this.baseUrl}/drafts/${id}`)
            .pipe(map(quoteDraft => plainToClass(ExtendedQuote, quoteDraft)));
    }

    getQuoteById(id: string): Observable<ExtendedQuote> {
        return this.http.get(`${this.baseUrl}/${id}`)
            .pipe(map(quote => plainToClass(ExtendedQuote, quote)));
    }

    create(quote: Quote): Observable<Quote> {
        return this.http.post(`${this.baseUrl}`, quote)
            .pipe(map(createdQuote => plainToClass(Quote, createdQuote)));
    }

    acceptFromDraft(quoteDraft: Quote): Observable<Invoice> {
        let createdQuote: Quote;
        return this.createFromDraft(quoteDraft).pipe(
            tap(quote => createdQuote = plainToClass(Quote, quote)),
            switchMap(quote => this.http.put(`${this.baseUrl}/accept/${createdQuote.id}`, quote)),
            map(invoice => plainToClass(Invoice, invoice))
        );
    }

    refuseFromDraft(quoteDraft: Quote): Observable<Quote> {
        return this.createFromDraft(quoteDraft).pipe(
            switchMap(quote => this.http.put(`${this.baseUrl}/refuse/${quote.id}`, quote)),
            map(quote => plainToClass(ExtendedQuote, quote))
        );
    }

    cancelFromDraft(quoteDraft: Quote): Observable<ExtendedQuote> {
        return this.createFromDraft(quoteDraft).pipe(
            switchMap(quote => this.http.put(`${this.baseUrl}/cancel/${quote.id}`, {})),
            map(quote => plainToClass(ExtendedQuote, quote))
        );
    }

    createFromDraft(quoteDraft: Quote): Observable<Quote> {
        return this.http.post<Quote>(`${this.baseUrl}/fromDraft/${quoteDraft.id}`, null)
            .pipe(
                map(quote => plainToClass(Quote, quote))
            );
    }

    getCurrentBillingNo(): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/billingNum`);
    }

    updateQuoteDraft(quoteDraft: Quote): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/drafts`, quoteDraft);
    }
}
