import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'a-boa-big-loader',
    templateUrl: './a-boa-big-loader.component.html',
    styleUrls: ['./a-boa-big-loader.component.scss']
})
export class ABoaBigLoaderComponent implements OnInit {
    constructor() { }

    ngOnInit(): void {
    }
}
