import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UniqueLoginDirective} from './unique-login.directive';
import { BoaNotBlankDirective } from './boa-not-blank.directive';

@NgModule({
    declarations: [
        UniqueLoginDirective,
        BoaNotBlankDirective
    ],
    exports: [
        UniqueLoginDirective,
        BoaNotBlankDirective
    ],
    providers: [
        UniqueLoginDirective
    ],
    imports: [
        CommonModule
    ]
})
export class UserDirectivesModule { }
