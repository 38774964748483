<div ngForm #form="ngForm" class="lineFormContainer">
    <a-input [boaNotBlank]="true"
             [required]="true"
             class="lineFormContainer-item _descriptionColumn"
             [name]="'description'"
             [(ngModel)]="invoiceLineBuffer.description"
             [placeholder]="'Description'"
             [class._articleLine]="!showTokenSessionHeaders"></a-input>
    <div class="lineFormContainer-item _actionsColumn">
        <a-button-icon (click)="addInvoiceLine();" [disabled]="form.invalid || form.pristine"><i class="fas fa-plus"></i></a-button-icon>
    </div>
</div>
