import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomerAccountService} from '../../../services/customer-account.service';
import {BoaNotNullPipe} from '../../../../../shared/pipes/not-null.pipe';

@Component({
    selector: 'a-select-customer',
    templateUrl: './a-select-customer.component.html',
    styleUrls: ['./a-select-customer.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ASelectCustomerComponent),
            multi: true
        }
    ]
})
export class ASelectCustomerComponent implements OnInit, ControlValueAccessor {
    @Input()
    required: boolean;

    @Input()
    label: string;

    customerAccountId: string;
    allCustomersName: { label: string, value: string }[];

    onChange = (custAccountId: string) => {};
    onTouched = (custAccountId: string) => {};

    constructor(private customerService: CustomerAccountService,
                private boaNotNull: BoaNotNullPipe) { }

    ngOnInit(): void {
        this.customerService
            .getAllCustomerAccountsMap('name_map')
            .subscribe(res => {
                this.allCustomersName = res
                    .map(el => {
                        el.label = this.boaNotNull.transform(el.label, 'common.not.provided');
                        return el;
                    });
            });
    }

    onCustomerChange(newCustomerAccount: any): void {
        this.customerAccountId = newCustomerAccount;
        this.onChange(this.customerAccountId);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: string): void {
        this.customerAccountId = obj;
    }
}
