import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors} from '@angular/forms';

@Directive({
    selector: '[boaMax]',
    providers: [{provide: NG_VALIDATORS, useExisting: BoaMaxDirective, multi: true}]
})
export class BoaMaxDirective {

    constructor() { }

    @Input()
    boaMax: number = null;

    validate(control: AbstractControl): ValidationErrors | null {
        if (this.boaMax !== null && parseInt(control.value, 10) > this.boaMax) {
            return {boaMax: true};
        } else {
            return null;
        }
    }

}
