<h2 class="cell shrink">{{'o-quick-access.last-connections' | translate}}</h2>
<h4 class="cell shrink">{{nbConnections ? nbConnections : 0}} {{'user.last.connection.subtitle' | translate}}</h4>

<spacer size="24"></spacer>

<div class="cell auto grid-y">
    <div class="cell auto cell-block-y grid-y">
        <m-user-last-connection-entry class="cell shrink lastConnections-item"
                                      *ngFor="let user of users"
                                      [user]="user">
        </m-user-last-connection-entry>
    </div>
</div>
