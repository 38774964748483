import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Article, ArticleType} from '../../../models/article';
import {InvoiceLine} from '../../../models/invoices/invoice-line';
import {TranslateService} from '@ngx-translate/core';
import {ArticleService} from '../../../services/article.service';
import {InvoiceLineService} from '../../../services/invoice-line.service';
import {ArticleSession} from '../../../models/article-session';
import {Amount} from '../../../models/invoices/amount';
import {CurrencyEnum} from '../../../models/invoices/currency-enum';

@Component({
    selector: 'a-invoice-line-form-creation',
    templateUrl: './a-invoice-line-form-creation.component.html',
    styleUrls: ['./a-invoice-line-form-creation.component.scss']
})
export class AInvoiceLineFormCreationComponent implements OnInit {
    @Input()
    linesFilters: ArticleType[];

    @Input()
    articles: {label: string, data: Article, id: string }[];

    @Input()
    vatRate: number;

    @Input()
    showTokenSessionHeaders: boolean;

    @Output()
    lineAdded = new EventEmitter<InvoiceLine>();

    @Output()
    dirty = new EventEmitter<void>();

    isCredit: boolean;
    invoiceLineBuffer: InvoiceLine;
    sessionsNumBuffer: number;
    simulationsNumBuffer: number;
    teamNumBuffer: number;
    articleType = ArticleType;

    constructor(public readonly invoiceLineService: InvoiceLineService) {
    }

    ngOnInit(): void {
        if (!this.vatRate) {
            this.vatRate = 0;
        }
        this.invoiceLineBuffer = InvoiceLine.empty();
    }

    resetSessionMetadata(): void {
        this.sessionsNumBuffer = null;
        this.teamNumBuffer = null;
        this.simulationsNumBuffer = null;
    }

    addInvoiceLine(): void {
        this.invoiceLineBuffer.kind = this.invoiceLineBuffer.getCorrespondantKind()
            ? this.invoiceLineBuffer.getCorrespondantKind()
            : InvoiceLine.articleToInvoiceKind(this.linesFilters[0]);
        if (this.simulationsNumBuffer) {
            this.invoiceLineBuffer.metadata.sessionArticleMetadata = {
                simulationNumber: this.simulationsNumBuffer,
                teamNumber: this.teamNumBuffer,
                sessionNumber: this.sessionsNumBuffer
            };
        }
        this.lineAdded.emit(this.invoiceLineBuffer);
        this.resetSessionMetadata();
        this.invoiceLineBuffer = InvoiceLine.empty();
    }

    calculateQuantityForBuffer(): void {
        this.invoiceLineBuffer.quantity = this.invoiceLineService.computeSessionTokenQuantity(
            this.invoiceLineBuffer.metadata.article as ArticleSession,
            this.sessionsNumBuffer,
            this.simulationsNumBuffer,
            this.teamNumBuffer
        );
        this.invoiceLineService.calculateGross(this.invoiceLineBuffer, null, null, this.vatRate);
        if (this.invoiceLineBuffer.metadata.article.type === ArticleType.SESSION) {
            this.invoiceLineBuffer.description = this.invoiceLineBuffer.metadata.article.description;
            this.invoiceLineService.interpolateDescription(this.invoiceLineBuffer, String(this.sessionsNumBuffer));
            this.invoiceLineService.interpolateDescription(this.invoiceLineBuffer, String(this.teamNumBuffer));
            this.invoiceLineService.interpolateDescription(this.invoiceLineBuffer, String(this.simulationsNumBuffer));
        }
    }

    onArticleChange(newArticle: Article): void {
        this.invoiceLineBuffer.unitCost = new Amount(newArticle.unitaryCost, CurrencyEnum.EUR);
        this.invoiceLineBuffer.description = newArticle.description;
    }

    /**
     * Can't trust the input type (https://github.com/angular/angular/issues/13243) so consider it a string and parse
     * manually to number
     */
    onQuantityChange(newQuantity: string): void {
        this.invoiceLineBuffer.quantity = parseFloat(newQuantity);
        this.invoiceLineService.calculateGross(this.invoiceLineBuffer, this.invoiceLineBuffer.quantity, null, this.vatRate);
    }
}
