import {TableHeader} from '../models/table-header';

export function setTableHeaderSort(tableHeaders: Array<TableHeader>, sort: string, separator: string = ':'): Array<TableHeader> {
    const value = sort?.split(separator)[0];
    const direction = sort?.split(separator)[1];
    return tableHeaders.map(el => {
        if (el.value === value) {
            el.sortActive = true;
            el.sortDirection = direction as 'ASC' | 'DESC' | null;
        }
        return el;
    });
}
