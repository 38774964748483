import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {ExtendedInvoice, Invoice} from '../models/invoices/invoice';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {plainToClass} from 'class-transformer';
import {catchError, map} from 'rxjs/operators';
import {allLines, BillingDocument, BillingDocumentType} from '../models/invoices/billing-document';
import {ArticleType} from '../models/article';
import {Payment} from '../models/payment';
import {toPrecision} from '../../../shared/functions/to-precision';
import {of} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {Quote} from '../models/invoices/quote';
import {Credit} from '../models/invoices/credit';
import {InvoiceLine} from '../models/invoices/invoice-line';

@Injectable()
export class InvoiceHttpService {

    private baseUrl = '/api/invoice/invoices';

    constructor(private http: HttpClient, private notificationService: ToastrService) {
        // tslint:disable-next-line:typedef
        Date.prototype.toJSON = function() {
            return new Date(this.getTime() - (this.getTimezoneOffset() * 60000)).toISOString();
        };
    }

    getById(id: string): Observable<ExtendedInvoice> {
        return this.http.get(`${this.baseUrl}/${id}`)
            .pipe(map(invoice => plainToClass(ExtendedInvoice, invoice)));
    }

    getAll(): Observable<Array<Invoice>> {
        return this.http.get(`${this.baseUrl}`)
            .pipe(map(invoices => plainToClass(Invoice, invoices as [object])));
    }

    create(invoice: Invoice): Observable<Invoice> {
        return this.http.post(`${this.baseUrl}`, invoice)
            .pipe(map(invoiceRes => plainToClass(Invoice, invoiceRes)));
    }

    createFromDraft(invoiceDraftId: string): Observable<Invoice> {
        return this.http.post(`${this.baseUrl}/fromDraft/${invoiceDraftId}`, null)
            .pipe(map(invoice => plainToClass(Invoice, invoice as object)));
    }

    createInvoiceDraft(invoiceDraft: Invoice): Observable<Invoice> {
        return this.http.post(`${this.baseUrl}/drafts`, invoiceDraft)
            .pipe(
                map(invoiceDraftRes => plainToClass(Invoice, invoiceDraftRes)),
                catchError((error: HttpErrorResponse) => {
                    this.notificationService.error(error.message);
                    return of(null);
                }),
            );
    }

    updateInvoiceDraft(invoiceDraft: Invoice): Observable<Invoice> {
        return this.http.put(`${this.baseUrl}/drafts/`, invoiceDraft)
            .pipe(map(invoiceDraftRes => plainToClass(Invoice, invoiceDraftRes)));
    }

    getInvoiceDraftById(id: string): Observable<ExtendedInvoice> {
        return this.http.get(`${this.baseUrl}/drafts/${id}`)
            .pipe(map(invoiceDraft => plainToClass(ExtendedInvoice, invoiceDraft)));
    }

    getCurrentBillingNo(): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/billingNum/preserve`);
    }

    doPayment(payment: Payment, invoiceId: string): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/pay/${invoiceId}`, payment);
    }

    getPayments(invoiceId: string): Observable<Array<Payment>> {
        return this.http.get<Array<Payment>>(`${this.baseUrl}/payments/${invoiceId}`)
            .pipe(map(invoicePayments => plainToClass(Payment, invoicePayments as object[])));
    }

    instantiateBillingDocument(billingDoc: BillingDocument): BillingDocument {
        if (billingDoc.billingDocumentType === BillingDocumentType.INVOICE) {
            return plainToClass(Invoice, billingDoc);
        } else if (billingDoc.billingDocumentType === BillingDocumentType.QUOTE) {
            return plainToClass(Quote, billingDoc);
        } else if (billingDoc.billingDocumentType === BillingDocumentType.CREDIT) {
            return plainToClass(Credit, billingDoc);
        }
    }

    retrySendingToChorusPro(id: string): Observable<ExtendedInvoice> {
        return this.http.post(`${this.baseUrl}/${id}/retrySendingToChorusPro`, {})
            .pipe(map(invoice => plainToClass(ExtendedInvoice, invoice)));
    }
}

