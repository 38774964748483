import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InvoiceLine} from '../../../models/invoices/invoice-line';
import {Article, ArticleType} from '../../../models/article';
import {Amount} from '../../../models/invoices/amount';
import {CurrencyEnum} from '../../../models/invoices/currency-enum';
import {plainToClass} from 'class-transformer';
import {InvoiceLineService} from '../../../services/invoice-line.service';
import {ArticleSession} from '../../../models/article-session';

@Component({
  selector: 'a-invoice-line',
  templateUrl: './a-invoice-line.component.html',
  styleUrls: ['./a-invoice-line.component.scss']
})
export class AInvoiceLineComponent {

    @Input()
    showTokenSessionFields = false;

    @Input()
    disabled = false;

    @Input()
    invoiceLine: InvoiceLine;

    @Input()
    articles: { label: string, data: Article, id: string }[];

    @Input()
    vatRate: number;

    @Output()
    deletion = new EventEmitter<void>();

    @Output()
    lineEdited = new EventEmitter<InvoiceLine>();

    @Input()
    trustValues = false;

    editMode = false;
    invoiceLineBuffer: InvoiceLine;
    articleType = ArticleType;

    constructor(public invoiceLineService: InvoiceLineService) {}

    validateChanges(): void {
        this.invoiceLine = plainToClass(InvoiceLine, {... this.invoiceLineBuffer});
        this.editMode = false;
        this.lineEdited.emit(this.invoiceLine);
    }

    cancelChanges(): void {
        this.editMode = false;
    }

    calculateQuantityForBuffer(): void {
        if (!this.trustValues) {
            this.invoiceLineBuffer.quantity = this.invoiceLineService.computeSessionTokenQuantity(
                this.invoiceLineBuffer.metadata.article as ArticleSession,
                this.invoiceLineBuffer.metadata.sessionArticleMetadata.sessionNumber,
                this.invoiceLineBuffer.metadata.sessionArticleMetadata.simulationNumber,
                this.invoiceLineBuffer.metadata.sessionArticleMetadata.teamNumber
            );
            this.invoiceLineService.calculateGross(this.invoiceLineBuffer, null, null, this.vatRate);
            if (this.invoiceLineBuffer.metadata.article.type === ArticleType.SESSION) {
                this.invoiceLineBuffer.description = this.invoiceLineBuffer.metadata.article.description;
                this.invoiceLineService.interpolateDescription(this.invoiceLineBuffer,
                    String(this.invoiceLineBuffer.metadata.sessionArticleMetadata.sessionNumber));
                this.invoiceLineService.interpolateDescription(this.invoiceLineBuffer,
                    String(this.invoiceLineBuffer.metadata.sessionArticleMetadata.teamNumber));
                this.invoiceLineService.interpolateDescription(this.invoiceLineBuffer,
                    String(this.invoiceLineBuffer.metadata.sessionArticleMetadata.simulationNumber));
            }
        }
    }

    onChange(newArticleId: string): void {
        const newArticle = this.articles.find(article => article.id === newArticleId).data;
        this.invoiceLineBuffer.metadata.article = newArticle;
        this.invoiceLineBuffer.unitCost = new Amount(newArticle.unitaryCost, CurrencyEnum.EUR);
        this.invoiceLineBuffer.description = newArticle.description;
    }

    onEdit(): void {
        this.invoiceLineBuffer = plainToClass(InvoiceLine, {... this.invoiceLine});
        this.editMode = true;
    }

    delete(): void {
        this.deletion.emit();
    }

    /**
     * Can't trust the input type (https://github.com/angular/angular/issues/13243) so consider it a string and parse
     * manually to number
     */
    onQuantityChange(newQuantity: string): void {
        this.invoiceLineBuffer.quantity = parseFloat(newQuantity);
        this.invoiceLineService.calculateGross(this.invoiceLineBuffer, this.invoiceLineBuffer.quantity, null, this.vatRate);
    }
}
