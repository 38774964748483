<button [class]="'button ' + fromSize(size) + ' ' + fromRole(role)"
        type="submit"
        [class._validate]="validate"
        [class._disabled]="disabled"
        [class._loading]="loading"
        [class.expanded]="expanded"
        [disabled]="disabled">
    <i *ngIf="loading" class="fas fa-spinner fa-spin"></i>
    <ng-content></ng-content>
</button>
