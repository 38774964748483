import {Component, Input, OnInit} from '@angular/core';
import {BillingDocument} from '../../../models/invoices/billing-document';
import {Operation} from '../../../../operations/models/operation';
import {OperationsService} from '../../../../operations/services/operations.service';

@Component({
    selector: 'o-linked-transfers',
    templateUrl: './o-linked-transfers.component.html',
    styleUrls: ['./o-linked-transfers.component.scss']
})
export class OLinkedTransfersComponent implements OnInit {

    @Input()
    billingDocument: BillingDocument;

    operations: Operation[];
    lastCardinality = 5;

    constructor(private operationService: OperationsService) {
    }

    ngOnInit(): void {
        this.operationService.find(
            0,
            this.lastCardinality,
            null,
            null,
            null,
            'OPERATION_DATE:DESC',
            null,
            null,
            null,
            this.billingDocument.id
        ).subscribe(operations => {
            this.operations = operations.data;
        });
    }

}
