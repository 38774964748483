import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import {ArticleService} from '../services/article.service';
import {Article} from '../models/article';
import {Injectable} from '@angular/core';

@Injectable()
export class AllArticlesResolver implements Resolve<Array<Article>> {
    constructor(private articlesService: ArticleService) {
    }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Article>> {
    return this.articlesService.search(route.queryParamMap.get('search'), route.queryParamMap.getAll('types'));
  }
}
