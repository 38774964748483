<label [class._required]="required">
    {{label}}
    <div class="input-group tokenInput">
        <input class="input-group-field tokenInput-field"
               #input [class._delete]="delete"
               [required]="required"
               [name]="name"
               [class._disabled]="disabled"
               [disabled]="disabled"
               [placeholder]="placeholder"
               [type]="inputType"
               (blur)="onBlur()"
               [ngModel]="data"
               (ngModelChange)="onChange($event)"
               [min]="min"
               [max]="max">
        <span class="input-group-button tokenInput-label">
            {{'common.token' | translate}}
        </span>
    </div>
</label>
