import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserListComponent} from './views/users-list/user-list.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {UserDetailComponent} from './views/user-detail/user-detail.component';
import {UserService} from './services/user.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NoNullQueryInterceptor} from '../../shared/interceptors/no-null-query.interceptor';
import {UserComponentsModule} from './components/user-components.module';
import {ConsumptionsModule} from '../consumptions/consumptions.module';
import {OperationsModule} from '../operations/operations.module';

const components = [
    UserListComponent,
    UserDetailComponent
];

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        UserComponentsModule,
        ConsumptionsModule,
        OperationsModule
    ],
    providers: [
        UserService,
        {provide: HTTP_INTERCEPTORS, useClass: NoNullQueryInterceptor, multi: true}
    ],

})
export class UsersModule {
}
