<div class="cell shrink addressMenu">
    <div class="addressMenu-item" [class._active]="contact === billingContact" (click)="switchBilling()">
        <i class="fas fa-file-invoice-dollar"></i>
    </div>
    <div class="addressMenu-item" [class._active]="contact === deliveryContact" (click)="switchDelivery()">
        <i class="fas fa-truck"></i>
    </div>
</div>

<div class="cell auto grid-y grid-margin-y address" [formGroup]="addressForm">
    <ng-container *ngIf="contact.address.type === addressType.BILLING" formGroupName="billing">
        <a-input class="cell shrink"
                 label="{{'customer-detail.address.name' | translate}}"
                 formControlName="name"></a-input>
        <a-input class="cell shrink"
                 label="{{'customer-detail.address.line1' | translate}}"
                 formControlName="line1"></a-input>
        <a-input class="cell shrink"
                 label="{{'customer-detail.address.line2' | translate}}"
                 formControlName="line2"></a-input>
        <div class="cell shrink">
            <div class="grid-x grid-margin-x">
                <a-input class="cell phone-6" [label]="'customer-detail.address.postal.code' | translate"
                         formControlName="postalCode"></a-input>
                <a-input class="cell auto" [label]="'customer-detail.address.city' | translate"
                         formControlName="city"></a-input>
                <a-input class="cell auto" [label]="'customer-detail.address.country' | translate"
                         formControlName="country"></a-input>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="contact.address.type === addressType.DELIVERY" formGroupName="delivery">
        <a-input class="cell shrink"
                 label="{{'customer-detail.address.name' | translate}}"
                 formControlName="name"></a-input>
        <a-input class="cell shrink"
                 label="{{'customer-detail.address.line1' | translate}}"
                 formControlName="line1"></a-input>
        <a-input class="cell shrink"
                 label="{{'customer-detail.address.line2' | translate}}"
                 formControlName="line2"></a-input>
        <div class="cell shrink">
            <div class="grid-x grid-margin-x">
                <a-input class="cell phone-6" [label]="'Code postal'"
                         formControlName="postalCode"></a-input>
                <a-input class="cell auto" [label]="'Ville'"
                         formControlName="city"></a-input>
                <a-input class="cell auto" [label]="'Pays'"
                         formControlName="country"></a-input>
            </div>
        </div>
    </ng-container>
</div>
