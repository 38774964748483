import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Payment, PaymentModeEnum, PaymentStatus, PaymentStatusEnum} from '../../../models/payment';
import {TranslateService} from '@ngx-translate/core';
import {Amount} from '../../../models/invoices/amount';
import {CurrencyEnum} from '../../../models/invoices/currency-enum';
import {ActivatedRoute, Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {Credit} from '../../../models/invoices/credit';
import {NavigationService} from '../../../../common/services/navigation.service';
import {CreditService} from '../../../services/credit.service';
import {forkJoin} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {InvoiceHttpService} from '../../../services/invoice-http.service';

@Component({
    selector: 'o-invoice-info-payment',
    templateUrl: './o-invoice-info-payment.component.html',
    styleUrls: ['./o-invoice-info-payment.component.scss']
})
export class OInvoiceInfoPaymentComponent implements OnInit {

    @Input()
    invoiceId: string;

    @Input()
    netAmount: Amount;

    @Input()
    invoiceCustomerId: string; // needed to do payments

    @ViewChild('form')
    form: NgForm;

    payments: Array<Payment>;
    credits: Array<Credit>;
    genericPayments: Array<Payment | Credit> = [];

    isPaid: boolean;
    paid: number;
    credit: number;
    due: number;
    paymentModes: Array<{ label: string, value: PaymentModeEnum }>;

    // creation of new payment
    paymentDateBuffer: Date;
    paymentAmountBuffer: number;
    paymentModeBuffer: PaymentModeEnum;


    constructor(private translateService: TranslateService,
                private route: ActivatedRoute,
                private router: Router,
                private invoiceService: InvoiceHttpService,
                private creditService: CreditService,
                public navigationService: NavigationService) { }

    ngOnInit(): void {
        this.paymentModes = Object.keys(PaymentModeEnum).map(el => {
            return {
                label: this.translateService.instant('payment.modes.' + el) as string,
                value: PaymentModeEnum[el] as PaymentModeEnum
            };
        });

        this.refreshPaymentsAndCredits().subscribe(([_, __]) => this.setBuffersDefault());
    }

    addPayment(): void {
        this.invoiceService.doPayment(
            new Payment(
                null,
                new Amount(this.paymentAmountBuffer, CurrencyEnum.EUR),
                this.paymentDateBuffer,
                this.paymentModeBuffer,
                this.invoiceCustomerId,
                new PaymentStatus(
                    PaymentStatusEnum.DONE,
                    null
                )
            ),
            this.invoiceId
        )
            .pipe(
                switchMap(_ => this.refreshPaymentsAndCredits()),
                tap(_ => this.setBuffersDefault()),
            )
            .subscribe();
    }

    refreshPaymentsAndCredits(): Observable<[Payment[], Credit[]]> {
        return forkJoin([
            this.invoiceService.getPayments(this.invoiceId),
            this.creditService.getLinkedCredits(this.invoiceId)
        ]).pipe(
            tap(([payments, credits]) => {
                this.payments = payments;
                this.credits = credits;
                this.paid = this.payments.reduce<number>((acc, current) => {
                    return current.status.value === PaymentStatusEnum.DONE
                        ? acc + current.amount.value
                        : acc;
                }, 0);
                this.credit = this.credits.reduce<number>((acc, current) => {
                    return current.netAmount.value + acc;
                }, 0);
                this.genericPayments = [];
                this.genericPayments = this.genericPayments
                    .concat(this.payments)
                    .concat(this.credits)
                    .sort((a, b) => {
                        if (a.date > b.date) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                this.due = parseFloat((this.netAmount.value - this.paid - this.credit).toFixed(2));
                this.isPaid = this.due === 0;
            })
        );
    }

    setBuffersDefault(): void {
        this.paymentDateBuffer = new Date();
        this.paymentModeBuffer = PaymentModeEnum.WIRE_TRANSFER;
        this.paymentAmountBuffer = this.due;
    }

    isCredit(genericPayment: Payment | Credit): boolean {
        return (genericPayment as Payment).amount === undefined;
    }
}
