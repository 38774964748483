import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ASelectYearComponent),
    multi: true
};

@Component({
    selector: 'a-select-year',
    templateUrl: './a-select-year.component.html',
    styleUrls: ['./a-select-year.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class ASelectYearComponent implements OnInit, ControlValueAccessor {

    @Input()
    name: string;
    @Input()
    label: string;

    public year: number;
    public yearsList: Array<number> = [];
    private onNgChange: (year: number) => void;
    onNgTouched: () => void;

    constructor() {
    }

    ngOnInit(): void {
        const currentYear = new Date().getFullYear();
        for (let i = 0; i <= 10; i++) {
            this.yearsList.push(currentYear - i);
        }
        this.writeValue(currentYear);

    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(year: number): void {
        this.year = year;
    }

    onChange(year: number): void {
        this.year = year;
        this.onNgChange(year);
    }

}



