import {NgModule} from '@angular/core';
import {AButtonCancelComponent} from './a-button-cancel/a-button-cancel.component';
import {AButtonDefaultComponent} from './a-button-default/a-button-default.component';
import {AButtonSubmitComponent} from './a-button-submit/a-button-submit.component';
import {AButtonIconComponent} from './a-button-icon/a-button-icon.component';
import {ALinkCancelComponent} from './a-link-cancel/a-link-cancel.component';
import {ALinkDefaultComponent} from './a-link-default/a-link-default.component';
import {ALinkIconComponent} from './a-link-icon/a-link-icon.component';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {AButtonBackComponent} from './a-button-back/a-button-back.component';
import {ASplitButtonComponent} from './a-split-button/a-split-button.component';
import {AButtonSortComponent} from './a-button-sort/a-button-sort.component';
import {AButtonEditComponent} from './a-button-edit/a-button-edit.component';
import {ADashboardButtonIconComponent} from './a-dashboard-button-icon/a-dashboard-button-icon.component';


const components = [
    AButtonCancelComponent,
    AButtonBackComponent,
    AButtonDefaultComponent,
    AButtonSubmitComponent,
    AButtonIconComponent,
    ALinkCancelComponent,
    ALinkDefaultComponent,
    ALinkIconComponent,
    ASplitButtonComponent,
    AButtonSortComponent,
    AButtonEditComponent,
    ADashboardButtonIconComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild()
    ],
    declarations: components,
    exports: components
})

export class ButtonsModule {
}
