import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
    selector: '[boaNotBlank]',
    providers: [{provide: NG_VALIDATORS, useExisting: BoaNotBlankDirective, multi: true}]
})
export class BoaNotBlankDirective implements Validator {

    @Input()
    boaNotBlank = false;

    constructor() { }

    validate(control: AbstractControl): ValidationErrors | null {
        if (control.value?.length > 0 && this.boaNotBlank) {
            if (control.value?.trim().length === 0) {
                return {blank: true};
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

}
