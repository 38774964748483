import {Component, EventEmitter, Input, Output} from '@angular/core';
import {fromRole, fromSize, fromVisibility, Role, Size, Visibility} from '../buttons-utils';

@Component({
    selector: 'a-button-default',
    templateUrl: './a-button-default.component.html',
    styleUrls: [
        './../buttons.scss',
        './a-button-default.component.scss'
    ]
})

export class AButtonDefaultComponent {

    @Input()
    public disabled = false;

    @Input()
    public validate: boolean;

    @Input()
    public visibility: Visibility = 'MEDIUM';

    @Input()
    public size: Size = 'DEFAULT';

    @Input()
    public width: string;

    @Input()
    public role: Role = 'DEFAULT';

    @Input()
    public loading = false;

    @Output()
    public click: EventEmitter<MouseEvent> = new EventEmitter();

    public fromVisibility = fromVisibility;
    public fromSize = fromSize;
    public fromRole = fromRole;

    constructor() {
    }

    public doAction(event: MouseEvent): void {
        event.stopPropagation();
        if (!this.disabled) {
            this.click.emit(event);
        }
    }
}
