import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvoiceLine} from '../../../models/invoices/invoice-line';
import {ArticleType} from '../../../models/article';

@Component({
    selector: 'a-invoice-empty-line-form',
    templateUrl: './a-invoice-empty-line-form.component.html',
    styleUrls: ['./a-invoice-empty-line-form.component.scss']
})
export class AInvoiceEmptyLineFormComponent implements OnInit {

    @Input()
    linesFilters: ArticleType[];

    @Input()
    showTokenSessionHeaders: boolean;

    @Output()
    lineAdded = new EventEmitter<InvoiceLine>();

    invoiceLineBuffer: InvoiceLine;

    constructor() { }

    ngOnInit(): void {
        this.invoiceLineBuffer = InvoiceLine.empty();
    }

    addInvoiceLine(): void {
        this.invoiceLineBuffer.kind = this.invoiceLineBuffer.getCorrespondantKind()
            ? this.invoiceLineBuffer.getCorrespondantKind()
            : InvoiceLine.articleToInvoiceKind(this.linesFilters[0]);
        this.invoiceLineBuffer.metadata.emptyLine = true;
        this.lineAdded.emit(this.invoiceLineBuffer);
        this.invoiceLineBuffer = InvoiceLine.empty();
    }
}
