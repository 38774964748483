import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
@Component({
    selector: 'm-date-picker-query',
    templateUrl: './m-date-picker-query.component.html',
    styleUrls: ['./m-date-picker-query.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MDatePickerQueryComponent),
            multi: true
        }
    ]
})
export class MDatePickerQueryComponent implements OnInit, ControlValueAccessor {
    @Input()
    private queryName: string;

    @Input()
    placeholder: string;

    date: Date;
    onChange = () => {};
    onTouched = () => {};

    constructor(private router: Router, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
    }

    onDateChange(newDate: Date): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
              [this.queryName]: newDate ? newDate.toISOString() : null
            },
            queryParamsHandling: 'merge'
        });
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(newDate: Date): void {
        this.date = newDate;
    }
}
