<o-block [headerLabel]="'user.manager.information.title' | translate" [userType]="role.ACCOUNT_MANAGER">
    <ng-container button>
        <a-dashboard-button-icon class="cell shrink show-for-small hide-for-big-desktop"
                                 *ngIf="manager && showTransferTokens"
                                 [visibility]="'LOW'" [title]="'user.manager.information.action' | translate"
                                 (click)="transferTokens()">
            <i class="fas fa-exchange-alt"></i>
        </a-dashboard-button-icon>

        <a-button-default class="cell shrink hide-for-small show-for-big-desktop"
                          *ngIf="manager && showTransferTokens"
                          (click)="transferTokens()">
            <i class="fas fa-exchange-alt"></i>{{'user.manager.information.action' | translate}}
        </a-button-default>
    </ng-container>

    <div *ngIf="!manager && !creationMode">
        <spacer size="24"></spacer>
        <div class="grid-x align-center align-middle">
            <a-button-default (click)="createAccountManagerForCustomer()" [visibility]="'LOW'" [size]="'LARGE'">
                <i class="fas fa-plus"></i> {{'user.manager.information.action.create' | translate}}
            </a-button-default>
        </div>
        <spacer size="16"></spacer>
    </div>

    <span class="userName"
          *ngIf="manager && !creationMode">{{((manager?.person?.firstName | boaNotNull : 'no-manager' | translate) + ' ' + (manager?.person?.lastName | boaNotNull)) | titlecase}}</span>

    <a-field *ngIf="manager?.login" [label]="'user.detail.username' | translate">
        <i class="fas fa-user"></i>
        <ng-container value>
            <a class="_manager" [routerLink]="['/boa/users/', manager?.id]">{{manager?.login}}</a>
        </ng-container>
    </a-field>
    <a-field *ngIf="manager?.person?.contact.mail" [label]="'user.detail.mail' | translate">
        <i class="fas fa-envelope"></i>
        <ng-container value>
            {{manager?.person?.contact.mail}}
        </ng-container>
    </a-field>
    <a-field *ngIf="manager?.person?.contact.mailOptional" [label]="'user.detail.mail.optional' | translate">
        <i class="far fa-envelope"></i>
        <ng-container value>
            {{manager?.person?.contact.mailOptional}}
        </ng-container>
    </a-field>
    <a-field *ngIf="manager?.person?.contact.mobilePhone" [label]="'user.detail.phone.mobile' | translate">
        <i class="fas fa-phone-alt"></i>
        <ng-container value>
            {{manager?.person?.contact.mobilePhone}}
        </ng-container>
    </a-field>
    <a-field [label]="'user.detail.token.amount' | translate" *ngIf="manager">
        <i class="fas fa-coins"></i>
        <ng-container value>
            {{(customerAccount?.tokenBalance ? customerAccount?.tokenBalance : 0) | number}} {{'common.token' | translate}}
        </ng-container>
    </a-field>

    <ng-template #creationBlock [ngIf]="creationMode">
        <form class="grid-y grid-margin-y" #form='ngForm'>
            <a-input [required]="true" [boaNotBlank]="true" #login="ngModel" [boaUniqueLogin]="true" [(ngModel)]="managerBuffer.login"
                     [name]="'login'" [label]="'user.detail.manager.creation.login' | translate"
                     [helpTextID]="'loginHelpText'"
                     [class._required]="login.errors && login.dirty" class="cell shrink"></a-input>
            <div class="cell shrink">
                <div class="grid-x grid-margin-x">
                    <a-input [required]="true" [boaNotBlank]="true" #firstName="ngModel" [(ngModel)]="managerBuffer.person.firstName"
                             [name]="'firstName'" [label]="'user.detail.manager.creation.firstName' | translate"
                             [helpTextID]="'firstNameHelpText'"
                             [class._required]="firstName.errors && firstName.dirty" class="cell phone-12"></a-input>
                    <a-input #lastName="ngModel" [(ngModel)]="managerBuffer.person.lastName"
                             [required]="true"
                             [boaNotBlank]="true"
                             [name]="'lastName'" [label]="'user.detail.manager.creation.lastName' | translate"
                             [helpTextID]="'lastNameHelpText'"
                             [class._required]="lastName.errors && lastName.dirty" class="cell phone-12"></a-input>
                </div>
            </div>
            <div class="cell shrink">
                <div class="grid-x grid-margin-x">
                    <a-input [boaNotBlank]="true" [(ngModel)]="managerBuffer.person.contact.mail" [name]="'mail'"
                             [label]="'user.detail.manager.creation.mail' | translate"
                             [required]="true"
                             class="cell phone-12"></a-input>
                    <a-input [boaNotBlank]="true"
                             [(ngModel)]="managerBuffer.person.contact.mailOptional"
                             [name]="'mailOpt'" [label]="'user.detail.manager.creation.mail-opt' | translate"
                             class="cell phone-12"></a-input>
                </div>
            </div>
            <div class="cell shrink">
                <div class="grid-x grid-margin-x">
                    <a-input [boaNotBlank]="true" #mobilePhone="ngModel" [(ngModel)]="managerBuffer.person.contact.mobilePhone"
                             [name]="'mobilePhone'" [label]="'user.detail.manager.creation.mobile-phone' | translate"
                             class="cell phone-12"></a-input>
                    <a-input [boaNotBlank]="true" #fixPhone="ngModel" [(ngModel)]="managerBuffer.person.contact.fixPhone"
                             [name]="'fixPhone'" [label]="'user.detail.manager.creation.fix-phone' | translate"
                             class="cell phone-12"></a-input>
                </div>
            </div>

            <spacer size="16"></spacer>

            <div class="cell shrink grid-x align-center grid-margin-x">
                <a-button-cancel class="cell shrink"
                                 (click)="cancelCreation()">{{'common.action.cancel' | translate}}</a-button-cancel>

                <a-button-default class="cell shrink"
                                 [visibility]="'HIGH'"
                                 [loading]="creationLoading"
                                 (click)="submitCreation()" [disabled]="form.pristine || form.invalid">
                    {{'common.validate' | translate}}
                </a-button-default>
            </div>
        </form>
    </ng-template>

</o-block>
