import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {ConsumptionListComponent} from './views/consumption-list/consumption-list.component';
import {OLastConsumptionsComponent} from './components/organisms/o-last-consumptions/o-last-consumptions.component';
import {ConsumptionDashboardComponent} from './views/consumption-dashboard/consumption-dashboard.component';
import {OConsumptionStatsComponent} from './components/organisms/o-consumption-stats/o-consumption-stats.component';
import {OInvoicesQuotesListsComponent} from './components/organisms/o-invoices-quotes-lists/o-invoices-quotes-lists.component';
import {OperationsModule} from '../operations/operations.module';
import {ConsumptionsService} from './service/consumptions.service';
import {AllConsumptionsResolver} from './resolvers/all-consumptions.resolver';
import {StatisticsModule} from '../statistics/statistics.module';
import {AccountingModule} from '../accounting/accounting.module';

const components = [
    ConsumptionDashboardComponent,
    ConsumptionListComponent,
    OLastConsumptionsComponent,
    OConsumptionStatsComponent,
    OInvoicesQuotesListsComponent
];

@NgModule({
    declarations: [components],
    exports: [components],
    imports: [
        AccountingModule,
        CommonModule,
        FormsModule,
        OperationsModule,
        RouterModule,
        SharedModule,
        StatisticsModule,
        TranslateModule.forChild(),
    ],
    providers: [
        AllConsumptionsResolver,
        ConsumptionsService
    ]
})
export class ConsumptionsModule {
}
