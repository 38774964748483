import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'm-user-info-token-balance',
  templateUrl: './m-user-info-token-balance.component.html',
  styleUrls: ['./m-user-info-token-balance.component.scss']
})
export class MUserInfoTokenBalanceComponent implements OnInit {

    @Input()
    tokenBalance: number;

    @Input()
    label: string;

    constructor() { }

    ngOnInit(): void {
    }

}
