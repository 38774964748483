<ng-container *ngIf="customerAccountTokenDeliveryInformation">
    <div class="grid-x align-middle align-justify header">
        <div class="cell auto header-title">
            <span *ngIf="isChargedCustomer" class="header-title-chargedCustomer">€</span>
            <a [routerLink]="['/boa', 'customers', customerAccountTokenDeliveryInformation.id]">
                {{customerAccountTokenDeliveryInformation.name}}
            </a>
        </div>
        <a-dashboard-button-icon class="cell shrink"
                                 [visibility]="'LOW'"
                                 (click)="onSelect()"
                                 [disabled]="!customerAccountTokenDeliveryInformation.accountManager">
            <i class="fas fa-exchange-alt"></i>
        </a-dashboard-button-icon>
    </div>

    <a-field [label]="'common.account.manager' | translate">
        <i class="fas fa-user"></i>
        <ng-container value >
            <a [routerLink]="['/boa', 'users', customerAccountTokenDeliveryInformation.accountManager.id]"
               *ngIf="customerAccountTokenDeliveryInformation.accountManager">
                {{customerAccountTokenDeliveryInformation.accountManager.login}}
            </a>
            <ng-container *ngIf="!customerAccountTokenDeliveryInformation.accountManager">
                {{'common.not.provided' | translate}}
            </ng-container>
        </ng-container>
    </a-field>

    <spacer [size]="4"></spacer>

    <a-field [label]="'invoice.select.customer.account.for.token.delivery.customer.current.token.amount' | translate">
        <i class="fas fa-coins"></i>
        <ng-container value *ngIf="customerAccountTokenDeliveryInformation.accountManager">
            {{customerAccountTokenDeliveryInformation.accountManager.tokenBalance}} {{'common.token' | translate}}
        </ng-container>
    </a-field>
</ng-container>
