import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {CustomerAccountForTokenDelivery} from '../../../../models/invoices/billing-document';

@Component({
  selector: 'a-invoice-token-customer-line',
  templateUrl: './a-invoice-token-customer-line.component.html',
  styleUrls: ['./a-invoice-token-customer-line.component.scss']
})
export class AInvoiceTokenCustomerLineComponent {
    @HostBinding('attr.class') class = 'grid-x align-middle align-justify';

  initialCustomerAccountId: string;

  customerAccountId: string;
  customerAccountName: string;
  customerAccountAccountingReference: string;

  @Input()
  set customerAccountForTokenDelivery(customerAccount: CustomerAccountForTokenDelivery) {
    this.initialCustomerAccountId = customerAccount.id;
    this.customerAccountName = customerAccount.name;
    this.customerAccountAccountingReference = customerAccount.accountingReference;
    // Set editMode to false here to display readonly component correctly after update
    this.editMode = false;
    this.customerAccountId = customerAccount.id;
    this._availableCustomersByName.push({label: this.customerAccountName, value: this.customerAccountId});
    this._availableCustomersByAccountingReference.push({
      label: this.customerAccountAccountingReference,
      value: this.customerAccountId
    });
  }

  // tslint:disable-next-line:variable-name
  private _availableCustomersByName: Array<{label: string, value: string}> = [];

  @Input()
  set availableCustomersByName(availableCustomersByName: Array<{label: string, value: string}>) {
    this._availableCustomersByName = [];
    availableCustomersByName.forEach(customerByName => this._availableCustomersByName.push(customerByName));
    if (this.customerAccountName && this.customerAccountId) {
      this._availableCustomersByName.push({label: this.customerAccountName, value: this.customerAccountId});
    }
  }

  get availableCustomersByName(): Array<{label: string, value: string}> {
    return this._availableCustomersByName;
  }

  // tslint:disable-next-line:variable-name
  private _availableCustomersByAccountingReference: Array<{label: string, value: string}> = [];

  @Input()
  set availableCustomersByAccountingReference(availableCustomersByAccountingReference: Array<{label: string, value: string}>) {
    this._availableCustomersByAccountingReference = [];
    availableCustomersByAccountingReference.forEach(customerByAccountingReference =>
      this._availableCustomersByAccountingReference.push(customerByAccountingReference)
    );
    if (this.customerAccountAccountingReference && this.customerAccountId) {
      this._availableCustomersByAccountingReference.push({label: this.customerAccountAccountingReference, value: this.customerAccountId});
    }
  }

  get availableCustomersByAccountingReference(): Array<{label: string, value: string}> {
    return this._availableCustomersByAccountingReference;
  }

  @Output()
  deleted = new EventEmitter<void>();

  @Output()
  updated = new EventEmitter<string>();

  @Input()
  disabled = false;

  editMode = false;

  onEditModeToggle(): void {
    this.editMode = true;
  }

  delete(): void {
    this.deleted.emit();
  }

  onUpdate(): void {
    this.updated.emit(this.customerAccountId);
  }

  onCancel(): void {
    this.customerAccountId = this.initialCustomerAccountId;
    this.editMode = false;
  }
}
