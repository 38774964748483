import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CustomerAccount} from '../../../models/customer-account';
import {CustomerAccountService} from '../../../services/customer-account.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerAccountTypes} from '../../../models/customer-account-types';
import {CustomerAccountContact} from '../../../../users/models/contact';
import {AddressType} from '../../../../common/models/address/addressType';
import {TranslateService} from '@ngx-translate/core';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
    selector: 'o-customer-account-information',
    templateUrl: './o-customer-account-information.component.html',
    styleUrls: ['./o-customer-account-information.component.scss']
})
export class OCustomerAccountInformationComponent implements OnInit, OnChanges {

    @Input()
    customerAccount: CustomerAccount;

    @Input()
    creationMode = false;

    @Output()
    editModeLaunched = new EventEmitter<void>();

    @Output()
    editModeQuit = new EventEmitter<void>();

    customerAccountBuffer: CustomerAccount;
    billingContactBuffer: CustomerAccountContact;

    billingContact: CustomerAccountContact;
    type: CustomerAccountTypes;
    typePointer = Object.values(CustomerAccountTypes).map(el => {
        return {value: el, label: this.translateService.instant('customers.type.' + el)};
    });
    updateLoading = false;
    creationLoading = false;
    chorusProActivated = false;
    edit: boolean;
    customerAccountsWithSameSiret: CustomerAccount[];

    constructor(private customerAccountService: CustomerAccountService,
                private router: Router,
                private translateService: TranslateService,
                private route: ActivatedRoute,
                private notificationService: ToastrService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerAccount) {
            this.customerAccountBuffer = CustomerAccount.from(this.customerAccount ? this.customerAccount : CustomerAccount.empty());
            this.billingContact = this.customerAccount.getContact(AddressType.BILLING);
            this.billingContactBuffer = this.customerAccountBuffer.getContact(AddressType.BILLING);
            this.chorusProActivated = this.customerAccount.chorusProActivated;
        }
    }

    ngOnInit(): void {
        // creation
        if (!this.customerAccount) {
            this.customerAccount = CustomerAccount.empty();
            this.customerAccountBuffer = CustomerAccount.empty();
            this.billingContact = this.customerAccount.getContact(AddressType.BILLING);
            this.billingContactBuffer = this.customerAccount.getContact(AddressType.BILLING);
        }
    }

    onCancel(): void {
        this.customerAccountBuffer = CustomerAccount.from(this.customerAccount ? this.customerAccount : CustomerAccount.empty());
        this.quitEditMode();
    }

    refresh(): void {
        this.router.navigate([], {
            relativeTo: this.route
        });
    }

    onSave(): void {
        this.quitEditMode();
        this.updateLoading = true;
        this.customerAccountService.updateCustomer(this.customerAccountBuffer).subscribe(_ => {
            this.updateLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.refresh();
        }, (err: HttpErrorResponse) => {
            this.updateLoading = false;
            this.notificationService.error(this.translateService.instant(err.headers.get('x_exception_message')));
        });
    }

    onEditModeLaunched(): void {
        this.editModeLaunched.emit();
        this.edit = true;
    }

    onCreate(): void {
        this.creationLoading = true;
        this.customerAccountService.createCustomer(this.customerAccountBuffer).subscribe(customerAccount => {
            this.creationLoading = false;
            this.notificationService.success(this.translateService.instant('common.success'));
            this.router.navigate(['/boa', 'customers', customerAccount.id]);
        }, err => {
            this.notificationService.error(this.translateService.instant('common.error'));
        });
    }

    private quitEditMode(): void {
        this.edit = false;
        this.editModeQuit.emit();
    }
}
