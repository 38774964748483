import {
    ChangeDetectorRef,
    Component, DoCheck,
    ElementRef,
    EventEmitter, HostBinding,
    HostListener,
    Input,
    Output
} from '@angular/core';
import {TypeList} from '../../../models/type-list';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
    selector: 'm-type-select-query',
    templateUrl: './m-type-select-query.component.html',
    styleUrls: ['./m-type-select-query.component.scss']
})
export class MTypeSelectQueryComponent implements DoCheck {
    public openClass: boolean;

    constructor(private router: Router,
                private route: ActivatedRoute,
                private changeDetector: ChangeDetectorRef,
                private eRef: ElementRef) { }

    @HostBinding('class') get class(): string {
        return this.disabled ? '_disabled' : '';
    }

    @Input()
    public mergeQueryType: 'merge' | 'preserve' = 'merge';

    @Input()
    public disabled = false;

    @Input()
    public selectTitle = 'Types';

    @Input()
    public types: TypeList = new TypeList([]);

    @Output()
    public typesChange = new EventEmitter<TypeList>();

    @Input()
    public queryName = 'types';

    public allSelected = false;

    public typesSelected: Map<string, boolean> = new Map();
    private rawPreviousTypes: Array<string>;

    ngDoCheck(): void {
        // needed because ngOnChanges would not have been fired here,
        // since types is a complex object and a change of types value doesn't mean a change of
        // types object address
        if (this.types.getSelectedValues() !== this.rawPreviousTypes) {
            this.rawPreviousTypes = this.types.getSelectedValues();
            this.updateView(this.types);
        }
    }

    toggleOpen(): void {
        if (!this.disabled) {
            this.openClass = !this.openClass;
        }
    }

    updateView(typeList: TypeList): void {
        typeList.types.forEach((typeEntry) => {
            this.typesSelected[typeEntry.value] = typeEntry.selected;
        });
        this.updateAllSelected(this.types);
    }

    updateQuery(queryParams: Params): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: this.mergeQueryType
        });
    }

    updateAllSelected(typeList: TypeList): void {
        this.allSelected = typeList.isAllSelected();
    }

    onModelChange(): void {
        this.types.types = this.types.types.map(typeEntry => {
            typeEntry.selected = this.typesSelected[typeEntry.value];
            return typeEntry;
        });
        this.updateAllSelected(this.types);

        this.typesChange.emit(this.types);
        this.updateQuery({
            [this.queryName]: this.types.getSelectedValues()
        });
    }

    allChecked(newValue: boolean): void {
        if (newValue === true) {
            this.types.types.forEach(typeEntry => {
                this.typesSelected[typeEntry.value] = true;
            });
        } else if (newValue === false) {
            this.types.types.forEach(typeEntry => {
                this.typesSelected[typeEntry.value] = false;
            });
        }
        this.onModelChange();
    }

    @HostListener('document:click', ['$event'])
    clickout(event): void {
        if (!this.eRef.nativeElement.contains(event.target)) {
            this.openClass = false;
        }
    }
}
