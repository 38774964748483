import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvoiceLine} from '../../../models/invoices/invoice-line';
import {Article, ArticleType} from '../../../models/article';
import {TranslateService} from '@ngx-translate/core';
import {ArticleService} from '../../../services/article.service';
import {max} from 'lodash';
import {DndDropEvent} from 'ngx-drag-drop';
import { LineEdit } from '../../../models/invoices/line-edit';

@Component({
    selector: 'm-invoice-articles-list',
    templateUrl: './m-invoice-articles-list.component.html',
    styleUrls: ['./m-invoice-articles-list.component.scss']
})
export class MInvoiceArticlesListComponent implements OnInit {

    @Input()
    showTokenSessionHeaders = false;

    @Input()
    lines: InvoiceLine[] = [];

    @Input()
    articlesTypes: ArticleType[];

    @Input()
    areLinesReadonly: boolean;

    @Input()
    vatRate: number;

    @Input()
    trustValues = false;

    @Output()
    lineEdited = new EventEmitter<LineEdit>();

    @Output()
    lineAdded = new EventEmitter<InvoiceLine>();

    @Output()
    lineDeleted = new EventEmitter<number>();

    @Output()
    dirty = new EventEmitter<void>();

    invoiceLineBuffer: InvoiceLine;
    articles: {label: string, data: Article, id: string }[];

    constructor(private translateService: TranslateService,
                private articleService: ArticleService) {
    }

    ngOnInit(): void {
        if (!this.vatRate) {
            this.vatRate = 0;
        }
        this.invoiceLineBuffer = InvoiceLine.empty();

        // on affiche que les lignes d'articles dont on a été paramétré pour
        this.articleService.search(null, this.articlesTypes).subscribe(articles => {
            this.articles = articles.map(article => {
                return {label: article.title, data: article, id: article.id};
            }).sort((article1, article2) => article1.label.localeCompare(article2.label));
        });
    }

    addInvoiceLine(lineToAdd: InvoiceLine): void {
        this.lineAdded.emit(lineToAdd);
        this.dirty.emit();
    }

    onInvoiceLineDelete(invoiceLineIndex: number): void {
        this.lineDeleted.emit(invoiceLineIndex);
        this.dirty.emit();
    }

    onLineEdited(index: number, newLine: InvoiceLine): void {
        this.lineEdited.emit(new LineEdit(index, newLine));
        this.dirty.emit();
    }

    onDrop(event: DndDropEvent): void {
        const draggedIndex = this.lines.indexOf(this.lines.find(line => {
            return line.description === event.data.description &&
                line.grossAmount.value === event.data.grossAmount.value &&
                line.unitCost.value === event.data.unitCost.value &&
                line.quantity === event.data.quantity;
        }));
        if (draggedIndex > -1) {
            this.lines.splice(draggedIndex, 1);
            let dropIndex = event.index === undefined ? this.lines.length : event.index;

            /**
             * When dragged index is lower than drop index, it means that drop index counts
             * the currently dragged object so there is a need to subtract one to place it
             * in the correct position
             */
            if (draggedIndex < dropIndex) {
                dropIndex -= 1;
            }

            this.lines.splice(dropIndex, 0, InvoiceLine.copy(event.data));
            this.dirty.emit();
        }
    }

    trackByLineCode(index: number): number {
        return index;
    }

    showTokenSessionFields(line: InvoiceLine): boolean {
        return this.showTokenSessionHeaders && line.metadata.article &&  line.metadata.article.type === ArticleType.SESSION;
    }
}
