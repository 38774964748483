import { Component, OnInit } from '@angular/core';
import {ContextService} from '../../../services/context.service';

@Component({
  selector: 'o-quick-access',
  templateUrl: './o-quick-access.component.html',
  styleUrls: ['./o-quick-access.component.scss']
})
export class OQuickAccessComponent implements OnInit {
    isNotesOpened = false;
    isLastConnectionsOpened = false;
    count = 0;
    constructor(private contextService: ContextService) { }

    ngOnInit(): void {
        this.contextService.unreadNotesCount$.subscribe(count => this.count = count);
    }
}
