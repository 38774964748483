import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'a-invoice-line-toggle',
  templateUrl: './a-invoice-line-toggle.component.html',
  styleUrls: ['./a-invoice-line-toggle.component.scss']
})
export class AInvoiceLineToggleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
