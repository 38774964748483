import {Component, EventEmitter, OnInit, Output} from '@angular/core';

@Component({
  selector: 'a-button-edit',
  templateUrl: './a-button-edit.component.html',
  styleUrls: ['./a-button-edit.component.scss']
})
export class AButtonEditComponent implements OnInit {

    @Output()
    click = new EventEmitter<void>();

    constructor() { }

    ngOnInit(): void {
    }

    onClick(): void {
        this.click.emit();
    }
}
