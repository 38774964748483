import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ConsumptionsService} from '../../../service/consumptions.service';
import {Consumption} from '../../../models/consumption';
import {Params} from '@angular/router';

@Component({
    selector: 'o-last-consumptions',
    templateUrl: './o-last-consumptions.component.html',
    styleUrls: ['./o-last-consumptions.component.scss']
})
export class OLastConsumptionsComponent implements OnInit, OnChanges {
    @Input()
    userIds: Array<string> = [];

    @Input()
    verticalLayout = false;

    @Input()
    customerAccountId: string;

    consumptions: Array<Consumption> = [];

    queryParams: Params;

    constructor(private consumptionService: ConsumptionsService) {
    }

    ngOnChanges(): void {
        this.consumptionService.findLasts(4, this.customerAccountId, this.userIds)
            .subscribe(consumptions => this.consumptions = consumptions.data);
    }

    ngOnInit(): void {
        this.consumptionService.findLasts(4, this.customerAccountId, this.userIds)
            .subscribe(consumptions => this.consumptions = consumptions.data);
        this.queryParams = {sort: 'CONSUMPTION_DATE:DESC', scenarioNames: 'ALL' };
        if (this.customerAccountId) {
            this.queryParams.customerAccountId = this.customerAccountId;
        }
        if (this.userIds && this.userIds.length > 0) {
            this.queryParams.animatorId = this.userIds[0];
        }

    }

}
