export class ChorusProCustomerSettings {
    structureCode: string;
    services: ChorusProService[];
    structureIdMandatory: boolean;
}

export class ChorusProService {
    code: string;
    name: string;
    id: number;
}
