import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'o-invoices-quotes-lists',
    templateUrl: './o-invoices-quotes-lists.component.html',
    styleUrls: ['./o-invoices-quotes-lists.component.scss']
})
export class OInvoicesQuotesListsComponent implements OnInit {

    @Input()
    listTitle;

    constructor() {
    }

    ngOnInit(): void {
    }

}
