<h1 class="cell shrink">{{'consumption.dashboard.title' | translate}}</h1>

<spacer size="24"></spacer>

<div class="cell shrink">
    <div class="grid-x grid-margin-x grid-margin-y">
        <o-consumption-stats class="cell phone-8"></o-consumption-stats>
        <o-last-consumptions [verticalLayout]="true" class="cell phone-8"></o-last-consumptions>
        <o-accounting-lists class="cell phone-8"
                            [verticalLayout]="true"
                            [listTitle]="'accounting.dashboard.invoice.late.title'|translate"
                            [type]="billingDocumentType.INVOICE"
                            [status]="[invoiceStatus.OVERDUE]"
                            [illustrationId]="3"
                            [emptyLabel]="'accounting.dashboard.invoice.late.empty.message'|translate">
        </o-accounting-lists>
    </div>
</div>

<spacer size="16"></spacer>

<o-content-block class="cell shrink">
    <o-arkhe-last-operations></o-arkhe-last-operations>
</o-content-block>

<spacer size="16"></spacer>

<o-content-block class="cell shrink">
    <o-account-managers-last-operations></o-account-managers-last-operations>
</o-content-block>

<spacer size="24"></spacer>
