import { Pipe, PipeTransform } from '@angular/core';
import {sirenFormatter} from '../../../shared/functions/formatter-utils';

@Pipe({
  name: 'boaSirenFormatterPipe'
})
export class SirenFormatterPipePipe implements PipeTransform {

  transform(value: string | number, ...args: unknown[]): unknown {
    return sirenFormatter(value);
  }

}
