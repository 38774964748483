import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MUserInfoTokenBalanceComponent} from './molecules/m-user-info-token-balance/m-user-info-token-balance.component';
import {OCustomerInformationComponent} from './organisms/o-customer-information/o-customer-information.component';
import {OUserManagerInformationComponent} from './organisms/o-user-manager-information/o-user-manager-information.component';
import {OUserPersonalInformationComponent} from './organisms/o-user-personal-information/o-user-personal-information.component';
import {OUserConnectionComponent} from './organisms/o-user-connection/o-user-connection.component';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {SharedModule} from '../../../shared/shared.module';
import {ButtonsModule} from '../../../shared/components/atoms/buttons/buttons.module';
import {OAnimatorsListComponent} from './organisms/o-animators-list/o-animators-list.component';
import {OResetPasswordComponent} from './organisms/o-reset-password/o-reset-password.component';
import {MUserSelectComponent} from './molecules/m-user-select/m-user-select.component';
import {OLastConnectionsComponent} from './organisms/o-last-connections/o-last-connections.component';
import {MUserLastConnectionEntryComponent} from './molecules/m-user-last-connection-entry/m-user-last-connection-entry.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {NoNullQueryInterceptor} from '../../../shared/interceptors/no-null-query.interceptor';
import {UserDirectivesModule} from '../directives/user-directives.module';
import { OPlayerSessionComponent } from './organisms/o-player-session/o-player-session.component';
import { ASelectUserComponent } from './atoms/a-select-user/a-select-user.component';

const components = [
    MUserInfoTokenBalanceComponent,
    MUserLastConnectionEntryComponent,
    MUserSelectComponent,
    OAnimatorsListComponent,
    OCustomerInformationComponent,
    OLastConnectionsComponent,
    OResetPasswordComponent,
    OUserConnectionComponent,
    OUserManagerInformationComponent,
    OUserPersonalInformationComponent,
    OPlayerSessionComponent
];

@NgModule({
    declarations: [components, ASelectUserComponent],
    exports: [components, ASelectUserComponent],
    imports: [
        CommonModule,
        ButtonsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        TranslateModule,
        UserDirectivesModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: NoNullQueryInterceptor, multi: true}
    ]
})
export class UserComponentsModule {
}
