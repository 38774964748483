<o-content-block class="cell auto">
    <div class="articlesAmountLine">
        <span class="articlesAmountLine-label">Total HT</span>
        <span class="articlesAmountLine-value">{{_grossTotal - _discountTotal | currency : 'EUR'}}</span>
    </div>

    <spacer size="4"></spacer>

    <div class="articlesAmountLine _tva">
        <span class="articlesAmountLine-label">TVA</span>
        <span class="articlesAmountLine-value">+ {{_vatTotal | currency : 'EUR'}}</span>
    </div>

    <spacer size="24"></spacer>

    <div class="articlesTotal">
        <span class="articlesTotal-label">Total TTC</span>
        <!-- Invoice automatically add discount in net total, so we do the same in our system -->
        <span class="articlesTotal-value">{{_netTotal | currency : 'EUR'}}</span>
    </div>
</o-content-block>
