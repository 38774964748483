import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {OQuickAccessComponent} from './components/organisms/o-quick-access/o-quick-access.component';
import {UserComponentsModule} from '../users/components/user-components.module';
import {ContextService} from './services/context.service';
import { ONotesComponent } from './components/organisms/o-notes/o-notes.component';
import { MNoteComponent } from './components/molecules/m-note/m-note.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule} from '@angular/forms';
import {CustomersModule} from '../customers/customers.module';
import {AccountingModule} from '../accounting/accounting.module';
import {RouterModule} from '@angular/router';
import { BoaLoadingComponent } from './components/molecules/boa-loading/boa-loading.component';

const components = [
    OQuickAccessComponent
];

@NgModule({
    declarations: [components, ONotesComponent, MNoteComponent, BoaLoadingComponent],
    exports: [components, BoaLoadingComponent],
    imports: [
        CommonModule,
        SharedModule,
        UserComponentsModule,
        TranslateModule,
        FormsModule,
        CustomersModule,
        AccountingModule,
        RouterModule
    ],
    providers: [ContextService]
})
export class NewBoaCommonModule {
}
