import {AddressInvoice, InvoiceAddressWithName} from './address-invoice';
import {Type} from 'class-transformer';
import {ChorusProCustomerSettings} from '../../../customers/models/chorus-pro-settings';

export class Customer {
    @Type(() => InvoiceAddressWithName)
    deliveryAddress: InvoiceAddressWithName;
    @Type(() => InvoiceAddressWithName)
    billingAddress: InvoiceAddressWithName;
    @Type(() => ChorusProCustomerSettings)
    chorusProCustomerSettings: ChorusProCustomerSettings;

    constructor(public id: string,
                public name: string,
                public fullName: string,
                public siren: string,
                public siret: string,
                public chorusProServiceCode: string,
                chorusProSettings: ChorusProCustomerSettings,
                deliveryAddress: InvoiceAddressWithName,
                billingAddress: InvoiceAddressWithName) {
        this.deliveryAddress = deliveryAddress;
        this.billingAddress = billingAddress;
        this.chorusProCustomerSettings = chorusProSettings;
    }

    static empty(): Customer {
        return new Customer(
            '',
            '',
            '',
            '',
            '',
            '',
            null,
            InvoiceAddressWithName.empty(),
            InvoiceAddressWithName.empty()
        );
    }
}
