<!-- normal lines -->
<ng-template [ngIf]="!editMode && !invoiceLine?.metadata.emptyLine">
    <div class="invoiceLine">
        <span dndHandle class="drag-drop-icon invoiceLine-item _dragColumn active"  *ngIf="!disabled">
            <i class="fas fa-grip-horizontal"></i>
        </span>
        <span class="drag-drop-icon invoiceLine-item _dragColumn" *ngIf="disabled">
            <i class="fas fa-grip-horizontal"></i>
        </span>
        <span class="invoiceLine-item _articlesColumn">{{invoiceLine.metadata.article?.title | translate}}</span>
        <span class="invoiceLine-item _sessionsColumn" *ngIf="showTokenSessionFields">
            {{invoiceLine.metadata.sessionArticleMetadata ? invoiceLine.metadata.sessionArticleMetadata.sessionNumber : ''}}
        </span>
        <span class="invoiceLine-item _teamsColumn" *ngIf="showTokenSessionFields">
            {{invoiceLine.metadata.sessionArticleMetadata ? invoiceLine.metadata.sessionArticleMetadata.teamNumber : ''}}
        </span>
        <span class="invoiceLine-item _simulationsColumn" *ngIf="showTokenSessionFields">
            {{invoiceLine.metadata.sessionArticleMetadata ? invoiceLine.metadata.sessionArticleMetadata.simulationNumber : ''}}
        </span>
        <span class="invoiceLine-item _quantityColumn">{{invoiceLine.quantity}}</span>
        <span class="invoiceLine-item _costColumn">{{invoiceLine.unitCost.value}}</span>
        <span class="invoiceLine-item _amountColumn">{{invoiceLine.grossAmount.value | currency : 'EUR'}}</span>
        <div class="invoiceLine-item _actionsColumn" *ngIf="!disabled">
            <a-button-icon (click)="onEdit()"><i class="fas fa-pencil-alt"></i></a-button-icon>
            <a-button-icon [visibility]="'LOW'" (click)="delete()"><i class="fas fa-trash"></i></a-button-icon>
        </div>
    </div>
    <span class="invoiceLine-description">{{invoiceLine.description}}</span>
</ng-template>

<ng-template [ngIf]="editMode && !invoiceLine?.metadata.emptyLine">
    <div class="invoiceLineEdition">
        <div class="invoiceLineEdition-form">
            <div class="invoiceLineEdition-form-item _toggleColumn"></div>
            <a-select class="invoiceLineEdition-form-item _articlesColumn"
                      [ngModel]="invoiceLineBuffer.metadata.article?.id"
                      (ngModelChange)="onChange($event)"
                      [bindValue]="'id'"
                      [bindLabel]="'label'"
                      [itemList]="articles"
                      [placeholder]="'accounting.invoice.article.list.article'|translate"></a-select>
            <ng-template [ngIf]="invoiceLineBuffer.metadata.article?.type === articleType.SESSION"
                         [ngIfElse]="tokenBlock"
                         ngForm #form="ngForm">
                <m-input-tooltip
                    [min]="0"
                    [boaMin]="0"
                    class="invoiceLineEdition-form-item _sessionsColumn"
                    name="sessions-number-tokensessions-edit"
                    (change)="calculateQuantityForBuffer()"
                    [(ngModel)]="invoiceLineBuffer.metadata.sessionArticleMetadata.sessionNumber"
                    [tooltipLabel]="'accounting.invoice.article.list.sessions.number' |translate"
                    [inputType]="'number'"></m-input-tooltip>
                <m-input-tooltip
                    [min]="0"
                    [boaMin]="0"
                    class="invoiceLineEdition-form-item _teamsColumn"
                    name="teams-number-tokensessions-edit"
                    (change)="calculateQuantityForBuffer()"
                    [(ngModel)]="invoiceLineBuffer.metadata.sessionArticleMetadata.teamNumber"
                    [tooltipLabel]="'accounting.invoice.article.list.teams.number'|translate"
                    [inputType]="'number'"></m-input-tooltip>
                <m-input-tooltip
                    [min]="0"
                    [boaMin]="0"
                    class="invoiceLineEdition-form-item _simulationsColumn"
                    name="simulations-number-tokensessions-edit"
                    (change)="calculateQuantityForBuffer()"
                    [(ngModel)]="invoiceLineBuffer.metadata.sessionArticleMetadata.simulationNumber"
                    [tooltipLabel]="'accounting.invoice.article.list.simulations.number'|translate"
                    [inputType]="'number'"></m-input-tooltip>
                <a-input class="invoiceLineEdition-form-item _quantityColumn"
                         [inputType]="'number'"
                         (ngModelChange)="invoiceLineService.calculateGross(invoiceLineBuffer, $event, null, vatRate)"
                         [disabled]="true"
                         [placeholder]="'accounting.invoice.article.list.quantity'|translate"
                         [boaMin]="0"
                         [name]="'quantity-tokensessions-edit'"
                         [(ngModel)]="invoiceLineBuffer.quantity"></a-input>
                <a-input class="invoiceLineEdition-form-item _costColumn"
                         [inputType]="'number'"
                         [boaMin]="0"
                         (ngModelChange)="invoiceLineService.calculateGross(invoiceLineBuffer, null, $event, vatRate)"
                         [name]="'unitary-cost-tokensessions-edit'"
                         [placeholder]="'accounting.invoice.article.list.unit.cost'|translate"
                         [(ngModel)]="invoiceLineBuffer.unitCost.value"></a-input>
                <span class="invoiceLineEdition-form-item _amountColumn">
                    {{invoiceLineBuffer.grossAmount.value | currency : 'EUR'}}
                </span>
                <div class="invoiceLineEdition-form-item _actionsColumn">
                    <a-button-icon [disabled]="form.invalid" (click)="validateChanges()"><i
                        class="fas fa-check"></i></a-button-icon>
                    <a-button-icon [visibility]="'LOW'" (click)="cancelChanges()"><i class="fas fa-times"></i>
                    </a-button-icon>
                </div>
            </ng-template>
            <ng-template #tokenBlock ngForm #form="ngForm">
                <span class="invoiceLineEdition-form-item _sessionsColumn" *ngIf="showTokenSessionFields"></span>
                <span class="invoiceLineEdition-form-item _teamsColumn" *ngIf="showTokenSessionFields"></span>
                <span class="invoiceLineEdition-form-item _simulationsColumn" *ngIf="showTokenSessionFields"></span>
                <a-input class="invoiceLineEdition-form-item _quantityColumn"
                         [min]="0"
                         [boaMin]="0"
                         [inputType]="'number'"
                         [name]="'quantity-edit'"
                         (ngModelChange)="onQuantityChange($event)"
                         [placeholder]="'accounting.invoice.article.list.quantity'|translate"
                         [ngModel]="invoiceLineBuffer.quantity"></a-input>
                <a-input class="invoiceLineEdition-form-item _costColumn"
                         [min]="0"
                         [boaMin]="0"
                         [name]="'unitary-cost-edit'"
                         [inputType]="'number'"
                         (ngModelChange)="invoiceLineService.calculateGross(invoiceLineBuffer, null, $event, vatRate)"
                         [placeholder]="'accounting.invoice.article.list.unit.cost'|translate"
                         [(ngModel)]="invoiceLineBuffer.unitCost.value"></a-input>
                <span class="invoiceLineEdition-form-item _amountColumn">
                    {{invoiceLineBuffer.grossAmount.value | currency : 'EUR'}}
                </span>
                <div class="invoiceLineEdition-form-item _actionsColumn">
                    <a-button-icon [disabled]="form.invalid" (click)="validateChanges()"><i
                        class="fas fa-check"></i>
                    </a-button-icon>
                    <a-button-icon [visibility]="'LOW'" (click)="cancelChanges()"><i class="fas fa-times"></i>
                    </a-button-icon>
                </div>
            </ng-template>
        </div>

        <ng-template [ngIf]="invoiceLineBuffer.metadata.article?.type === articleType.SESSION"
                     [ngIfElse]="tokenBlockDescription">
            <a-input class="descriptionColumn" name="'description-edit'" [(ngModel)]="invoiceLineBuffer.description"
                     [placeholder]="'accounting.invoice.article.list.description'|translate"></a-input>
        </ng-template>

        <ng-template #tokenBlockDescription>
            <a-input class="descriptionColumn"
                     [boaMin]="0"
                     name="description"
                     [(ngModel)]="invoiceLineBuffer.description"
                     [placeholder]="'accounting.invoice.article.list.description'|translate"></a-input>
        </ng-template>
    </div>
</ng-template>

<!-- empty lines -->
<ng-template [ngIf]="!editMode && invoiceLine?.metadata.emptyLine">
    <div class="invoiceLine">
        <span dndHandle class="drag-drop-icon invoiceLine-item _dragColumn active"  *ngIf="!disabled">
            <i class="fas fa-grip-horizontal"></i>
        </span>
      <span class="drag-drop-icon invoiceLine-item _dragColumn" *ngIf="disabled">
            <i class="fas fa-grip-horizontal"></i>
        </span>
        <span class="invoiceLine-item _onlyDescriptionColumn">{{invoiceLine.description}}</span>
        <div class="invoiceLine-item _actionsColumn" *ngIf="!disabled">
            <a-button-icon (click)="onEdit()">
                <i class="fas fa-pencil-alt"></i>
            </a-button-icon>
            <a-button-icon [visibility]="'LOW'" (click)="delete()">
                <i class="fas fa-trash"></i>
            </a-button-icon>
        </div>
    </div>
</ng-template>

<ng-template [ngIf]="editMode && invoiceLine?.metadata.emptyLine">
    <div class="invoiceLine">
        <span class="invoiceLine-item _toggleColumn"></span>
        <a-input class="invoiceLine-item _onlyDescriptionColumn" [boaNotBlank]="true" [required]="true"
                 name="description" [(ngModel)]="invoiceLineBuffer.description" [placeholder]="'accounting.invoice.article.list.description'|translate">
        </a-input>
        <div class="invoiceLine-item _actionsColumn">
            <a-button-icon (click)="validateChanges()">
                <i class="fas fa-check"></i>
            </a-button-icon>
            <a-button-icon [visibility]="'LOW'" (click)="cancelChanges()">
                <i class="fas fa-times"></i>
            </a-button-icon>
        </div>
    </div>
</ng-template>
