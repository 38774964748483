import {CurrencyEnum} from './currency-enum';
import {InvoiceLine} from './invoice-line';
import {Vendor} from './vendor';
import {Customer} from './customer';
import {plainToClass, Type} from 'class-transformer';
import {
    BillingDocument,
    BillingDocumentAdditionalInformation,
    BillingDocumentMetadata,
    BillingDocumentType, buildFileNameSuffix,
    ExtendedBillingDocument
} from './billing-document';
import {Invoice, InvoiceStatus} from './invoice';
import {Amount} from './amount';
import {QuoteAcceptedStatus} from './quote-accepted-status';
import {CustomerAccount} from '../../../customers/models/customer-account';
import {ChorusProCustomerSettings} from '../../../customers/models/chorus-pro-settings';

export class Quote implements BillingDocument {
    @Type(() => Date)
    validityDate: Date;
    quoteStatus: QuoteStatus;
    acceptedStatus: QuoteAcceptedStatus;

    id: string;
    reference: string;
    @Type(() => Date)
    date: Date;
    @Type(() => Customer)
    customer: Customer;
    @Type(() => Vendor)
    vendor: Vendor;
    subject: string;
    @Type(() => InvoiceLine)
    tokensArticlesLines: InvoiceLine[];
    @Type(() => InvoiceLine)
    articlesLines: InvoiceLine[];
    @Type(() => Amount)
    vatsAmount: Amount[];
    @Type(() => Amount)
    grossAmount: Amount;
    @Type(() => Amount)
    netAmount: Amount;
    @Type(() => Amount)
    vatAmount: Amount;
    @Type(() => Amount)
    discountAmount: Amount;
    @Type(() => BillingDocumentMetadata)
    metadata: BillingDocumentMetadata;
    detail: string;
    legalNotice: string;
    withVAT: boolean;
    currency: CurrencyEnum;
    showLegalNoticeForeignCustomer: boolean;
    billingDocumentType: BillingDocumentType;
    grossAmountAfterDiscount: Amount;
    trustValues: boolean;
    billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation;
    chorusProSendFailError: string;

    constructor(id: string,
                date: Date,
                validityDate: Date,
                subject: string,
                currency: CurrencyEnum,
                withVat: boolean,
                tokensArticlesLines: Array<InvoiceLine>,
                articlesLines: Array<InvoiceLine>,
                vendor: Vendor,
                customer: Customer,
                detail: string,
                legalNotice: string,
                showLegalNoticeForeignCustomer: boolean,
                metadata: BillingDocumentMetadata,
                vatsAmount: Array<Amount>,
                quoteStatus: QuoteStatus,
                quoteAcceptedStatus: QuoteAcceptedStatus,
                grossAmount: Amount,
                netAmount: Amount,
                discountAmount: Amount,
                vatAmount: Amount,
                grossAmountAfterDiscount: Amount,
                trustValues: boolean,
                billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation) {
        this.grossAmount = grossAmount;
        this.netAmount = netAmount;
        this.discountAmount = discountAmount;
        this.id = id;
        this.date = date;
        this.validityDate = validityDate;
        this.subject = subject;
        this.currency = currency;
        this.withVAT = withVat;
        this.tokensArticlesLines = tokensArticlesLines;
        this.articlesLines = articlesLines;
        this.vendor = vendor;
        this.customer = customer;
        this.detail = detail;
        this.legalNotice = legalNotice;
        this.showLegalNoticeForeignCustomer = showLegalNoticeForeignCustomer;
        this.metadata = metadata;
        this.quoteStatus = quoteStatus;
        this.vatsAmount = vatsAmount;
        this.acceptedStatus = quoteAcceptedStatus;
        this.vatAmount = vatAmount;
        this.grossAmountAfterDiscount = grossAmountAfterDiscount;
        this.trustValues = trustValues;
        this.billingDocumentAdditionalInformation = billingDocumentAdditionalInformation;
        this.billingDocumentType = BillingDocumentType.QUOTE;
    }

    static empty(): Quote {
        return new Quote(
            undefined,
            new Date(),
            new Date(),
            '',
            CurrencyEnum.EUR,
            true,
            [],
            [],
            Vendor.empty(),
            Customer.empty(),
            '',
            '',
            false,
            BillingDocumentMetadata.empty(),
            [],
            undefined,
            undefined,
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            false,
            BillingDocumentAdditionalInformation.empty()
        );
    }

    getStatus(): QuoteStatus | InvoiceStatus {
        return this.quoteStatus;
    }

    isInProgress(): boolean {
        return (this.quoteStatus === QuoteStatus.IN_PROGRESS) || (this.quoteStatus === undefined);
    }

    isStrictlyInProgress(): boolean {
        return (this.quoteStatus === QuoteStatus.IN_PROGRESS);
    }

    getPdfLink(): string {
        return this.isDraft()
            ? `/api/invoice/quotes/drafts/print/${this.id}`
            : `/api/invoice/quotes/print/${this.id}`;
    }

    isDraft(): boolean {
        return this.quoteStatus === QuoteStatus.IN_PROGRESS;
    }

    getFileName(): string {
        return `Devis ${buildFileNameSuffix(this)}`;
    }

    getType(): BillingDocumentType {
        return BillingDocumentType.QUOTE;
    }

    getReference(): string {
        return this.reference;
    }

    getDetailLink(): string {
        return this.isDraft() ? `quotes/drafts/${this.id}`: `quotes/${this.id}`;
    }

    duplicate(): Quote {
        const copy = plainToClass(Quote, {... this});
        copy.quoteStatus = undefined;
        copy.id = undefined;
        copy.acceptedStatus = undefined;
        copy.trustValues = false;
        copy.date = new Date();
        return copy;
    }
    isChorusProServiceMandatory(chorusProSettings: ChorusProCustomerSettings): boolean {
        return false;
    }
}

export class ExtendedQuote extends Quote implements ExtendedBillingDocument {
    deliveredTokens: number;
    toDeliverTokens: number;
    isDelivered: boolean;

    constructor(id: string,
                date: Date,
                validityDate: Date,
                subject: string,
                currency: CurrencyEnum,
                withVat: boolean,
                tokensArticlesLines: Array<InvoiceLine>,
                articlesLines: Array<InvoiceLine>,
                vendor: Vendor,
                customer: Customer,
                detail: string,
                legalNotice: string,
                showLegalNoticeForeignCustomer: boolean,
                metadata: BillingDocumentMetadata,
                vatsAmount: Array<Amount>,
                quoteStatus: QuoteStatus,
                quoteAcceptedStatus: QuoteAcceptedStatus,
                grossAmount: Amount,
                netAmount: Amount,
                discountAmount: Amount,
                vatAmount: Amount,
                deliveredTokens: number,
                toDeliverTokens: number,
                isDelivered: boolean,
                grossAmountAfterDiscount: Amount,
                trustValues: boolean,
                billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation) {
        super(id,
            date,
            validityDate,
            subject,
            currency,
            withVat,
            tokensArticlesLines,
            articlesLines,
            vendor,
            customer,
            detail,
            legalNotice,
            showLegalNoticeForeignCustomer,
            metadata,
            vatsAmount,
            quoteStatus,
            quoteAcceptedStatus,
            grossAmount,
            netAmount,
            discountAmount,
            vatAmount,
            grossAmountAfterDiscount,
            trustValues,
            billingDocumentAdditionalInformation);
        this.deliveredTokens = deliveredTokens;
        this.toDeliverTokens = toDeliverTokens;
        this.isDelivered = isDelivered;
    }

    static empty(): ExtendedQuote {
        return new ExtendedQuote(
            undefined,
            new Date(),
            new Date(),
            '',
            CurrencyEnum.EUR,
            false,
            [],
            [],
            Vendor.empty(),
            Customer.empty(),
            '',
            '',
            false,
            BillingDocumentMetadata.empty(),
            [],
            undefined,
            undefined,
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            0,
            0,
            false,
            Amount.empty(),
            false,
            BillingDocumentAdditionalInformation.empty()
        );
    }

}

export enum QuoteStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    CANCELED = 'CANCELED',
    DENIED = 'DENIED',
    VALIDATED = 'VALIDATED',
    DELETED = 'DELETED',
}
