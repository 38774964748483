<div ngForm #form="ngForm">
    <div class="lineFormContainer">
        <a-select [(ngModel)]="invoiceLineBuffer.metadata.article"
                  [name]="'article-select'"
                  (ngModelChange)="onArticleChange($event)"
                  [dropdown]="'_fitDropdown'"
                  [searchable]="true"
                  [bindValue]="'data'"
                  [bindLabel]="'label'"
                  [itemList]="articles"
                  [placeholder]="'Article'"
                  [required]="true"
                  class="lineFormContainer-item _articlesColumn">
        </a-select>

        <ng-template [ngIf]="invoiceLineBuffer.metadata.article?.type === articleType.SESSION" [ngIfElse]="tokenBlock">
            <m-input-tooltip [min]="0"
                             class="lineFormContainer-item _sessionsColumn"
                             name="sessions-number-sessiontokens"
                             (change)="calculateQuantityForBuffer()"
                             [ngModel]="sessionsNumBuffer"
                             (ngModelChange)="sessionsNumBuffer = +$event"
                             [tooltipLabel]="'Nombre de session'" [inputType]="'number'"></m-input-tooltip>

            <m-input-tooltip [min]="0" class="lineFormContainer-item _teamsColumn" name="teams-number-sessiontokens"
                             (change)="calculateQuantityForBuffer()"
                             [ngModel]="teamNumBuffer"
                             (ngModelChange)="teamNumBuffer = +$event"
                             [tooltipLabel]="'Nombre d\'équipe'"
                             [inputType]="'number'"></m-input-tooltip>

            <m-input-tooltip [min]="0"
                             class="lineFormContainer-item _simulationsColumn"
                             name="simulations-number-sessiontokens"
                             (change)="calculateQuantityForBuffer()"
                             [ngModel]="simulationsNumBuffer"
                             (ngModelChange)="simulationsNumBuffer = +$event"
                             [tooltipLabel]="'Nombre de simulations'"
                             [inputType]="'number'"></m-input-tooltip>

            <a-input [inputType]="'number'"
                     (ngModelChange)="invoiceLineService.calculateGross(invoiceLineBuffer, $event, null, vatRate)"
                     [disabled]="true"
                     [placeholder]="'Quantité'"
                     [min]="0"
                     [name]="'quantity-sessiontokens'"
                     [ngModel]="invoiceLineBuffer.quantity"
                     class="lineFormContainer-item _quantityColumn">
            </a-input>


            <a-input [inputType]="'number'"
                     (ngModelChange)="invoiceLineService.calculateGross(invoiceLineBuffer, null, $event, vatRate)"
                     [min]="0"
                     [placeholder]="'Coût unitaire'"
                     [name]="'unitary-cost-sessiontokens'"
                     [ngModel]="invoiceLineBuffer.unitCost.value"
                     class="lineFormContainer-item _costColumn">
            </a-input>

            <span class="lineFormContainer-item _amountColumn">
                {{invoiceLineBuffer.grossAmount.value | currency : 'EUR'}}
            </span>
    </ng-template>

    <ng-template #tokenBlock>
        <div class="lineFormContainer-item _sessionsColumn" *ngIf="showTokenSessionHeaders"></div>
        <div class="lineFormContainer-item _teamsColumn" *ngIf="showTokenSessionHeaders"></div>
        <div class="lineFormContainer-item _simulationsColumn" *ngIf="showTokenSessionHeaders"></div>

        <a-input [alignment]="'text-right'"
                 [min]="0"
                 [inputType]="'number'"
                 [name]="'quantity'"
                 (ngModelChange)="onQuantityChange($event)"
                 [ngModel]="invoiceLineBuffer.quantity"
                 [placeholder]="'Quantité'"
                 class="lineFormContainer-item _quantityColumn">
        </a-input>

        <a-input [alignment]="'text-right'"
                 [min]="0"
                 [inputType]="'number'"
                 [name]="'unitary-cost'"
                 (ngModelChange)="invoiceLineService.calculateGross(invoiceLineBuffer,null, $event, vatRate)"
                 [ngModel]="invoiceLineBuffer.unitCost.value"
                 [placeholder]="'Coût unitaire'"
                 class="lineFormContainer-item _costColumn">
        </a-input>

        <span class="lineFormContainer-item _amountColumn">{{invoiceLineBuffer.grossAmount.value | currency : 'EUR'}}</span>
    </ng-template>

        <div class="lineFormContainer-item _actionsColumn">
            <a-button-icon (click)="addInvoiceLine();" [disabled]="form.invalid || form.pristine"><i
                class="fas fa-plus"></i></a-button-icon>
        </div>
    </div>

    <ng-template [ngIf]="invoiceLineBuffer.metadata.article?.type === articleType.SESSION"
                 [ngIfElse]="tokenBlockDescription">
        <a-input [boaNotBlank]="true"
                 class="descriptionColumn"
                 name="description-session-sessiontokens"
                 [(ngModel)]="invoiceLineBuffer.description"
                 [placeholder]="'Description'"></a-input>
    </ng-template>

    <ng-template #tokenBlockDescription>
        <a-input [boaNotBlank]="true"
                 class="descriptionColumn"
                 [name]="'description'"
                 [(ngModel)]="invoiceLineBuffer.description"
                 [placeholder]="'Description'">
        </a-input>
    </ng-template>
</div>
