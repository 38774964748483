import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {Operation} from '../models/operation';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Result} from '../../common/models/shared/result';
import {Router} from '@angular/router';

@Injectable()
export class OperationsService {

    private baseUrl = '/api/operations';
    private radix = 10;

    constructor(private http: HttpClient, private router: Router) {
    }

    getCsvLink(): string {
        return this.router.createUrlTree([this.baseUrl, 'csv'], {}).toString();
    }

    getCustomerLasts(limit: number, id: string): Observable<Array<Operation>> {
        return this.http.get<Array<Operation>>(`${this.baseUrl}/lasts`, {
            params: {
                limit,
                id,
                type: 'CUSTOMER'
            }
        }).pipe(
            map(result => plainToClass(Operation, result))
        );
    }

    getArkheLasts(limit: number): Observable<Array<Operation>> {
        return this.http.get<Array<Operation>>(`${this.baseUrl}/lasts`, {
            params: {
                limit,
                type: 'ARKHE'
            }
        }).pipe(
            map(result => plainToClass(Operation, result))
        );
    }

    getUserLasts(limit: number, id: string, type: 'ANIMATOR' | 'CUSTOMER'): Observable<Array<Operation>> {
        return this.http.get<Array<Operation>>(`${this.baseUrl}/lasts`, {
            params: {
                limit,
                id,
                type
            }
        }).pipe(
            map(result => plainToClass(Operation, result))
        );
    }

    getAnimatorsLasts(limit: number): Observable<Array<Operation>>  {
        return this.http.get<Array<Operation>>(`${this.baseUrl}/lasts`, {
            params: {
                limit,
                type: 'ANIMATOR'
            }
        }).pipe(
            map(result => plainToClass(Operation, result))
        );
    }

    find(page: number = 0,
         limit: number = 50,
         gift: boolean,
         searchQuery: string = null,
         operationQueryFilter: Array<string> = null,
         sort: string = null,
         fromDate: string = null,
         toDate: string = null,
         accountId: string = null,
         linkedDocumentId: string = null): Observable<Result<Operation>> {

        const params = this.buildHttpParams(page,
            limit,
            gift,
            searchQuery,
            sort,
            operationQueryFilter,
            fromDate,
            toDate,
            accountId,
            linkedDocumentId);

        return this.http.get<Array<Operation>>(`${this.baseUrl}`, {
            params,
            observe: 'response',
        }).pipe(
            map(operations => {
                return new Result<Operation>(
                    plainToClass(Operation, operations.body),
                    parseInt(operations.headers.get('TOTAL_COUNT'), this.radix),
                    new Map([['amount', parseInt(operations.headers.get('X_AGGREGATE_AMOUNT'), 10)]])
                );
            })
        );
    }

    public buildHttpParams(page: number,
                           limit: number,
                           gift: boolean,
                           searchQuery: string,
                           sort: string,
                           operationQueryFilter: Array<string>,
                           fromDate: string,
                           toDate: string,
                           accountId: string,
                           linkedDocumentId: string): HttpParams {
        let params = new HttpParams();
        params = params.append('page', page);
        params = params.append('limit', limit);
        params = params.append('gift', gift);
        params = params.append('search', searchQuery);
        if (sort) {
            params = params.append('sort', sort);
        }
        if (operationQueryFilter) {
            operationQueryFilter.forEach(operationType => params = params.append('operationType', operationType));
        }
        if (fromDate) {
            params = params.append('fromDate', fromDate);
        }
        if (toDate) {
            params = params.append('toDate', toDate);
        }
        if (accountId) {
            params = params.append('sourceId', accountId);
        }
        if (linkedDocumentId) {
            params = params.append('linkedDocumentId', linkedDocumentId);
        }

        return params.append('aggregate', 'amount');
    }

    create(operation: Operation): Observable<void> {
        return this.http
            .post<void>(`${this.baseUrl}`, operation);
    }
}
