import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CustomerAccount} from '../../../../../customers/models/customer-account';
import {CustomerAccountForTokenDelivery} from '../../../../models/invoices/billing-document';
import {UserService} from '../../../../../users/services/user.service';
import {CustomerAccountTokenDeliveryInformation} from './customer-account-token-delivery-information';

@Component({
  selector: 'o-invoice-select-customer-account-for-token-delivery',
  templateUrl: './o-invoice-select-customer-account-for-token-delivery.component.html',
  styleUrls: ['./o-invoice-select-customer-account-for-token-delivery.component.scss']
})
export class OInvoiceSelectCustomerAccountForTokenDeliveryComponent {
  public chargedCustomerAccountTokenDeliveryInformation: CustomerAccountTokenDeliveryInformation;
  public additionalCustomerAccountsTokenDeliveryInformation: CustomerAccountTokenDeliveryInformation[];

  @Output()
  customerAccountSelectedForTokenDelivery = new EventEmitter<CustomerAccountTokenDeliveryInformation>();

  @Output()
  operationCanceled = new EventEmitter<void>();

  constructor(private readonly userService: UserService) {
  }

  @Input()
  set chargedCustomerAccount(chargedCustomerAccount: CustomerAccount) {
    if (chargedCustomerAccount && chargedCustomerAccount.id) {
      this.userService.findCustomerAccountManager(chargedCustomerAccount.id).subscribe(accountManager => {
        this.chargedCustomerAccountTokenDeliveryInformation = CustomerAccountTokenDeliveryInformation.from(
          chargedCustomerAccount,
          accountManager
        );
      });
    }
  }

  @Input()
  set additionalCustomerAccountsForTokenDelivery(
    additionalCustomerAccountsForTokenDelivery: CustomerAccountForTokenDelivery[]
  ) {
    if (additionalCustomerAccountsForTokenDelivery) {
        this.additionalCustomerAccountsTokenDeliveryInformation = new Array(additionalCustomerAccountsForTokenDelivery.length);
        additionalCustomerAccountsForTokenDelivery.forEach((customerAccountForTokenDelivery, index) =>
            this.userService.findCustomerAccountManager(customerAccountForTokenDelivery.id).subscribe(accountManager => {
                this.additionalCustomerAccountsTokenDeliveryInformation[index] = CustomerAccountTokenDeliveryInformation.from(
                    customerAccountForTokenDelivery,
                    accountManager
                );
            }, _ => {
                this.additionalCustomerAccountsTokenDeliveryInformation[index] = CustomerAccountTokenDeliveryInformation.from(
                    customerAccountForTokenDelivery,
                    null
                );
            }));
    }
  }

  onSelect(customerAccountTokenDeliveryInformation: CustomerAccountTokenDeliveryInformation): void {
    this.customerAccountSelectedForTokenDelivery.emit(customerAccountTokenDeliveryInformation);
  }

  onCancel(): void {
    this.operationCanceled.emit();
  }
}
