import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CustomerAccount} from '../models/customer-account';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Observable} from 'rxjs/internal/Observable';
import {Result} from '../../common/models/shared/result';
import {CustomerStatus} from '../models/customer-status';
import {CustomerAccountInvoiceInformation} from '../models/customer-account-invoice-information';

@Injectable()
export class CustomerAccountService {

    private baseUrl = '/api/customerAccounts';
    private radix = 10;

    constructor(private http: HttpClient) {
    }

    getAllCustomerAccountsMap(responseType: string): Observable<Array<any>> {
        return this.http.get<Array<{ label: string, value: string }>>(this.baseUrl, {
            params: {
                status: [CustomerStatus.ACTIVE, CustomerStatus.PROSPECT],
                responseType
            }
        });
    }

    public getAllCustomerAccounts(): Observable<Array<CustomerAccount>> {
        return this.http.get<Array<CustomerAccount>>(this.baseUrl, {
            params: {
                status: [CustomerStatus.ACTIVE, CustomerStatus.PROSPECT]
            }
        }).pipe(
            map(custAccounts => plainToClass(CustomerAccount, custAccounts))
        );
    }

    public getCSVLink(): string {
        return `${this.baseUrl}/csv`;
    }

    public getCurrentCustomerAccountNumber(): Observable<string> {
        return this.http.get<string>(`${this.baseUrl}/currentNumber`);
    }

    public getPaginatedCustomerAccounts(page: number,
                                        limit: number,
                                        searchQuery: string = '',
                                        statusQuery: Array<string> = [],
                                        typeQuery: Array<string> = [],
                                        sort: string = '')
        : Observable<Result<CustomerAccount>> {
        if (statusQuery.includes('ALL')) {
            statusQuery = Object.keys(CustomerStatus);
        }

        if (typeQuery.includes('ALL')) {
            typeQuery = [];
        }

        return this.http.get<Array<CustomerAccount>>(this.baseUrl, {
            params: {
                page,
                limit,
                sort,
                search: searchQuery,
                status: statusQuery,
                type: typeQuery,
                aggregate: 'globalTokenBalance'
            },
            observe: 'response'
        }).pipe(
            map(custAccounts => new Result(
                plainToClass(CustomerAccount, custAccounts.body),
                parseInt(custAccounts.headers.get('X_TOTAL_COUNT'), this.radix),
                new Map([ ['globalTokenBalance', parseInt(custAccounts.headers.get('X_AGGREGATE_GLOBALTOKENBALANCE'), 10)] ])
            ))
        );
    }

    public getCustomerAccountById(id: string): Observable<CustomerAccount> {
        return this.http.get(`${this.baseUrl}/${id}`)
            .pipe(map(result => plainToClass(CustomerAccount, result)));
    }

    public updateCustomer(customerAccount: CustomerAccount): Observable<CustomerAccount> {
        return this.http.put(`${this.baseUrl}/${customerAccount.id}`, customerAccount)
            .pipe(
                map(result => plainToClass(CustomerAccount, result))
            );
    }

    createCustomer(customerAccountToCreate: CustomerAccount): Observable<CustomerAccount> {
        return this.http.post(`${this.baseUrl}`, customerAccountToCreate)
            .pipe(
                map(result => plainToClass(CustomerAccount, result))
            );
    }

    getCustomerAccountInvoiceInformation(customerAccountId: string): Observable<CustomerAccountInvoiceInformation> {
        return this.http.get<CustomerAccountInvoiceInformation>(`${this.baseUrl}/${customerAccountId}/invoice-information`)
            .pipe(map(el => plainToClass(CustomerAccountInvoiceInformation, el as object)));
    }

    updateCustomerAccountLock(customerAccountId: string, locked: boolean): Observable<CustomerAccount> {
        return this.http.put(`${this.baseUrl}/${customerAccountId}/lock`, {locked}).pipe(
            map(custAccount => plainToClass(CustomerAccount, custAccount))
        );
    }

    findCustomerAccountsBySiret(siretNumber: string, excludedIds: string[]): Observable<CustomerAccount[]> {
        return this.http.get<CustomerAccount[]>(`${this.baseUrl}/searchBySiret`, {
            params: {
                siret: siretNumber,
                excludedId: excludedIds
            }
        }).pipe(
            map(customerAccounts => plainToClass(CustomerAccount, customerAccounts))
        );
    }
}
