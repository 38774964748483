import {CustomerAccount} from '../../customers/models/customer-account';
import {Animator} from '../../users/models/animator';
import {Type} from 'class-transformer';

export class Consumption {
    type: ConsumptionType;
    @Type(() => CustomerAccount)
    customerAccount: CustomerAccount;
    sessionName: string;
    @Type(() => Animator)
    animator: Animator;
    @Type(() => Animator)
    sourceAnimator: Animator;
    @Type(() => Date)
    consumptionDate: Date;
    amount: number;
    scenarioName: string;
    simulationPeriod: string;
    details: string;
    teamCount: number;
}

export enum ConsumptionType {
    PHASE_1_LAUNCH = 'PHASE_1_LAUNCH',
    PHASE_2_LAUNCH = 'PHASE_2_LAUNCH',
    PERIOD_SIMULATION = 'PERIOD_SIMULATION',
    REVERT_PERIOD = 'REVERT_PERIOD',
    SCENARIO_HISTORY = 'SCENARIO_HISTORY'
}
