import {AfterViewInit, Component, ElementRef, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as moment from 'moment';
import {DatePickerComponent} from 'ng2-date-picker';

const DATE_PICKER_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ADatePickerComponent),
    multi: true
};

@Component({
    selector: 'a-date-picker',
    templateUrl: './a-date-picker.component.html',
    styleUrls: ['./a-date-picker.component.scss'],
    providers: [DATE_PICKER_VALUE_ACCESSOR]
})
export class ADatePickerComponent implements ControlValueAccessor {
    @Input()
    public label: string;

    @Input()
    public required: boolean;

    @Input() dateFormat?: string;
    @Input() placeholder: string;
    @Input() datePickerFormGroup: FormGroup;
    @Input() datePickerFormControlName: string;
    @Input() tabindex?: string;
    @ViewChild('picker', {static: true})
    datePicker: DatePickerComponent;
    @ViewChild('dateInput')
    dateInput: ElementRef;
    dateMoment: moment.Moment;
    date: Date;
    disabled = false;
    config = {format: 'DD/MM/YYYY', locale: 'fr'};

    writeValue(obj: any): void {
        this.date = obj;
        if (obj) {
            this.dateMoment = moment(obj);
        } else {
            this.dateMoment = null;
            if (this.dateInput) {
                this.dateInput.nativeElement.value = undefined;
            }
        }
    }

    onNgChange = (_: any) => {
    }
    onNgTouched = () => {
    }

    registerOnChange(fn: (value: any) => any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: () => any): void {
        this.onNgTouched = fn;
    }

    dateChange(date: any): void {
        // to keep pristine state
        if (date) {
            if (this.isMobileDevice()) {
                this.onNgChange(moment(date, 'YYYY-MM-DD').toDate());
            } else if (moment.isMoment(date)) {
                this.onNgChange(date.toDate());
            } else {
                this.onNgChange(date);
            }
        } else {
            this.onNgChange(undefined);
        }
    }

    public isMobileDevice(): RegExpMatchArray {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i)
            || navigator.userAgent.match(/Android/i);
    }

    setDisabledState(isDisabled: boolean): void {
        if (this.datePicker) {
            this.datePicker.setDisabledState(isDisabled);
        }
        this.disabled = isDisabled;
    }

    clear(): void {
        this.writeValue(null);
        this.dateChange(null);
    }

    onBlur(): void {
        this.onNgTouched();
    }
}
