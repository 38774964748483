<ng-template [ngIf]="!creationMode">
    <h2 class="cell shrink">{{'common.notes.title'|translate}} ({{count}})</h2>
    <span *ngIf="currentlyCustomerFiltered">{{'o-notes.currently-customer-filtered' | translate}}</span>

    <spacer size="24"></spacer>

    <div class="cell auto grid-y">
        <div class="cell auto cell-block-y grid-y grid-margin-y" style="height:500px; overflow:scroll" infiniteScroll [scrollWindow]="false" [infiniteScrollDistance]="2.5"
             (scrolled)="onScroll()">
            <m-note (noteMarkedAsRead)="reload()" class="cell shrink" *ngFor="let note of notes" [note]="note"></m-note>
            <div *ngIf="loading" class="loadingList">
                <i class="fas fa-spinner fa-spin"></i>
            </div>
        </div>
    </div>

    <spacer size="16"></spacer>

    <div class="cell shrink grid-x align-center">
        <a-button-default class="cell shrink" (click)="switchCreationMode()"><i class="fas fa-sticky-note"></i>
            {{'common.notes.action.add' | translate}}
        </a-button-default>
    </div>
</ng-template>

<!--NEW NOTE-->
<ng-template [ngIf]="creationMode">

    <h2 class="cell shrink">{{'common.notes.form.title' | translate}}</h2>

    <spacer size="16"></spacer>

    <form class="cell auto grid-y" #form="ngForm">
        <div class="cell auto grid-y">
            <div class="cell auto cell-block-y grid-y grid-margin-y">
                <a-select-customer class="cell shrink notesSelect"
                                   [label]="'common.notes.form.account' | translate"
                                   required="true"
                                   [name]="'customerAccount'"
                                   [ngModel]="noteBuffer.customerAccount.id"
                                   (ngModelChange)="onCustomerChange($event)"></a-select-customer>
                <a-select class="cell shrink notesSelect"
                          [clearable]="true"
                          [name]="'documentReferenceType'"
                          [itemList]="noteDocumentReferenceTypeItemList"
                          [bindLabel]="'label'"
                          [bindValue]="'value'"
                          [(ngModel)]="noteBuffer.documentReferenceType"
                          [label]="'note.linked.entity' | translate"></a-select>
                <a-select-user
                    *ngIf="noteBuffer.documentReferenceType === noteDocumentReferenceType.USER"
                    class="cell shrink notesSelect"
                    [disabled]="!noteBuffer.customerAccount.id"
                    [customerAccountRefFilter]="noteBuffer.customerAccount.id"
                    [name]="'user'"
                    [(ngModel)]="noteBuffer.documentRef"
                    [label]="'note.linked.entity.reference' | translate"></a-select-user>
                <a-select-billing
                    *ngIf="noteBuffer.documentReferenceType === noteDocumentReferenceType.BILLING"
                    class="cell shrink notesSelect"
                    [disabled]="!noteBuffer.customerAccount.id"
                    [customerAccountRefFilter]="noteBuffer.customerAccount.id"
                    [name]="'user'"
                    [(ngModel)]="noteBuffer.documentRef"
                    [label]="'note.linked.entity.reference' | translate"
                ></a-select-billing>
                <a-textarea class="cell auto _fullHeight" [name]="'description'" [label]="'common.notes.form.content'|translate"
                            [(ngModel)]="noteBuffer.description"></a-textarea>
                <a-checkbox [size]="'small'" [name]="'priority'" [(ngModel)]="noteBuffer.priority"
                            [label]="'common.notes.form.important'|translate"></a-checkbox>
            </div>
        </div>

        <spacer size="24"></spacer>

        <div class="cell shrink grid-x grid-margin-x align-center">
            <a-button-icon visibility="LOW" class="cell shrink" (click)="reset(); switchCreationMode()">
                <i class="fas fa-arrow-left"></i>
            </a-button-icon>
            <a-button-default class="cell shrink"
                              [disabled]="form.invalid"
                              [visibility]="'HIGH'"
                              (click)="switchCreationMode(); sendNote()">
              <i class="fab fa-telegram-plane"></i> {{'common.notes.action.send'|translate}}
            </a-button-default>
        </div>
    </form>

</ng-template>
