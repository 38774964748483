import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BillingDocument, ExtendedBillingDocument} from '../../../models/invoices/billing-document';
import {Operation} from '../../../../operations/models/operation';
import {FullUser} from '../../../../users/models/full-user';
import {CustomerAccount} from '../../../../customers/models/customer-account';
import {
  CustomerAccountTokenDeliveryInformation
} from './o-invoice-select-customer-account-for-token-delivery/customer-account-token-delivery-information';

type ComponentState = 'SELECT_CUSTOMER' | 'TRANSFER_TOKEN';

@Component({
  selector: 'm-invoice-token-transfer',
  templateUrl: './m-invoice-token-transfer.component.html',
  styleUrls: ['./m-invoice-token-transfer.component.scss']
})
export class MInvoiceTokenTransferComponent {
  // tslint:disable-next-line:variable-name
  private _initialBillingDocument: ExtendedBillingDocument | BillingDocument;
  tokenTransferTarget: FullUser;
  currentState: ComponentState;

  @Input()
  hide = false;

  @Input()
  customerAccount: CustomerAccount;

  @Input()
  initialOrigin: FullUser;

  @Input()
  set customerAccountManager(customerAccountManager: FullUser) {
    this.tokenTransferTarget = customerAccountManager;
  }

  @Output()
  operationSent = new EventEmitter<Operation>();

  @Output()
  operationCanceled = new EventEmitter<void>();


  private isCustomerAccountSelectionAvailable(): boolean {
    return this._initialBillingDocument.billingDocumentAdditionalInformation.additionalCustomerAccountsForTokenDelivery.length > 0;
  }


  @Input()
  set initialBillingDocument(initialBillingDocument: ExtendedBillingDocument | BillingDocument) {
    this._initialBillingDocument = initialBillingDocument;
    if (this.isCustomerAccountSelectionAvailable()) {
      this.currentState = 'SELECT_CUSTOMER';
    } else {
      this.currentState = 'TRANSFER_TOKEN';
    }
  }

  get initialBillingDocument(): ExtendedBillingDocument | BillingDocument {
    return this._initialBillingDocument;
  }

  onCustomerAccountSelect(customerAccountTokenDeliveryInformation: CustomerAccountTokenDeliveryInformation): void {
    this.tokenTransferTarget = customerAccountTokenDeliveryInformation.accountManager;
    this.currentState = 'TRANSFER_TOKEN';
  }

  onSelectionCancel(): void {
    this.operationCanceled.emit();
  }

  onOperationCancel(): void {
    if (this.isCustomerAccountSelectionAvailable()) {
      this.currentState = 'SELECT_CUSTOMER';
    } else {
      this.operationCanceled.emit();
    }
  }

  onOperationSent($event: Operation): void {
      this.operationSent.emit($event);
      if (this.isCustomerAccountSelectionAvailable()) {
          this.currentState = 'SELECT_CUSTOMER';
      }
  }
}
