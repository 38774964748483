import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TypeList} from '../../../models/type-list';

@Component({
  selector: 'm-type-select',
  templateUrl: './m-type-select.component.html',
  styleUrls: ['./m-type-select.component.scss']
})
export class MTypeSelectComponent implements OnInit {
    public openClass: boolean;

  constructor() { }

    @Input()
    public selectTitle: string;

    @Input()
    public types: TypeList = new TypeList([]);

    @Output()
    public typesChange: EventEmitter<TypeList> = new EventEmitter();

    public allSelected = false;

    public typesSelected: Map<string, boolean> = new Map();

    ngOnInit(): void {
        this.types.types.forEach((typeEntry) => {
            this.typesSelected[typeEntry.value] = typeEntry.selected;
        });
        this.allSelected = this.types.types.every(el => el.selected);
    }

    toggleOpen(): void {
        this.openClass = !this.openClass;
    }

    onModelChange(): void {
        this.types.types = this.types.types.map(typeEntry => {
            typeEntry.selected = this.typesSelected[typeEntry.value];
            return typeEntry;
        });
        this.allSelected = this.types.types.every(el => el.selected);
        this.typesChange.emit(this.types);
    }

    allChecked(newValue: boolean): void {
        if (newValue === true) {
            this.types.types.forEach(typeEntry => {
                this.typesSelected[typeEntry.value] = true;
            });
        } else if (newValue === false) {
            this.types.types.forEach(typeEntry => {
                this.typesSelected[typeEntry.value] = false;
            });
        }
        this.onModelChange();
    }
}
