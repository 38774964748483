<ng-template [ngIf]="billingAddress || deliveryAddress" [ngIfElse]="noAddresses">
    <o-content-block class="cell phone-shrink">
        <div class="grid-x align-middle align-justify">
            <h2 class="cell shrink invoiceAddresses-title" >{{'invoice.header.address.billing' | translate}}
                <span class="invoiceAddresses-title-customer"></span>
            </h2>
            <!-- <a-dashboard-button-icon *ngIf="isInProgress" (click)="resetBillingAddress()" class="cell shrink" [visibility]="'LOW'">
                <i class="fas fa-redo-alt"></i>
            </a-dashboard-button-icon> -->
        </div>
        <form #billingAddressForm='ngForm'>
            <div class="grid-x grid-margin-x grid-margin-y">
                <a-input [disabled]="disabled" class="cell phone-24"
                         [(ngModel)]="billingAddress.name"
                         name="billing-address-name"
                         [label]="'common.address.name'| translate">
                </a-input>
                <a-input [disabled]="disabled" class="cell phone-12"
                         [(ngModel)]="billingAddress.body"
                         name="address-line1"
                         [label]="'common.address.line1' | translate">
                </a-input>
                <a-input [disabled]="disabled" class="cell phone-12"
                         [(ngModel)]="billingAddress.additionalAddress"
                         name="address-line2"
                         [label]="'common.address.line2' | translate">
                </a-input>
                <a-input [disabled]="disabled" class="cell shrink _postalCode"
                         [label]="'invoice.header.address.postal.code'| translate"
                         [(ngModel)]="billingAddress.zipCode"
                         name="address-zip">
                </a-input>
                <a-input [disabled]="disabled" class="cell auto"
                         [label]="'invoice.header.address.city'| translate"
                         [(ngModel)]="billingAddress.city"
                         name="address-city">
                </a-input>
                <a-input [disabled]="disabled" class="cell phone-12"
                         [label]="'invoice.header.address.country'| translate"
                         [(ngModel)]="billingAddress.country"
                         name="address-country">
                </a-input>
            </div>
        </form>
    </o-content-block>
    <spacer size="16"></spacer>
    <o-content-block class="cell phone-shrink _fullHeight">
        <div class="grid-x align-middle align-justify">
            <h2 class="cell shrink invoiceAddresses-title" >{{'invoice.header.address.delivery' | translate}}
                <span class="invoiceAddresses-title-customer"></span>
            </h2>
            <!-- <a-dashboard-button-icon *ngIf="isInProgress" (click)="resetDeliveryAddress()" class="cell shrink" [visibility]="'LOW'">
                <i class="fas fa-redo-alt"></i>
            </a-dashboard-button-icon> -->
        </div>
        <form #deliveryAddressForm='ngForm'>
            <div class="grid-x grid-margin-x grid-margin-y">
                <a-input [disabled]="disabled"
                         class="cell phone-24"
                         [(ngModel)]="deliveryAddress.name"
                         name="delivery-address-name"
                         [label]="'common.address.name'| translate"></a-input>
                <a-input [disabled]="disabled" class="cell phone-12"
                         [(ngModel)]="deliveryAddress.body"
                         name="address-body"
                         [label]="'common.address.line1' | translate">
                </a-input>
                <a-input [disabled]="disabled" class="cell phone-12"
                         [(ngModel)]="deliveryAddress.additionalAddress"
                         name="address-line2"
                         [label]="'common.address.line2' | translate">
                </a-input>
                <a-input [disabled]="disabled" class="cell shrink _postalCode"
                         [label]="'invoice.header.address.postal.code' | translate"
                         [(ngModel)]="deliveryAddress.zipCode"
                         name="address-zip">
                </a-input>
                <a-input [disabled]="disabled" class="cell auto"
                         [label]="'invoice.header.address.city' | translate"
                         [(ngModel)]="deliveryAddress.city"
                         name="address-city">
                </a-input>
                <a-input [disabled]="disabled" class="cell phone-12"
                         [label]="'invoice.header.address.country' | translate"
                         [(ngModel)]="deliveryAddress.country"
                         name="address-country">
                </a-input>
            </div>
        </form>
    </o-content-block>
</ng-template>

<ng-template #noAddresses>
    <o-content-block class="cell phone-shrink big-desktop-auto">
        <!-- TODO: Mettre un écran d'absence d'adresse -->
        <span>Aucune adresse</span>
    </o-content-block>
</ng-template>
