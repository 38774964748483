import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {

  constructor() {
  }

  get lang(): string {
    return window.localStorage.getItem(LocalStorageKeys.LANG);
  }

  set lang(lang: string) {
    window.localStorage.setItem(LocalStorageKeys.LANG, lang);
  }
}

enum LocalStorageKeys {
  LANG = 'lang',
}
