<m-card-header [label]="'customers.detail.information.title'|translate" [userType]="customer.status"></m-card-header>

<spacer size="16"></spacer>

<a-field [label]="'customers.detail.information.institution'|translate">
    <i class="fas fa-school"></i>
    <ng-container value>
        <a-customer-link [customerAccount]="customer" [class]="'_' + customer.status.toLowerCase()"></a-customer-link>
    </ng-container>
</a-field>

<a-field [label]="'customers.detail.information.code'|translate">
    <i class="fas fa-user"></i>
    <ng-container value>
        {{customer?.accountingReference | boaNotNull : 'common.not.provided'}}
    </ng-container>
</a-field>

<spacer size="16"></spacer>

<div class="grid-x align-center">
    <m-user-info-token-balance class="cell shrink _small"
                               [ngClass]="'_' + customer.status.toLowerCase()"
                               [tokenBalance]="customer?.globalTokenBalance"
                               [label]="('customers.detail.information.token.amount' | translate) + ' ' + ('CUSTOMER' | translate | lowercase)"></m-user-info-token-balance>
</div>




