<o-last-operations [operations]="operations"
                   [label]="'operations.last.operations.arkhe.title' | translate"
                   [verticalLayout]="verticalLayout"
                   [operationType]="['ARKHE_TO_ACCOUNT_MANAGER', 'ACCOUNT_MANAGER_TO_ARKHE']">
    <ng-container button>
        <a-dashboard-button-icon *ngIf="verticalLayout" [visibility]="'LOW'" (click)="onViewOperationsClick()">
            <i class="fas fa-list"></i>
        </a-dashboard-button-icon>
        <a-button-default *ngIf="!verticalLayout" (click)="onViewOperationsClick()">
            <i class="fas fa-list"></i> {{'operations.arkhe.last.operations.list.action' | translate}}
        </a-button-default>
    </ng-container>
</o-last-operations>
