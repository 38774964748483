<h3>{{'user.reset.password.title' | translate}}</h3>

<m-choice-buttons [ngModel]="mode" (ngModelChange)="onModeChange($event)"></m-choice-buttons>

<spacer size="16"></spacer>

<a-password [placeholder]="'user.reset.password.new.password' | translate" *ngIf="mode !== 'automatic'"
            [(ngModel)]="newPassword"></a-password>

<spacer size="8"></spacer>

<a-checkbox [label]="'user.reset.password.send.email' | translate" size="tiny"
            [(ngModel)]="sendMail"
            [disabled]="true"></a-checkbox>

<spacer size="16"></spacer>

<a-button-default width="_fullWidth"
                  (click)="saveNewPassword()">
    <i class="fas fa-save"></i>
    {{'user.reset.password.save' | translate}}
</a-button-default>
