<label class="checkbox"
       [ngClass]="'_' + size + ' ' + margin"
       [class._disabled]="disabled">

    <input type="checkbox"
           [ngModel]="checked"
           (ngModelChange)="onCheckedChange($event)"
           [disabled]="disabled">

    <span class="checkbox-skin"></span>

    <span class="checkbox-label" *ngIf="label" [innerHTML]="label"></span>
    <span class="checkbox-label" *ngIf="!label"><ng-content></ng-content></span>
</label>
