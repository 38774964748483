import {AfterContentInit, Component, HostBinding, Input, OnInit} from '@angular/core';
import {Article, ArticleType} from '../../models/article';
import {TypeEntry} from '../../../../shared/models/type-entry';
import {ActivatedRoute, Router} from '@angular/router';
import {ArticleService} from '../../services/article.service';
import {ToastrService} from 'ngx-toastr';
import {skip, tap} from 'rxjs/operators';
import {TypeList} from '../../../../shared/models/type-list';

@Component({
  selector: 'boa-article-list',
  templateUrl: './article-list.component.html',
  styleUrls: ['./article-list.component.scss']
})
export class ArticleListComponent implements OnInit, AfterContentInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    @Input()
    public articles: Array<Article>;

    public searchQuery: string;
    public types = new TypeList([
        new TypeEntry('Jeton', ArticleType.TOKEN, false),
        new TypeEntry('Session', ArticleType.SESSION, false),
        new TypeEntry('Animation', ArticleType.ANIMATION, false),
        new TypeEntry('Frais', ArticleType.FEE, false),
        new TypeEntry('Travaux', ArticleType.WORKS, false),
    ]);
    public creationMode: boolean;
    public creatingLoading = false;
    public updatingLoading = false;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private articleService: ArticleService,  private notificationService: ToastrService) { }

    ngAfterContentInit(): void {
        this.route.queryParamMap.pipe(
            tap(params => this.searchQuery = params.get('search')),
            tap(params => this.types.setSelected(params.getAll('types'))),
            skip(1)
        ).subscribe(_ => {
            this.search();
        });
    }

    ngOnInit(): void {
        this.route.data.subscribe((data: {articles: Array<Article>}) => {
            this.articles = data.articles;
        });
    }

    private search(): void {
        this.articleService.search(this.searchQuery, this.types.getSelectedValues()).subscribe(articles => {
            this.articles = articles;
        });
    }

    updateArticle(article: Article): void {
        this.updatingLoading = true;
        this.articleService.update(article).subscribe(_ => {
            this.updatingLoading = false;
            this.notificationService.success('Article modifié');
            this.search();
        });
    }

    onArticleDelete(article: Article): void {
        this.articleService.delete(article).subscribe( _ => {
            this.notificationService.success('Article supprimé');
            this.search();
        });
    }

    onCreateClick(): void {
        this.creationMode = true;
    }

    createArticle(article: Article): void {
        this.creatingLoading = true;
        this.articleService.create(article).subscribe(_ => {
            this.search();
            this.creatingLoading = false;
            this.creationMode = false;
            this.notificationService.success('Article crée');
        });
    }

    onCreationCancel(): void {
        this.creationMode = false;
    }
}
