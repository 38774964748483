import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppRootComponent} from './app-root.view';
import {CommonModule, registerLocaleData} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {NewBoaModule} from '../newboa/newboa.module';
import {TranslateService} from '@ngx-translate/core';
import {LoggedInGuard} from './guards/logged-in.guard';
import {LocalStorageService} from './services/local-storage.service';
import {ConnectedUserService} from './services/connected-user.service';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);

@NgModule({
    declarations: [
        AppRootComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        NewBoaModule,
        SharedModule
    ],
    providers: [
        TranslateService,
        LoggedInGuard,
        ConnectedUserService,
        LocalStorageService,
        {provide: LOCALE_ID, useValue: 'fr-FR'}
    ],
})
export class AppModule {
}
