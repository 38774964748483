<div class="tooltipContainer" *ngIf="tooltipLabelKey">
    <i class="tooltipContainerIcon fas fa-info-circle"></i>
    <div class="tooltipContainerContent">
        {{tooltipLabelKey | translate}}
    </div>
</div>

<div class="indicator-label">
    <ng-content select="[label]"></ng-content>
    <span *ngIf="!!content" class="tokenInformation"> -
        <i class="fas fa-coins"></i> <ng-content select="[tokenInformation]"></ng-content>
    </span>
</div>

<div class="indicator-footer">
    <div class="indicator-footer-infos">
        <div class="indicator-icon">
            <ng-content select="[icon]"></ng-content>
        </div>

        <div class="indicator-value">
            <ng-content select="[value]"></ng-content>
        </div>
    </div>
</div>
