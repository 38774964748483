import {Component, Input, OnInit} from '@angular/core';
import {BillingDocumentService} from '../../../../accounting/services/billing-document.service';
import {
    BillingDocument,
    BillingDocumentType,
    ExtendedBillingDocument
} from '../../../../accounting/models/invoices/billing-document';
import {InvoiceStatus} from '../../../../accounting/models/invoices/invoice';
import {Params, Router} from '@angular/router';
import {combineLatest} from 'rxjs';
import {CustomerAccountService} from '../../../services/customer-account.service';
import {CustomerAccountInvoiceInformation} from '../../../models/customer-account-invoice-information';
import {map} from 'rxjs/operators';
import {CustomerAccount} from '../../../models/customer-account';
import {uniq} from 'lodash';

@Component({
    selector: 'o-invoice-information',
    templateUrl: './o-invoice-information.component.html',
    styleUrls: ['./o-invoice-information.component.scss']
})
export class OInvoiceInformationComponent implements OnInit {

    @Input()
    customerAccount: CustomerAccount;

    billingDocuments: Array<ExtendedBillingDocument> = [];
    creationButtonLoading: boolean;
    customerAccountInvoiceInformation: CustomerAccountInvoiceInformation;
    filterParams: Params;

    constructor(
        private router: Router,
        private billingDocumentService: BillingDocumentService,
        private customerAccountService: CustomerAccountService) {
    }

    ngOnInit(): void {
        this.filterParams = {
            customerAccountId: this.customerAccount.id,
            billingTypes: ['ALL'],
            billingStatus: ['ALL']
        };
        // Late invoices first
        const lateSubscription = this.billingDocumentService.findExtended(
            null,
            null,
            [BillingDocumentType.INVOICE],
            [InvoiceStatus.OVERDUE],
            4,
            0,
            'DATE:DESC',
            this.customerAccount.id,
            null);

        // All documents, most recent first
        const allRecentSubscription = this.billingDocumentService.findExtended(
            null,
            null,
            [BillingDocumentType.QUOTE, BillingDocumentType.INVOICE],
            ['ALL'],
            4,
            0,
            'DATE:DESC',
            this.customerAccount.id,
            null);

        combineLatest([lateSubscription, allRecentSubscription])
            .pipe(map(([lates, recents]) => ({lates, recents})))
            .subscribe(pair => this.billingDocuments = [...pair.lates.data, ...pair.recents.data]
                .filter((el, index, self) => self.findIndex(it => it.reference === el.reference) === index)
                .slice(0, 4)
            );

        this.customerAccountService.getCustomerAccountInvoiceInformation(this.customerAccount.id)
            .subscribe(customerAccountInvoiceInformation => this.customerAccountInvoiceInformation = customerAccountInvoiceInformation);
    }

    goToInvoiceCreationPage(): void {
        this.creationButtonLoading = true;
        this.router.navigate(['boa', 'accounting', 'invoices', 'new'], {
            state: {customerAccountBuffer: this.customerAccount}
        });
    }

    goToQuoteCreationPage(): void {
        this.creationButtonLoading = true;
        this.router.navigate(['boa', 'accounting', 'quotes', 'new'], {
            state: {customerAccountBuffer: this.customerAccount}
        });
    }

    onListButtonClicked(buttonId: number): void {
        if (buttonId === 0) {
            this.goToInvoiceCreationPage();
        }
    }
}
