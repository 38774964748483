import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'a-badge',
    templateUrl: './a-badge.component.html',
    styleUrls: ['./a-badge.component.scss']
})
export class ABadgeComponent implements OnInit {

    @Input()
    empty = false;

    constructor() { }

    ngOnInit(): void {
    }

}
