<ng-template [ngIf]="customerAccount || !billingDocument.id" [ngIfElse]="noCustomerAccountBlock">

    <div class="grid-x align-middle align-justify customerInfo-headerContainer"
         [class._customerMenu]="customerMenu">
        <div class="cell auto grid-x align-middle customerInfo-header">
            <h2 class="cell shrink big-desktop-24 customerInfo-header-title">{{'invoice.charged.customer.title' | translate}}</h2>
            <div class="grid-x grid-margin-x">
                <span class="cell shrink accountType" [class._empty]="!customerAccount.type"
                      [ngClass]="'_' + customerAccount.status?.toLowerCase()">
                        {{(customerAccount.type ? 'customers.type.' + customerAccount.type : 'common.not.provided') | translate}}
                </span>
                <a-chorus-pro-badge *ngIf="customerAccount?.chorusProActivated"></a-chorus-pro-badge>
            </div>
        </div>
        <a-dashboard-button-icon class="cell shrink customerInfo-header-action"
                                 *ngIf="customerAccountId && !disabled"
                                 (click)="refreshCustomer()"
                                 [visibility]="'LOW'"
                                 [tooltipLabelKey]="'Attention, vous allez rafraîchir l\'ensemble des données client'">
            <i class='fas fa-redo-alt'></i>
        </a-dashboard-button-icon>
        <a-dashboard-button-icon class="cell shrink customerInfo-header-action"
                                 [disabled]="!customerAccountId || !billingDocument.id"
                                 (click)="goToCustomerAccountPage()"
                                 [visibility]="'LOW'"><i class="far fa-eye"></i></a-dashboard-button-icon>
    </div>

    <spacer size="16"></spacer>

    <form #form1='ngForm' *ngIf="!disabled else customerFixed">
        <div class="grid-x grid-margin-x grid-margin-y">
            <a-select class="cell phone-12 big-desktop-24"
                      [label]="'invoice.customer.name' | translate"
                      [required]="isInProgress"
                      [itemList]="allCustomersName"
                      [bindValue]="'value'"
                      [name]="'customer-name'"
                      (ngModelChange)="onCustomerChange($event)"
                      [ngModel]="customerAccountId"></a-select>

            <a-select class="cell phone-12 big-desktop-24"
                      [label]="'invoice.customer.accounting.reference' | translate"
                      [required]="isInProgress"
                      [itemList]="allCustomersCode"
                      [bindValue]="'value'"
                      [name]="'customer-code'"
                      (ngModelChange)="onCustomerChange($event)"
                      [ngModel]="customerAccountId"></a-select>
        </div>
    </form>

    <ng-template #customerFixed>
        <div class="grid-x">
            <p class="cell shrink Subtitle-invoice-header-info-customers-">Nom &nbsp;</p>
            <p class="cell auto content-invoice-header-info-customers">{{customerAccount.name}}</p>
        </div>

        <spacer size="16"></spacer>

        <div class="grid-x">
            <p class="cell shrink Subtitle-invoice-header-info-customers-">Code client &nbsp;</p>
            <p class="cell auto content-invoice-header-info-customers">{{customerAccount.accountingReference}}</p>
        </div>
    </ng-template>

    <spacer size="16"></spacer>

    <form #form2='ngForm'>
        <div class="grid-x grid-margin-x grid-margin-y align-bottom">
            <a-select-vat-rate class="cell phone-7 big-desktop-10"
                               [label]="'Taux de TVA'"
                               [name]="'customer-vat'"
                               [(ngModel)]="billingDocument.metadata.vatRate"
                               [disabled]="disabled"
                               (ngModelChange)="onVatRateChange($event)">
            </a-select-vat-rate>

            <div class="cell phone-12 big-desktop-24">
                <a-field [label]="'N° SIRET'">
                    <ng-container value>
                        {{customerAccount.siretNumber}}
                    </ng-container>
                </a-field>

                <a-field [label]="'N° TVA'">
                    <ng-container value>
                        {{customerAccount.vatNumber}}
                    </ng-container>
                </a-field>
            </div>

            <a-select class="cell phone-12 big-desktop-24"
                      [(ngModel)]="billingDocument.customer.chorusProServiceCode"
                      [label]="'invoice.chorus.pro.service' | translate"
                      [bindValue]="'code'"
                      [bindLabel]="'code'"
                      name="chorus-pro-service-code"
                      [itemList]="chorusProSettings.services"
                      [clearable]="true"
                      [disabled]="!billingDocument.isInProgress()"
                      [required]="isChorusProServiceMandatory"
                      *ngIf="chorusProSettings && chorusProSettings.services.length > 0">
            </a-select>
        </div>
    </form>
</ng-template>

<ng-template #noCustomerAccountBlock>
    <!-- TODO mettre un écran d'absence de customer account (ne devrais jamais arriver) -->
    <span>Aucun customer account</span>
</ng-template>
