<o-content-block class="cell auto">
    <div class="grid-x align-center">
        <!--    TODO : Disabled le bouton reload tant que le taux n'a pas été édité-->
        <a-input-discount class="cell shrink"
                          [disabled]="disabled"
                          [label]="'Taux de remise calculé'"
                          [ngModel]="_discountPercentage ? _discountPercentage * 100 : 0"
                          (ngModelChange)="onTokenDiscountPercentageChange($event)"
                          (resetClick)="onResetTokenPercentage()">
        </a-input-discount>
    </div>

    <spacer size="32"></spacer>

    <div class="articlesAmountLine">
        <span class="articlesAmountLine-label">Montant total</span>
        <span class="articlesAmountLine-value">{{_tokenGrossTotal | currency : 'EUR'}}</span>
    </div>

    <spacer size="4"></spacer>

    <div class="articlesAmountLine _discount">
        <span class="articlesAmountLine-label">Remise</span>
        <span class="articlesAmountLine-value">- {{_discountTotal | currency : 'EUR'}}</span>
    </div>

    <spacer size="24"></spacer>

    <div class="articlesTotal">
        <span class="articlesTotal-label">Total après remise HT</span>
        <span class="articlesTotal-value">{{(_tokenGrossTotal - _discountTotal) | currency : 'EUR'}}</span>
    </div>
</o-content-block>
