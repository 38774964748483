import {Component, HostBinding, OnInit} from '@angular/core';
import {User} from '../../../models/user';
import {UserService} from '../../../services/user.service';
import {ContextService} from '../../../../common/services/context.service';
import {BoaNavigationEvent, BoaNavigationEventType} from '../../../../common/models/shared/boa-navigation-event';

@Component({
    selector: 'o-last-connections',
    templateUrl: './o-last-connections.component.html',
    styleUrls: ['./o-last-connections.component.scss']
})
export class OLastConnectionsComponent implements OnInit {

    @HostBinding('attr.class') class = 'grid-y';

    nbConnections: number;
    users: Array<User>;

    private limit = 10;
    private lastEvent: BoaNavigationEvent = new BoaNavigationEvent(BoaNavigationEventType.NONE, undefined);

    constructor(private userService: UserService, private contextService: ContextService) {
    }

    ngOnInit(): void {
        this.contextService.contextEvent.subscribe((event: BoaNavigationEvent) => {
                if (event?.type !== this.lastEvent.type && event?.data !== this.lastEvent.data) {
                    this.lastEvent = event;
                    if (event.type === BoaNavigationEventType.CUSTOMER) {
                        this.load(event.data);
                    }
                } else if (event.type === BoaNavigationEventType.NONE) {
                    this.load(null);
                }
            }
        );
    }

    load(customerAccountId: string): void {
        this.userService.getLastConnections('', this.limit, false, customerAccountId).subscribe(users => {
            this.users = users.data;
            this.nbConnections = this.users.length;
        });
    }
}
