import {Component, OnInit} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'boa-loading',
    templateUrl: './boa-loading.component.html',
    styleUrls: ['./boa-loading.component.scss']
})
export class BoaLoadingComponent implements OnInit {

    loading = false;

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.router.events.pipe(
            tap(routerEvent => {
                if (routerEvent instanceof NavigationStart) {
                    this.loading = true;
                } else if (routerEvent instanceof NavigationEnd ||
                    routerEvent instanceof NavigationCancel ||
                    routerEvent instanceof NavigationError) {
                    this.loading = false;
                }
            })
        ).subscribe();
    }

}
