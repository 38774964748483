import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'o-filters',
    templateUrl: './o-filters.component.html',
    styleUrls: ['./o-filters.component.scss'],
    host: {class: 'grid-x align-justify align-middle'}
})
export class OFiltersComponent implements OnInit {

    @Input()
    public countsMap: Map<string, number>;

    constructor() {
    }

    ngOnInit(): void {
    }

}
