import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {Role} from '../../../models/role';
import {Animator} from '../../../models/animator';
import {NavigationEnd, Router} from '@angular/router';
import {AnimatorType} from '../../../models/user';

@Component({
  selector: 'o-animators-list',
  templateUrl: './o-animators-list.component.html',
  styleUrls: ['./o-animators-list.component.scss']
})
export class OAnimatorsListComponent {
    // tslint:disable-next-line:variable-name
    _customerAccountId: string;

    @Input()
    set customerAccountId(customerAccountId: string) {
        this.userService.getCustomerAccountsAnimatorLastConnections(customerAccountId, this.limit).subscribe(result => {
            this.animators = (result.data as [Animator]);
        });
        this._customerAccountId = customerAccountId;
    }

    get customerAccountId(): string {
        return this._customerAccountId;
    }

    @Input()
    verticalLayout = false;

    @Output()
    transferClicked = new EventEmitter<Animator>();

    animators: Array<Animator> = [];

    private limit = 4;
    animatorType = AnimatorType;

    constructor(private userService: UserService, private router: Router) { }

    onViewAnimatorsClick(): void {
        this.router.navigate(['/boa/users'], {
            queryParams: {
                customerAccountRef: this.customerAccountId,
                roles: [Role.ANIMATOR, AnimatorType.DEMONSTRATION]
            }
        });
    }

    onTransferClick(animator: Animator): void {
        this.transferClicked.emit(animator);
    }
}
