import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {TableHeader} from '../../../models/table-header';
import {ActivatedRoute, Router} from '@angular/router';
import {SortDirection} from '../../../models/sort-direction';

@Component({
  selector: 'th[m-entry-query]',
  templateUrl: './m-entry-query.component.html',
  styleUrls: ['./m-entry-query.component.scss']
})
export class MEntryQueryComponent implements OnInit {

    @Input()
    public tableHeader: TableHeader;

    @HostBinding('class') get class(): string {
        return this.tableHeader.clazz ;
    }

    @Output()
    public sortClicked = new EventEmitter<TableHeader>();

    public ascActive = false;
    public descActive = false;

    constructor(private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.ascActive = this.tableHeader.sortDirection === SortDirection.ASC;
        this.descActive = this.tableHeader.sortDirection === SortDirection.DESC;
    }

    sort(direction: 'ASC' | 'DESC' | null): void {
        this.tableHeader.sortActive = true;
        this.tableHeader.sortDirection = direction;
        if (this.tableHeader.sortDirection === null) {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    sort: null
                },
                queryParamsHandling: 'merge'
            });
        } else {
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    sort: `${this.tableHeader.value}:${this.tableHeader.sortDirection}`
                },
                queryParamsHandling: 'merge'
            });
        }
        this.ascActive = this.tableHeader.sortDirection === SortDirection.ASC;
        this.descActive = this.tableHeader.sortDirection === SortDirection.DESC;
        this.sortClicked.emit(this.tableHeader);
    }
}
