import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../../shared/shared.module';
import {CustomerAccountService} from './services/customer-account.service';
import {CustomerAccountsResolver} from './resolvers/customer-accounts-resolver.service';
import {CustomersDetailComponent} from './views/customers-detail/customers-detail.component';
import {ButtonsModule} from '../../shared/components/atoms/buttons/buttons.module';
import {ToastrModule} from 'ngx-toastr';
import {CustomerListComponent} from './views/customer-list/customer-list.component';
import {ConsumptionsModule} from '../consumptions/consumptions.module';
import { OCustomerAccountInformationComponent } from './components/organisms/o-customer-account-information/o-customer-account-information.component';
import {UserComponentsModule} from '../users/components/user-components.module';
import {MCustomersTypeSelectComponent} from '../operations/components/molecules/m-customers-type-select/m-customers-type-select.component';
import {OLastDemonstrationAnimatorComponent} from './components/organisms/o-last-demonstration-animator/o-last-demonstration-animator.component';
import { MCustomerBillingContactComponent } from './components/molecules/m-customer-billing-contact/m-customer-billing-contact.component';
import { MCustomerAddressesComponent } from './components/molecules/m-customer-addresses/m-customer-addresses.component';
import {UserDirectivesModule} from '../users/directives/user-directives.module';
import {OperationsModule} from '../operations/operations.module';
import { OInvoiceInformationComponent } from './components/organisms/o-invoice-information/o-invoice-information.component';
import {AccountingModule} from '../accounting/accounting.module';
import { SirenFormatterPipePipe } from './pipes/siren-formatter-pipe.pipe';
import { SiretFormatterPipe } from './pipes/siret-formatter.pipe';
import { ASelectCustomerComponent } from './components/atoms/a-select-customer/a-select-customer.component';
import {BoaNotNullPipe} from '../../shared/pipes/not-null.pipe';
import { MCustomerBillingContactEditComponent } from './components/molecules/edit/m-customer-billing-contact-edit/m-customer-billing-contact-edit.component';
import { OCustomerAccountInformationEditComponent } from './components/organisms/edit/o-customer-account-information-edit/o-customer-account-information-edit.component';
import { MCustomerAddressesEditComponent } from './components/molecules/edit/m-customer-addresses-edit/m-customer-addresses-edit.component';
import { OCustomerAccountCreationComponent } from './components/organisms/creation/o-customer-account-creation/o-customer-account-creation.component';

const components = [
    CustomersDetailComponent,
    CustomerListComponent,
    MCustomersTypeSelectComponent,
    OCustomerAccountInformationComponent,
    OLastDemonstrationAnimatorComponent,
    ASelectCustomerComponent
];

@NgModule({
    declarations: [
        components,
        MCustomerBillingContactComponent,
        MCustomerAddressesComponent,
        OInvoiceInformationComponent,
        SirenFormatterPipePipe,
        SiretFormatterPipe,
        MCustomerBillingContactEditComponent,
        OCustomerAccountInformationEditComponent,
        MCustomerAddressesEditComponent,
        OCustomerAccountCreationComponent,
    ],
    exports: [components],
    imports: [
        ButtonsModule,
        CommonModule,
        ConsumptionsModule,
        FormsModule,
        RouterModule,
        SharedModule,
        ToastrModule,
        TranslateModule,
        UserComponentsModule,
        UserDirectivesModule,
        OperationsModule,
        AccountingModule,
        ReactiveFormsModule
    ],
    providers: [
        CustomerAccountService,
        CustomerAccountsResolver,
        BoaNotNullPipe,
    ]
})
export class CustomersModule {
}
