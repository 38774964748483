<div class="grid-y grid-margin-y reveal" data-reveal modal>
    <h2 class="cell auto modal-title"><ng-content select="[modalTitle]"></ng-content></h2>
    <div class="cell auto modal-container">
        <ng-content select="[modalContent]"></ng-content>
    </div>
    <div class="cell shrink grid-x grid-margin-x grid-padding-y align-center">
        <a-button-cancel class="cell shrink" (click)="onCancel()">{{'common.action.cancel' | translate}}</a-button-cancel>
        <a-button-submit class="cell shrink" (click)="onConfirm()">{{'common.validate' | translate}}</a-button-submit>
    </div>
</div>
