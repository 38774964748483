import {AfterContentInit, Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerAccount} from '../../models/customer-account';
import {CustomerAccountService} from '../../services/customer-account.service';
import {Result} from '../../../common/models/shared/result';
import {ASearchComponent} from '../../../../shared/components/atoms/forms/a-search/a-search.component';
import {skip, tap} from 'rxjs/operators';
import {TypeEntry} from '../../../../shared/models/type-entry';
import {CustomerStatus} from '../../models/customer-status';
import {TypeList} from '../../../../shared/models/type-list';
import {TableHeader} from '../../../../shared/models/table-header';
import {TranslateService} from '@ngx-translate/core';
import {DatePipe} from '@angular/common';
import {NavigationService} from '../../../common/services/navigation.service';
import {DownloadService} from '../../../common/services/download.service';
import {CustomerAccountTypes} from '../../models/customer-account-types';
import {Subscription} from 'rxjs';

@Component({
    selector: 'boa-customer-list',
    templateUrl: './customer-list.component.html',
    styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit, AfterContentInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';

    private readonly TOTAL_KEY = 'common.lists.results.number';

    public customerAccounts: Array<CustomerAccount> = [];
    public searchQuery = '';

    public countsMap: Map<string, number> = new Map();
    public aggregations: Map<string, number> = new Map();

    @ViewChild('searchQueryComponent')
    public searchQueryComponent: ASearchComponent;

    public status: TypeList = new TypeList([
        new TypeEntry('Actif', CustomerStatus.ACTIVE, false),
        new TypeEntry('Prospect', CustomerStatus.PROSPECT, false),
    ]);
    public customerAccountsTypes: TypeList = new TypeList(Object.values(CustomerAccountTypes).map(type => {
        return new TypeEntry(this.translateService.instant('customers.type.' + type), type, false);
    }));
    public bottomPageSpinner: boolean;
    public tableHeaders: Array<TableHeader> = [];
    public wholePageSpinner: boolean;

    private sort: string;
    private page = 1;
    private limit = 50;
    private searchSubscription: Subscription | null = null;

    constructor(private route: ActivatedRoute,
                private router: Router,
                private downloadService: DownloadService,
                private datePipe: DatePipe,
                public navigationService: NavigationService,
                private customerAccountService: CustomerAccountService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.route.data.subscribe((resolved: { customerAccounts: Result<CustomerAccount> }) => {
            this.customerAccounts = resolved.customerAccounts.data;
            this.aggregations = resolved.customerAccounts.aggregations;
            this.countsMap.set(this.TOTAL_KEY, resolved.customerAccounts.totalCount);
        });

        this.translateService.get([
            'customers.list.name',
            'customers.list.code',
            'customers.list.type',
            'customers.list.token.amount',
            'customers.list.token.aggregation',
            'customers.list.manager'
        ]).subscribe(translations =>
            this.tableHeaders = [
                new TableHeader('', false, 'STATUS', 'tableRoleIcon', null),
                new TableHeader(translations['customers.list.name'], true, 'NAME', 'customerNameColumn'),
                new TableHeader(translations['customers.list.code'], true, 'ACCOUNTING_REFERENCE', 'codeColumn'),
                new TableHeader(translations['customers.list.type'], true, 'TYPE', 'typeColumn'),
                new TableHeader(translations['customers.list.token.amount'], true, 'GLOBAL_TOKEN_BALANCE', 'tokenColumn', false, null,
                    translations['customers.list.token.aggregation'] + ' : '),
                new TableHeader(translations['customers.list.manager'], false, 'MANAGER', 'managerColumn')
            ]);
        this.reloadTooltips();
    }

    ngAfterContentInit(): void {
        this.route.queryParamMap
            .pipe(
                tap(params => this.status.setSelected(params.getAll('status'))),
                tap(params => this.customerAccountsTypes.setSelected(params.getAll('type'))),
                tap(params => {
                    this.sort = params.get('sort');
                    const value = this.sort?.split(':')[0];
                    const direction = this.sort?.split(':')[1];
                    this.tableHeaders = this.tableHeaders.map(el => {
                        if (el.value === value) {
                            el.sortActive = true;
                            el.sortDirection = direction as 'ASC' | 'DESC' | null;
                        }
                        return el;
                    });
                }),
                tap(params => this.searchQuery = params.get('search')),
                skip(1)
            )
            .subscribe(_ =>
                this.search()
            );
    }

    search(): void {
        this.page = 0;
        this.wholePageSpinner = true;

        if (this.searchSubscription != null) {
            this.searchSubscription.unsubscribe();
        }

        this.searchSubscription = this.customerAccountService.getPaginatedCustomerAccounts(
            this.page,
            this.limit,
            this.blankOrValue(this.searchQuery),
            this.status.getSelectedValues(),
            this.customerAccountsTypes.getSelectedValues(),
            this.sort
        ).subscribe(result => {
            this.wholePageSpinner = false;
            this.customerAccounts = result.data;
            this.countsMap.set(this.TOTAL_KEY, result.totalCount);
            this.reloadTooltips();
        });
    }

    private reloadTooltips(): void {
        this.tableHeaders[4].tooltipData = this.aggregations.get('globalTokenBalance');
    }

    loadNext(): void {
        this.bottomPageSpinner = true;
        this.page++;
        this.customerAccountService.getPaginatedCustomerAccounts(
            this.page,
            this.limit,
            this.blankOrValue(this.searchQuery),
            this.status.getSelectedValues(),
            this.customerAccountsTypes.getSelectedValues(),
            this.sort
        ).subscribe(result => {
                this.customerAccounts = this.customerAccounts.concat(result.data);
                this.bottomPageSpinner = false;
            }
        );
    }
    blankOrValue(value: string | null): string {
        return value == null ? '' : value;
    }

    downloadCsv(): void {
        this.downloadService.downloadFileWithParams(
            this.customerAccountService.getCSVLink(),
            `comptes-clients-${this.datePipe.transform(Date.now(), 'dd/MM/yy')}`,
            'csv',
            {
                page: null,
                limit: null,
                sort: this.sort,
                search: this.searchQuery,
                status: this.status.getSelectedValues(),
                type: this.customerAccountsTypes.getSelectedValues()
            }
        );
    }
}
