import {Component, HostBinding, OnInit} from '@angular/core';
import {Note} from '../../../../notes/models/Note';
import {NoteService} from '../../../../notes/service/note.service';
import {NotificationService} from '../../../../../shared/services/notification.service';
import {TranslateService} from '@ngx-translate/core';
import {CustomerAccountService} from '../../../../customers/services/customer-account.service';
import {SelectEntry} from '../../../models/select/select-entry';
import {NoteDocumentReferenceType} from '../../../../notes/models/NoteDocumentReferenceType';
import {ContextService} from '../../../services/context.service';
import {BoaNavigationEventType} from '../../../models/shared/boa-navigation-event';
import {BillingDocumentService} from '../../../../accounting/services/billing-document.service';
import {UserService} from '../../../../users/services/user.service';

@Component({
    selector: 'o-notes',
    templateUrl: './o-notes.component.html',
    styleUrls: ['./o-notes.component.scss']
})
export class ONotesComponent implements OnInit {

    @HostBinding('attr.class') class = 'grid-y';

    notes: Note[] = [];
    noteBuffer: Note = Note.empty();
    page = 0;
    limit = 10;
    loading = false;

    creationMode: boolean;
    noteDocumentReferenceTypeItemList: SelectEntry[] = [
        new SelectEntry(
            this.translationService.instant('common.' + NoteDocumentReferenceType.USER.toLowerCase()),
            NoteDocumentReferenceType.USER
        ),
        new SelectEntry(
            this.translationService.instant('common.' + NoteDocumentReferenceType.BILLING.toLowerCase()),
            NoteDocumentReferenceType.BILLING
        ),
    ];
    noteDocumentReferenceType = NoteDocumentReferenceType;

    customerAccountRefFilter: string | null = null;
    documentRefFilter: string;
    noteDocumentTypeFilter: NoteDocumentReferenceType;

    currentlyCustomerFiltered = false;
    count: number;

    constructor(private noteService: NoteService,
                private contextService: ContextService,
                private translationService: TranslateService,
                private customerAccountService: CustomerAccountService,
                private userService: UserService,
                private billingService: BillingDocumentService,
                private notificationService: NotificationService) {
        this.contextService.contextEvent.subscribe(contextEvent => {
            this.page = 0;
            if (contextEvent.type === BoaNavigationEventType.CUSTOMER) {
                this.customerAccountRefFilter = contextEvent.data;
                this.searchNotes(this.customerAccountRefFilter);
                this.fillNoteBuffer(
                    this.customerAccountRefFilter,
                    null,
                    null
                );
            }
            if (contextEvent.type === BoaNavigationEventType.DOCUMENT) {
                this.documentRefFilter = contextEvent.data;
                this.noteDocumentTypeFilter = NoteDocumentReferenceType.BILLING;
                this.billingService.getById(this.documentRefFilter).subscribe(billingDocument => {
                    this.customerAccountRefFilter = billingDocument.metadata.customerAccountId;
                    this.searchNotes(this.customerAccountRefFilter);
                    this.fillNoteBuffer(
                        this.customerAccountRefFilter,
                        this.documentRefFilter,
                        this.noteDocumentTypeFilter
                    );
                });
            }
            if (contextEvent.type === BoaNavigationEventType.USER) {
                this.documentRefFilter = contextEvent.data;
                this.noteDocumentTypeFilter = NoteDocumentReferenceType.USER;
                this.userService.getUserById(this.documentRefFilter).subscribe(user => {
                    if (user.customerAccount) {
                        this.customerAccountRefFilter = user.customerAccount.id;
                        this.searchNotes(this.customerAccountRefFilter);
                        this.fillNoteBuffer(
                            this.customerAccountRefFilter,
                            this.documentRefFilter,
                            this.noteDocumentTypeFilter
                        );
                    }
                });
            }
            if (contextEvent.type === BoaNavigationEventType.NONE) {
                this.documentRefFilter = null;
                this.noteDocumentTypeFilter = null;
                this.customerAccountRefFilter = null;
                this.searchNotes(null);
                this.fillNoteBuffer(
                    this.customerAccountRefFilter,
                    this.documentRefFilter,
                    this.noteDocumentTypeFilter
                );
            }
        });
    }

    fillNoteBuffer(customerAccountRef, documentRef, noteDocumentType): void {
        this.noteBuffer.customerAccount.id = customerAccountRef;
        this.noteBuffer.documentRef = documentRef;
        this.noteBuffer.documentReferenceType = noteDocumentType;
    }


    ngOnInit(): void {
        this.searchNotes(
            this.customerAccountRefFilter
        );
    }

    switchCreationMode(): void {
        this.creationMode = !this.creationMode;
    }

    sendNote(): void {
        this.noteService.create(this.noteBuffer).subscribe(_ => {
            this.notificationService.success(
                this.translationService.instant('common.success')
            );
            this.reload();
        });
        this.reset();
    }

    onCustomerChange(newCustomerAccountId: string): void {
        this.customerAccountService
            .getCustomerAccountById(newCustomerAccountId)
            .subscribe(customerAccount => this.noteBuffer.customerAccount = customerAccount);
    }

    reload(): void {
        this.loading = true;
        this.noteService.find(
            0,
            this.limit,
            this.customerAccountRefFilter
        ).subscribe(notes => {
            this.count = notes.totalCount;
            this.notes = notes.data;
            this.contextService.unreadNotesCount$.next(notes.totalCount);
            this.loading = false;
        });
    }

    reset(): void {
        this.noteBuffer = Note.empty();
        this.fillNoteBuffer(
            this.customerAccountRefFilter,
            this.documentRefFilter,
            this.noteDocumentTypeFilter
        );
    }

    private searchNotes(customerAccountRef): void {
        this.currentlyCustomerFiltered = customerAccountRef !== null;
        this.loading = true;
        this.noteService.find(
            this.page,
            this.limit,
            customerAccountRef,
        ).subscribe(notes => {
            this.count = notes.totalCount;
            this.notes = notes.data;
            this.contextService.unreadNotesCount$.next(notes.totalCount);
            this.loading = false;
        });
    }

    private loadNext(): void {
        this.page++;
        this.loading = true;
        this.noteService.find(
            this.page,
            this.limit,
            this.customerAccountRefFilter
        ).subscribe(notes => {
            this.count = notes.totalCount;
            this.notes = this.notes.concat(notes.data);
            this.loading = false;
        });
    }

    onScroll(): void {
        this.loadNext();
    }
}
