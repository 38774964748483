import {Component, ContentChild, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'a-big-indicator',
  templateUrl: './a-big-indicator.component.html',
  styleUrls: ['./a-big-indicator.component.scss']
})
export class ABigIndicatorComponent implements OnInit {
    @ContentChild('content') content: ElementRef;
    @Input()
    tooltipLabelKey: string;

  constructor() { }

  ngOnInit(): void {
  }

}
