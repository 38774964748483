export class PasswordChange {
    oldPassword: string;
    newPassword: string;
    sendMail: boolean;

    constructor(oldPassword, newPassword, sendMail) {
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
        this.sendMail = sendMail;
    }
}
