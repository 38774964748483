import {Component, HostBinding, OnInit} from '@angular/core';
import {User} from '../../../users/models/user';
import {ActivatedRoute} from '@angular/router';
import {Result} from '../../../common/models/shared/result';
import {NavigationService} from '../../../common/services/navigation.service';
import {BillingDocumentType} from '../../../accounting/models/invoices/billing-document';
import {QuoteStatus} from '../../../accounting/models/invoices/quote';
import {InvoiceStatus} from '../../../accounting/models/invoices/invoice';

@Component({
    selector: 'boa-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    @HostBinding('attr.class') class = 'cell auto grid-y';

    public users: Array<User>;
    public nbConnections: number;

    public billingDocumentType = BillingDocumentType;
    public quoteStatus = QuoteStatus;
    public invoiceStatus = InvoiceStatus;

    constructor(route: ActivatedRoute, public navigationService: NavigationService) {
        route.data.subscribe((data: { users: Result<User> }) => {
            this.users = data.users.data;
            this.nbConnections = data.users.totalCount;
        });
    }

    ngOnInit(): void {
  }

}
