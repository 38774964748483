import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CustomerAccountService} from '../../../../customers/services/customer-account.service';
import {CustomerAccountForTokenDelivery} from '../../../models/invoices/billing-document';

@Component({
  selector: 'o-invoice-token-customers',
  templateUrl: './o-invoice-token-customers.component.html',
  styleUrls: ['./o-invoice-token-customers.component.scss']
})
export class OInvoiceTokenCustomersComponent {
  private alreadySelectedCustomerAccountIds = new Set<string>();
  @Output()
  tokenDeliveryCustomersTargetUpdated = new EventEmitter<CustomerAccountForTokenDelivery[]>();

  // tslint:disable-next-line:variable-name
  private _tokenDeliveryCustomersTarget: CustomerAccountForTokenDelivery[] = [];

  get tokenDeliveryCustomersTarget(): CustomerAccountForTokenDelivery[] {
    return this._tokenDeliveryCustomersTarget;
  }

  @Input()
  set tokenDeliveryCustomersTarget(tokenDeliveryCustomersTarget: CustomerAccountForTokenDelivery[]) {
    if (tokenDeliveryCustomersTarget) {
      this._tokenDeliveryCustomersTarget = tokenDeliveryCustomersTarget;
      const alreadySelectedCustomerAccountIds = new Set<string>();
      tokenDeliveryCustomersTarget.forEach(customerAccount => alreadySelectedCustomerAccountIds.add(customerAccount.id));
      this.alreadySelectedCustomerAccountIds = alreadySelectedCustomerAccountIds;
    }
  }

  // tslint:disable-next-line:variable-name
  private _allCustomersName: Array<{label: string, value: string}> = [];

  @Input()
  set allCustomersName(allCustomersName: Array<{label: string, value: string}>) {
    this._allCustomersName = allCustomersName;
    this.recomputeAvailableCustomersByName();
  }

  get allCustomersName(): Array<{ label: string; value: string }> {
    return this._allCustomersName;
  }

  // tslint:disable-next-line:variable-name
  private _allCustomersCode: Array<{label: string, value: string}> = [];

  @Input()
  set allCustomersCode(allCustomersCode: Array<{label: string, value: string}>) {
    this._allCustomersCode = allCustomersCode;
    this.recomputeAvailableCustomersByAccountantReference();
  }

  get allCustomersCode(): Array<{ label: string; value: string }> {
    return this._allCustomersCode;
  }

  @Input()
  set chargedCustomerId(chargedCustomerId: string) {
    if (chargedCustomerId) {
      const alreadySelectedCustomerAccountIds = new Set<string>();
      alreadySelectedCustomerAccountIds.add(chargedCustomerId);
      this.tokenDeliveryCustomersTarget.forEach(customer => alreadySelectedCustomerAccountIds.add(customer.id));
      this.alreadySelectedCustomerAccountIds = alreadySelectedCustomerAccountIds;
      this.recomputeAvailableCustomersByName();
      this.recomputeAvailableCustomersByAccountantReference();
    }
  }

  @Input()
  disabled = false;

  availableCustomersByName: Array<{label: string, value: string}>;
  availableCustomersByAccountingReference: Array<{label: string, value: string}>;

  customerIdBuffer = '';

  constructor(private readonly customerAccountService: CustomerAccountService) {
  }

  addCustomer(): void {
    this.customerAccountService.getCustomerAccountById(this.customerIdBuffer).subscribe(customerAccount => {
      this.tokenDeliveryCustomersTarget.push(customerAccount);
      this.emitTokenDeliveryCustomersTargetUpdate();
      this.alreadySelectedCustomerAccountIds.add(this.customerIdBuffer);
      this.recomputeAvailableCustomersByName();
      this.recomputeAvailableCustomersByAccountantReference();
      this.customerIdBuffer = '';
    });
  }

  private recomputeAvailableCustomersByName(): void {
    this.availableCustomersByName = this.allCustomersName.filter(customerIdByName =>
      !this.alreadySelectedCustomerAccountIds.has(customerIdByName.value)
    );
  }


  private recomputeAvailableCustomersByAccountantReference(): void {
    this.availableCustomersByAccountingReference = this.allCustomersCode.filter(customerIdByCode =>
      !this.alreadySelectedCustomerAccountIds.has(customerIdByCode.value)
    );
  }

  onDelete(index: number): void {
    const customerAccountId = this.tokenDeliveryCustomersTarget[index].id;
    this.tokenDeliveryCustomersTarget.splice(index, 1);
    this.emitTokenDeliveryCustomersTargetUpdate();
    this.alreadySelectedCustomerAccountIds.delete(customerAccountId);
    this.recomputeAvailableCustomersByAccountantReference();
    this.recomputeAvailableCustomersByName();
  }

  onUpdate(index: number, customerAccountId: string): void {
    this.customerAccountService.getCustomerAccountById(customerAccountId).subscribe(customerAccount => {
      this.tokenDeliveryCustomersTarget[index] = customerAccount;
      this.emitTokenDeliveryCustomersTargetUpdate();
    });
  }

  emitTokenDeliveryCustomersTargetUpdate(): void {
    this.tokenDeliveryCustomersTargetUpdated.emit(this.tokenDeliveryCustomersTarget);
  }
}
