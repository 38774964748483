import { Injectable } from '@angular/core';
import {InvoiceLine} from '../models/invoices/invoice-line';
import {ArticleSession} from '../models/article-session';

@Injectable()
export class InvoiceLineService {

  constructor() { }

    public interpolateDescription(invoiceLine: InvoiceLine, value: string): void {
        invoiceLine.description = this.replaceAtIndex(invoiceLine.description, invoiceLine.description.indexOf('?'), value);
    }

    private replaceAtIndex(source: string, srcIndex: number, toInsert: string): string {
        const splitted = source.substring(0, srcIndex) + ' ' + source.substring(srcIndex + 1);
        const result = splitted.split('');
        result[srcIndex] = toInsert;
        return result.join('');
    }

    public computeSessionTokenQuantity(session: ArticleSession, nbSession: number, nbSim: number, nbTeam: number): number {
        return nbSession * (session.creationTokenCost + (nbTeam * nbSim * session.periodTokenCost));
    }

    public calculateGross(invoiceLine: InvoiceLine, quantity: number, unitCost: number, vatRate: number): void {
        if (quantity) { invoiceLine.quantity = +quantity; }
        if (unitCost) { invoiceLine.unitCost.value = +unitCost; }
        invoiceLine.grossAmount.value = invoiceLine.quantity * invoiceLine.unitCost.value;
        this.calculateNet(invoiceLine, vatRate);
    }

    public calculateNet(invoiceLine: InvoiceLine, vatRate: number): void {
        invoiceLine.vatRate.rate = vatRate * 100;
        invoiceLine.vatAmount.value = invoiceLine.grossAmount.value * vatRate;
        invoiceLine.netAmount.value = invoiceLine.grossAmount.value + invoiceLine.vatAmount.value;
    }

}
