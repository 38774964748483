import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {ASearchComponent} from './a-search/a-search.component';
import {AInputComponent} from './a-input/a-input.component';
import {ASelectComponent} from './a-select/a-select.component';
import {ACheckboxComponent} from './a-checkbox/a-checkbox.component';
import {ATextareaComponent} from './a-textarea/a-textarea.component';
import {ADatePickerComponent} from './a-date-picker/a-date-picker.component';
import {DpDatePickerModule} from 'ng2-date-picker';
import {APasswordComponent} from './a-password/a-password.component';
import {ATokenInputComponent} from './a-token-input/a-token-input.component';
import {UserDirectivesModule} from '../../../../newboa/users/directives/user-directives.module';
import {AInputDiscountComponent} from './a-input-discount/a-input-discount.component';
import {AInputLoginComponent} from './a-input-login/a-input-login.component';
import {DirectivesModule} from '../../../directives/directives.module';

const components = [
    ACheckboxComponent,
    AInputComponent,
    APasswordComponent,
    ASearchComponent,
    ASelectComponent,
    ATextareaComponent,
    ATokenInputComponent,
    ADatePickerComponent,
    AInputDiscountComponent,
    AInputLoginComponent,
];

@NgModule({
    imports: [
        CommonModule,
        DpDatePickerModule,
        FormsModule,
        TranslateModule.forChild(),
        NgSelectModule,
        UserDirectivesModule,
        DirectivesModule
    ],
    declarations: [
        components,
    ],
    exports: [
        components,
    ]
})

export class AFormsModule {
}
