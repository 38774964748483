import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CustomerAccount} from '../../../models/customer-account';
import {CustomerAccountContact} from '../../../../users/models/contact';
import {AddressType} from '../../../../common/models/address/addressType';

@Component({
  selector: 'm-customer-billing-contact',
  templateUrl: './m-customer-billing-contact.component.html',
  styleUrls: ['./m-customer-billing-contact.component.scss']
})
export class MCustomerBillingContactComponent implements OnChanges {

    @Input()
    customerAccount: CustomerAccount;

    billingContact: CustomerAccountContact;

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerAccount) {
            this.billingContact = this.customerAccount.getContact(AddressType.BILLING);
        }
    }

}
