import {Customer} from './customer';
import {plainToClass, Type} from 'class-transformer';
import {Vendor} from './vendor';
import {CurrencyEnum} from './currency-enum';
import {InvoiceLine} from './invoice-line';
import {Amount} from './amount';
import {PaymentStatusEnum} from './payment-status-enum';
import {
    BillingDocument, BillingDocumentAdditionalInformation,
    BillingDocumentMetadata,
    BillingDocumentType, buildFileNameSuffix,
    ExtendedBillingDocument
} from './billing-document';
import {QuoteStatus} from './quote';
import {Credit} from './credit';
import {CustomerAccount} from '../../../customers/models/customer-account';
import {ChorusProCustomerSettings} from '../../../customers/models/chorus-pro-settings';

export class Invoice implements BillingDocument {
    @Type(() => Date)
    dueDate: Date;
    paymentStatusEnum: PaymentStatusEnum;
    invoiceStatus: InvoiceStatus;

    id: string;
    reference: string;
    @Type(() => Date)
    date: Date;
    @Type(() => Customer)
    customer: Customer;
    @Type(() => Vendor)
    vendor: Vendor;
    subject: string;
    @Type(() => InvoiceLine)
    tokensArticlesLines: InvoiceLine[];
    @Type(() => InvoiceLine)
    articlesLines: InvoiceLine[];
    @Type(() => Amount)
    vatsAmount: Amount[];
    @Type(() => Amount)
    grossAmount: Amount;
    @Type(() => Amount)
    netAmount: Amount;
    @Type(() => Amount)
    vatAmount: Amount;
    @Type(() => Amount)
    discountAmount: Amount;
    @Type(() => BillingDocumentMetadata)
    metadata: BillingDocumentMetadata;
    detail: string;
    legalNotice: string;
    withVAT: boolean;
    currency: CurrencyEnum;
    showLegalNoticeForeignCustomer: boolean;
    billingDocumentType: BillingDocumentType;
    grossAmountAfterDiscount: Amount;
    trustValues: boolean;
    billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation;
    chorusProSendFailError: string;

    constructor(id: string,
                reference: string,
                customer: Customer,
                vendor: Vendor,
                date: Date,
                dueDate: Date,
                subject: string,
                currency: CurrencyEnum,
                withVat: boolean,
                tokensArticlesLines: Array<InvoiceLine>,
                articlesLines: Array<InvoiceLine>,
                grossAmount: Amount,
                netAmount: Amount,
                vatAmount: Amount,
                vatsAmount: Array<Amount>,
                discountAmount: Amount,
                detail: string,
                legalNotice: string,
                showLegalNoticeForeignCustomer: boolean,
                metadata: BillingDocumentMetadata = BillingDocumentMetadata.empty(),
                paymentStatusEnum: PaymentStatusEnum,
                invoiceStatus: InvoiceStatus,
                grossAmountAfterDiscount: Amount,
                trustValues: boolean,
                billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation,
                chorusProSendFailError: string) {
        this.id = id;
        this.reference = reference;
        this.customer = customer;
        this.vendor = vendor;
        this.date = date;
        this.dueDate = dueDate;
        this.subject = subject;
        this.currency = currency;
        this.withVAT = withVat;
        this.tokensArticlesLines = tokensArticlesLines;
        this.articlesLines = articlesLines;
        this.grossAmount = grossAmount;
        this.netAmount = netAmount;
        this.vatAmount = vatAmount;
        this.vatsAmount = vatsAmount;
        this.discountAmount = discountAmount;
        this.detail = detail;
        this.legalNotice = legalNotice;
        this.showLegalNoticeForeignCustomer = showLegalNoticeForeignCustomer;
        this.metadata = metadata;
        this.paymentStatusEnum = paymentStatusEnum;
        this.invoiceStatus = invoiceStatus;
        this.grossAmountAfterDiscount = grossAmountAfterDiscount;
        this.trustValues = trustValues;
        this.billingDocumentAdditionalInformation = billingDocumentAdditionalInformation;
        this.chorusProSendFailError = chorusProSendFailError;
        this.billingDocumentType = BillingDocumentType.INVOICE;
    }

    static empty(): Invoice {
        const dateInAMonth = new Date();
        dateInAMonth.setMonth(dateInAMonth.getMonth() + 2);
        return new Invoice(
            undefined,
            '',
            Customer.empty(),
            Vendor.empty(),
            new Date(),
            dateInAMonth,
            '',
            CurrencyEnum.EUR,
            true,
            [],
            [],
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            [],
            Amount.empty(),
            '',
            '',
            false,
            BillingDocumentMetadata.empty(),
            undefined,
            undefined,
            Amount.empty(),
            false,
            BillingDocumentAdditionalInformation.empty(),
            null
        );
    }

    getStatus(): QuoteStatus | InvoiceStatus {
        return this.invoiceStatus;
    }

    isInProgress(): boolean {
        return (this.invoiceStatus === InvoiceStatus.IN_PROGRESS) || (this.invoiceStatus === undefined);
    }

    isStrictlyInProgress(): boolean {
        return (this.invoiceStatus === InvoiceStatus.IN_PROGRESS);
    }

    getPdfLink(): string {
        return this.isDraft()
            ? `/api/invoice/invoices/drafts/print/${this.id}`
            : `/api/invoice/invoices/print/${this.id}`;
    }

    isDraft(): boolean {
        return this.invoiceStatus === InvoiceStatus.IN_PROGRESS;
    }

    getFileName(): string {
        return `Facture ${buildFileNameSuffix(this)}`;
    }

    getType(): BillingDocumentType {
        return BillingDocumentType.INVOICE;
    }

    getReference(): string {
        return this.reference;
    }

    getDetailLink(): string {
        return this.isDraft() ? `invoices/drafts/${this.id}`: `invoices/${this.id}`;
    }

    duplicate(): Invoice {
        const copy = plainToClass(Invoice, {... this});
        copy.invoiceStatus = undefined;
        copy.id = undefined;
        copy.paymentStatusEnum = undefined;
        copy.trustValues = false;
        copy.chorusProSendFailError = undefined;
        const now = new Date();
        copy.date = now;
        const dueDate = new Date(now);
        dueDate.setMonth(now.getMonth() + 2);
        copy.dueDate = dueDate;
        return copy;
    }

    toCredit(creditNoteReference: string): Credit {
        return new Credit(
            BillingDocumentType.CREDIT,
            this.currency,
            this.customer,
            new Date(),
            this.detail,
            this.discountAmount,
            this.grossAmount,
            null,
            this.legalNotice,
            this.tokensArticlesLines,
            this.articlesLines,
            this.metadata,
            this.netAmount,
            creditNoteReference,
            this.showLegalNoticeForeignCustomer,
            this.subject,
            this.vatAmount,
            this.vatsAmount,
            this.vendor,
            this.withVAT,
            this.id,
            '',
            this.grossAmountAfterDiscount,
            this.trustValues,
            this.billingDocumentAdditionalInformation
        );
    }

    isChorusProServiceMandatory(chorusProSettings: ChorusProCustomerSettings): boolean {
        return this.billingDocumentType === 'INVOICE' &&
            this.isInProgress() &&
            chorusProSettings &&
            chorusProSettings.structureIdMandatory;
    }
}

export class ExtendedInvoice extends Invoice implements ExtendedBillingDocument {
    deliveredTokens: number;
    isDelivered: boolean;
    toDeliverTokens: number;

    constructor(id: string,
                reference: string,
                customer: Customer,
                vendor: Vendor,
                date: Date,
                dueDate: Date,
                subject: string,
                currency: CurrencyEnum,
                withVat: boolean,
                tokensArticlesLines: Array<InvoiceLine>,
                articlesLines: Array<InvoiceLine>,
                grossAmount: Amount,
                netAmount: Amount,
                vatAmount: Amount,
                vatsAmount: Array<Amount>,
                discountAmount: Amount,
                detail: string,
                legalNotice: string,
                showLegalNoticeForeignCustomer: boolean,
                metadata: BillingDocumentMetadata,
                paymentStatusEnum: PaymentStatusEnum,
                invoiceStatus: InvoiceStatus,
                deliveredTokens: number,
                isDelivered: boolean,
                toDeliverTokens: number,
                grossAmountAfterDiscount: Amount,
                trustValues: boolean,
                billingDocumentAdditionalInformation: BillingDocumentAdditionalInformation,
                chorusProSendFailError: string) {
        super(id,
            reference,
            customer,
            vendor,
            date,
            dueDate,
            subject,
            currency,
            withVat,
            tokensArticlesLines,
            articlesLines,
            grossAmount,
            netAmount,
            vatAmount,
            vatsAmount,
            discountAmount,
            detail,
            legalNotice,
            showLegalNoticeForeignCustomer,
            metadata,
            paymentStatusEnum,
            invoiceStatus,
            grossAmountAfterDiscount,
            trustValues,
            billingDocumentAdditionalInformation,
            chorusProSendFailError
          );
        this.deliveredTokens = deliveredTokens;
        this.isDelivered = isDelivered;
        this.toDeliverTokens = toDeliverTokens;
    }

    static empty(): ExtendedInvoice {
        const dateInAMonth = new Date();
        dateInAMonth.setMonth(dateInAMonth.getMonth() + 1);
        return new ExtendedInvoice(
            undefined,
            '',
            Customer.empty(),
            Vendor.empty(),
            new Date(),
            dateInAMonth,
            '',
            CurrencyEnum.EUR,
            false,
            [],
            [],
            Amount.empty(),
            Amount.empty(),
            Amount.empty(),
            [],
            Amount.empty(),
            '',
            '',
            false,
            BillingDocumentMetadata.empty(),
            undefined,
            undefined,
            0,
            false,
            0,
            Amount.empty(),
            false,
            BillingDocumentAdditionalInformation.empty(),
            null
        );
    }
}

export enum InvoiceStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    DELETED = 'DELETED',
    CUSTOMER_SENT = 'CUSTOMER_SENT',
    CHORUS_SENT = 'CHORUS_SENT',
    PARTLY_PAID = 'PARTLY_PAID',
    CHORUS_REFUSED = 'CHORUS_REFUSED',
    PAID = 'PAID',
    OVERDUE = 'OVERDUE',
    UNPAID = 'UNPAID',
    CANCELED = 'CANCELED'
}
