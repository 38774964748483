<h3 class="billingContact-title">{{'m-customer-billing-contact.title' | translate}}</h3>
<span class="billingContact-name">{{billingContact.firstName | boaNotNull : 'm-customer-billing-contact.no-name' }} {{billingContact.lastName | boaNotNull}}</span> -
<span class="billingContact-role">{{billingContact.service | boaNotNull : 'm-customer-billing-contact.no-service'}}</span>

<spacer size="16"></spacer>

<a-field [label]="'Mail'">
    <i class="fas fa-envelope"></i>
    <ng-container value>
        {{billingContact.mail | boaNotNull : 'm-customer-billing-contact.no-mail'}}
    </ng-container>
</a-field>

<a-field [label]="'Téléphone'">
    <i class="fas fa-phone-alt"></i>
    <ng-container value>
        {{billingContact.mobilePhone | boaNotNull : 'm-customer-billing-contact.no-phone'}}
    </ng-container>
</a-field>
