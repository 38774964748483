<ng-container *ngIf="display === 'display'">
    <div class="grid-x align-middle align-justify">
        <m-card-header class="cell shrink" [label]="headerLabel" [userType]="styleType"></m-card-header>
        <div class="cell shrink grid-x align-middle grid-margin-x">
            <ng-content select="[button]"></ng-content>
            <a-button-edit class="cell shrink" (click)="onEditClick()"></a-button-edit>
        </div>
    </div>

    <spacer [size]="16"></spacer>

    <ng-content select="[display]"></ng-content>
</ng-container>

<form #form="ngForm">
    <ng-container *ngIf="display === 'edit'">
        <m-card-header [label]="headerLabel" [userType]="styleType" class="cell shrink">
            <div class="cell shrink grid-x grid-margin-x" button>
                <a-button-cancel class="cell shrink" (click)="onCancelClick()">{{'common.action.cancel' | translate}}</a-button-cancel>
                <a-button-default [disabled]="form.invalid || form.pristine || validateDisabled" class="cell shrink"
                                 [loading]="updateLoading"
                                 (click)="onSaveClick()">{{'common.action.save' | translate}}</a-button-default>
            </div>
        </m-card-header>
        <spacer size="16"></spacer>
        <ng-content select="[edit]"></ng-content>
    </ng-container>
    <ng-container *ngIf="display === 'creation'">
        <m-card-header [label]="headerLabel" [userType]="styleType" class="cell shrink">
            <div class="cell shrink grid-x grid-margin-x" button>
                <a-button-default [disabled]="(form.pristine ? true : form.pristine) || validateDisabled" class="cell shrink"
                                 [visibility]="'HIGH'"
                                 [loading]="creationLoading"
                                 (click)="onSaveClick()">{{'common.action.save' | translate}}</a-button-default>
            </div>
        </m-card-header>
        <spacer size="16"></spacer>
        <ng-content select="[creation]"></ng-content>
    </ng-container>
</form>
