import {Component, ElementRef, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'a-password',
    templateUrl: './a-password.component.html',
    styleUrls: [
        './../forms.scss',
        './a-password.component.scss'
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => APasswordComponent),
            multi: true
        }
    ]
})
export class APasswordComponent {

    @Input()
    public name: string;

    @Input()
    public inputType = 'text';

    @Input()
    public label: string;

    @Input()
    public placeholder = '';

    @Input()
    public required: boolean;

    @Input()
    public disabled: boolean;

    @Input()
    public delete: boolean;

    @Input()
    public info: string;

    @Input()
    public min: number;

    @Input()
    public max: number;

    public data: string;
    @Output()
    public deleted: EventEmitter<void> = new EventEmitter<void>();
    @Output()
    public blur: EventEmitter<string> = new EventEmitter<string>();
    @ViewChild('input')
    public inputField: ElementRef;
    private onNgChange: (value: string) => void;

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(obj: string): void {
        this.data = obj;
    }

    public onChange(data: string): void {
        this.data = data;
        this.onNgChange(data);
    }

    public onBlur(): void {
        this.blur.next(this.data);
    }

    public onDelete(): void {
        this.deleted.emit();
    }

    private onNgTouched: () => void = () => {
    }
}
