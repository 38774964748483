import {Component, EventEmitter, Input, Output} from '@angular/core';
import {fromRole, fromSize, fromVisibility, Role, Size, Visibility} from '../buttons-utils';
import {Router} from '@angular/router';

@Component({
  selector: 'a-split-button',
  templateUrl: './a-split-button.component.html',
  styleUrls: [
      './../buttons.scss',
      './a-split-button.component.scss'
  ]
})
export class ASplitButtonComponent {

    @Input()
    public disabled = false;

    @Input()
    public validate: boolean;

    @Input()
    public visibility: Visibility = 'MEDIUM';

    @Input()
    public size: Size = 'DEFAULT';

    @Input()
    public role: Role = 'DEFAULT';

    @Input()
    public buttonsList: Array<{
        label: string;
        icon: string;
        cssClass: string;
    }>;

    @Input()
    public loading = false;

    @Output()
    public clicked: EventEmitter<MouseEvent> = new EventEmitter();

    @Output()
    public listButtonClicked = new EventEmitter<number>();

    public fromVisibility = fromVisibility;
    public fromSize = fromSize;
    public fromRole = fromRole;
    public listOpened = false;

    constructor() {
    }

    public toggleListOpened(): void {
        if (!this.disabled) {
            this.listOpened = !this.listOpened;
        }
    }


    public doAction(event: MouseEvent): void {
        event.stopPropagation();
        if (!this.loading && !this.disabled) {
            this.clicked.emit(event);
        }
    }

    onListButtonClicked(buttonId: number): void {
        this.listButtonClicked.emit(buttonId);
    }
}
