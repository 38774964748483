<div class="article" *ngIf="!isEdit else editionBlock">
    <div class="grid-x align-middle align-justify articleHeader">
        <h2 class="cell auto articleHeader-title">{{'accounting.article.action.new' | translate}}</h2>
    </div>
    <ng-template [ngTemplateOutlet]="contentForm"></ng-template>
</div>

<ng-template #editionBlock>
    <div class="article">
        <div class="grid-x align-middle articleHeader">
            <div class="cell shrink">
                <span class="articleHeader-icon" [ngClass]="'_' + article?.type.toLowerCase()"></span>
            </div>
            <div class="cell shrink">
                <h2 class="articleHeader-title">{{this.article?.type}}</h2>
            </div>
        </div>
        <ng-template [ngTemplateOutlet]="contentForm"></ng-template>
    </div>
</ng-template>

<ng-template #contentForm>
    <form #articleForm="ngForm">
        <div class="grid-x grid-margin-y grid-margin-x">
            <a-select *ngIf="!isEdit" class="cell phone-24"
                      [name]="'type'"
                      [required]="true"
                      [bindLabel]="'label'"
                      [bindValue]="'value'"
                      [itemList]="allTypes"
                      [(ngModel)]="article.type"
                      [label]="'accounting.article.type'|translate"
                      (ngModelChange)="onTypeChange($event)">
            </a-select>
            <a-select class="cell phone-24"
                      [name]="'operationType'"
                      [required]="true"
                      [bindLabel]="'label'"
                      [bindValue]="'value'"
                      [itemList]="allOperationTypes"
                      [(ngModel)]="article.operationType"
                      [label]="'accounting.article.operation.type'|translate">
            </a-select>
            <a-input class="cell phone-24"
                     [name]="'title'"
                     [required]="true"
                     [inputType]="'text'"
                     [label]="'accounting.article.creation.title' | translate"
                     [(ngModel)]="article.title">
            </a-input>
            <a-textarea class="cell phone-24"
                        [name]="'description'"
                        [required]="true"
                        [label]="'accounting.article.description'|translate"
                        [(ngModel)]="article.description">
            </a-textarea>
            <ng-template [ngIf]="isSession">
                <a-input class="cell phone-24 tablet-landscape-12"
                         [name]="'creationCost'"
                         [required]="true"
                         [label]="'accounting.article.creation'|translate"
                         [inputType]="'number'"
                         [(ngModel)]="creationTokenCost">
                </a-input>
                <a-input class="cell phone-24 tablet-landscape-12"
                         [name]="'periodCost'"
                         [required]="true"
                         [label]="'accounting.article.simulation'|translate"
                         [inputType]="'number'"
                         [(ngModel)]="periodTokenCost">
                </a-input>
            </ng-template>
            <a-input class="cell phone-24"
                     [name]="'unitPrice'"
                     [required]="true"
                     [label]="'accounting.article.unit.price'|translate"
                     [inputType]="'number'"
                     [(ngModel)]="article.unitaryCost">
            </a-input>
        </div>

        <spacer size="24"></spacer>

        <div class="grid-x grid-margin-x align-center">
            <a-button-cancel (click)="cancel()" class="cell shrink">
                {{'accounting.article.action.cancel' | translate}}
            </a-button-cancel>
            <a-button-default class="cell shrink"
                              [visibility]="'HIGH'"
                              (click)="save()"
                              [disabled]="articleForm.invalid">
                {{'common.action.save' | translate}}
            </a-button-default>
        </div>
    </form>
</ng-template>
