import {Params} from '@angular/router';

export class MenuItem {

    iconPath: string;
    target: string;
    label: string;
    active: boolean;
    queries: Params;

    constructor(imageUrl: string, target: string, label: string, queries: Params = {}) {
        this.iconPath = imageUrl;
        this.target = target;
        this.label = label;
        this.queries = queries;
    }
}
