import {Component, Input, OnInit} from '@angular/core';
import {CustomerAccount} from '../../../../customers/models/customer-account';

@Component({
  selector: 'o-customer-information',
  templateUrl: './o-customer-information.component.html',
  styleUrls: ['./o-customer-information.component.scss']
})
export class OCustomerInformationComponent implements OnInit {

    @Input()
    customer: CustomerAccount;

    constructor() { }

    ngOnInit(): void {
    }
}
