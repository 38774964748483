import {Component, Input} from '@angular/core';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'spacer',
    templateUrl: './spacer.component.html',
    styleUrls: ['./spacer.component.scss']
})
export class SpacerComponent {

    @Input()
    public size: number;
}
