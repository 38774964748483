import {Component, Input, OnInit, Output} from '@angular/core';
import {EventEmitter} from '@angular/core';

@Component({
    selector: 'm-invoice-articles-tokens-total',
    templateUrl: './m-invoice-articles-tokens-total.component.html',
    styleUrls: ['./m-invoice-articles-tokens-total.component.scss'],
    host: {class: 'grid-y'}
})
export class MInvoiceArticlesTokensTotalComponent {
    @Input()
    disabled = false;

    @Input()
    set tokenGrossTotal(newTokenGrossTotal: number) {
        this._tokenGrossTotal = newTokenGrossTotal ? newTokenGrossTotal : 0;
    }
    // tslint:disable-next-line:variable-name
    _tokenGrossTotal: number;

    @Input()
    set discountTotal(newDiscountTotal: number) {
        this._discountTotal = newDiscountTotal ? newDiscountTotal : 0;
    }
    // tslint:disable-next-line:variable-name
    _discountTotal: number;

    @Input()
    set discountPercentage(newDiscountPercentage: number) {
        this._discountPercentage = newDiscountPercentage ? newDiscountPercentage : 0;
    }
    // tslint:disable-next-line:variable-name
    _discountPercentage: number;

    @Output()
    discountPercentageChange = new EventEmitter<number>();

    @Output()
    private resetClick = new EventEmitter<void>();

    onTokenDiscountPercentageChange(newPercentage: number): void {
        this.discountPercentage = newPercentage / 100;
        this.discountPercentageChange.emit(this._discountPercentage);
    }

    onResetTokenPercentage(): void {
        this.resetClick.emit();
    }
}
