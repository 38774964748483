<m-invoice-articles-list class="cell auto"
                         (lineDeleted)="onLineDeleted($event)"
                         (lineAdded)="onLineAdded($event)"
                         (lineEdited)="onLineEdited($event)"
                         [vatRate]="vatRate"
                         (dirty)="dirty.emit()"
                         [showTokenSessionHeaders]="true"
                         [articlesTypes]="[articleTypes.SESSION, articleTypes.TOKEN]"
                         [lines]="billingDocument.tokensArticlesLines"
                         [areLinesReadonly]="areLinesReadonly()"></m-invoice-articles-list>

<m-invoice-articles-tokens-total class="cell shrink"
                                 (resetClick)="onResetClick()"
                                 [disabled]="!billingDocument.isInProgress()"
                                 [discountPercentage]="discountPercentage"
                                 (discountPercentageChange)="onDiscountPercentageChange($event)"
                                 [tokenGrossTotal]="tokenGrossTotal"
                                 [discountTotal]="discountTotal"></m-invoice-articles-tokens-total>
