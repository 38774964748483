import { Component, OnInit } from '@angular/core';
import {QuotesService} from '../../services/quotes.service';
import {Quote} from '../../models/invoices/quote';

@Component({
  selector: 'boa-quote-list',
  templateUrl: './quote-list.component.html',
  styleUrls: ['./quote-list.component.scss']
})
export class QuoteListComponent implements OnInit {

    quotes: Array<Quote> = [];

    constructor(private quoteService: QuotesService) { }

    ngOnInit(): void {
        this.quoteService.getAllQuotes().subscribe(quotes => {
            this.quotes = quotes;
        });
    }
}
