export const DEFAULT_VAT_RATE_VALUE = 0.2;

export class VatRate {
    label: string;
    rate: number;

    constructor(label: string, rate: number) {
        this.label = label;
        this.rate = rate;
    }

    static empty(): VatRate {
        return new VatRate('', 0);
    }
}
