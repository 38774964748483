import {Component, OnInit} from '@angular/core';
import {DownloadService} from '../../../../common/services/download.service';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceHttpService} from '../../../services/invoice-http.service';

@Component({
    selector: 'm-accounting-export',
    templateUrl: './m-accounting-export.component.html',
    styleUrls: ['./m-accounting-export.component.scss']
})
export class MAccountingExportComponent implements OnInit {

    year = new Date().getFullYear();
    month = new Date().getMonth();

    constructor(private readonly invoiceService: InvoiceHttpService,
                private downloadService: DownloadService,
                private translateService: TranslateService) {
    }

    ngOnInit(): void {
    }

    public exportSales(): void {
        const periodAsString = `${this.year}-${this.month < 10 ? '0' + this.month : this.month}`;
        this.downloadService.downloadFileWithParams(
            'api/accounting/exports/sales',
            `${this.translateService.instant('common.sales')}-${periodAsString}`,
            'xlsx',
            {
                period: periodAsString
            }
        );
    }
}
