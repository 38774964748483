import {AfterContentInit, Component, HostBinding, Input} from '@angular/core';
import {AnimatorType} from '../../../../users/models/user';
import {FullUser} from '../../../../users/models/full-user';
import {ActivatedRoute, Router} from '@angular/router';
import {Animator} from '../../../../users/models/animator';
import {AnimatorAccountService} from '../../../../animatorAccounts/services/animator-account.service';
import {ToastrService} from 'ngx-toastr';
import {Person} from '../../../../users/models/person';
import {TranslateService} from '@ngx-translate/core';
import {StringUtils} from '../../../../../shared/string-utils';

@Component({
  selector: 'o-last-demonstration-animator',
  templateUrl: './o-last-demonstration-animator.component.html',
  styleUrls: ['./o-last-demonstration-animator.component.scss']
})
export class OLastDemonstrationAnimatorComponent implements AfterContentInit {

    animatorType = AnimatorType;

    @HostBinding('class') get class(): string {
        return this.creationMode ? '_editionMode' : '';
    }

    @Input()
    lastAnimator: FullUser = FullUser.empty();

    @Input()
    customerAccountRef: string;

    demonstrationAnimatorBuffer: Animator;
    creationMode = false;
    validateCreationLoading = false;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private notificationService: ToastrService,
                private translateService: TranslateService,
                private animatorAccountService: AnimatorAccountService) { }

    seeAllAnimators(): void {
        this.router.navigate(['/boa/users'], {
            queryParams: {
                roles: [this.animatorType.DEMONSTRATION],
                customerAccountRef: this.customerAccountRef
            }
        });
    }

    refresh(): void {
        this.router.navigate([], {
            relativeTo: this.activatedRoute
        });
    }

    createNewAnimator(): void {
        this.creationMode = true;
    }

    ngAfterContentInit(): void {
        this.demonstrationAnimatorBuffer = new Animator('animator');
        this.demonstrationAnimatorBuffer.person = Person.empty();
    }

    onCancelCreation(): void {
        this.creationMode = false;
        this.demonstrationAnimatorBuffer = new Animator('animator');
    }

    onValidateCreation(): void {
        this.creationMode = false;
        this.validateCreationLoading = true;
        this.demonstrationAnimatorBuffer.animatorType = AnimatorType.DEMONSTRATION;
        this.animatorAccountService.createDemonstrationAnimator(this.demonstrationAnimatorBuffer, this.customerAccountRef)
            .subscribe(_ => {
                this.validateCreationLoading = false;
                this.demonstrationAnimatorBuffer = new Animator('animator');
                this.notificationService.success(this.translateService.instant('common.success'));
                this.refresh();
            }, err => {
                this.validateCreationLoading = false;
                this.demonstrationAnimatorBuffer = new Animator('animator');
                this.notificationService.error(this.translateService.instant('common.error'));
            });
    }

    nameChanged(): void {
        if (!this.demonstrationAnimatorBuffer.login) {
            const firstName = StringUtils.normalize(this.demonstrationAnimatorBuffer.person.firstName);
            const lastName = StringUtils.normalize(this.demonstrationAnimatorBuffer.person.lastName);
            this.demonstrationAnimatorBuffer.login = `demo.${firstName}.${lastName}`;
        }
    }
}
