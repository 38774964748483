import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {BoaNavigationEvent, BoaNavigationEventType} from '../models/shared/boa-navigation-event';

@Injectable()
export class ContextService {

    private NONE = new BoaNavigationEvent(BoaNavigationEventType.NONE, null);

    public contextEvent = new BehaviorSubject<BoaNavigationEvent>(this.NONE);
    public unreadNotesCount$ = new BehaviorSubject<number>(0);

    constructor(private router: Router) {
        this.router.events.subscribe( event => {
            if (event instanceof NavigationEnd) {
                const boaNavigationEvent = this.buildBoaNavigationEvent(event.url.split('/'));
                if (this.contextEvent.getValue() !== boaNavigationEvent) {
                    this.contextEvent.next(boaNavigationEvent);
                }
            }
        });
    }

    private buildBoaNavigationEvent(url: string[]): BoaNavigationEvent {
        if (url.length > 2 && url[2] === 'users') {
            return new BoaNavigationEvent(BoaNavigationEventType.USER, url.reverse()[0]);
        }

        if (url.length > 2 && url[2] === 'customers') {
            return new BoaNavigationEvent(BoaNavigationEventType.CUSTOMER, url.reverse()[0]);
        }

        if (url.length > 4 && url[2] === 'accounting' && url.reverse()[0] !== 'new') {
            return new BoaNavigationEvent(BoaNavigationEventType.DOCUMENT, url[0]);
        }

        return this.NONE;
    }
}
