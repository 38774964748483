import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HomeComponent} from './views/home/home.component';
import {SharedModule} from '../../shared/shared.module';
import {ButtonsModule} from '../../shared/components/atoms/buttons/buttons.module';
import {FormsModule} from '@angular/forms';
import {OHomeStatsComponent} from './components/organisms/o-home-stats/o-home-stats.component';
// tslint:disable-next-line:max-line-length
import {OScenarioInformationComponent} from './components/organisms/o-games-informations/o-scenario-information.component';
import {MScenarioDetailComponent} from './components/molecules/m-game-detail/m-scenario-detail.component';
import {TranslateModule} from '@ngx-translate/core';
import {OperationsModule} from '../operations/operations.module';
import {AccountingModule} from '../accounting/accounting.module';

@NgModule({
    declarations: [
        HomeComponent,
        MScenarioDetailComponent,
        OHomeStatsComponent,
        OScenarioInformationComponent,
    ],
    exports: [
        HomeComponent
    ],
  imports: [
      AccountingModule,
      ButtonsModule,
      CommonModule,
      FormsModule,
      OperationsModule,
      SharedModule,
      TranslateModule.forChild(),
  ]
})
export class HomeModule {
}
