import {AddressType} from './addressType';
import {BaseObject} from '../../base-object';


export class Address extends BaseObject {
    constructor(
        public name: string,
        public line1: string,
        public line2: string,
        public postalCode: string,
        public city: string,
        public country: string = 'FRANCE',
        public type: AddressType = AddressType.BILLING
    ) {
        super();
    }

    static empty(addressType: AddressType): Address {
        return new Address('', '', '', '', '', '', addressType);
    }
}
