import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {GenericSession} from '../../genericSessions/models/generic-session';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    private baseUrl = '/api/teams';

    constructor(private http: HttpClient) { }


    public getSessionFromTeam(teamId: string): Observable<GenericSession> {
        return this.http.get(`${this.baseUrl}/${teamId}/currentSession`).pipe(
            map(el => plainToClass(GenericSession, el as object))
        );
    }
}
