export class BoaNavigationEvent {
    public type: BoaNavigationEventType;
    public data: any;

    constructor(type: BoaNavigationEventType, data: any) {
        this.type = type;
        this.data = data;
    }
}

export enum BoaNavigationEventType {
    CUSTOMER = 'CUSTOMER_REF',
    USER = 'USER_REF',
    NONE = 'NONE',
    DOCUMENT = 'DOCUMENT',
}
