import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountantCode, Article, ArticleOperationType, ArticleType} from '../../../models/article';
import {ArticleSession} from '../../../models/article-session';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'm-article-edit',
    templateUrl: './m-article-edit.component.html',
    styleUrls: ['./m-article-edit.component.scss']
})
export class MArticleEditComponent implements OnInit {

    @Input()
    public article: Article = null;

    @Input()
    public loading = false;

    @Output()
    public articleSaved = new EventEmitter<Article>();

    @Output()
    public canceled = new EventEmitter<void>();

    public isEdit: boolean;
    public isSession: boolean;
    public periodTokenCost: number;
    public creationTokenCost: number;
    public allTypes = [
        {label: this.translateService.instant(ArticleType.TOKEN), value: ArticleType.TOKEN},
        {label: this.translateService.instant(ArticleType.WORKS), value: ArticleType.WORKS},
        {label: this.translateService.instant(ArticleType.ANIMATION), value: ArticleType.ANIMATION},
        {label: this.translateService.instant(ArticleType.FEE), value: ArticleType.FEE},
        {label: this.translateService.instant(ArticleType.SESSION), value: ArticleType.SESSION}
    ];
    public allOperationTypes = [
        {label: this.translateService.instant('article.operation.type.' + ArticleOperationType.GOODS), value: ArticleOperationType.GOODS},
        {label: this.translateService.instant('article.operation.type.' + ArticleOperationType.SERVICES), value: ArticleOperationType.SERVICES},
    ];

    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.isEdit = this.article !== null;
        this.isSession = this.article ? this.article.type === ArticleType.SESSION : false;
        if (this.isSession) {
            this.periodTokenCost = (this.article as ArticleSession).periodTokenCost;
            this.creationTokenCost = (this.article as ArticleSession).creationTokenCost;
        }
        if (!this.isEdit) {
            this.article = Article.empty();
        }
    }

    save(): void {
        if (this.isSession) {
            (this.article as ArticleSession).creationTokenCost = this.creationTokenCost;
            (this.article as ArticleSession).periodTokenCost = this.periodTokenCost;
        }
        if (!this.isEdit) {
            this.article.accountantCode = AccountantCode[this.article.type];
        }
        this.articleSaved.emit(this.article);
    }

    onTypeChange(newValue: ArticleType): void {
        this.isSession = newValue === ArticleType.SESSION;
    }

    cancel(): void {
        this.canceled.emit();
    }
}
