import {Component, Input, OnInit} from '@angular/core';
import {CustomerAccountTypes} from '../../../../newboa/customers/models/customer-account-types';
import {CustomerStatus} from '../../../../newboa/customers/models/customer-status';

@Component({
    selector: 'a-account-type',
    templateUrl: './a-account-type.component.html',
    styleUrls: ['./a-account-type.component.scss']
})
export class AAccountTypeComponent implements OnInit {

    // 'établissement'
    @Input()
    type: CustomerAccountTypes;

    // type (prospect or customer)
    @Input()
    customerStatus: CustomerStatus;

    constructor() { }

    ngOnInit(): void {
    }

}
