import {TypeEntry} from './type-entry';

export class TypeList {

    public types: Array<TypeEntry>;

    constructor(types: Array<TypeEntry>) {
        this.types  = types;
    }

    public getSelectedValues(): Array<string> {
        return [].concat.apply([], this.types.filter(el => el.selected).map(el => el.value.split(',')));
    }

    public isAllSelected(): boolean {
        return this.types.length > 0 ? this.types.every(el => el.selected) : false;
    }

    public getSelected(): Array<TypeEntry> {
        return this.types.filter(el => el.selected);
    }

    /**
     * Will set the TypeEntry which values are in valueArray to selected
     */
    public setSelected(valueArray: Array<string>): void {
        if (valueArray.includes('ALL')) {
            this.setAllSelected();
        } else {
            this.types = this.types.map(el => {
                el.selected = valueArray.includes(el.value.split(',')[0]);
                return el;
            });
        }
    }

    public setAllSelected(): void {
       this.types = this.types.map(el => {
            el.selected = true;
            return el;
        });
    }
}
