import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {Invoice} from '../models/invoices/invoice';
import {InvoiceHttpService} from '../services/invoice-http.service';

@Injectable()
export class InvoiceByIdResolver implements Resolve<Invoice> {

    constructor(private router: Router, private readonly invoiceService: InvoiceHttpService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Invoice> {
        return this.invoiceService.getById(route.paramMap.get('id')).pipe(
            catchError(_  => {
                this.router.navigate(['/boa/accounting']);
                return of(null);
            })
        );
    }
}
