import {CustomerAccount} from '../../../../../customers/models/customer-account';
import {CustomerAccountForTokenDelivery} from '../../../../models/invoices/billing-document';
import {FullUser} from '../../../../../users/models/full-user';

export class CustomerAccountTokenDeliveryInformation {
  constructor(readonly id: string,
              readonly name: string,
              readonly accountManager: FullUser) {
  }

  static from(
    customerAccount: CustomerAccount | CustomerAccountForTokenDelivery,
    accountManager: FullUser
  ): CustomerAccountTokenDeliveryInformation {
    return new CustomerAccountTokenDeliveryInformation(
      customerAccount.id,
      customerAccount.name,
      accountManager
    );
  }
}
