import {Component, Input, OnInit} from '@angular/core';
import {FullUser} from '../../../models/full-user';

@Component({
  selector: 'o-user-connection',
  templateUrl: './o-user-connection.component.html',
  styleUrls: ['./o-user-connection.component.scss']
})
export class OUserConnectionComponent implements OnInit {

    @Input()
    user: FullUser;

    @Input()
    mode: 'simple' | 'full' = 'full';

    constructor() { }

    ngOnInit(): void {
    }

}
