<div class="cell shrink grid-y align-center">
    <img class="cell shrink menuLogo" [routerLink]="'/boa/home'"
         alt="{{'menu.logo.illustration.alt' | translate}}"
         src="./assets/images/svg/logo/logo-white.svg"/>

    <div class="cell shrink grid-y align-center align-middle menu">

        <a class="cell shrink" *ngFor="let menu of menus" [queryParams]="menu.queries" [routerLink]="[menu.target]">
            <div class="menu-item" [class._active]="menu.active">
                <i [ngClass]="menu.iconPath"></i>
                <span class="menu-item-link">{{menu.label}}</span>
            </div>
        </a>
    </div>
</div>

<div class="cell shrink grid-x align-center menuAction">
    <a-button-icon class="cell shrink" (click)="disconnect()">
        <i class="fas fa-sign-out-alt"></i>
    </a-button-icon>
</div>
