import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class NotificationService {

    constructor(private toastrService: ToastrService,
                private translateService: TranslateService) {
    }

    success(messageKey: string): void {
        this.toastrService.success(this.translateService.instant(messageKey));
    }

}
