import {Article, ArticleType} from '../models/article';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {plainToClass} from 'class-transformer';
import {map} from 'rxjs/operators';
import {ArticleSession} from '../models/article-session';
import {Injectable} from '@angular/core';

@Injectable()
export class ArticleService {

    constructor(private http: HttpClient) {
    }

    private baseUrl = '/api/articles';

    private static getArticleType(articleType: ArticleType): any {
        switch (articleType) {
            case ArticleType.SESSION:
                return ArticleSession;
            default:
                return Article;
        }
    }

    find(): Observable<Array<Article>> {
        return this.http.get(`${this.baseUrl}`).pipe(
            map( (articles: Array<Article>) => articles.map(article => plainToClass(ArticleService.getArticleType(article.type), article)))
        );
    }

    search(searchQuery: string, types: Array<string>): Observable<Array<Article>> {
        return this.http.get(`${this.baseUrl}`, { params: {search: searchQuery ? searchQuery : '', types} }).pipe(
            map( (articles: Array<Article>) => articles.map(article => plainToClass(ArticleService.getArticleType(article.type), article)))
        );
    }

    update(article: Article): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/${article.id}`, article);
    }

    create(article: Article): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}`, article);
    }

    delete(article: Article): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${article.id}`);
    }
}
