import {Component, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'm-preview-button',
  templateUrl: './m-preview-button.component.html',
  styleUrls: ['./m-preview-button.component.scss']
})
export class MPreviewButtonComponent {

    @Input()
    public blobSrc: string;

    @Input()
    public fileName: string;

    constructor(private readonly http: HttpClient) {
    }

    openPdf(): void {
        this.http.get(this.blobSrc, {responseType: 'blob'}).subscribe(blob => {
            const downloadLink = window.URL.createObjectURL(new File([blob], this.fileName, {type: 'application/pdf'}));
            window.open(downloadLink);
        })
    }
}
