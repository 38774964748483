import { Injectable } from '@angular/core';
import {
    Resolve,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import {Credit} from '../models/invoices/credit';
import {CreditService} from '../services/credit.service';

@Injectable()
export class CreditResolver implements Resolve<Credit> {
    constructor(private creditService: CreditService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Credit> {
        return this.creditService.getCreditById(route.paramMap.get('id'));
    }
}
