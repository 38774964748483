<m-invoice-articles-list class="cell auto"
                         (lineEdited)="onLineEdited($event)"
                         (lineAdded)="onLineAdded($event)"
                         (lineDeleted)="onLineDeleted($event)"
                         [vatRate]="vatRate"
                         (dirty)="dirty.emit()"
                         [showTokenSessionHeaders]="false"
                         [articlesTypes]="[articleTypes.ANIMATION, articleTypes.FEE, articleTypes.WORKS]"
                         [lines]="billingDocument.articlesLines"
                         [areLinesReadonly]="areLinesReadonly()"
></m-invoice-articles-list>

<m-invoice-articles-total [discountTotal]="discountTotal"
                          [vatTotal]="vatTotal"
                          [grossTotal]="grossTotal"
                          [netTotal]="netTotal"
                          class="cell shrink"></m-invoice-articles-total>
