import {BaseObject} from '../../common/base-object';
import {Contact} from './contact';

export class Person extends BaseObject {
    firstName: string;
    lastName: string;
    contact: Contact;


    constructor(firstName: string, lastName: string, contact: Contact) {
        super();
        this.firstName = firstName;
        this.lastName = lastName;
        this.contact = contact;
    }

    static empty(): Person {
        return new Person('', '', new Contact());
    }
}
