export class Attachment {
    id: string;
    linkedObjectId: string;
    fileName: string;
    contentType: string;
    size: number;
    extension: string;

    constructor(id: string, linkedObjectId: string, fileName: string, contentType: string, size: number, extension: string) {
        this.id = id;
        this.linkedObjectId = linkedObjectId;
        this.fileName = fileName;
        this.contentType = contentType;
        this.size = size;
        this.extension = extension;
    }
}
