<div class="cell shrink addressMenu">
    <div class="addressMenu-item" [class._active]="contact === billingContact" (click)="switchBilling()">
        <i class="fas fa-file-invoice-dollar"></i>
    </div>
    <div class="addressMenu-item" [class._active]="contact === deliveryContact" (click)="switchDelivery()">
        <i class="fas fa-truck"></i>
    </div>
</div>

<div class="cell auto address">
    <span class="address-item">{{contact?.address?.name}}</span>
    <span class="address-item">{{contact?.address?.line1}}</span>
    <span class="address-item">{{contact?.address?.line2}}</span>
    <span class="address-item">{{contact?.address?.postalCode}} {{contact?.address?.city}}</span>
    <span class="address-item">{{contact?.address?.country}}</span>
</div>
