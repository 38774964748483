<a-button-back class="cell shrink">{{'common.action.back' | translate}}</a-button-back>

<spacer [size]="16"></spacer>

<div class="cell auto grid-y">
    <div class="cell shrink">
        <div class="grid-x grid-margin-x grid-margin-y">

            <!-- user infos -->
            <o-user-personal-information [user]="user"
                                         *ngIf="user.role === role.ANIMATOR && user.animatorType === animatorType.REGULAR else noTransferTokens"
                                         [showTransferTokens]="true"
                                         (transferTokensCliked)="onCustomerTransferClicked()"
                                         [ngClass]="userPersonalInfoEditMode ? 'cell phone-24 desktop-16' : 'cell phone-24 desktop-8'"
                                         (editModeLaunched)="onEditModeLaunched()"
                                         (editModeQuit)="onEditModeQuit()">
            </o-user-personal-information>

            <ng-template #noTransferTokens>
                <o-user-personal-information [user]="user"
                                             [showTransferTokens]="false"
                                             [ngClass]="userPersonalInfoEditMode ? 'cell phone-24 desktop-16' : 'cell phone-24 desktop-8'"
                                             (editModeLaunched)="onEditModeLaunched()"
                                             (editModeQuit)="onEditModeQuit()">
                </o-user-personal-information>
            </ng-template>

            <div class="cell auto" [class._userEdit]="userPersonalInfoEditMode">
                <div class="grid-x grid-margin-x grid-margin-y">
                    <o-user-connection class="cell"
                                       [user]="user"
                                       [mode]="userConnexionDisplayMode"
                                       [ngClass]="userPersonalInfoEditMode ? 'cell phone-24' : 'cell phone-12'">
                    </o-user-connection>
                    <div class="cell grid-y"
                         [ngClass]="userPersonalInfoEditMode ? 'cell phone-24' : 'cell phone-12'">
                        <o-customer-information *ngIf="user.role !== 'PLAYER'"
                                                [customer]="user?.customerAccount"
                                                class="cell auto"></o-customer-information>

                        <o-player-session *ngIf="user.role === 'PLAYER'" [playerId]="user.id" class="cell auto"></o-player-session>

                        <spacer size="16" *ngIf="user?.role === 'ANIMATOR'"></spacer>

                        <o-user-manager-information *ngIf="user?.role === 'ANIMATOR'"
                                                    class="cell auto"
                                                    [showTransferTokens]="false"
                                                    [manager]="user?.customerAccount.accountManager"
                                                    [customerAccount]="user?.customerAccount"></o-user-manager-information>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <spacer *ngIf="user?.role == 'ACCOUNT_MANAGER'" size="16"></spacer>
    <spacer size="16" *ngIf="user?.role == 'ANIMATOR'"></spacer>

    <div class="cell shrink">
        <div class="grid-x grid-margin-x align-right">
            <div class="oTokenTransfer" [ngClass]="showTransferWindow ? 'cell phone-24 desktop-12 _show' : '_hide'">
                <o-token-transfer
                    *ngIf="showTransferWindow"
                    [initialOrigin]="originUserBuffer" [initialTarget]="targetUserBuffer"
                    (operationSent)="onOperationSaved()"
                    (operationCanceled)="closeTransferWindow()"></o-token-transfer>
            </div>

            <div class="cell grid-y oTokenTransferRight" [ngClass]="showTransferWindow ? 'phone-24 desktop-12 _small' : 'phone-24 _full'">
                <o-animators-list *ngIf="user?.role == 'ACCOUNT_MANAGER'"
                                  (transferClicked)="onAnimatorTransferClicked($event)"
                                  [customerAccountId]="user?.customerAccount.id"
                                  [ngClass]="showTransferWindow ? 'cell auto' : 'cell shrink'">
                </o-animators-list>

                <o-user-last-operations *ngIf="user?.role == 'ANIMATOR' && user?.animatorType == 'REGULAR'"
                                        [user]="user" [ngClass]="(user?.role == 'ANIMATOR' && showTransferWindow) ? 'cell auto' : 'cell shrink'"></o-user-last-operations>
            </div>
        </div>
    </div>

    <spacer size="16" *ngIf="user?.role == 'ANIMATOR' || user?.role == 'ACCOUNT_MANAGER'"></spacer>

    <o-last-consumptions
        *ngIf="user?.role == 'ANIMATOR' || user?.role == 'ACCOUNT_MANAGER'"
        [userIds]="user?.role == 'ANIMATOR' ? [user.id] : null"
        [customerAccountId]="user.customerAccount.id"
        class="cell shrink">
    </o-last-consumptions>

    <spacer size="24"></spacer>
</div>
