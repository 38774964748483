import {Component, HostBinding, OnInit} from '@angular/core';
import {ArticleType} from '../../models/article';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BillingDocumentType} from '../../models/invoices/billing-document';
import {QuoteStatus} from '../../models/invoices/quote';
import {InvoiceStatus} from '../../models/invoices/invoice';
import {NavigationService} from '../../../common/services/navigation.service';

@Component({
  selector: 'boa-accounting-dashboard',
  templateUrl: './accounting-dashboard.component.html',
  styleUrls: ['./accounting-dashboard.component.scss']
})
export class AccountingDashboardComponent implements OnInit{

    @HostBinding('attr.class') class = 'cell auto grid-y';

    public defaultTypes: Params;

    public creationButtonLoading = false;

    billingType = BillingDocumentType;
    quoteStatus = QuoteStatus;
    invoiceStatus = InvoiceStatus;

    constructor(private router: Router,
                private navigationService: NavigationService,
                private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.defaultTypes = {
            types: [ArticleType.SESSION, ArticleType.FEE, ArticleType.WORKS, ArticleType.TOKEN, ArticleType.ANIMATION]
        };
    }

    goToQuoteCreationPage(): void {
        this.creationButtonLoading = true;
        this.router.navigate(['quotes', 'new'], {relativeTo: this.route});
    }

    onListButtonClicked(buttonId: number): void {
        if (buttonId === 0) {
            this.creationButtonLoading = true;
            this.navigationService.goToInvoiceCreationPage();
        }
    }
}
