<m-card-header [label]="listTitle">
    <a-dashboard-button-icon [queryParams]="{'billingTypes': type, 'billingStatus': status, sort: 'DATE:DESC', isDelivered: isDelivered}"
                             [routerLink]="'/boa/accounting/billings'"
                             [visibility]="'LOW'"
                             button
                             class="cell shrink">
        <i class="fas fa-list"></i>
    </a-dashboard-button-icon>
</m-card-header>

<spacer size="16"></spacer>

<m-invoices-list [billingDocuments]="billingDocuments"
                 [ngClass]="verticalLayout ? 'phone-up-1 medium-desktop-up-2' : 'phone-up-8 desktop-up-6'"></m-invoices-list>

<div class="emptyList" [class]="billingDocumentClass" *ngIf="billingDocuments?.length == 0">
    <img class="emptyList-img" [src]="'/assets/images/svg/accounting-dashboard/accounting-list-case-' + illustrationId + '.svg'">
    <span class="emptyList-message">{{emptyLabel}}</span>
</div>
