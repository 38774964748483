import {Component, Input, OnInit} from '@angular/core';
import {Operation} from '../../../models/operation';
import {OperationsService} from '../../../services/operations.service';
import {FullUser} from '../../../../users/models/full-user';
import {Router} from '@angular/router';
import {OperationDestinationType} from '../../../models/operation-destination';

@Component({
  selector: 'o-user-last-operations',
  templateUrl: './o-user-last-operations.component.html',
  styleUrls: ['./o-user-last-operations.component.scss']
})
export class OUserLastOperationsComponent  {
    // tslint:disable-next-line:variable-name
    private _userId: string;

    @Input()
    set user(user: FullUser) {
        if (user) {
            const type = user.role === 'ANIMATOR' ? 'ANIMATOR' : 'CUSTOMER';
            this.load(user.id, type);
            this._userId = user.id;
        }
    }

    get userId(): string {
        return this._userId;
    }

    operations: Array<Operation> = [];
    operationDestinationType = OperationDestinationType;

    private limit = 4;

    constructor(private operationService: OperationsService, private router: Router) { }

    private load(userId: string, type: 'ANIMATOR' | 'CUSTOMER'): void {
        this.operationService.getUserLasts(this.limit, userId, type).subscribe(data => {
            this.operations = data.map(operation => {
                operation.type = Operation.getOperationType(operation);
                operation.title = Operation.getOperationTitle(operation);
                return operation;
            });
        });
    }
}
