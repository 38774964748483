import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {LoginModule} from './login/login.module';
import {TranslateModule} from '@ngx-translate/core';
import {NewBoaCommonModule} from './common/common.module';
import {SharedModule} from '../shared/shared.module';
import {ContentModule} from './content/content.module';
import {HomeModule} from './home/home.module';
import {AccountingModule} from './accounting/accounting.module';
import {ConsumptionsModule} from './consumptions/consumptions.module';
import {UsersModule} from './users/users.module';
import {CustomersModule} from './customers/customers.module';
import {FormsModule} from '@angular/forms';
import {OperationsModule} from './operations/operations.module';
import {StatisticsModule} from './statistics/statistics.module';

@NgModule({
    declarations: [
    ],
    imports: [
        AccountingModule,
        CommonModule,
        ConsumptionsModule,
        ContentModule,
        CustomersModule,
        HomeModule,
        LoginModule,
        NewBoaCommonModule,
        OperationsModule,
        SharedModule,
        StatisticsModule,
        TranslateModule,
        UsersModule,
        FormsModule
    ],
    exports: [
    ],
    providers: [DatePipe]
})
export class NewBoaModule {
}
