import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs/internal/Observable';
import {Periodicity} from '../../newboa/common/models/scenario/periodicity';
import {filter, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Pipe({
    name: 'boaPeriod'
})
export class BoaPeriodPipe implements PipeTransform {

    private scenariiMap: Map<string, Periodicity> = new Map();

    constructor(private translateService: TranslateService) {
        this.scenariiMap.set('kalypso_quarterly', Periodicity.QUARTERLY);
        this.scenariiMap.set('kalypso_monthly', Periodicity.MONTHLY);
        this.scenariiMap.set('kalypso', Periodicity.MONTHLY);
        this.scenariiMap.set('kreafirm', Periodicity.MONTHLY);
        this.scenariiMap.set('kolumpi', Periodicity.MONTHLY);
        this.scenariiMap.set('keyflash', Periodicity.MONTHLY);
        this.scenariiMap.set('keeshirt', Periodicity.MONTHLY);
        this.scenariiMap.set('subakoua', Periodicity.MONTHLY);
        this.scenariiMap.set('bilboket', Periodicity.MONTHLY);
        this.scenariiMap.set('bilboket_lamp', Periodicity.MONTHLY);
        this.scenariiMap.set('kiwi', Periodicity.MONTHLY);
    }

    transform(value: unknown, ...args: string[]): Observable<string> {
        return of(value).pipe(
            filter(period => period != null),
            switchMap(period => this.getPeriodLabel(period, this.scenariiMap.get(args[0])))
        );
    }

    private getPeriodLabel(period, periodicity): Observable<string> {
        const year = Number.parseInt(period.substring(0, 2), 10);
        const numPeriod = Number.parseInt(period.substring(2, 4), 10);
        const monthKey = `common.${(periodicity ? periodicity.toLowerCase() : periodicity)}.${numPeriod}`;
        return this.translateService.get(['common.year', monthKey]).pipe(
            map(translations => translations['common.year'] + ' ' + year + ' - ' + translations[monthKey]));
    }

}
