<a-invoice-line-toggle class="lineCreator-item _toggleColumn" [class._description]="emptyLine"
                       (click)="emptyLine = !emptyLine"></a-invoice-line-toggle>

<a-invoice-line-form-creation *ngIf="!emptyLine"
                              class="lineCreator-item _lineForm"
                              [articles]="articles"
                              [showTokenSessionHeaders]="showTokenSessionHeaders"
                              [vatRate]="vatRate"
                              [linesFilters]="linesFilters"
                              (dirty)="dirty.emit()"
                              (lineAdded)="addInvoiceLine($event)">
</a-invoice-line-form-creation>

<a-invoice-empty-line-form *ngIf="emptyLine"
                           class="lineCreator-item _lineForm"
                           (lineAdded)="addInvoiceLine($event)"
                           [linesFilters]="linesFilters"
                           [showTokenSessionHeaders]="showTokenSessionHeaders">
</a-invoice-empty-line-form>
