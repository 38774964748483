import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {AnimatorType} from '../../../../newboa/users/models/user';
import {Role} from '../../../../newboa/users/models/role';
import {CustomerStatus} from '../../../../newboa/customers/models/customer-status';

@Component({
    selector: 'm-card-header',
    templateUrl: './m-card-header.component.html',
    styleUrls: ['./m-card-header.component.scss']
})
export class MCardHeaderComponent implements OnInit {

    @HostBinding('attr.class') class = 'grid-x align-middle align-justify';

    @Input()
    label = '';

    @Input()
    userType: Role | AnimatorType | CustomerStatus;

    constructor() {
    }

    ngOnInit(): void {
    }

}
