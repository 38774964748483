import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild
} from '@angular/core';
import {NgForm, NgModel} from '@angular/forms';
import {AnimatorType} from '../../../../newboa/users/models/user';
import {Role} from '../../../../newboa/users/models/role';
import {CustomerStatus} from '../../../../newboa/customers/models/customer-status';

@Component({
  selector: 'o-editable-block',
  templateUrl: './o-editable-block.component.html',
  styleUrls: ['./o-editable-block.component.scss']
})
export class OEditableBlockComponent implements OnInit, AfterViewInit {
    @ViewChild(NgForm, {static: false}) public form: NgForm;
    @ContentChildren(NgModel, {descendants: true}) public models: QueryList<NgModel>;

    @Input()
    validateDisabled: boolean;

    @Input()
    display: 'display' | 'edit' | 'creation' = 'display';

    @Input()
    headerLabel = '';

    @Input()
    showIcon = true;

    @Input()
    styleType: Role | AnimatorType | CustomerStatus;

    @Output()
    cancel = new EventEmitter<void>();

    @Output()
    save = new EventEmitter<void>();

    @Output()
    editModeLaunched = new EventEmitter<void>();

    @Input()
    updateLoading = false;

    @Input()
    creationLoading = false;

    constructor() { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        // a workaround from https://stackoverflow.com/questions/40843307/q-how-to-use-angular-2-template-form-with-ng-content
        if (this.form) {
            this.models.forEach(model => {
                this.form.addControl(model);
            });
        }
    }

    onEditClick(): void {
        this.display = 'edit';
        this.editModeLaunched.emit();
    }

    onCancelClick(): void {
        this.display = 'display';
        this.cancel.emit();
    }

    onSaveClick(): void {
        this.display = 'display';
        this.save.emit();
    }
}
