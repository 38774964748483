import {Component, Input, OnChanges} from '@angular/core';
import {Role} from '../../../../newboa/users/models/role';
import {AnimatorType} from '../../../../newboa/users/models/user';
import {CustomerStatus} from '../../../../newboa/customers/models/customer-status';
import {BillingDocumentType} from '../../../../newboa/accounting/models/invoices/billing-document';
import {OperationDestinationType} from '../../../../newboa/operations/models/operation-destination';

@Component({
    selector: 'td[m-icon],div[m-icon]',
    templateUrl: './m-icon.component.html',
    styleUrls: ['./m-icon.component.scss']
})
export class MIconComponent {
    public iconSrc = '';

    private icons = new Map<Role | AnimatorType | CustomerStatus | BillingDocumentType | OperationDestinationType, string>([
        [AnimatorType.REGULAR, '/assets/images/svg/roles/animators.svg'],
        [Role.ANIMATOR, '/assets/images/svg/roles/animators.svg'],
        [Role.PLAYER, '/assets/images/svg/roles/players.svg'],
        [Role.ACCOUNT_MANAGER, '/assets/images/svg/roles/managers.svg'],
        [AnimatorType.DEMONSTRATION, '/assets/images/svg/roles/demos.svg'],
        [CustomerStatus.ACTIVE, '/assets/images/svg/roles/customers.svg'],
        [CustomerStatus.PROSPECT, '/assets/images/svg/roles/prospects.svg'],
        [BillingDocumentType.QUOTE, '/assets/images/svg/roles/quotes.svg'],
        [BillingDocumentType.INVOICE, '/assets/images/svg/roles/invoices.svg'],
        [BillingDocumentType.CREDIT, '/assets/images/svg/roles/credit-notes.svg'],
        [OperationDestinationType.ARKHE, '/assets/images/svg/roles/arkhe.svg'],
        [OperationDestinationType.CUSTOMER, '/assets/images/svg/roles/customers.svg'],
        [OperationDestinationType.ANIMATOR, '/assets/images/svg/roles/animators.svg']
    ]);

    @Input()
    set type(type: CustomerStatus | AnimatorType | Role | BillingDocumentType | OperationDestinationType) {
        this.iconSrc = this.icons.get(type);
    }
}
