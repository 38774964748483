<div class="paymentBlock">
    <div class="paymentSteps">
        <div class="paymentSteps-item _paid">
            <div class="paymentSteps-item-img"></div>
            <span class="paymentSteps-item-label">Payé</span>
            <span class="paymentSteps-item-value"> {{this.paid | currency : 'EUR'}}</span>
        </div>
        <div class="paymentSteps-item _credit">
            <div class="paymentSteps-item-img"></div>
            <span class="paymentSteps-item-label">Avoir</span>
            <span class="paymentSteps-item-value">{{this.credit | currency : 'EUR'}}</span>
        </div>
        <div class="paymentSteps-item _unpaid">
            <div class="paymentSteps-item-img"></div>
            <span class="paymentSteps-item-label">Reste à payer</span>
            <span class="paymentSteps-item-value">{{this.due | currency : 'EUR'}}</span>
        </div>
    </div>
</div>

<div class="paymentBlock _right">
    <h2>Liste des paiements</h2>
    <form class="cell auto medium-desktop-12" #form="ngForm" (ngSubmit)="addPayment()" novalidate>
        <table class="table_info_payment">
            <thead>
            <tr>
                <th class="dateColumn">Date</th>
                <th class="amountColumn text-right">Montant</th>
                <th class="meansColumn">Moyen</th>
                <th class="table-button actionColumn"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="!isPaid">
                <td>
                    <a-date-picker
                        #paymentDate="ngModel"
                        [name]="'paymentDate'"
                        [(ngModel)]="paymentDateBuffer"
                        [required]="true"
                        class="white-form"></a-date-picker>
                </td>
                <td>
                    <a-input
                        #paymentAmount="ngModel"
                        [name]="'paymentAmount'"
                        [(ngModel)]="paymentAmountBuffer"
                        [inputType]="'number'"
                        [min]="0"
                        [boaNotZeroValidator]="true"
                        [max]="this.due"
                        [boaMax]="this.due"
                        [required]="true"
                        [boaNotBlank]="true"
                        [placeholder]="'0 €'"></a-input>
                </td>
                <td>
                    <a-select class="white-form"
                              #paymentMode="ngModel"
                              [name]="'paymentMode'"
                              [(ngModel)]="paymentModeBuffer"
                              [required]="true"
                              [placeholder]="'Mode de paiement'"
                              [bindLabel]="'label'"
                              [bindValue]="'value'"
                              [itemList]="paymentModes"></a-select>
                </td>
                <td>
                    <a-button-icon [type]="'submit'" [disabled]="!form.valid"><i class="fas fa-plus"></i>
                    </a-button-icon>
                </td>
            </tr>

            <tr *ngFor="let payment of genericPayments">
                <td>{{payment.date | boaDate}}</td>
                <td class="text-right">{{payment.amount
                    ? (payment.amount.value | currency : 'EUR')
                    : (payment.netAmount.value | currency : 'EUR')
                    }}</td>
                <td>{{'payment.modes.' + (payment.mode ? payment.mode : 'CREDIT') | translate}}</td>
                <td>
                    <a-button-icon *ngIf="isCredit(payment)" (click)="navigationService.goToCreditPage(payment.id)"><i
                        class="fas fa-eye"></i></a-button-icon>
                </td>
            </tr>
        </table>
    </form>
</div>
