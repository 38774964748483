import {Injectable} from '@angular/core';
import {MenuItem} from '../../../shared/models/menu-item';
import {Role} from '../../users/models/role';
import {CustomerStatus} from '../../customers/models/customer-status';
import {Scenarii} from '../models/scenario/scenarii';
import {Router} from '@angular/router';
import {BillingDocument, BillingDocumentType} from '../../accounting/models/invoices/billing-document';
import {Quote} from '../../accounting/models/invoices/quote';
import {Invoice} from '../../accounting/models/invoices/invoice';
import {Credit} from '../../accounting/models/invoices/credit';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private router: Router) {
    }

    public APPLICATION_MENUS: Array<MenuItem> = [
        new MenuItem('fas fa-file-invoice-dollar', '/boa/accounting', 'Comptabilité'),
        new MenuItem('fas fa-school', '/boa/customers', 'Gestion des clients', {
            status: [CustomerStatus.ACTIVE, CustomerStatus.PROSPECT]
        }),
        new MenuItem('fas fa-coins', '/boa/consumptions', 'Gestion des consommations', {
            scenarioNames: [].concat.apply([], [
                Scenarii.BILBOKET.split(','),
                Scenarii.KALYPSO.split(','),
                Scenarii.KEESHIRT,
                Scenarii.KEYFLASH,
                Scenarii.KIWI,
                Scenarii.KOLUMPI,
                Scenarii.SUBAKOUA,
                Scenarii.KREAFIRM
            ])
        }),
        new MenuItem('fas fa-user', '/boa/users', 'Gestion des utilisateurs', {
            roles: [Role.ACCOUNT_MANAGER, Role.ANIMATOR],
        })
    ];

    goToQuoteCreationPage(): void {
        this.router.navigate(['/boa', 'accounting', 'quotes', 'new']);
    }

    goToInvoiceCreationPage(): void {
        this.router.navigate(['/boa', 'accounting', 'invoices', 'new']);
    }

    goToProspectCreationPage(): void {
        this.router.navigate(['/boa', 'customers', 'new']);
    }

    goToQuotePage(id: string): void{
        this.router.navigate(['/boa', 'accounting', 'quotes', id]);
    }

    goToQuoteDraftPage(id: string): void{
        this.router.navigate(['/boa', 'accounting', 'quotes', 'drafts', id]);
    }

    goToInvoicePage(id: string): void {
        this.router.navigate(['/boa', 'accounting', 'invoices', id]);
    }

    goToInvoiceDraftPage(id: string): void {
        this.router.navigate(['/boa', 'accounting', 'invoices', 'drafts', id]);
    }

    goToBillingPage(billingDocument: BillingDocument): void {
        if (billingDocument instanceof Quote) {
            if (billingDocument.isInProgress()) {
                this.goToQuoteDraftPage(billingDocument.id);
            } else {
                this.goToQuotePage(billingDocument.id);
            }
        } else if (billingDocument instanceof Invoice) {
            if (billingDocument.isInProgress()) {
                this.goToInvoiceDraftPage(billingDocument.id);
            } else {
                this.goToInvoicePage(billingDocument.id);
            }
        }
    }

    goToCreditCreationPage(credit: Credit, creditNotToExceed: number): void {
        this.router.navigate(['/boa', 'accounting', 'credit', 'new'], {
            state: {
                credit,
                creditNotToExceed
            }
        });
    }

    goToCreditPage(creditId: string): void {
        this.router.navigate(['/boa', 'accounting', 'credit', creditId]);
    }

    goToUserPage(id: string): void {
        this.router.navigate(['/boa', 'users', id]);
    }

    goToDuplicatePage(billingDocument: BillingDocument): void {
        if (billingDocument.billingDocumentType === BillingDocumentType.INVOICE) {
            this.router.navigate(['/boa', 'accounting', 'invoices', 'duplication'], {
                state: {
                    duplicate: billingDocument
                }
            });
        } else if (billingDocument.billingDocumentType === BillingDocumentType.QUOTE) {
            this.router.navigate(['/boa', 'accounting', 'quotes', 'duplication'], {
                state: {
                    duplicate: billingDocument
                }
            });
        }
    }
}
