<m-card-header [label]="'accounting.stats.title'| translate">
</m-card-header>

<spacer size="16"></spacer>

<a-info-message>{{'accounting.stats.message' | translate}}</a-info-message>

<spacer size="16"></spacer>

<div class="grid-x grid-margin-x grid-margin-y phone-up-3 tablet-landscape-up-4 desktop-up-1 medium-desktop-up-2">
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.turnover.year.tooltip">
        <i icon class="fas fa-euro-sign"></i>
        <span label>{{'accounting.stats.turnover.year'|translate}}</span>
        <span value>{{accountingStats?.turnoverYear | number : '1.2'}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.turnover.month.tooltip">
        <i icon class="fas fa-euro-sign"></i>
        <span label>{{'accounting.stats.turnover.month'|translate}}</span>
        <span value>{{accountingStats?.turnoverMonth | number : '1.2'}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.customer.debt.tooltip">
        <i icon class="fas fa-euro-sign"></i>
        <span label>{{'accounting.stats.customer.debt'|translate}}</span>
        <span value>{{accountingStats?.customerDebtYear | number : '1.2'}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.potential.turnover.tooltip">
        <i icon class="fas fa-euro-sign"></i>
        <span label>{{'accounting.stats.potential.turnover'|translate}}</span>
        <span value>{{accountingStats?.potentialTurnoverYear | number : '1.2'}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.invoice.year.count.tooltip">
        <i icon class="fas fa-file-invoice-dollar"></i>
        <span label>{{'accounting.stats.invoice.year.count'|translate}}</span>
        <span value>{{accountingStats?.invoiceCountYear | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.invoiced.customer.month.count.tooltip">
        <i icon class="fas fa-user"></i>
        <span label>{{'accounting.stats.invoiced.customer.month.count'|translate}}</span>
        <span value>{{accountingStats?.customerInvoicedCountMonth | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.invoice.unpaid.year.tooltip">
        <i icon class="fas fa-search-dollar"></i>
        <span label>{{'accounting.stats.invoice.unpaid.year'|translate}}</span>
        <span value>{{accountingStats?.unpaidYear | number}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell" tooltipLabelKey="accounting.stats.token.average.cost.tooltip">
        <i icon class="fas fa-euro-sign"></i>
        <span label>{{'accounting.stats.token.average.cost'|translate}}</span>
        <span value>{{accountingStats?.tokenAverageCost | currency : 'EUR'}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
</div>
