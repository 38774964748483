<m-card-header [label]="'home.stats.title' | translate"></m-card-header>

<spacer size="16"></spacer>

<a-info-message>
    {{'home.stats.message' | translate}}
</a-info-message>

<spacer size="16"></spacer>

<div class="grid-x grid-margin-x grid-margin-y phone-up-2 desktop-up-1 medium-desktop-up-2">
    <a-big-indicator class="cell">
        <i icon class="fas fa-euro-sign"></i>
        <span label>{{'home.stats.turnover.year'|translate}}</span>
        <span value>{{generalStatistics?.turnoverYear | number : '.2'}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-euro-sign"></i>
        <span label>{{'home.stats.turnover.month'|translate}}</span>
        <span value>{{generalStatistics?.turnoverMonth | number : '.2'}} {{'common.grossAmount' | translate}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-coins"></i>
        <span label>{{'home.stats.delivered.tokens.year'|translate}}</span>
        <span value>{{generalStatistics?.deliveredTokensYear | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-coins"></i>
        <span label>{{'home.stats.delivered.tokens.month'|translate}}</span>
        <span value>{{generalStatistics?.deliveredTokensMonth | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-user"></i>
        <span label>{{'home.stats.customers.count'| translate}}</span>
        <span value>{{generalStatistics?.activeCustomersCount | number}}</span>
    </a-big-indicator>
    <a-big-indicator class="cell">
        <i icon class="fas fa-user"></i>
        <span label>{{'home.stats.prospects.count'| translate}}</span>
        <span value>{{generalStatistics?.prospectCustomersCount | number}}</span>
    </a-big-indicator>
</div>
