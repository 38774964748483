import {User} from '../../users/models/user';
import {CustomerAccount} from '../../customers/models/customer-account';
import {BaseEntity} from '../../../shared/models/base-entity';
import {NoteDocumentReferenceType} from './NoteDocumentReferenceType';
import {BillingDocument} from '../../accounting/models/invoices/billing-document';
import {FullUser} from '../../users/models/full-user';

export class Note extends BaseEntity {
    author: User;
    customerAccount: CustomerAccount;
    documentRef: string;
    description: string;
    priority: boolean;
    treated: boolean;
    documentReferenceType: NoteDocumentReferenceType;
    documentName: string;

    constructor(author: User,
                customerAccount: CustomerAccount,
                documentRef: string,
                description: string,
                priority: boolean,
                treated: boolean,
                documentReferenceType: NoteDocumentReferenceType,
                documentName: string
    ) {
        super();
        this.author = author;
        this.customerAccount = customerAccount;
        this.documentRef = documentRef;
        this.description = description;
        this.priority = priority;
        this.treated = treated;
        this.documentReferenceType = documentReferenceType;
        this.documentName = documentName;
    }

    static empty(): Note {
        return new Note(
            undefined,
            CustomerAccount.empty(),
            '',
            '',
            false,
            false,
            undefined,
            undefined
        );
    }
}

export class NoteLinkedToBillingDocument extends Note {
    linkedBilling: BillingDocument;

    constructor(author: User,
                customerAccount: CustomerAccount,
                documentRef: string,
                description: string,
                priority: boolean,
                treated: boolean,
                documentReferenceType: NoteDocumentReferenceType,
                documentName: string,
                linkedBilling: BillingDocument) {
        super(author, customerAccount, documentRef, description, priority, treated, documentReferenceType, documentName);
        this.linkedBilling = linkedBilling;
    }
}

export class NoteLinkedToUser extends Note
{
    linkedUser: FullUser;

    constructor(author: User,
                customerAccount: CustomerAccount,
                documentRef: string,
                description: string,
                priority: boolean,
                treated: boolean,
                documentReferenceType: NoteDocumentReferenceType,
                documentName: string,
                linkedUser: FullUser) {
        super(author, customerAccount, documentRef, description, priority, treated, documentReferenceType, documentName);
        this.linkedUser = linkedUser;
    }
}
