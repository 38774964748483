export class Team {
    teamNumber: number;
    name: string;
    languages: Array<string>;
    genericSessionRef: string;


    constructor(teamNumber: number, name: string, languages: Array<string>, genericSessionRef: string) {
        this.teamNumber = teamNumber;
        this.name = name;
        this.languages = languages;
        this.genericSessionRef = genericSessionRef;
    }
}
