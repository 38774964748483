import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class NoNullQueryInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
      let params = request.params;
      params.keys().map(el => {
          if (params.get(el) === null ||
              params.get(el) === 'null' ||
              params.get(el) === 'undefined' ||
              params.get(el) === undefined) {
              params = params.delete(el);
          }
      });
      return next.handle(request.clone({params}));
  }
}
