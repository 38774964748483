import { Injectable } from '@angular/core';
import {
    Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import {User} from '../models/user';
import {UserService} from '../services/user.service';
import {Result} from '../../common/models/shared/result';

@Injectable({
  providedIn: 'root'
})
export class LastConnectionResolver implements Resolve<Result<User>> {

    constructor(private userService: UserService) {
    }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Result<User>> {
    return this.userService.getLastConnections('ANIMATOR', null);
  }
}
