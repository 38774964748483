import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'boaNotNull'
})
export class BoaNotNullPipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(value: string, translateKey: string = null): string {
        if (value === null || value === undefined || value.trim() === '') {
            if (translateKey) {
                return this.translateService.instant(translateKey);
            } else {
                return '';
            }
        } else {
            return value;
        }
    }

}
