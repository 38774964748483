import {Component, forwardRef, Input, OnChanges} from '@angular/core';
import {UserService} from '../../../../users/services/user.service';
import {Role} from '../../../../users/models/role';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FullUser} from '../../../../users/models/full-user';

@Component({
    selector: 'm-customer-user-select',
    templateUrl: './m-customer-user-select.component.html',
    styleUrls: ['./m-customer-user-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => MCustomerUserSelectComponent),
        }
    ]
})
export class MCustomerUserSelectComponent implements OnChanges, ControlValueAccessor {

    constructor(private userService: UserService) { }

    @Input()
    customerAccountId: string;

    @Input()
    selectedUserId: string;

    users: Array<{ label: string, id: string, user: FullUser }>;
    selectedUser: FullUser;
    disabled: boolean;

    onChange = (newValue: FullUser) => {};
    onTouched = (newValue: FullUser) => {};

    ngOnChanges(): void {
        if (this.customerAccountId) {
            this.userService.getUsers(null, null, '', [Role.ANIMATOR, Role.ACCOUNT_MANAGER], '', this.customerAccountId)
                .subscribe(users => {
                    this.users = users.data.map(el => ({label: el.login, id: el.id, user: el}));
                });
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(user: FullUser): void {
        this.selectedUserId = user?.id;
    }

    onSelectedUserChange(newValue: string): void {
        this.selectedUserId = newValue;
        this.selectedUser = this.users.find(el => el.id === this.selectedUserId).user;
        this.onChange(this.selectedUser);
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
