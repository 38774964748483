<label [class._required]="required">
    <span class="formLabel" *ngIf="label">{{label}}</span>
        <dp-date-picker #picker
                [config]="config"
                [placeholder]="placeholder"
                [ngModel]="dateMoment"
                (open)="onBlur()"
                [displayDate]="dateMoment"
                tabindex="{{tabindex}}"
                (ngModelChange)="dateChange($event)">
        </dp-date-picker>
</label>
