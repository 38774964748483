import * as _ from 'lodash';

export class StringUtils {
    static normalize(str: string): string {
        return _.deburr(str.toLocaleLowerCase()
            .normalize('NFD')
            .replace(/[^a-z0-9]/gi, '')
            .trim());
    }
}
