<!-- Header -->
<ng-template [ngIf]="billingDocument">
    <div class="cell shrink">
        <div class="grid-x grid-margin-x grid-margin-y align-middle align-justify">

            <!-- go back button -->
            <a-button-back class="cell shrink _short" [title]="'common.action.back' | translate"></a-button-back>

            <!-- Title -->
            <ng-template [ngIf]="isInvoice">
                <div class="cell phone-24 desktop-auto">
                    <div class="grid-x align-middle invoiceHeader">
                        <h1 class="cell shrink invoiceHeader-title"
                            *ngIf="billingDocument.id else noBilling">{{'billings.type.INVOICE' | translate}}
                            n°{{billingDocument.getReference()}}</h1>
                        <ng-template #noBilling><h1
                            class="cell shrink invoiceHeader-title">{{'accounting.billings.create-invoice' | translate}}</h1>
                        </ng-template>
                        <a-badge class="cell shrink invoiceHeader-badge"
                                 [ngClass]="billingDocument.getStatus() || '_empty'">
                            {{'billings.status.' + billingDocument.getType() + '.' + billingDocument.getStatus() | translate}}
                        </a-badge>
                        <a-badge class="cell shrink invoiceHeader-badge" *ngIf="billingDocument.id"
                                 [class._undelivered]="!billingDocument.isDelivered">
                            {{'billings.delivery.status.' + billingDocument.getType() + '.' + billingDocument.isDelivered | translate}}
                        </a-badge>
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="isCredit">
                <div class="cell phone-24 desktop-auto" *ngIf="!billingDocument.id">
                    <div class="grid-x align-middle invoiceHeader">
                        <h1 class="cell shrink invoiceHeader-title">{{'billings.type.CREDIT.creation' | translate}}</h1>
                    </div>
                </div>

                <div class="cell phone-24 desktop-auto" *ngIf="billingDocument.id">
                    <div class="grid-x align-middle invoiceHeader">
                        <h1 class="cell shrink invoiceHeader-title">{{'billings.type.CREDIT' | translate}}
                            n° {{billingDocument.getReference()}}</h1>
                    </div>
                </div>
            </ng-template>
            <ng-template [ngIf]="isQuote">
                <div class="cell phone-24 desktop-auto">
                    <div class="grid-x align-middle invoiceHeader">
                        <h1 class="cell shrink invoiceHeader-title"
                            *ngIf="billingDocument.id else noBilling">{{'billings.type.QUOTE' | translate}}
                            n°{{billingDocument.getReference()}}</h1>
                        <ng-template #noBilling><h1
                            class="cell shrink invoiceHeader-title">{{'accounting.billings.create-quote' | translate}}</h1>
                        </ng-template>
                        <a-badge class="cell shrink invoiceHeader-badge"
                                 [ngClass]="billingDocument.getStatus() || '_empty'">
                            {{'billings.status.' + billingDocument.getType() + '.' + billingDocument.getStatus() | translate}}
                        </a-badge>
                        <a-badge class="cell shrink invoiceHeader-badge" *ngIf="billingDocument.id"
                                 [class._undelivered]="!billingDocument.isDelivered">
                            {{'billings.delivery.status.' + billingDocument.getType() + '.' + billingDocument.isDelivered | translate}}
                        </a-badge>
                    </div>
                </div>
            </ng-template>

            <!-- Buttons -->
            <div class="cell shrink">
                <div class="grid-x grid-margin-x">
                    <m-preview-button [blobSrc]="pdfLink"
                                       [fileName]="pdfFileName"
                                       *ngIf="billingDocument.id"
                                       class="cell shrink">{{'invoice-detail.download' | translate}}
                    </m-preview-button>

                    <a-button-default (click)="saveQuoteDraft()"
                                      [visibility]="'HIGH'"
                                      [disabled]="generalInfoInvalid || customersInvalid || addressesInvalid
                                || (!generalInfoDirty && !customersDirty && !addressesDirty && !linesDirty && !discountDirty)"
                                      [loading]="saveQuoteDraftLoading"
                                      *ngIf="$any(billingDocument).id && $any(billingDocument).quoteStatus === invoiceStatus.IN_PROGRESS"
                                      class="cell shrink">
                        <i class="fas fa-save"></i>{{'invoice-detail.draft.save' | translate}}
                    </a-button-default>

                    <a-button-default (click)="saveDraft()"
                                      [visibility]="'HIGH'"
                                      [disabled]="generalInfoInvalid || customersInvalid || addressesInvalid
                                        || (!generalInfoDirty && !customersDirty && !addressesDirty && !linesDirty && !discountDirty)"
                                      [loading]="saveInvoiceDraftLoading"
                                      *ngIf="$any(billingDocument).id && $any(billingDocument).invoiceStatus === invoiceStatus.IN_PROGRESS"
                                      class="cell shrink">
                        <i class="fas fa-save"></i> {{'invoice-detail.draft.save' | translate}}
                    </a-button-default>

                    <a-split-button class="cell shrink"
                                    [disabled]="isSplitButtonDisabled()"
                                    [loading]="isSplitButtonLoading()"
                                    [visibility]="'HIGH'"
                                    (listButtonClicked)="onListButtonClicked($event)"
                                    (clicked)="onSplitButtonMainButtonClick()"
                                    [buttonsList]="buttonsList"
                                    *ngIf="!(isCredit && billingDocument.id)">
                        <!-- No action available on credit note detail when it has already been created -->
                        <ng-container *ngIf="isMainButtonCreateCredit()">
                            <i class="fas fa-save"></i> {{'invoice-detail.credit.create' | translate}}
                        </ng-container>
                        <ng-container *ngIf="isMainButtonCreateQuote() || isMainButtonCreateInvoiceDraft()">
                            <i class="fas fa-save"></i> {{'common.action.create' | translate}}
                        </ng-container>
                        <ng-container *ngIf="isMainButtonAcceptQuote()">
                            <i class="fas fa-check-circle"></i> {{'invoice-detail.quote.draft.accept' | translate}}
                        </ng-container>

                        <ng-container *ngIf="isMainButtonDuplicate()">
                            <i class="fas fa-copy"></i> {{'invoice-detail.button.duplicate' | translate}}
                        </ng-container>

                        <ng-container *ngIf="isMainButtonSendDraft()">
                            <i class="fas fa-paper-plane"></i> {{'invoice-detail.invoice.draft.send' | translate}}
                        </ng-container>

                        <ng-container *ngIf="isMainButtonTokenTransfer()">
                            <i class="fas fa-exchange-alt"></i> {{'invoice-detail.transfer.tokens' | translate}}
                        </ng-container>

                        <ng-container *ngIf="isMainButtonResendChorusPro()">
                            <i class="fas fa-paper-plane"></i> {{'invoice-detail.invoice.resend.chorus.pro' | translate}}
                        </ng-container>
                    </a-split-button>
                </div>
            </div>
        </div>
        <spacer *ngIf="billingDocument.chorusProSendFailError" size="16"></spacer>
        <div *ngIf="billingDocument.chorusProSendFailError"
             class="invoiceAlert _error">
            <i class="invoiceAlert-icon fas fa-times-circle"></i>
            <b class="invoiceAlert-title">{{'invoice-detail.chorus-pro.error' | translate}} : </b>
            {{billingDocument.chorusProSendFailError}}
        </div>
    </div>

    <ng-container *ngIf="!billingDocument.id && isCredit">
        <spacer *ngIf="netTotal > creditNotToExceed" size="16"></spacer>
        <div class="invoiceAlert" *ngIf="netTotal > creditNotToExceed">
            <i class="invoiceAlert-icon fas fa-exclamation-circle"></i> {{'invoice-detail.credit.net-exceeded.error' | translate}}
        </div>
        <spacer *ngIf="netTotal > creditNotToExceed" size="16"></spacer>
    </ng-container>

    <spacer *ngIf="!netTotal || !creditNotToExceed || netTotal <= creditNotToExceed" size="24"></spacer>

    <o-invoice-info-payment *ngIf="isInvoice
                        && !this.billingDocument.isInProgress()"
                            [invoiceId]="billingDocument.id"
                            [invoiceCustomerId]="billingDocument.customer.id"
                            [netAmount]="billingDocument.netAmount"
                            class="cell shrink">
    </o-invoice-info-payment>

    <spacer size="16" *ngIf="isInvoice && !this.billingDocument.isInProgress()"></spacer>

    <div class="cell shrink">
        <div class="grid-x grid-margin-x grid-margin-y">
            <div class="cell phone-24 desktop-10 invoiceInformation-container">
                <div class="grid-x grid-margin-x grid-margin-y invoiceInformation-content">
                    <o-invoice-header-informations
                        [(invalid)]="generalInfoInvalid"
                        [(dirty)]="generalInfoDirty"
                        [billingDocument]="billingDocument"
                        class="cell desktop-24 big-desktop-12">
                    </o-invoice-header-informations>
                    <o-invoice-customers-info
                        [(invalid)]="customersInvalid"
                        [(dirty)]="customersDirty"
                        [disabled]="(billingDocument.id && !billingDocument.isInProgress()) || isCredit"
                        [customerAccount]="customerAccount"
                        (customerAccountChange)="refreshInvoiceCustomer($event)"
                        [allCustomersCode]="allCustomersCode"
                        [allCustomersName]="allCustomersName"
                        (vatRateChange)="applyNetForAll($event)"
                        [billingDocument]="billingDocument"
                        [chorusProSettings]="chorusProSettings"
                        class="cell desktop-24 big-desktop-12">
                    </o-invoice-customers-info>
                </div>
            </div>
            <o-invoice-header-addresses
                [(invalid)]="addressesInvalid"
                [(dirty)]="addressesDirty"
                [disabled]="!billingDocument.isInProgress() || isCredit"
                [(deliveryAddress)]="billingDocument.customer.deliveryAddress"
                [(billingAddress)]="billingDocument.customer.billingAddress"
                [customerName]="billingDocument.customer.name"
                class="cell phone-24 desktop-auto">
            </o-invoice-header-addresses>
        </div>
    </div>

    <spacer size="16"></spacer>

    <o-invoice-token-articles
        (dirty)="linesDirty = true"
        [vatRate]="billingDocument.metadata.vatRate"
        [tokenGrossTotal]="billingDocument.billingDocumentAdditionalInformation.tokenGrossAmount"
        [discountTotal]="billingDocument.discountAmount.value"
        [billingDocument]="billingDocument"
        [discountPercentage]="billingDocument.billingDocumentAdditionalInformation.tokenDiscountPercentage"
        [trustValues]="billingDocument.trustValues"
        (discountPercentageChange)="onDiscountPercentageChange($event)"
        (lineEdited)="editTokenArticleLine($event)"
        (lineAdded)="addTokenArticleLine($event)"
        (lineDeleted)="deleteTokenArticleLine($event)"
        (resetClick)="onResetPercentageClick()"
        class="cell shrink"></o-invoice-token-articles>

    <spacer size="16"></spacer>

    <o-invoice-articles
        (dirty)="linesDirty = true"
        [vatRate]="billingDocument.metadata.vatRate"
        [billingDocument]="billingDocument"
        [vatTotal]="vatTotal"
        [netTotal]="netTotal"
        [grossTotal]="grossTotal"
        [discountTotal]="billingDocument.discountAmount.value"
        (lineEdited)="editArticleLine($event)"
        (lineAdded)="addArticleLine($event)"
        (lineDeleted)="deleteArticleLine($event)"
        class="cell shrink"></o-invoice-articles>

    <spacer size="16"></spacer>

    <div class="cell shrink" *ngIf="!isCredit">
        <div class="grid-x grid-margin-x align-stretch">
            <o-linked-documents id="linkedDocuments"
                                class="cell phone-12"
                                *ngIf="billingDocument.id"
                                (fileEmitted)="refreshFileList()"
                                (fileDeleted)="refreshFileList()"
                                [linkedDocuments]="linkedDocuments"
                                [billingDocument]="billingDocument">
            </o-linked-documents>
            <o-linked-transfers class="cell phone-12"
                                [billingDocument]="billingDocument"
                                [class.hide]="!billingDocument.id || displayTokenTransfer">
            </o-linked-transfers>
            <m-invoice-token-transfer id="tokenTransfer"
                                      class="cell phone-12"
                                      [hide]="!displayTokenTransfer"
                                      [initialOrigin]="connectedUser"
                                      [customerAccount]="customerAccount"
                                      [customerAccountManager]="customerAccountManager"
                                      [initialBillingDocument]="billingDocument"
                                      (operationSent)="onTokenTransfer()"
                                      (operationCanceled)="hideTokenTransferBlock()">
            </m-invoice-token-transfer>
        </div>
    </div>

    <spacer size="24"></spacer>
</ng-template>

<!-- Confirmation popups -->
<a-popup-component
    *ngIf="documentType === 'QUOTE'"
    (confirmed)="acceptQuote()">
    <span modalTitle>{{'invoice-detail.popup.quote.title' | translate}}</span>
    <span modalContent [innerHTML]="'invoice-detail.popup.quote.content' | translate"></span>
</a-popup-component>


<a-popup-component
    *ngIf="documentType === 'INVOICE'"
    (confirmed)="sendDraft()">
    <span modalTitle>{{'invoice-detail.popup.invoice.title' | translate}}</span>
    <span modalContent [innerHTML]="'invoice-detail.popup.invoice.content' | translate"></span>
</a-popup-component>


<a-popup-component
    *ngIf="documentType === 'CREDIT'"
    (confirmed)="createCredit()">
    <span modalTitle>{{'invoice-detail.popup.credit.title' | translate}}</span>
    <span modalContent [innerHTML]="'invoice-detail.popup.credit.content' | translate"></span>
</a-popup-component>

