import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NgSelectComponent} from '@ng-select/ng-select';

const VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ASelectComponent),
    multi: true
};

@Component({
    selector: 'a-select',
    templateUrl: './a-select.component.html',
    styleUrls: ['./a-select.component.scss'],
    providers: [VALUE_ACCESSOR]
})
export class ASelectComponent implements OnInit, ControlValueAccessor {
    @ViewChild('ngSelectComponent')
    public ngSelect: NgSelectComponent;

    public model: string;

    @Input()
    public label: string;

    @Input()
    public dropdown: string;

    @Input()
    public bindLabel: string;

    // tslint:disable-next-line:variable-name
    private _bindValue: string;
    get bindValue(): string {
        return this._bindValue;
    }

    @Input()
    set bindValue(bindValue: string) {
        if (bindValue != null) {
            this._bindValue = bindValue;
            this.compareWith = (a, b) => (a[bindValue] === b);
        }
    }

    @Input()
    public searchable = true;
    @Input()
    public clearable: boolean;
    @Input()
    public placeholder: string;
    @Input()
    public itemList: Array<any> = [];
    @Input()
    public selectOnTab: boolean;
    @Input()
    public tabIndex: number;
    @Input()
    addTag: boolean | ((term: string) => any | Promise<any>) = false;
    @Input()
    isOpen: boolean = null;
    @Input()
    multiple = false;

    @Input()
    required = false;

    isDisabled = false;

    private onNgChange: (item: string) => void;
    private onNgTouched: () => void;

    constructor() {
    }

    @Input()
    public compareWith: (item1: any, item2: any) => boolean = (a, b) => (a === b)

    registerOnChange(fn: any): void {
        this.onNgChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onNgTouched = fn;
    }

    writeValue(item: string): void {
        this.model = item;
    }

    onChange(item: string): void {
        this.model = item;
        this.onNgChange(item);
    }

    ngOnInit(): void {
    }

    setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    onTouched(): void {
        this.onNgTouched();
    }
}


