<div [hidden]="hide">
  <o-invoice-select-customer-account-for-token-delivery
    *ngIf="currentState === 'SELECT_CUSTOMER'"
    [chargedCustomerAccount]="customerAccount"
    [additionalCustomerAccountsForTokenDelivery]="initialBillingDocument.billingDocumentAdditionalInformation.additionalCustomerAccountsForTokenDelivery"
    (customerAccountSelectedForTokenDelivery)="onCustomerAccountSelect($event)"
    (operationCanceled)="onSelectionCancel()">
  </o-invoice-select-customer-account-for-token-delivery>
  <o-token-transfer id="tokenTransfer"
                    class="cell phone-12"
                    [class.hide]="currentState !== 'TRANSFER_TOKEN'"
                    [initialOrigin]="initialOrigin"
                    [initialTarget]="tokenTransferTarget"
                    [initialBillingDocument]="initialBillingDocument"
                    (operationSent)="onOperationSent($event)"
                    (operationCanceled)="onOperationCancel()">
  </o-token-transfer>
</div>
