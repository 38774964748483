import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import {User} from '../../../users/models/user';
import {Authentication} from '../../models/authentication';

@Component({
    selector: 'boa-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    host: {class: 'grid-y align-center grid-padding-x'}
})
export class LoginComponent implements OnInit {

    public authentication: Authentication;
    public authenticationError = false;
    public loaderDisplayed = false;

    @ViewChild('loginForm')
    loginForm: NgForm;
    connectionLoading = false;

    constructor(private authenticationService: AuthenticationService, private router: Router) {
    }

    ngOnInit(): void {
        this.authentication = new Authentication();
    }

    public authenticate(form: NgForm): void {
        if (form.valid) {
            this.loaderDisplayed = true;
            this.connectionLoading = true;
            this.authenticationService.authenticate(this.authentication)
                .subscribe(
                    () => {
                        this.connectionLoading = false;
                        this.router.navigate(['/boa/home']);
                    },
                    _ => {
                        this.connectionLoading = false;
                        this.authenticationError = true;
                        this.loaderDisplayed = false;
                    }
                );
        }
    }

    userChange(user: User): void {
        this.authenticationError = false;
    }

    public onPasswordChange(): void {
        this.authenticationError = false;
    }
}
