import {Injectable} from '@angular/core';
import {catchError, filter, tap} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';
import {ConnectedUser} from '../models/connected-user';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, of} from 'rxjs';
import {FullUser} from '../../newboa/users/models/full-user';
import {Router} from '@angular/router';
import {LogoutService} from '../../shared/services/logout.service';

@Injectable()
export class ConnectedUserService {

  private baseUrl = '/api/sessions';
  private userEvents: BehaviorSubject<ConnectedUser> = new BehaviorSubject<ConnectedUser>(undefined);

  constructor(private http: HttpClient, private router: Router, private logoutService: LogoutService) {
    this.userEvents.pipe(filter(it => it !== undefined))
      .subscribe();
    this.logoutService.logoutEvents.subscribe(event => {
          if (event === 'logout') {
              this.logout();
          }
      });
  }

  public getCurrentUser(): Observable<ConnectedUser> {
    return this.userEvents;
  }

  public updateCurrentUser(user: ConnectedUser): void {
    this.userEvents.next(user);
  }

  public fetchCurrent(): Observable<ConnectedUser> {
    return this.http.get(this.baseUrl + '/current')
      .pipe(
          catchError(error => {
             console.log(error);
             return of(this.router.navigate(['/login']));
          }),
          tap((user: ConnectedUser) => {
          if (this.userEvents.getValue() === undefined) {
            this.userEvents.next(user);
          }
        }));
  }

    getCurrentFullUser(): Observable<FullUser> {
        return this.http.get<FullUser>(this.baseUrl + '/current/full');
    }

    public logout(): void {
        this.http.delete(`${this.baseUrl}/current`).subscribe(() => {
            this.updateCurrentUser(undefined);
            this.router.navigate(['/login']);
        });
    }
}
