import { Directive, ElementRef } from '@angular/core';
import { Reveal } from 'foundation-sites';
import * as $ from 'jquery';

@Directive({
  selector: '[modal]'
})
export class ModalDirective {

    private popup: FoundationSites.Reveal;

    constructor(private readonly el: ElementRef) {}

    public open(): void {
        this.popup = new Reveal($(this.el.nativeElement));
        this.popup.open();
    }

    public close(): void {
        if (this.popup) {
            this.popup.close();
        }
    }
}
