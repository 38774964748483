import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/internal/Observable';
import {Customer} from '../models/invoices/customer';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {

    private baseUrlCustomers = '/api/invoice/customers';

    constructor(private http: HttpClient) { }

    getCustomers(name: string, limit: number = null, offset: number = null): Observable<Array<Customer>> {
        return this.http.get(this.baseUrlCustomers, {
            params: {
                name,
                limit,
                offset
            }
        }).pipe(
            map(customers => plainToClass(Customer, customers as [object]))
        );
    }
}
