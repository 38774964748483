import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {VatRate} from '../models/invoices/vat-rate';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {
    private readonly baseUrlCompany = '/api/company'

    constructor(private readonly http: HttpClient) {}

    getVatRates(): Observable<VatRate[]> {
        return this.http.get<VatRate[]>(`${this.baseUrlCompany}/vatRates`);
    }
}
