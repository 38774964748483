<ng-container *ngIf="!editMode">
    <div class="cell auto tokenLine-infos">
        <a class="tokenLine-infos-name" [routerLink]="['/boa', 'customers', customerAccountId]">
            {{customerAccountName}}
        </a>
        <span class="tokenLine-infos-code">{{'invoice.customer.accounting.reference' | translate}}
            : {{customerAccountAccountingReference}}</span>
    </div>
    <div class="cell shrink grid-x grid-margin-x tokenLine-actions">
        <a-button-icon class="cell shrink" (click)="onEditModeToggle()" *ngIf="!disabled"><i
            class="fas fa-pencil-alt"></i></a-button-icon>
        <a-button-icon class="cell shrink" [visibility]="'LOW'" (click)="delete()" *ngIf="!disabled"><i
            class="fas fa-trash"></i>
        </a-button-icon>
    </div>
</ng-container>
<ng-container *ngIf="editMode">
    <div class="cell auto grid-x grid-margin-x">
        <a-select class="cell phone-12 big-desktop-24"
                  [label]="'invoice.customer.name' | translate"
                  [itemList]="availableCustomersByName"
                  [bindValue]="'value'"
                  [name]="'customer-name'"
                  [(ngModel)]="customerAccountId"></a-select>

        <a-select class="cell phone-12 big-desktop-24"
                  [label]="'invoice.customer.accounting.reference' | translate"
                  [itemList]="availableCustomersByAccountingReference"
                  [bindValue]="'value'"
                  [name]="'customer-code'"
                  [(ngModel)]="customerAccountId"></a-select>
    </div>

    <div class="cell shrink grid-x grid-margin-x tokenLine-actions">
        <a-button-icon class="cell shrink" (click)="onUpdate()">
            <i class="fas fa-check"></i>
        </a-button-icon>
        <a-button-icon class="cell shrink" [visibility]="'LOW'" (click)="onCancel()"><i class="fas fa-times"></i>
        </a-button-icon>
    </div>
</ng-container>
