import {Component, Input, OnInit} from '@angular/core';
import {Operation} from '../../../../models/operation';
import {OperationDestinationType} from '../../../../models/operation-destination';

@Component({
    selector: 'm-operation',
    templateUrl: './m-operation.component.html',
    styleUrls: ['./m-operation.component.scss']
})
export class MOperationComponent implements OnInit {

    @Input()
    operation: Operation;
    operationDestinationType = OperationDestinationType;
    type: string;
    title: string;

    constructor() { }

    ngOnInit(): void {
        this.type = Operation.getOperationType(this.operation);
        this.title = Operation.getOperationTitle(this.operation);
    }

}
