<h2>{{'invoice.select.customer.account.for.token.delivery.title' | translate}}</h2>

<spacer [size]="16"></spacer>

<div class="grid-x grid-margin-x grid-margin-y">
    <a-invoice-customer-account-for-token-delivery class="cell phone-12"
                                                   [isChargedCustomer]="true"
                                                   [customerAccountTokenDeliveryInformation]="chargedCustomerAccountTokenDeliveryInformation"
                                                   (customerAccountSelectedForTokenDelivery)="onSelect($event)">
    </a-invoice-customer-account-for-token-delivery>

    <ng-container
        *ngFor="let customerAccountTokenDeliveryInformation of additionalCustomerAccountsTokenDeliveryInformation">
        <a-invoice-customer-account-for-token-delivery class="cell phone-12"
                                                       [isChargedCustomer]="false"
                                                       [customerAccountTokenDeliveryInformation]="customerAccountTokenDeliveryInformation"
                                                       (customerAccountSelectedForTokenDelivery)="onSelect($event)">
        </a-invoice-customer-account-for-token-delivery>
    </ng-container>
</div>

<spacer [size]="16"></spacer>

<div class="grid-x align-right">
    <a-button-cancel class="cell shrink" (click)="onCancel()">{{'common.action.cancel' | translate}}</a-button-cancel>
</div>
