import {Component, HostBinding, OnInit} from '@angular/core';
import {BillingDocumentType} from '../../../accounting/models/invoices/billing-document';
import {InvoiceStatus} from '../../../accounting/models/invoices/invoice';

@Component({
    selector: 'boa-consumption-dashboard',
    templateUrl: './consumption-dashboard.component.html',
    styleUrls: ['./consumption-dashboard.component.scss']
})
export class ConsumptionDashboardComponent implements OnInit {

    @HostBinding('attr.class') class = 'cell auto grid-y';
    billingDocumentType = BillingDocumentType;
    invoiceStatus = InvoiceStatus;

    constructor() {
    }

    ngOnInit(): void {
    }

}
