import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserService} from '../../../services/user.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectEntry} from '../../../../common/models/select/select-entry';

@Component({
  selector: 'a-select-user',
  templateUrl: './a-select-user.component.html',
  styleUrls: ['./a-select-user.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ASelectUserComponent),
            multi: true
        }
    ]
})
export class ASelectUserComponent implements OnChanges, OnInit, ControlValueAccessor {

    @Input()
    required: boolean;

    @Input()
    disabled: boolean;

    @Input()
    label: string;

    @Input()
    customerAccountRefFilter: string;

    userId: string;
    allUsersName: SelectEntry[];

    onChange = (userId: string) => {};
    onTouched = (userId: string) => {};

    constructor(private userService: UserService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.customerAccountRefFilter) {
            this.userId = undefined;
            this.userService
                .getUsersMap(
                    null,
                    null,
                    null,
                    ['ALL'],
                    null,
                    this.customerAccountRefFilter,
                    'name_map'
                )
                .subscribe(res => {
                    this.allUsersName = res;
                });
        }
    }

    ngOnInit(): void {
        if (!this.customerAccountRefFilter) {
            console.log('[a-select-user] - no customer ref filter given - no search was made');
            return;
        }
        this.userService
            .getUsersMap(
                null,
                null,
                null,
                ['ALL'],
                null,
                this.customerAccountRefFilter,
                'name_map'
            )
            .subscribe(res => {
                this.allUsersName = res;
            });
    }

    onUserIdChange(newUserId: string): void {
        this.userId = newUserId;
        this.onChange(this.userId);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: string): void {
        this.userId = obj;
    }
}
