<div class="_tooltipLabel">
    {{tableHeader?.label}}
    <div class="sortable" *ngIf="tableHeader?.sortable">
        <a-button-sort class="sorting" *ngIf="!tableHeader.sortActive || (!ascActive && !descActive)"
                       (click)="sort('ASC')">
            <i class="fas fa-sort sortable-buttonIcon"></i>
        </a-button-sort>
        <a-button-sort [active]="ascActive && tableHeader.sortActive"
                       [class._active]="ascActive && tableHeader.sortActive"
                       *ngIf="ascActive && tableHeader.sortActive"
                       [direction]="'ASC'" (click)="sort('DESC')">
            <i class="fas fa-sort-up sortable-buttonIcon"></i>
        </a-button-sort>
        <a-button-sort [active]="descActive && tableHeader.sortActive"
                       [class._active]="descActive && tableHeader.sortActive"
                       *ngIf="descActive && tableHeader.sortActive"
                       [direction]="'DESC'" (click)="sort(null)">
            <i class="fas fa-sort-down sortable-buttonIcon"></i>
        </a-button-sort>
    </div>

    <div class="_totalTokens" *ngIf="tableHeader.tooltipText || tableHeader.tooltipData">
        {{tableHeader.tooltipText}}{{tableHeader.tooltipData | number}}
    </div>
</div>
