import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CustomerAccount} from '../../../../models/customer-account';
import {CustomerAccountContact} from '../../../../../users/models/contact';
import {CustomerAccountTypes} from '../../../../models/customer-account-types';
import {AddressType} from '../../../../../common/models/address/addressType';
import {
    OCustomerAccountInformationEditComponent
} from '../../edit/o-customer-account-information-edit/o-customer-account-information-edit.component';
import {Address} from '../../../../../common/models/address/address';
import {CustomerAccountService} from '../../../../services/customer-account.service';
import {DEFAULT_VAT_RATE_VALUE} from '../../../../../accounting/models/invoices/vat-rate';

type CustomersAccountsWithSameSiretErrorState = 'NONE' | 'ONE' | 'MULTIPLE';

@Component({
    selector: 'o-customer-account-creation',
    templateUrl: './o-customer-account-creation.component.html',
    styleUrls: ['./o-customer-account-creation.component.scss']
})
export class OCustomerAccountCreationComponent implements OnInit {
    customersAccountsWithSameSiretErrorState: CustomersAccountsWithSameSiretErrorState = 'NONE';
    customerAccountsWithSameSiret: CustomerAccount[] = [];

    constructor(private translateService: TranslateService, private fb: FormBuilder,
                private readonly customerAccountService: CustomerAccountService) {
    }

    @Input()
    customerAccount: CustomerAccount;

    @Output()
    cancel = new EventEmitter<void>();

    @Output()
    create = new EventEmitter<void>();

    chorusProActivated = false;
    billingContact: CustomerAccountContact;
    typePointer = Object.values(CustomerAccountTypes).map(el => {
        return {value: el, label: this.translateService.instant('customers.type.' + el)};
    });

    // form model //
    customerForm = this.fb.group({
        accountingReference: ['', [Validators.required]],
        abbreviation: [''],
        type: [undefined, [Validators.required]],
        name: ['', [Validators.required]],
        subtitle: [''],
        siretNumber: [''],
        sirenNumber: [{value: '', disabled: true}],
        vatRatio: [DEFAULT_VAT_RATE_VALUE],
        vatNumber: [{value: ''}],
        chorusProActivated: [false]
    });

    ngOnInit(): void {
        if (this.customerAccount) {
            this.billingContact = this.customerAccount.getContact(AddressType.BILLING);
            this.customerAccountService.getCurrentCustomerAccountNumber().subscribe(newReference => {
                this.customerAccount.accountingReference = newReference;
                this.fillForm(this.customerAccount);
                this.customerForm.valueChanges.subscribe();
            });
        }
    }

    onSiretChange(): void {
        this.customerForm.controls.sirenNumber.setValue(
            this.customerForm.controls.siretNumber.value.substring(0, 9)
        );
        const siren = this.customerForm.controls.sirenNumber.value;
        if (siren.length === 9 && !isNaN(parseInt(siren, 10))) {
            const vatKey = (12 + 3 * (parseInt(siren, 10) % 97)) % 97;
            this.customerForm.controls.vatNumber.setValue('FR' + vatKey + siren);
        } else {
            this.customerForm.controls.vatNumber.reset();
        }
    }

    onCancelClick(): void {
        this.cancel.emit();
    }

    onCreateClick(): void {
        this.fillCustomer(this.customerAccount);
        this.create.emit();
    }

    fillCustomer(customerAccount: CustomerAccount): void {
        // main data
        customerAccount.accountingReference = this.customerForm.get('accountingReference').value;
        customerAccount.abbreviation = this.customerForm.get('abbreviation').value;
        customerAccount.type = this.customerForm.get('type').value;
        customerAccount.name = this.customerForm.get('name').value;
        customerAccount.subtitle = this.customerForm.get('subtitle').value;
        customerAccount.siretNumber = this.customerForm.get('siretNumber').value;
        customerAccount.sirenNumber = this.customerForm.get('sirenNumber').value;
        customerAccount.vatRatio = this.customerForm.get('vatRatio').value;
        customerAccount.vatNumber = this.customerForm.get('vatNumber').value;
        customerAccount.chorusProActivated = this.customerForm.get('chorusProActivated').value;

        // billing contact
        const billingContactForm = this.customerForm.get('billingContact');
        this.billingContact.service = billingContactForm.get('service').value;
        this.billingContact.firstName = billingContactForm.get('firstName').value;
        this.billingContact.lastName = billingContactForm.get('lastName').value;
        this.billingContact.mail = billingContactForm.get('mail').value;
        this.billingContact.mobilePhone = billingContactForm.get('mobilePhone').value;

        // address
        const deliveryAddressForm = this.customerForm.get('address').get('delivery');
        const billingAddressForm = this.customerForm.get('address').get('billing');
        const deliveryContact = customerAccount.getContact(AddressType.DELIVERY);
        const billingContact = customerAccount.getContact(AddressType.BILLING);

        OCustomerAccountInformationEditComponent.fillAddress(deliveryContact, { ...deliveryAddressForm.value} as Address);
        OCustomerAccountInformationEditComponent.fillAddress(billingContact, { ...billingAddressForm.value} as Address);
    }

    fillForm(customerAccount: CustomerAccount): void {
        this.customerForm.patchValue(customerAccount);
    }

    onCustomerAccountNameChange(): void {
        const customerAccountName = this.customerForm.controls.name.value;
        const deliveryAddressNameControl = this.customerForm.get('address').get('delivery').get('name');

        if (deliveryAddressNameControl.value === '') {
            deliveryAddressNameControl.setValue(customerAccountName);
        }

        const billingAddressNameControl = this.customerForm.get('address').get('billing').get('name');

        if (billingAddressNameControl.value === '') {
            billingAddressNameControl.setValue(customerAccountName);
        }
    }

    findCustomerAccountsWithSameSiret(): void {
        this.customerAccountService.findCustomerAccountsBySiret(this.customerForm.get('siretNumber').value, [])
            .subscribe(customerAccountsWithSameSiret => {
                this.customerAccountsWithSameSiret = customerAccountsWithSameSiret;
                this.customersAccountsWithSameSiretErrorState = OCustomerAccountCreationComponent.computeNewSameSiretErrorState(
                    customerAccountsWithSameSiret
                );

            });
    }

    private static computeNewSameSiretErrorState(customerAccountsWithSameSiret: CustomerAccount[]): CustomersAccountsWithSameSiretErrorState {
        if (customerAccountsWithSameSiret.length === 1) {
            return 'ONE';
        }
        if (customerAccountsWithSameSiret.length > 0) {
            return 'MULTIPLE';
        }

        return 'NONE';
    }
}
