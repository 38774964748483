export class TableHeader{
    constructor(public label: string,
                public sortable: boolean,
                public value: string,
                public clazz: string,
                public sortActive: boolean = false,
                public sortDirection: 'ASC' | 'DESC' | null = null,
                public tooltipText: string = null,
                public tooltipData: number = null) {
    }
}
